import axios from "axios";
import apiRoutes from "../constants/apiRoutes";
import getDefaultHeaders from "../features/utils";
import {getTokenString} from "./auth";
import {jsonToBulletins} from "./mappers";
import {authCheckAndRedirect} from "../utilities/functions";

export const fetchBulletins = async () => {
  try {
    const { data } = await axios.get(apiRoutes.V1.BULLETIN.LIST, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: Bulletin[] = jsonToBulletins(data);
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};
