import React, { useState } from "react";
import { useSelector } from "react-redux";
import { volumesState } from "../../../features/volumes/volumesSlice";
import Spinner from "../../Utils/Spinner";
import AttachModal from "./AttachModal";

const VolumeAttach = (props: { volume: HCVolume }) => {
  const { volume } = props;
  const [modal, setModal] = useState(false);

  const {volumesPendings} = useSelector(volumesState)

  const actionKey = "volumeAttach" + volume.id;

  return (
    <>
      <button
        type="button"
        className="btn btn-success btn-sm mr-1 ml-1 mt-1"
        onClick={() => setModal(true)}
        disabled={volumesPendings.includes(actionKey)}
        style={{direction: "ltr"}}
      >
        Attach {volumesPendings.includes(actionKey) && <Spinner />}
      </button>
      {modal && <AttachModal volume={volume} dismiss={() => setModal(false)} />}
    </>
  );
};

export default VolumeAttach;
