import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import {
  createServer,
  createServerSnapshot,
  deleteServer,
  disableServerRescue,
  enableServerRescue,
  fetchServerState,
  requestServerAction,
  requestServerConsole,
  resetServerPassword,
} from "../api/servers";
import { APIQueryName } from "../constants/queries";
import { ServerAction } from "../constants/servers";
import { addNotification } from "../features/baseSlice";
import { addServerAction } from "../features/userServers/serverSlice";

export const useCreateHCServer = () => {
  const dispatch = useDispatch();

  return useMutation(
    (req: HCServerCreateRequest) => {
      return createServer(req);
    },
    {
      onSuccess: (result) => {
        dispatch(
          addNotification({
            type: "success",
            title: "ایجاد سرور",
            text: "سرور با موفقیت ایجاد شد.",
          })
        );
      },
      onError: (error: any) => {
        dispatch(
          addNotification({
            type: "error",
            title: "ایجاد سرور",
            text: error.response.data
              ? error.response.data.message
              : "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};

export const useHCServerUpdateState = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (slug: string) => {
      return fetchServerState(slug);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(
          APIQueryName.SERVER_DETAILS + result.slug,
          (details: any) => {
            if (!details) {
              return;
            }
            details.rescueMode = result.rescueMode;
            details.status = result.status;
            details.creditLocked = result.creditLocked;
            details.ready = result.ready;
            details.locked = result.locked;
            details.activeTime = result.activeTime;
            return details;
          }
        );
      },
    }
  );
};

export const useHCServerEnableRescue = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (req: HCServerEnableRescueRequest) => {
      return enableServerRescue(req);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(
          APIQueryName.SERVER_DETAILS + variables.slug,
          (details: any) => {
            if (!details) {
              return;
            }
            details.rescueMode = true;
            return details;
          }
        );
        dispatch(
          addNotification({
            type: "success",
            title: "فعال سازی Rescue Mode",
            text: "حالت Rescue با موفقیت فعال شد.",
          })
        );
      },
      onError: (error: any) => {
        dispatch(
          addNotification({
            type: "error",
            title: "فعال سازی Rescue Mode",
            text: error.response.data
              ? error.response.data.message
              : "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};

export const useHCServerDisableRescue = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (slug: string) => {
      return disableServerRescue(slug);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(
          APIQueryName.SERVER_DETAILS + variables,
          (details: any) => {
            if (!details) {
              return;
            }
            details.rescueMode = false;
            return details;
          }
        );
        dispatch(
          addNotification({
            type: "success",
            title: "غیر فعال سازی Rescue Mode",
            text: "حالت Rescue با موفقیت غیر فعال شد.",
          })
        );
      },
      onError: (error: any) => {
        dispatch(
          addNotification({
            type: "error",
            title: "غیر فعال سازی Rescue Mode",
            text: error.response.data
              ? error.response.data.message
              : "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};

export const useHCServerResetPassword = () => {
  const dispatch = useDispatch();

  return useMutation(
    (slug: string) => {
      return resetServerPassword(slug);
    },
    {
      onSuccess: (result, variables, context) => {
        dispatch(
          addNotification({
            type: "success",
            title: "تغییر رمز عبور",
            text: "رمز عبور سرور با موفقیت ریست شد.",
          })
        );
      },
      onError: (error: any) => {
        dispatch(
          addNotification({
            type: "error",
            title: "تغییر رمز عبور",
            text: error.response.data
              ? error.response.data.message
              : "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};

export const useHCServerConsoleRequest = () => {
  const dispatch = useDispatch();

  return useMutation(
    (slug: string) => {
      return requestServerConsole(slug);
    },
    {
      onSuccess: (result, variables, context) => {
        dispatch(
          addNotification({
            type: "success",
            title: "درخواست کنسول",
            text: "درخواست کنسول با موفقیت انجام شد.",
          })
        );
      },
      onError: (error: any) => {
        dispatch(
          addNotification({
            type: "error",
            title: "درخواست کنسول",
            text: error.response.data
              ? error.response.data.message
              : "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};

export const useHCServerAction = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (action: {
      serverSlug: string;
      actionType: ServerAction;
      actionKey: string;
      reqData?: any;
    }) => {
      return requestServerAction({
        actionType: action.actionType,
        serverSlug: action.serverSlug,
        reqData: action.reqData,
      });
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(
          APIQueryName.SERVER_DETAILS + variables.serverSlug,
          (details: any) => {
            if (!details) {
              return;
            }
            details.status = result.serverStatus;
            details.locked = result.serverLocked;
            return details;
          }
        );
        dispatch(
          addServerAction({
            actionId: result.id,
            actionType: variables.actionType,
            actionKey: variables.actionKey,
          })
        );
        dispatch(
          addNotification({
            type: "info",
            title: "عملیات در حال انجام",
            text: "درخواست شما دریافت و در حال اجراست!",
          })
        );
      },
      onError: (error: any) => {
        dispatch(
          addNotification({
            type: "error",
            title: "عملیات روی سرور",
            text: error.response.data
              ? error.response.data.message
              : "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};

export const useHCCreateSnapshot = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (req: HCServerSnapshotRequest) => {
      return createServerSnapshot(req);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(
          APIQueryName.SERVER_DETAILS + variables.slug,
          (details: any) => {
            if (!details) {
              return;
            }
            if (variables.backup) {
              details.backups.push(result);
            } else {
              details.snapshots.push(result);
            }
            return details;
          }
        );
        dispatch(
          addNotification({
            type: "success",
            title: variables.backup ? "ایجاد بک آپ" : "ایجاد اسنپ شات",
            text: variables.backup
              ? "بک آپ با موفقیت ایجاد شد."
              : "اسنپ شات با موفقیت ایجاد شد.",
          })
        );
      },
      onError: (result, variables, context) => {
        dispatch(
          addNotification({
            type: "error",
            title: variables.backup ? "ایجاد بک آپ" : "ایجاد اسنپ شات",
            text: "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};

export const useHCServerDelete = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (req: {slug: string, projectId?: number}) => {
      return deleteServer(req.slug);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(APIQueryName.PROJECT_SERVERS, (servers: any) => {
          if (!servers) {
            return;
          }
          return servers.filter((server: any) => server.slug !== variables.slug);
        });
        if (variables.projectId) {
          queryClient.setQueryData(
            APIQueryName.PROJECT_SERVERS + variables.projectId,
            (servers: any) => {
              if (!servers) {
                return;
              }
              return servers.filter((server: any) => server.slug !== variables.slug);
            }
          );
        }
        dispatch(
          addNotification({
            type: "success",
            title: "حذف سرور",
            text: "سرور با موفقیت حذف شد.",
          })
        );
      },
      onError: () => {
        dispatch(
          addNotification({
            type: "error",
            title: "حذف سرور",
            text: "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};
