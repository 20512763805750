import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchProject } from "../../api/projects";
import ProjectTabs from "../../components/Projects/ProjectTabs";
import SSHKeysCreate from "../../components/SSHKeys/SSHKeyCreate";
import SSHKeysTable from "../../components/SSHKeys/SSHKeysTable";
import { APIQueryName } from "../../constants/queries";
import Content from "../../containers/Content";

const ProjectSSHKeys = () => {
  const params = useParams();
  const projectId = params.projectId ? parseInt(params.projectId) : undefined;

  const { data } = useQuery(
    APIQueryName.PROJECT_DETAILS + projectId,
    () => fetchProject(projectId || 0),
    {
      staleTime: 120000,
      retry: 1,
    }
  );

  return (
    <Content>
      {data && (
        <div className="mt-3 mb-4">
          <h4 className="text-center">{data.name}</h4>
        </div>
      )}
      <ProjectTabs projectId={projectId || 0} />
      <SSHKeysCreate projectId={projectId} />
      <div className="card">
        <div className="card-header header-elements-inline">
          <h6 className="card-title">لیست کلید های این پروژه</h6>
        </div>
        <SSHKeysTable projectId={projectId} />
      </div>
    </Content>
  );
};

export default ProjectSSHKeys;
