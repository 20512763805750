import React, { useState, useEffect } from "react";
import {
  emailValidator,
  passwordValidator,
  phoneNumberValidator,
  postalCodeValidator,
} from "../../utilities/validators";
import { isEmailValid, isPhoneNumberValid, userRegister } from "../../api/auth";

const RegisterForm = (props: any) => {
  // Email
  const [emailCheckTimeOut, setEmailCheckTimeOut] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [emailChecked, setEmailChecked] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [phoneExists, setPhoneExists] = useState(false);

  // Password
  const [userPassword, setUserPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordChecked, setPasswordChecked] = useState(false);
  const [passwordCheckTimeOut, setPasswordCheckTimeOut] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const [passwordRepeatValid, setPasswordRepeatValid] = useState(false);
  const [passwordRepeatChecked, setPasswordRepeatChecked] = useState(false);
  const [passwordRepeatCheckTimeOut, setPasswordRepeatCheckTimeOut] =
    useState<ReturnType<typeof setTimeout> | null>(null);

  // Phone Number
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);
  const [phoneNumberChecked, setPhoneNumberChecked] = useState(false);
  const [phoneNumberCheckTimeOut, setPhoneNumberCheckTimeOut] =
    useState<ReturnType<typeof setTimeout> | null>(null);

  // Postal Code
  const [postalCodeValid, setPostalCodeValid] = useState(false);
  const [postalCodeChecked, setPostalCodeChecked] = useState(false);
  const [postalCodeCheckTimeOut, setPostalCodeCheckTimeOut] =
    useState<ReturnType<typeof setTimeout> | null>(null);

  // Address
  const [addressValid, setAddressValid] = useState(false);
  const [addressChecked, setAddressChecked] = useState(false);
  const [addressCheckTimeOut, setAddressCheckTimeOut] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  // First Name & Last Name
  const [firstNameValid, setFirstNameValid] = useState(false);
  const [lastNameValid, setLastNameValid] = useState(false);

  // Server Error
  const [serverError, setServerError] = useState(false);

  const formSubmitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const {
      registerEmail,
      registerPassword,
      registerAddress,
      registerPostalCode,
      registerPhoneNumber,
      registerRepeatPassword,
      registerFirstName,
      registerLastName,
    } = evt.currentTarget;

    if (!emailChecked) {
      emailHandler(registerEmail.value);
    }
    if (!passwordChecked) {
      passwordHandler(registerPassword.value);
    }
    if (!addressChecked) {
      addressHandler(registerAddress.value);
    }
    if (!postalCodeChecked) {
      postalCodeHandler(registerPostalCode.value);
    }
    if (!phoneNumberChecked) {
      phoneNumberHandler(registerPhoneNumber.value);
    }
    if (!passwordRepeatChecked) {
      passwordRepeatHandler(registerRepeatPassword.value);
    }
    if (registerFirstName.value.length < 1 || registerLastName.value < 0) {
      setFirstNameValid(registerFirstName.value.length > 0);
      setLastNameValid(registerLastName.value.value > 0);
      return;
    }
    if (
      !emailValid ||
      !passwordValid ||
      !passwordRepeatValid ||
      !postalCodeValid ||
      !phoneNumberValid ||
      !addressValid
    ) {
      return;
    }

    userRegister(
      registerEmail.value,
      registerPassword.value,
      registerAddress.value,
      registerPhoneNumber.value,
      registerPostalCode.value,
      registerFirstName.value,
      registerLastName.value
    ).then((response) => {
      if (response.success) {
        props.tabSwitcher(null, "login", true);
        return;
      } else {
        if (response.error) {
          if (response.error === "server" || response.error === "unknown") {
            setServerError(true);
          }
        }
      }
    });
  };

  const emailChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const email = evt.target.value;
    if (emailCheckTimeOut) {
      clearTimeout(emailCheckTimeOut);
    }
    const timeout = setTimeout(() => emailHandler(email), 300);
    setEmailCheckTimeOut(timeout);
  };

  const emailHandler = (email: string) => {
    const valid = emailValidator(email);
    if (valid) {
      isEmailValid(email).then((response) => {
        setEmailValid(response);
        setEmailExists(!response);
      });
    } else {
      setEmailValid(false);
    }
    setEmailChecked(true);
  };

  const passwordChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const password = evt.target.value;
    if (passwordCheckTimeOut) {
      clearTimeout(passwordCheckTimeOut);
    }
    setPasswordCheckTimeOut(setTimeout(() => passwordHandler(password), 300));
  };

  const passwordRepeatChangeHandler = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    evt.preventDefault();
    const password = evt.target.value;
    if (passwordRepeatCheckTimeOut) {
      clearTimeout(passwordRepeatCheckTimeOut);
    }
    setPasswordRepeatCheckTimeOut(
      setTimeout(() => passwordRepeatHandler(password), 300)
    );
  };

  const passwordHandler = (password: string) => {
    setUserPassword(password);
    const { status } = passwordValidator(password);
    setPasswordValid(status);
    setPasswordChecked(true);
  };

  const passwordRepeatHandler = (password: string) => {
    setPasswordRepeatValid(password === userPassword);
    setPasswordRepeatChecked(true);
  };

  const phoneNumberChangeHandler = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    evt.preventDefault();
    const phoneNumber = evt.target.value;
    if (phoneNumberCheckTimeOut) {
      clearTimeout(phoneNumberCheckTimeOut);
    }
    setPhoneNumberCheckTimeOut(
      setTimeout(() => phoneNumberHandler(phoneNumber), 300)
    );
  };

  const phoneNumberHandler = (phoneNumber: string) => {
    const valid = phoneNumberValidator(phoneNumber);
    if (valid) {
      isPhoneNumberValid(phoneNumber).then((response) => {
        setPhoneNumberValid(response);
        setPhoneExists(!response);
      });
    } else {
      setPhoneNumberValid(false);
    }
    setPhoneNumberChecked(true);
  };

  const postalCodeChangeHandler = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    evt.preventDefault();
    const postalCode = evt.target.value;
    if (postalCodeCheckTimeOut) {
      clearTimeout(postalCodeCheckTimeOut);
    }
    setPostalCodeCheckTimeOut(
      setTimeout(() => postalCodeHandler(postalCode), 300)
    );
  };

  const postalCodeHandler = (postalCode: string) => {
    setPostalCodeChecked(true);
    setPostalCodeValid(postalCodeValidator(postalCode));
  };

  const addressChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const address = evt.target.value;
    if (addressCheckTimeOut) {
      clearTimeout(addressCheckTimeOut);
    }
    setAddressCheckTimeOut(setTimeout(() => addressHandler(address), 300));
  };

  const addressHandler = (address: string) => {
    setAddressChecked(true);
    setAddressValid(address.length > 20);
  };

  useEffect(() => {
    return () => {
      emailCheckTimeOut && clearTimeout(emailCheckTimeOut);
      phoneNumberCheckTimeOut && clearTimeout(phoneNumberCheckTimeOut);
      postalCodeCheckTimeOut && clearTimeout(postalCodeCheckTimeOut);
      addressCheckTimeOut && clearTimeout(addressCheckTimeOut);
    };
  }, [
    emailCheckTimeOut,
    phoneNumberCheckTimeOut,
    postalCodeCheckTimeOut,
    addressCheckTimeOut,
  ]);

  return (
    <form onSubmit={formSubmitHandler}>
      <div className="form-group form-group-feedback form-group-feedback-right">
        <input
          required
          type="email"
          name="registerEmail"
          id="registerEmail"
          className="form-control"
          placeholder=" ایمیل "
          onChange={emailChangeHandler}
        />
        <div className="form-control-feedback">
          <i className="icon-mention text-muted" />
        </div>
        {!emailValid && emailChecked && (
          <span className="form-text text-danger">
            <i className="icon-cancel-circle2 mr-2" />
            {emailExists
              ? " ایمیل وارد شده قبلا ثبت شده است!"
              : " ایمیل وارد شده معتبر نمیباشد!"}
          </span>
        )}
      </div>

      <div className="form-group form-group-feedback form-group-feedback-right">
        <input
          required
          type="password"
          name="registerPassword"
          id="registerPassword"
          className="form-control"
          placeholder=" رمز عبور "
          onChange={passwordChangeHandler}
        />
        <div className="form-control-feedback">
          <i className="icon-user-lock text-muted" />
        </div>
        {!passwordValid && passwordChecked && (
          <span className="form-text text-danger">
            <i className="icon-cancel-circle2 mr-2" /> رمز انتخاب معتبر نمباشد.
            طول رمز باید بین ۸ تا ۳۰ و شامل حروف انگلیسی همراه با اعداد باشد!
          </span>
        )}
      </div>

      <div className="form-group form-group-feedback form-group-feedback-right">
        <input
          required
          type="password"
          name="registerRepeatPassword"
          id="registerRepeatPassword"
          className="form-control"
          placeholder=" تکرار رمز عبور "
          onChange={passwordRepeatChangeHandler}
        />
        <div className="form-control-feedback">
          <i className="icon-user-lock text-muted" />
        </div>
        {!passwordRepeatValid && passwordRepeatChecked && (
          <span className="form-text text-danger">
            <i className="icon-cancel-circle2 mr-2" /> رمز های وارد شده برابر
            نمیباشند!
          </span>
        )}
      </div>

      <div className="form-group form-group-feedback form-group-feedback-right">
        <input
          required
          type="text"
          name="registerFirstName"
          id="registerFirstName"
          className="form-control"
          placeholder=" نام "
          onChange={(evt) => setFirstNameValid(evt.target.value.length > 0)}
        />
        <div className="form-control-feedback">
          <i className="icon-mobile text-muted" />
        </div>
        {!firstNameValid && (
          <span className="form-text text-danger">
            <i className="icon-cancel-circle2 mr-2" /> نام نمیتواند خالی باشد!
          </span>
        )}
      </div>

      <div className="form-group form-group-feedback form-group-feedback-right">
        <input
          required
          type="text"
          name="registerLastName"
          id="registerLastName"
          className="form-control"
          placeholder=" نام خانوادگی "
          onChange={(evt) => setLastNameValid(evt.target.value.length > 0)}
        />
        <div className="form-control-feedback">
          <i className="icon-mobile text-muted" />
        </div>
        {!lastNameValid && (
          <span className="form-text text-danger">
            <i className="icon-cancel-circle2 mr-2" /> نام خانوادگی نمیتواند
            خالی باشد!
          </span>
        )}
      </div>

      <div className="form-group form-group-feedback form-group-feedback-right">
        <input
          required
          type="text"
          name="registerPhoneNumber"
          id="registerPhoneNumber"
          className="form-control"
          placeholder=" شماره موبایل: ۰۹۱۱۲۲۲۲۳۳۴۴ "
          onChange={phoneNumberChangeHandler}
        />
        <div className="form-control-feedback">
          <i className="icon-mobile text-muted" />
        </div>
        {!phoneNumberValid && phoneNumberChecked && (
          <span className="form-text text-danger">
            <i className="icon-cancel-circle2 mr-2" />
            {phoneExists
              ? " شماره موبایل وارد شده قبلا ثبت شده است!"
              : " شماره موبایل وارد شده معتبر نمیباشد!"}
          </span>
        )}
      </div>

      <div className="form-group form-group-feedback form-group-feedback-right">
        <input
          required
          type="number"
          name="registerPostalCode"
          id="registerPostalCode"
          className="form-control"
          placeholder=" کد پستی ۱۰ رقمی "
          onChange={postalCodeChangeHandler}
        />
        <div className="form-control-feedback">
          <i className="icon-mailbox text-muted" />
        </div>
        {!postalCodeValid && postalCodeChecked && (
          <span className="form-text text-danger">
            <i className="icon-cancel-circle2 mr-2" /> کد پستی معتبر نمیباشد!
          </span>
        )}
      </div>

      <div className="form-group form-group-feedback form-group-feedback-right">
        <input
          required
          type="text"
          name="registerAddress"
          id="registerAddress"
          className="form-control"
          placeholder=" آدرس محل سکونت یا محل کار "
          onChange={addressChangeHandler}
        />
        <div className="form-control-feedback">
          <i className="icon-home2 text-muted" />
        </div>
        {!addressValid && addressChecked && (
          <span className="form-text text-danger">
            <i className="icon-cancel-circle2 mr-2" /> لطفا آدرس کامل وارد کنید!
          </span>
        )}
      </div>

      <div className="form-group text-center text-muted content-divider">
        <span className="px-2"> قوانین و شرایط </span>
      </div>

      <div className="form-group">
        <span className="form-text text-center text-muted">
          ثبت نام یا وارد شدن و استفاده از سرویس ها به منزله مطالعه کامل و پذیرش{" "}
          <a href="https://arvix.cloud/tos">شرایط سرویس دهی و قوانین</a> است.
        </span>
      </div>

      <button type="submit" className="btn bg-dark btn-block">
        {" "}
        ساخت{" "}
      </button>

      <br />
      {serverError && (
        <div className="formt-group">
          <span className="form-text text-danger text-center">
            <i className="icon-cancel-circle2 mr-2" /> متاسفانه مشکلی از سمت ما
            وجود دارد. لطفا بعدا امتحان کنید!
          </span>
          <br />
        </div>
      )}
    </form>
  );
};

export default RegisterForm;
