import React from "react";

const TicketRowPrioritySpan = (props: { priority: TicketPriority }) => {
  switch (props.priority.title) {
    case "فوری":
      return (
        <span className="badge badge-danger" style={{ fontSize: "80%" }}>
          {props.priority.title}
        </span>
      );
    case "بالا":
      return (
        <span className="badge badge-warning" style={{ fontSize: "80%" }}>
          {props.priority.title}
        </span>
      );
    case "متوسط":
      return (
        <span className="badge badge-yellow" style={{ fontSize: "80%" }}>
          {props.priority.title}
        </span>
      );
    case "کم":
      return (
        <span className="badge badge-primary" style={{ fontSize: "80%" }}>
          {props.priority.title}
        </span>
      );
    case "خیلی کم":
      return (
        <span className="badge bg-purple" style={{ fontSize: "80%" }}>
          {props.priority.title}
        </span>
      );
    default:
      return (
        <span className="badge badge-info" style={{ fontSize: "80%" }}>
          {props.priority.title}
        </span>
      );
  }
};

export default TicketRowPrioritySpan;
