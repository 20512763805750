export function emailValidator(email: string) {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function passwordValidator(password: string) {
  let status = false;
  let lowerCase = false;
  let upperCase = false;
  let num = false;
  let len = false;

  let lowerCaseLetters = /[a-z]/g;
  let upperCaseLetters = /[A-Z]/g;
  let numbers = /[0-9]/g;

  if (password.length > 7 && password.length < 31) {
    len = true;
  }

  password.match(lowerCaseLetters) ? (lowerCase = true) : (lowerCase = false);
  password.match(upperCaseLetters) ? (upperCase = true) : (upperCase = false);
  password.match(numbers) ? (num = true) : (num = false);

  (lowerCase || upperCase) && num && len ? (status = true) : (status = false);

  return {
    status,
    lowerCase,
    upperCase,
    num,
    len,
  };
}

export function usernameValidator(username: string) {
  if (username.length > 3 && username.length < 25) {
    let re = /^[a-z0-9]+$/i;
    return re.test(username);
  }
  return false;
}

export function phoneNumberValidator(phoneNumber: string) {
  let validNumber = phoneNumber;
  if (phoneNumber.length === 11) {
    if (phoneNumber[0] === "0") {
      validNumber = phoneNumber.substr(1);
    } else {
      return false;
    }
  } else if (10 > phoneNumber.length || phoneNumber.length > 11) {
    return false;
  }

  const pattern = /^9\(?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (validNumber.match(pattern)) {
    return true;
  } else {
    return false;
  }
}

export function postalCodeValidator(postalCode: string) {
  return postalCode.length === 10;
}

export function volumeNameValidator(name: string) {
  if (name.length > 60 || name.length < 3) {
    return false;
  }
  const pattern = /^[a-z0-9][a-z0-9-_]*[a-z0-9A-Z]?([a-z0-9])$/gi;
  return pattern.test(name);
}

export function sshPublicKeyValidator(publicKey: string) {
  if (publicKey.split(" ")[0] === "ssh-rsa") return true;
  return false;
}

export function urlValidator(url: string) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(url);
}
