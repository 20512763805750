import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import { jsonToTicket, jsonToTicketStats } from "../../api/mappers";
import apiRoutes from "../../constants/apiRoutes";
import { authCheckAndRedirect } from "../../utilities/functions";
import { addNotification } from "../baseSlice";
import getDefaultHeaders from "../utils";

export const fetchTickets = createAsyncThunk(
  "ticketing/fetchTickets",
  async (token: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Axios.get(apiRoutes.V1.TICKETING.LIST, {
        headers: getDefaultHeaders(token),
      });
      const tickets: Ticket[] = response.data.map((item: any) => {
        return jsonToTicket(item)
      });
      return tickets;
    } catch (error: any) {
      authCheckAndRedirect(error.response);
      dispatch(
        addNotification({
          type: "error",
          title: "مشکل در لود تیکت ها",
          text: error.response.data.message,
        })
      );
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const fetchTicketStats = createAsyncThunk(
  "ticketing/fetchTicketStats",
  async (token: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Axios.get(apiRoutes.V1.TICKETING.STATS, {
        headers: getDefaultHeaders(token),
      });
      const stats: TicketStats = jsonToTicketStats(response.data)
      return stats;
    } catch (error: any) {
      authCheckAndRedirect(error.response);
      dispatch(
        addNotification({
          type: "error",
          title: "مشکل در لود آمار تیکت ها",
          text: error.response.data.message,
        })
      );
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const fetchTicketDepartments = createAsyncThunk(
  "ticketing/fetchTicketDepartments",
  async (token: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Axios.get(
        apiRoutes.V1.TICKETING.DEPARTMENTS_LIST,
        {
          headers: getDefaultHeaders(token),
        }
      );
      const departments: TicketDepartment[] = response.data.map((item: any) => {
        return {
          id: item.id,
          title: item.title,
        };
      });
      return departments;
    } catch (error: any) {
      authCheckAndRedirect(error.response);
      dispatch(
        addNotification({
          type: "error",
          title: "مشکل در لود",
          text: "متاسفانه مشکلی در دریافت لیست دپارتمان ها رخ داده است!",
        })
      );
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const fetchTicketPriorities = createAsyncThunk(
  "ticketing/fetchTicketPriorities",
  async (token: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Axios.get(apiRoutes.V1.TICKETING.PRIORITIES_LIST, {
        headers: getDefaultHeaders(token),
      });
      const priorities: TicketPriority[] = response.data.map((item: any) => {
        return {
          id: item.id,
          title: item.title,
        };
      });
      return priorities;
    } catch (error: any) {
      authCheckAndRedirect(error.response);
      dispatch(
        addNotification({
          type: "error",
          title: "مشکل در لود",
          text: "متاسفانه مشکلی در دریافت لیست اولویت ها رخ داده است!",
        })
      );
      return rejectWithValue({
        status: error.response.status,
        error: error,
      });
    }
  }
);
