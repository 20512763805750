import React, { useEffect } from "react";
import Modal from "../../../Utils/Modal";

const RebuildConfirmationModal = (props: { dismiss: () => void }) => {
  const { dismiss } = props;
  useEffect(() => {
    document.body.classList.add("modal-open");

    return () => document.body.classList.remove("modal-open");
  }, []);
  return (
    <Modal>
      <div>
        <Modal.Header>
          <>
            <h5 className="modal-title">تایید Rebuild</h5>
            <button type="button" className="close" onClick={() => dismiss()}>
              ×
            </button>
          </>
        </Modal.Header>
        <Modal.Body>
          <>
            <p className="text-center">
              با بازسازی سرور تمامی اطلاعات قبلی سرور از بین خواهد رفت و هیچ
              راهی برای بازیابی آن ها وجود نخواهد داشت.
            </p>
            <p className="text-center">
              در صورت اطمینان از انجام این عملیات لطغا این پیام را تایید کنید.
            </p>
          </>
        </Modal.Body>
        <Modal.Footer>
          <>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => dismiss()}
            >
              لغو
            </button>
            <button type="submit" className="btn bg-primary">
              تایید
            </button>
          </>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default RebuildConfirmationModal;
