import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

// export type NotificationProps = {
//   type: string;
//   title: string;
//   text: string;
//   id?: number;
// }

const baseSlice = createSlice({
  name: "base",
  initialState: {
    mobileSidebarOpen: false,
    notifications: [] as NotificationProps[],
  },
  reducers: {
    toggleSidebar: (state) => {
      state.mobileSidebarOpen = !state.mobileSidebarOpen;
    },
    addNotification: (state, { payload }) => {
      state.notifications.push({
        ...payload,
        id: Date.now(),
        notificationScope: "global",
      });
    },
    removeNotification: (state, { payload }) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== payload
      );
    },
  },
});

export const {
  toggleSidebar,
  addNotification,
  removeNotification,
} = baseSlice.actions;

export default baseSlice.reducer;

export const baseState = (state: RootState) => state.base;
