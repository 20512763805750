import React from "react";
import { useQuery } from "react-query";
import { APIQueryName } from "../../../../constants/queries";
import { ServerStatus } from "../../../../constants/servers";
import { useHCServerUpdateState } from "../../../../mutations/servers";
import Spinner from "../../../Utils/Spinner";

const spin = (status: string) => {
  return (
    status === ServerStatus.REBUILDING ||
    status === ServerStatus.RESTARTING ||
    status === ServerStatus.REBOOTING ||
    status === ServerStatus.STOPPING ||
    status === ServerStatus.STARTING ||
    status === ServerStatus.INITIALIZING
  );
};

const getStatusBadgeClass = (status: string) => {
  switch (status) {
    case ServerStatus.RUNNING:
      return "success";
    case ServerStatus.INITIALIZING:
      return "blue-400";
    case ServerStatus.OFF:
      return "danger";
    case ServerStatus.MIGRATING:
      return "orange-300";
    case ServerStatus.STOPPING:
    case ServerStatus.STARTING:
      return "orange-700";
    case ServerStatus.REBOOTING:
      return "indigo-400";
    case ServerStatus.RESTARTING:
      return "info-400";
    case ServerStatus.REBUILDING:
      return "brown-300";
    default:
      return "success";
  }
};

const ServerDetailsInfoBar = (props: { details: HCServerDetails }) => {
  const { mutate: updateState } = useHCServerUpdateState();
  const { details } = props;
  useQuery(
    APIQueryName.SERVER_STATE + details.slug,
    () => {
      updateState(details.slug);
    },
    {
      staleTime: 10000,
      retry: 1,
      // enabled: spin(details.status),
    }
  );

  if (!details) return <div></div>;

  return (
    <div className="card">
      <div className="card-header header-elements-sm-inline">
        <h3 className="card-title">
          <span
            className={`badge badge-mark border-${getStatusBadgeClass(
              details.status
            )} mr-1 ml-1`}
          />{" "}
          {details.name.toUpperCase()} {spin(details.status) && <Spinner />}
          {details.creditLock && (
            <i className="icon-lock2" title="سرور به دلیل بدهی قفل میباشد" />
          )}
        </h3>
        <div className="header-elements">
          <ul className="list-inline mb-0">
            <li className="list-inline-item" style={{ direction: "ltr" }}>
              <strong>Status: </strong>
              <span
                className={`badge bg-${getStatusBadgeClass(details.status)}`}
              >
                {details.status}
              </span>
            </li>
            <li className="list-inline-item" style={{ direction: "ltr" }}>
              <strong>Floating IPs: </strong>-
            </li>
            <li className="list-inline-item" style={{ direction: "ltr" }}>
              <strong>IPv6: </strong>
              {details.ipv6}
            </li>
            <li className="list-inline-item" style={{ direction: "ltr" }}>
              <strong>IPv4: </strong>
              {details.ipv4}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ServerDetailsInfoBar;
