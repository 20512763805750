import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchServerTypes } from "./createServerAPI";

const createServerSlice = createSlice({
  name: "createServer",
  initialState: {
    // Server Types
    serverTypes: [] as ServerType[],
    serverTypesLoading: false,
    serverTypesLoaded: 0,
    serverTypesLoadError: false,
  },
  reducers: {
    // addTicket: (state, { payload }) => {
    //   state.tickets.unshift(payload);
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServerTypes.pending, (state) => {
        state.serverTypesLoading = true;
        state.serverTypesLoadError = false;
      })
      .addCase(fetchServerTypes.rejected, (state) => {
        state.serverTypesLoadError = true;
        state.serverTypesLoading = false;
        state.serverTypesLoaded = 0;
      })
      .addCase(fetchServerTypes.fulfilled, (state, { payload }) => {
        state.serverTypesLoaded = Date.now();
        state.serverTypesLoading = false;
        state.serverTypesLoadError = false;
        // state.serverTypes = payload;
      });
  },
});

// export const {  } = createServerSlice.actions;

export default createServerSlice.reducer;

export const createServerState = (state: RootState) => state.createServer;
