import React, {useState} from "react";
import {datetimeToString} from "../../utilities/functions";

const Bulletin = (props: { bulletin: Bulletin }) => {
  const [alertShow, setAlertShow] = useState(true);
  const {bulletin} = props;

  return (
    <div
      className={`alert alert-${bulletin.class !== '' ? bulletin.class : 'info'} border-0 alert-dismissible col-md-12`}
      style={{display: !alertShow ? "none" : ""}}
    >
      <button
        type="button"
        className="close"
        onClick={() => setAlertShow(false)}
      >
        <span>×</span>
      </button>
      {bulletin.title && <span className="font-weight-semibold">{bulletin.title}</span>}
      <p>{bulletin.body}</p>
      <span className={"mb-0"}>{datetimeToString(bulletin.createdAt)}</span>
    </div>
  );
};

export default Bulletin;
