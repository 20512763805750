import React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { fetchProjects } from "../../api/projects";
import CreateProject from "../../components/Projects/CreateProject";
import ProjectCard from "../../components/Projects/ProjectCard";
import Alert from "../../components/Utils/Alert";
import SimpleLoader from "../../components/Utils/SimpleLoader";
import { APIQueryName } from "../../constants/queries";
import Content from "../../containers/Content";
import ContentCenter from "../../containers/ContentCenter";

const Projects = () => {
  const { state } = useLocation();

  const { data, isError, isLoading } = useQuery(
    APIQueryName.PROJECTS,
    fetchProjects,
    {
      staleTime: 60000,
      retry: 1,
    }
  );
  if (isLoading) {
    return (
      <Content>
        <ContentCenter>
          <div className="text-center mb-5 mt-2">
            <SimpleLoader iconSize={5} />
            <p className="mt-3">در حال دریافت لیست پروژه ها ...</p>
          </div>
        </ContentCenter>
      </Content>
    );
  }

  if (isError) {
    return (
      <Content>
        <ContentCenter>
          <div className="text-center mb-5 mt-2">
            <i className="icon-warning2 icon-5x mr-2" />
            <p className="mt-3">متاسفانه مشکلی در لود پروژه ها رخ داده است!</p>
          </div>
        </ContentCenter>
      </Content>
    );
  }
  return (
    <Content>
      {state && state.newServerCreate && (
        <Alert
          alertType="info"
          text="لطفا یکی از پروژه ها را برای ایجاد سرور انتخاب کنید."
        />
      )}
      <div className="row">
        {data &&
          data.map((project) => (
            <div className="col-sm-3" key={"project" + project.id}>
              <ProjectCard
                project={project}
                create={state && state.newServerCreate}
              />
            </div>
          ))}

        <div className="col-sm-3">
          <CreateProject />
        </div>
      </div>
    </Content>
  );
};

export default Projects;
