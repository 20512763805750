import React, { useEffect } from "react";
import Modal from "../Utils/Modal";

const VolumeInfoModal = (props: {
  dismiss: () => void;
  volume: HCVolume;
}) => {
  const { dismiss, volume } = props;
  useEffect(() => {
    document.body.classList.add("modal-open");

    return () => document.body.classList.remove("modal-open");
  }, []);
  return (
    <Modal size="large">
      <div>
        <Modal.Header>
          <>
            <h5 className="modal-title">تنظیمات</h5>
            <button type="button" className="close" onClick={() => dismiss()}>
              ×
            </button>
          </>
        </Modal.Header>
        <Modal.Body>
          <>
            <p className="text-center code">
              برای تنظیم فضای اضافه شده می توانید از راهنمای زیر استفاده کنید.
            </p>
            <p className="text-center font-weight-bold">فرمت فضا</p>
            <p className="text-center">
              <code style={{ direction: "ltr" }}>
                {"sudo mkfs.ext4 -F /dev/disk/by-id/scsi-0HC_Volume_" +
                  volume.hetznerId}
              </code>
            </p>
            <p className="text-center font-weight-bold">ساخت فولدر</p>
            <p className="text-center">
              <code style={{ direction: "ltr" }}>
                {"mkdir /mnt/" + volume.name}
              </code>
            </p>
            <p className="text-center font-weight-bold">مانت کردن فضا</p>
            <p className="text-center">
              <code style={{ direction: "ltr" }}>
                {"mount -o discard,defaults /dev/disk/by-id/scsi-0HC_Volume_" +
                  volume.hetznerId +
                  "/mnt/" +
                  volume.name}
              </code>
            </p>
            <p className="text-center font-weight-bold">
              اضافه کردن به FSTAB (اختیاری)
            </p>
            <p className="text-center">
              <code
                style={{ direction: "ltr" }}
              >{`echo "/dev/disk/by-id/scsi-0HC_Volume_${volume.hetznerId} /mnt/${volume.name} ext4 discard,nofail,defaults 0 0" >> /etc/fstab`}</code>
            </p>
            <p className="text-center">
              فضای اضافه شده بعد از انجام این مراحل در مسیر "/mnt/{volume.name}"
              قابل دسترسی خواهد بود. همچنین در صورت جدا کردن فضا از سرور،
              اطلاعات همچنان باقی خواهد ماند و در صورت اتصال مجدد و یا اتصال به
              سرور دیگری در دسترس خواهد بود.
            </p>
          </>
        </Modal.Body>
        <Modal.Footer>
          <>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => dismiss()}
            >
              بستن
            </button>
          </>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default VolumeInfoModal;
