import React from "react";
import ServerSnapshotTableRow from "./SnapshotTableRow";

const ServerDetailssnapshotsTable = (props: {
  snapshots: HCImage[];
  serverSlug: string;
}) => {
  const { snapshots, serverSlug } = props;
  return (
    <div
      className="table-responsive table-scrollable"
      style={{ maxHeight: "100%" }}
    >
      <table className="table">
        <thead>
          <tr>
            <th colSpan={6}>
              <h5 className="text-center">اسنپ شات های موجود از این سرور</h5>
            </th>
          </tr>
          <tr className="bg-purple">
            <th>توضیحات</th>
            <th>تاریخ ایجاد</th>
            <th>حجم</th>
            <th>سرور</th>
            <th>وضعیت</th>
            <th className="text-center">گزینه ها</th>
          </tr>
        </thead>
        <tbody>
          {snapshots.map((snapshot) => (
            <ServerSnapshotTableRow
              snapshot={snapshot}
              serverSlug={serverSlug}
              key={"serverSnapshot" + snapshot.id}
            />
          ))}

          {snapshots.length === 0 && (
            <tr>
              <td colSpan={6}>
                <p className="text-center">
                  هیچ اسنپ شاتی از این سرور موجود نمیباشد.
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ServerDetailssnapshotsTable;
