import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { fetchWallet } from "../../api/wallet";
import { APIQueryName } from "../../constants/queries";
import routes from "../../constants/routes";
import { numberWithCommas } from "../../utilities/functions";
import SimpleLoader from "../Utils/SimpleLoader";

const UserBillingStats = () => {

  const { data, isLoading } = useQuery(
    APIQueryName.WALLET_SUMMARY,
    fetchWallet,
    {
      staleTime: 20000,
      retry: 1,
    }
  );

  return (
    <div className="card card-body mt-3">
      <p className="text-center font-weight-semibold text-muted">وضعیت مالی</p>
      <div className="row text-center">
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 p-2 text-center">
          <p className="text-center">
            <Link to={routes.BILLING}>
              <i className="icon-wallet icon-2x d-inline-block text-teal-600" />
            </Link>
          </p>
          {isLoading && <SimpleLoader />}
          {!isLoading && (
            <h5 className="font-weight-semibold mb-0 text-center persian-number">
              {numberWithCommas(data ? data.balance : 0)}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">موجودی</span>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 p-2 text-center">
          <p className="text-center">
            <Link to={routes.BILLING_LOGS}>
              <i className="icon-cart-add2 icon-2x d-inline-block text-info-800" />
            </Link>
          </p>
          {isLoading && <SimpleLoader />}
          {!isLoading && (
            <h5 className="font-weight-semibold mb-0 text-center persian-number">
              {numberWithCommas(data ? data.monthUsage : 0)}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">مصرف این ماه</span>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 p-2 text-center">
          <p className="text-center">
            <Link to={routes.BILLING}>
              <i className="icon-cart icon-2x d-inline-block text-danger-800" />
            </Link>
          </p>
          {isLoading && <SimpleLoader />}
          {!isLoading && (
            <h5 className="font-weight-semibold mb-0 text-center persian-number">
              {numberWithCommas(data ? data.totalDeposits : 0)}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">کل پرداختی ها</span>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 p-2 text-center">
          <p className="text-center">
            <Link to={routes.BILLING_ADD_FUND}>
              <i className="icon-plus2 icon-3x d-inline-block text-success-600" />
            </Link>
          </p>
          <span className="text-muted ml-2 font-size-sm">افزایش موجودی</span>
        </div>
      </div>
    </div>
  );
};

export default UserBillingStats;
