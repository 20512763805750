import React from "react";
import {
  useHCServerDisableRescue,
  useHCServerEnableRescue,
} from "../../../../../mutations/servers";
import ServerNewPassword from "../PasswordReset/PasswordBox";
import RescueOSList from "./RescueOSList";

const ServerRescueMode = (props: { details: HCServerDetails }) => {
  const { details } = props;
  const {
    mutate: enableMutate,
    data,
    isLoading: enabling,
  } = useHCServerEnableRescue();
  const { mutate: disableMutate, isLoading: disabling } =
    useHCServerDisableRescue();

  if (!details) return <div></div>;

  const { rescueMode } = details;

  const rescueEnableHandler = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const os = evt.currentTarget.rescueOS.value;
    enableMutate({ slug: details.slug, os_type: os });
  };

  const rescueDisableHandler = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    evt.preventDefault();
    disableMutate(details.slug);
  };
  return (
    <div className="card">
      <div className="card-body text-center">
        <i className="icon-wrench3 icon-2x text-teal border-teal border-3 rounded-round p-3 mb-3 mt-1"></i>
        <h5 className="card-title text-center">Rescue</h5>
        <p className="mb-3 text-center">
          از این حالت برای حل مشکلاتی مانند بوت نشدن سرور و یا نصب سیستم عامل
          های غیر پیشفرض استفاده می شود.
        </p>
        <p className="mb-3 text-center">
          بعد از فعال سازی این حالت باید سرور خود را طی ۱ ساعت آینده ریبوت کنید
          تا این حالت بر روی سرور شما فعال شود. بعد از ریبوتی دیگر، سرور به حالت
          عادی باز خواهد گشت.
        </p>

        {data && rescueMode && (
          <ServerNewPassword password={data.rootPassword} />
        )}
        <form onSubmit={rescueEnableHandler} className="text-center">
          {!rescueMode && (
            <div className="row justify-content-md-center">
              <RescueOSList />
            </div>
          )}
          {!rescueMode && (
            <button
              className="btn bg-teal"
              type="submit"
              disabled={enabling}
              style={{ direction: "ltr" }}
            >
              Enable Rescue{" "}
              {enabling && <i className="icon-spinner6 spinner mr-2" />}
            </button>
          )}
        </form>
        {rescueMode && (
          <button
            className="btn bg-teal"
            type="button"
            disabled={disabling}
            onClick={rescueDisableHandler}
            style={{ direction: "ltr" }}
          >
            Disable Rescue{" "}
            {disabling && <i className="icon-spinner6 spinner mr-2" />}
          </button>
        )}
      </div>
    </div>
  );
};

export default ServerRescueMode;
