import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchHCImages } from "../../../../api/hcImages";
import { APIQueryName } from "../../../../constants/queries";
import ServerImageOSItem from "./ServerImageOSItem";
import ServerImageTabs from "./ServerImageTabs";
import ServerSnapshotItem from "./ServerSnapshotItem";

const ServerImageSection = (props: { projectId: number }) => {
  const { projectId } = props;
  const { data, isError, isLoading } = useQuery(
    [APIQueryName.HC_IMAGES, projectId],
    () => fetchHCImages([] as string[], undefined, projectId),
    { staleTime: 20000, retry: 1 }
  );

  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedImageFlavor, setSelectedImageFlavor] = useState("");
  const [activeTab, setActiveTab] = useState<"snapshots" | "os">("os");
  const [osImages, setOSImages] = useState([] as HCImage[][]);
  const [snapshots, setSnapshots] = useState([] as HCImage[]);

  const switchTabHandler = (tab: "snapshots" | "os") => {
    if (tab === activeTab) return;
    setSelectedImage(0);
    setSelectedImageFlavor("");
    setActiveTab(tab);
  };

  const getOSGroups = (images: HCImage[]) => {
    let groups: { [key: string]: HCImage[] } = {};
    images.forEach((image) => {
      if (
        image.type === "system" ||
        image.type === "custom" ||
        image.type === "os"
      ) {
        groups[image.osFlavor]
          ? groups[image.osFlavor].push(image)
          : (groups[image.osFlavor] = [image]);
      }
    });
    let groupsArray: HCImage[][] = [];

    Object.keys(groups).forEach((groupKey) =>
      groupsArray.push(groups[groupKey])
    );

    return groupsArray;
  };

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setOSImages(getOSGroups(data));

      const snapshots = data.filter((img) => img.type === "snapshot");
      setSnapshots(snapshots);
    }
  }, [isLoading, isError, data]);

  return (
    <>
      <ServerImageTabs activeTab={activeTab} switchHandler={switchTabHandler} />
      <div className="row d-flex justify-content-center">
        <input
          name="serverImage"
          id="serverImage"
          type="hidden"
          value={selectedImage}
        />

        {activeTab === "snapshots" &&
          snapshots.map((snapshot) => (
            <ServerSnapshotItem
              key={"serverSnapshot" + snapshot.id}
              snapshot={snapshot}
              selected={snapshot.id === selectedImage}
              selectHandler={(id: number) => setSelectedImage(id)}
            />
          ))}
        {activeTab === "snapshots" &&
          !isLoading &&
          !isError &&
          snapshots.length === 0 && (
            <p className="text-center">شما هیچ اسنپ شاتی ندارید!</p>
          )}

        {activeTab === "os" &&
          osImages.map((group) => (
            <ServerImageOSItem
              key={"osGroup" + group[0].osFlavor}
              osGroup={group}
              selected={selectedImageFlavor === group[0].osFlavor}
              selectHandler={(id: number, flavor: string) => {
                setSelectedImage(id);
                setSelectedImageFlavor(flavor);
              }}
            />
          ))}
      </div>
    </>
  );
};

export default ServerImageSection;
