import React from "react";

const ServerRescaleNotes = () => {
  return (
    <div className="card">
      <div className="card-body text-center">
        <i className="icon-wrench icon-2x text-danger border-danger border-3 rounded-round p-3 mb-3 mt-1" />
        <h5 className="card-title text-center">Rescale</h5>
        <p className="mb-3 text-center">
          در این قسمت می توانید پلن سرویس خود را به پلنی قویتر و یا ضعیفتر تغییر
          دهید.
        </p>
        <p className="mb-3 text-center">
          دقت داشته باشید که سرور تنها به پلن هایی قابل کاهش است که فضای دیسک
          یکسانی داشته باشند. برای عدم از دست رفتن قابلیت کاهش پلن در زمان
          ارتقاء سرور حتما تیک مربوط به تغییر تنها CPU و RAM را زده تا دیسک سرور
          تغییری نکند تا در آینده قادر به کاهش پلن خود باشید.
        </p>
        <p className="mb-3 text-center">
          برای انجام این عملیات باید ابتدا سرور را از قسمت کنترل خاموش کنید. همچنین این عمل ممکن است دقایقی به طول بی انجامد.
        </p>
      </div>
    </div>
  );
};

export default ServerRescaleNotes;
