import React from "react";
import { useSelector } from "react-redux";
import { ServerAction } from "../../../../constants/servers";
import { serversState } from "../../../../features/userServers/serverSlice";
import { useHCServerAction } from "../../../../mutations/servers";
import Alert from "../../../Utils/Alert";
import ServerBackupCreate from "./BackupCreate";
import ServerDetailsBackupsTable from "./BackupsTable";

const ServerDetailsBackupSection = (props: { details: HCServerDetails }) => {
  const { details } = props;
  const { mutate } = useHCServerAction();
  const { serverPendings } = useSelector(serversState);

  const enableActionKey = `server_${ServerAction.ENABLE_BACKUP}_${details.slug}`;
  const disableActionKey = `server_${ServerAction.DISABLE_BACKUP}_${details.slug}`;

  const backupSwitchHandler = () => {
    const action = details.backupEnabled
      ? ServerAction.DISABLE_BACKUP
      : ServerAction.ENABLE_BACKUP;
    const actionKey = ServerAction.DISABLE_BACKUP
      ? enableActionKey
      : disableActionKey;
    mutate({ serverSlug: details.slug, actionType: action, actionKey });
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body text-center">
            <i className="icon-copy4 icon-2x text-primary border-primary border-3 rounded-round p-3 mb-3 mt-1" />
            <h5 className="card-title text-center">Backup</h5>

            <p className="mb-3 text-center">
              بک آپ ها کپی های خودکاری از سرور شما هستند. هر سرور قابلیت نگهداری
              ۷ نسخه بک آپ را دارد.
            </p>
            <p className="mb-3 text-center">
              در صورت پر بودن تعداد بک آپ های قابل نگه داری، قدیمیترین بک آپ حذف
              و بک آپ جدید جایگزین خواهد شد. همچنین در صورت حذف سرور، تمامی بک
              آپ ها نیز حذف خواهند شد.
            </p>
            <p className="mb-3 text-center">
              برای جلوگیری از مشکلات بک آپ های تهیه شده توصیه می کنیم ابتدا سرور
              خود را خاموش و سپس اقدام به تهیه بک آپ کنید.
            </p>
            <div className="text-center">
              <Alert
                alertType="info"
                text="فعال سازی بک آپ ۲۰٪ هزینه سرور را افزایش خواهد داد."
              />
            </div>
            <br />

            {(serverPendings.indexOf(enableActionKey) >= 0 ||
              serverPendings.indexOf(disableActionKey) >= 0) && (
              <button
                type="button"
                disabled
                className="btn bg-primary"
                style={{ direction: "rtl" }}
              >
                <i className="icon-spinner6 spinner mr-2" />{" "}
                {!details.backupEnabled
                  ? "در حال فعالسازی بک آپ"
                  : "در حال غیر فعالسازی بک آپ"}{" "}
                ...
              </button>
            )}
            {serverPendings.indexOf(enableActionKey) < 0 &&
              serverPendings.indexOf(disableActionKey) < 0 && (
                <button
                  className="btn bg-primary"
                  style={{ direction: "rtl" }}
                  onClick={backupSwitchHandler}
                  disabled={!details.backupEnabled && details.creditLock}
                >
                  {!details.backupEnabled
                    ? "فعالسازی بک آپ"
                    : "غیر فعالسازی بک آپ"}
                </button>
              )}
            {details.backupEnabled &&
              serverPendings.indexOf(enableActionKey) < 0 &&
              serverPendings.indexOf(disableActionKey) < 0 && (
                <ServerBackupCreate slug={details.slug} />
              )}
          </div>
          <ServerDetailsBackupsTable backups={details.backups} serverSlug={details.slug} />
        </div>
      </div>
    </div>
  );
};

export default ServerDetailsBackupSection;
