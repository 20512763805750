import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchUser } from "./userAPI";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userProfile: null,
    userProfileLoaded: false,
    userProfileLoading: false,
    alert: null,
    email: "",
    firstName: "",
    lastName: "",
    isStaff: false,
    userBalance: 0,
    postalCode: null,
    address: null,
    phoneNumber: null,
    servers: {
      count: 0,
      memories: 0,
      cores: 0,
      disk: 0,
    },
    volumes: {
      count: 0,
      size: 0,
    },
    ips: 0,
    snapshots: {
      count: 0,
      size: 0.0,
    },
    monthUsage: 0.0,
    totalUsage: 0.0,
    ticketing: {
      total: 0,
      waiting: 0,
      answered: 0,
    },
    emailVerified: true,
    phoneVerified: true,
  },
  reducers: {
    loadingUserProfile: (state) => {
      state.userProfileLoading = true;
    },
    verifyUserEmail: (state) => {
      state.emailVerified = true;
    },
    verifyUserPhoneNumber: (state) => {
      state.phoneVerified = true;
    },
    updateUserProfile: (state, { payload }) => {
      state.address = payload.address;
      state.phoneNumber = payload.phoneNumber;
      state.email = payload.email;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.postalCode = payload.postalCode;
    },
    userAddBalance: (state, { payload }) => {
      state.userBalance += payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.userProfileLoading = true;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        console.log("failed!", action);
      })
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        state.userProfile = payload;
        state.userProfileLoaded = true;
        state.userProfileLoading = false;
        state.userBalance = payload.balance;
        state.postalCode = payload.postal_code;
        state.phoneNumber = payload.phone;
        state.emailVerified = payload.email_verified;
        state.phoneVerified = payload.phone_verified;
        state.firstName = payload.first_name;
        state.lastName = payload.last_name;
        state.email = payload.email;
        state.address = payload.address;
      });
  },
});

export const {
  loadingUserProfile,
  verifyUserEmail,
  verifyUserPhoneNumber,
  updateUserProfile,
  userAddBalance,
} = userSlice.actions;

export default userSlice.reducer;

export const userState = (state: RootState) => state.user;
