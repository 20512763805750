import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import TicketDepartmentsSelect from "../../components/Ticketing/Create/DepartmentsSelect";
import TicketPrioritiesSelect from "../../components/Ticketing/Create/PrioritiesSelect";
import routes from "../../constants/routes";
import Content from "../../containers/Content";
import { useCreateTicket } from "../../mutations/ticketing";

const TicketCreate = () => {
  const [textError, setTextError] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const { mutate, data, isSuccess, isLoading } = useCreateTicket();

  const formSubmitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const title = evt.currentTarget.ticketTitle.value;
    const text = evt.currentTarget.ticketText.value;
    const priority = evt.currentTarget.priority.value;
    const department = evt.currentTarget.department.value;

    if (text.length < 1) {
      setTextError(true);
      return;
    } else if (title.length < 1 || title.length > 250) {
      setTitleError(true);
      return;
    }
    mutate({
      title: title,
      text: text,
      department: department,
      priority: priority,
    });
  };

  if (isSuccess) {
    return <Navigate to={routes.TICKET_DETAILS + data?.slug} />;
  }

  return (
    <Content>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <form onSubmit={formSubmitHandler}>
                <div className="form-group">
                  <label> موضوع :</label>
                  <input
                    name="ticketTitle"
                    id="ticketTitle"
                    type="text"
                    className="form-control"
                    placeholder="مثال: مشکل در تهیه سرویس"
                    maxLength={250}
                  />
                  {titleError && (
                    <span className="form-text text-danger">
                      <i className="icon-cancel-circle2 mr-2" /> لطفا عنوانی با
                      طول حداکثر ۲۵۰ حرف وارد کنید
                    </span>
                  )}
                </div>

                <div className="row">
                  <div className="form-group col-6">
                    <label>دپارتمان :</label>
                    <TicketDepartmentsSelect />
                  </div>
                  <div className="form-group col-6">
                    <label>اهمیت :</label>
                    <TicketPrioritiesSelect />
                  </div>
                </div>
                <div className="form-group">
                  <label> پیغام شما :</label>
                  <textarea
                    name="ticketText"
                    id="ticketText"
                    rows={5}
                    cols={5}
                    className="form-control"
                    placeholder=" پیام خود را اینجا وارد نمایید "
                  />
                  {textError && (
                    <span className="form-text text-danger">
                      <i className="icon-cancel-circle2 mr-2" /> متن نمیتواند
                      خالی باشد! لطفا مشکل خود را توضیح دهید.
                    </span>
                  )}
                </div>

                <div className="text-left">
                  {!isLoading && (
                    <button type="submit" className="btn btn-primary">
                      ارسال درخواست
                    </button>
                  )}
                  {isLoading && (
                    <button disabled className="btn btn-primary">
                      <i className="icon-spinner6 spinner mr-2" /> در حال ارسال
                      ...
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default TicketCreate;
