import React, { useState } from "react";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import { useDeleteHCImage, useUpdateHCImage } from "../../mutations/images";
import { datetimeToString } from "../../utilities/functions";

const UserSnapshotTableRow = (props: { snapshot: HCImage }) => {
  const { snapshot } = props;
  const { mutate: deleteMutate, isLoading: deleting } = useDeleteHCImage();
  const { mutate: updateMutate, isLoading: updating } = useUpdateHCImage();

  const [editDescription, setEditDescription] = useState(false);

  const backupUpdateHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const description = evt.currentTarget.backupNewName.value;
    if (description === snapshot.description) {
      setEditDescription(false);
      return;
    }
    updateMutate({
      id: snapshot.id,
      description: description,
      serverSlug: snapshot.createdFrom ? snapshot.createdFrom.slug : "",
      type: "",
    });
    setEditDescription(false);
  };

  const backupDeleteHandler = () => {
    deleteMutate({
      id: snapshot.id,
      serverSlug: snapshot.createdFrom ? snapshot.createdFrom.slug : "",
    });
  };

  return (
    <tr>
      <td>
        {!editDescription && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setEditDescription(true)}
          >
            {snapshot.description}{" "}
            <i
              className={`${
                updating ? "icon-spinner spinner" : "icon-pencil7"
              } top-0 ml-1`}
            />
          </span>
        )}
        {editDescription && (
          <form onSubmit={backupUpdateHandler}>
            <div className="input-group">
              <input
                style={{ direction: "ltr" }}
                name="backupNewName"
                id="backupNewName"
                type="text"
                autoFocus={true}
                className="form-control"
                disabled={updating}
                defaultValue={snapshot.description ? snapshot.description : ""}
              />
              <span className="input-group-append">
                <button
                  className="btn btn-light"
                  type="submit"
                  disabled={updating}
                >
                  تغییر
                </button>
                <button
                  className="btn btn-light"
                  type="button"
                  disabled={updating}
                  onClick={() => setEditDescription(false)}
                >
                  انصراف
                </button>
              </span>
            </div>
          </form>
        )}
      </td>
      <td style={{ direction: "ltr" }}>
        {datetimeToString(snapshot.createdAt)}
      </td>
      <td>
        {snapshot.imageSize ? snapshot.imageSize.toFixed(2) + "GB" : " - "}
      </td>
      <td>{snapshot.createdFrom ? (<Link to={routes.SERVER_DETAILS + snapshot.createdFrom.slug}>{snapshot.createdFrom.name}</Link>) : " - "}</td>
      <td>{snapshot.status}</td>
      <td className="text-center">
        <button
          type="button"
          className="btn bg-danger-400 mr-1 ml-1"
          onClick={backupDeleteHandler}
          disabled={deleting}
        >
          <span>حذف</span>
          {deleting && <i className="icon-spinner spinner ml-1" />}
        </button>
      </td>
    </tr>
  );
};

export default UserSnapshotTableRow;
