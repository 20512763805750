import React from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { fetchConfigurations } from "../../../../api/servers";
import { APIQueryName } from "../../../../constants/queries";
import { ServerAction, ServerStatus } from "../../../../constants/servers";
import { serversState } from "../../../../features/userServers/serverSlice";
import { useHCServerAction } from "../../../../mutations/servers";
import ServerTypesSection from "../../../CreateServer/FormSections/ServerType/ServerTypesSection";
import Alert from "../../../Utils/Alert";
import Spinner from "../../../Utils/Spinner";
import ServerRescaleRAMAndCPUOnly from "./RescaleRAMAndCPUOnly";

const ServerRescaleForm = (props: { details: HCServerDetails }) => {
  const { details } = props;
  const { mutate } = useHCServerAction();
  const { data } = useQuery(
    APIQueryName.SERVER_CONFIGURATIONS,
    fetchConfigurations,
    {
      staleTime: 60000,
      retry: 1,
    }
  );
  const { serverPendings } = useSelector(serversState);
  const { serverType, status, slug, diskSize } = details;

  const actionKey = "serverRescale" + slug;

  const formSubmitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const ramAndCPUOnly = evt.currentTarget.cpuRamOnly.value;
    const plan = evt.currentTarget.selectedServerType.value;

    if (plan === serverType.id) return;
    if (plan === "0" || plan === 0) return;
    if (status !== ServerStatus.OFF) return;

    mutate({
      serverSlug: slug,
      actionType: ServerAction.RESCALE,
      actionKey,
      reqData: {
        upgrade_disk: ramAndCPUOnly === "false",
        server_type: parseInt(plan),
      },
    });
  };

  return (
    <form onSubmit={formSubmitHandler}>
      <ServerRescaleRAMAndCPUOnly />
      <ServerTypesSection
        diskSize={diskSize}
        currentPlan={serverType.id}
        storageType={serverType.storageType === "local" ? "local" : "network"}
        configurations={data}
        selectedIndex={0}
        loading={false}
      />

      <div className="text-center">
        <div className="mt-3">
          <Alert
            alertType="info"
            text="توجه داشته باشید که باید حداقل به اندازه 25% هزینه ماهانه پلن جدید دارای اعتبار باشید. در غیر این صورت این عملیات ناموفق خواهد بود."
          />
        </div>
        {status !== ServerStatus.OFF && status !== ServerStatus.MIGRATING && (
          <div className="mt-3">
            <Alert alertType="warning" text="لطفا ابتدا سرور را خاموش کنید" />
          </div>
        )}

        <button className="btn btn-primary" disabled={status !== "off"}>
          Rescale {serverPendings.includes(actionKey) && <Spinner />}
        </button>
      </div>
    </form>
  );
};

export default ServerRescaleForm;
