import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import { jsonToWallet } from "../../api/mappers";
import apiRoutes from "../../constants/apiRoutes";
import { authCheckAndRedirect } from "../../utilities/functions";
import getDefaultHeaders from "../utils";


export const fetchWallet = createAsyncThunk(
  "wallet/fetchWallet",
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await Axios.get(apiRoutes.V1.WALLET.SUMMARY, {
        headers: getDefaultHeaders(token),
      });
      return jsonToWallet(response.data);
    } catch (error: any) {
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);
