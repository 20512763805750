import { createSlice } from "@reduxjs/toolkit";
import { ServerAction, ServerStatus } from "../../constants/servers";
import { RootState } from "../../store";
import {
  createServerSnapshot,
  deleteServerImage,
  fetchServerDetails,
  fetchUserServers,
  rescaleServer,
  triggerServerAction,
  updateServerImage,
} from "./serversAPI";

type ServerDetailsState = null | UserServerDetails;

const serversSlice = createSlice({
  name: "servers",
  initialState: {
    serverImagesChanged: false,
    serverVolumesChanged: false,
    serverNotifications: [] as NotificationProps[],
    serverPendings: [] as string[],
    serversLoading: false,
    serversLoadingError: null as null | number | string,
    serversLoadedTimestamp: 0,
    servers: [] as UserServerData[],
    serverActions: [] as ActionData[],
    serverActionNames: [] as string[],
    serverUpdates: [] as string[],
    currentServerSlug: "",
    serverDetailsLoading: false,
    serverDetailsLoadingError: null as null | number | string,
    serverDetailsLoadedTimestamp: 0,
    serverDetails: null as ServerDetailsState,
    serverDetailsActiveTab: "info",
  },
  reducers: {
    addServerUpdate: (state, { payload }) => {
      state.serverUpdates.push(payload);
    },
    removeServerUpdate: (state, { payload }) => {
      state.serverUpdates = state.serverUpdates.filter(
        (update) => update !== payload
      );
    },
    serverUpdateStatus: (state, { payload }) => {
      if (!state.serverDetails) return;
      if (state.serverDetails.slug !== payload.slug) return;
      state.serverDetails.status = payload.status;
      state.serverDetails.locked = payload.locked;
    },
    resetServerImagesChanged: (state) => {
      state.serverImagesChanged = false;
    },
    addNewServer: (state, { payload }) => {
      state.servers.push(payload);
    },
    removeServer: (state, { payload }) => {
      state.servers = state.servers.filter((server) => server.slug !== payload);
      if (state.serverDetails && state.serverDetails.slug === payload) {
        state.serverDetails = null;
        state.currentServerSlug = "";
      }
    },
    removeServerNotification: (state, { payload }) => {
      state.serverNotifications = state.serverNotifications.filter(
        (notification) => notification.id !== payload
      );
    },
    addServerNotification: (state, { payload }) => {
      state.serverNotifications.push({ ...payload, id: Date.now() });
    },
    setCurrentServerSlug: (state, { payload }) => {
      if (state.currentServerSlug !== payload) {
        state.serverDetails = null;
        state.serversLoadingError = null;
        state.serversLoadedTimestamp = 0;
      }
      state.currentServerSlug = payload;
      // to switch to the main section
      state.serverDetailsActiveTab = "info";
    },
    setServerDetailsActiveTab: (state, { payload }) => {
      state.serverDetailsActiveTab = payload;
    },
    setServerStatus: (state, { payload }) => {
      if (!state.serverDetails) return;
      state.serverDetails.status = payload;
    },
    updateServerDetails: (state, { payload }) => {
      state.serverDetails = payload;
    },
    updateServerVolume: (state, { payload }) => {
      if (!state.serverDetails) return;
      // Volume has been detached
      // Or server changed
      // Or if volume is deleted (volume.server will be null)
      if (!payload.server || payload.server.slug !== state.serverDetails.slug) {
        state.serverDetails.volumes = state.serverDetails.volumes.filter(
          (volume) => volume.id !== payload.id
        );
        return;
      }
      // If volume just got updated
      state.serverDetails.volumes = state.serverDetails.volumes.map(
        (volume) => {
          if (volume.id === payload.id) return payload;
          return volume;
        }
      );
    },
    addServerAction: (state, { payload }) => {
      state.serverActions.push(payload);
      state.serverActionNames.push(payload.actionType);
      state.serverPendings.push(payload.actionKey);
    },
    serverRemoveVolumeAction: (state, { payload }) => {
      if (!state.serverDetails) return;
      state.serverVolumesChanged = true;
      state.serverDetails.volumes = state.serverDetails.volumes.filter(
        (volume) => volume.id !== payload.id
      );
    },
    serverAddBackup: (state, { payload }) => {
      state.serverImagesChanged = true;
      if (!state.serverDetails) return;
      if (
        payload.boundTo &&
        payload.boundTo.slug === state.serverDetails.slug
      ) {
        state.serverDetails.backups.push(payload);
      }
    },
    serverAddSnapshot: (state, { payload }) => {
      state.serverImagesChanged = true;
      if (!state.serverDetails) return;
      if (
        payload.createdFrom &&
        payload.createdFrom.slug === state.serverDetails.slug
      ) {
        state.serverDetails.snapshots.push(payload);
      }
    },
    updateServerBackupObject: (state, { payload }) => {
      state.serverImagesChanged = true;
      if (!state.serverDetails) return;
      state.serverDetails.backups = state.serverDetails.backups.map(
        (backup) => {
          if (backup.id === payload.id) return payload;
          return backup;
        }
      );
    },
    setServerRescueMode: (state, { payload }) => {
      if (!state.serverDetails) return;
      state.serverDetails.rescueMode = payload;
    },
    removeServerAction: (state, { payload }) => {
      state.serverActions = state.serverActions.filter(
        (action) => action.actionId !== payload.actionId
      );
      state.serverActionNames = state.serverActionNames.filter(
        (action) => action !== payload.actionType
      );
      if (payload.actionKey) {
        state.serverPendings = state.serverPendings.filter(
          (action) => action !== payload.actionKey
        );
      }
    },
    addServerVolume: (state, { payload }) => {
      if (!state.serverDetails) return;
      state.serverDetails.volumes.push(payload);
      state.serverVolumesChanged = true;
    },
    setServerActionStatus: (state, { payload }) => {
      if (!state.serverDetails) return;
      switch (payload) {
        case ServerAction.REBOOT:
          state.serverDetails.status = ServerStatus.REBOOTING;
          break;
        case ServerAction.RESET:
          state.serverDetails.status = ServerStatus.RESTARTING;
          break;
        case ServerAction.POWER_OFF:
          state.serverDetails.status = ServerStatus.STOPPING;
          break;
        case ServerAction.POWER_ON:
          state.serverDetails.status = ServerStatus.STARTING;
          break;
        case ServerAction.SHUTDOWN:
          state.serverDetails.status = ServerStatus.STOPPING;
          break;
        default:
          break;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserServers.pending, (state) => {
        state.serversLoading = true;
        state.serversLoadingError = null;
      })
      .addCase(fetchUserServers.rejected, (state, action) => {
        state.serversLoading = false;
        state.serversLoadingError = action.error.code || "unknown";
        state.serverDetailsLoadedTimestamp = 0;
        state.serverDetails = null;
      })
      .addCase(fetchUserServers.fulfilled, (state, { payload }) => {
        state.serversLoadedTimestamp = Date.now();
        state.serversLoading = false;
        state.serversLoadingError = null;
        // state.servers = payload;
      })
      .addCase(triggerServerAction.pending, (state, { meta }) => {
        state.serverPendings.push(meta.arg.actionKey);
      })
      .addCase(triggerServerAction.rejected, (state, action) => {
        state.serverPendings = state.serverPendings.filter(
          (actionKey) => actionKey !== action.meta.arg.actionKey
        );
      })
      .addCase(triggerServerAction.fulfilled, (state, { payload, meta }) => {
        state.serverActions.push({
          actionId: payload,
          actionType: meta.arg.actionType,
          actionKey: meta.arg.actionKey,
        });
        state.serverActionNames.push(meta.arg.actionType);
        if (!state.serverDetails) return;
        if (meta.arg.actionType === ServerAction.REBUILD) {
          state.serverDetails.locked = true;
          state.serverDetails.status = ServerStatus.MIGRATING;
        }
      })
      .addCase(rescaleServer.pending, (state, { meta }) => {
        state.serverPendings.push(meta.arg.actionKey);
      })
      .addCase(rescaleServer.rejected, (state, action) => {
        state.serverPendings = state.serverPendings.filter(
          (actionKey) => actionKey !== action.meta.arg.actionKey
        );
      })
      .addCase(rescaleServer.fulfilled, (state, { payload, meta }) => {
        state.serverActions.push({
          actionId: payload,
          actionType: ServerAction.RESCALE,
          actionKey: meta.arg.actionKey,
        });
        state.serverActionNames.push(ServerAction.RESCALE);
        if (!state.serverDetails) return;
        state.serverDetails.locked = true;
        state.serverDetails.status = ServerStatus.MIGRATING;
      })
      .addCase(fetchServerDetails.pending, (state) => {
        state.serverDetailsLoading = true;
        state.serverDetailsLoadingError = null;
        state.serverVolumesChanged = false;
        state.serverImagesChanged = false;
      })
      .addCase(fetchServerDetails.rejected, (state, action) => {
        state.serverDetailsLoading = false;
        state.serverDetailsLoadingError = action.error.code || "unknown";
      })
      .addCase(fetchServerDetails.fulfilled, (state, { payload }) => {
        state.serverDetailsLoadedTimestamp = Date.now();
        state.serverDetailsLoading = false;
        state.serverDetailsLoadingError = null;
        // state.serverDetails = payload;
      })
      .addCase(updateServerImage.pending, (state, { meta }) => {
        state.serverPendings.push(meta.arg.actionKey);
      })
      .addCase(updateServerImage.rejected, (state, { meta }) => {
        state.serverPendings = state.serverPendings.filter(
          (action) => action !== meta.arg.actionKey
        );
      })
      .addCase(updateServerImage.fulfilled, (state, { payload, meta }) => {
        state.serverImagesChanged = true;
        state.serverPendings = state.serverPendings.filter(
          (action) => action !== meta.arg.actionKey
        );
        if (!state.serverDetails) return;
        if (meta.arg.changeToSnapshot) {
          // state.serverDetails.backups = state.serverDetails.backups.filter(
          //   (backup) => backup.id !== payload.id
          // );
          // state.serverDetails.snapshots.push(payload);
        } else {
          // state.serverDetails.backups = state.serverDetails.backups.map(
          //   (backup) => {
          //     if (backup.id === payload.id) return payload;
          //     return backup;
          //   }
          // );
          // state.serverDetails.snapshots = state.serverDetails.snapshots.map(
          //   (snapshot) => {
          //     if (snapshot.id === payload.id) return payload;
          //     return snapshot;
          //   }
          // );
        }
      })
      .addCase(deleteServerImage.pending, (state, { meta }) => {
        state.serverPendings.push(meta.arg.actionKey);
      })
      .addCase(deleteServerImage.rejected, (state, { meta }) => {
        state.serverPendings = state.serverPendings.filter(
          (action) => action !== meta.arg.actionKey
        );
      })
      .addCase(deleteServerImage.fulfilled, (state, { payload, meta }) => {
        state.serverImagesChanged = true;
        state.serverPendings = state.serverPendings.filter(
          (action) => action !== meta.arg.actionKey
        );
        if (!state.serverDetails) return;
        state.serverDetails.snapshots = state.serverDetails.snapshots.filter(
          (snapshot) => snapshot.id !== payload
        );
        state.serverDetails.backups = state.serverDetails.backups.filter(
          (backup) => backup.id !== payload
        );
      })
      .addCase(createServerSnapshot.pending, (state, { meta }) => {
        state.serverPendings.push(meta.arg.actionKey);
      })
      .addCase(createServerSnapshot.rejected, (state, { meta }) => {
        state.serverPendings = state.serverPendings.filter(
          (action) => action !== meta.arg.actionKey
        );
      })
      .addCase(createServerSnapshot.fulfilled, (state, { payload, meta }) => {
        state.serverImagesChanged = true;
        state.serverPendings = state.serverPendings.filter(
          (action) => action !== meta.arg.actionKey
        );
        if (!state.serverDetails) return;
        if (meta.arg.backup) {
          // state.serverDetails.backups.push(payload);
          return;
        }
        // state.serverDetails.snapshots.push(payload);
      });
  },
});

export const {
  setCurrentServerSlug,
  setServerDetailsActiveTab,
  setServerActionStatus,
  addServerAction,
  removeServerAction,
  setServerStatus,
  updateServerDetails,
  serverAddBackup,
  updateServerBackupObject,
  removeServerNotification,
  addServerNotification,
  resetServerImagesChanged,
  setServerRescueMode,
  addServerVolume,
  updateServerVolume,
  serverAddSnapshot,
  addNewServer,
  removeServer,
  addServerUpdate,
  removeServerUpdate,
  serverUpdateStatus,
} = serversSlice.actions;

export default serversSlice.reducer;

export const serversState = (state: RootState) => state.servers;
