import React from "react";
import { useParams } from "react-router-dom";
import SSHKeysCreate from "../components/SSHKeys/SSHKeyCreate";
import SSHKeysTable from "../components/SSHKeys/SSHKeysTable";
import Content from "../containers/Content";

const SSHKeys = () => {
  const params = useParams();
  const projectId = params.projectId ? parseInt(params.projectId) : undefined;
  return (
    <Content>
      <SSHKeysCreate projectId={projectId} />
      <div className="card">
        <div className="card-header header-elements-inline">
          <h6 className="card-title">لیست کلید های شما</h6>
        </div>
        <SSHKeysTable projectId={projectId} />
      </div>
    </Content>
  );
};

export default SSHKeys;
