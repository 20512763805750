import React from "react";

const BillingAddFundNotes = () => {
  return (
    <div className="card">
      <div className="card-body text-center">
        <i className="icon-piggy-bank icon-3x text-teal mt-1 mb-3" />
        <h6 className="font-weight-semibold text-center">روش های پرداخت</h6>
        <p className="text-center text-muted">شماره کارت</p>
        <p className="mb-3 text-center">6219861905276142</p>
        <p className="text-center text-muted">کد شباء</p>
        <p className="mb-3 text-center">IR370560611828005131032101</p>
        <p className="text-center">به نام سید رضا زارع پاک ضیابری</p>

        <p className="mt-3 text-center font-width-semibold text-danger">
          در صورت پرداخت به کارت یا حساب لطفا مشخصات پرداخت را در قسمت پشتیبانی
          برای اعمال به حساب شما ارسال کنید.
        </p>
      </div>
    </div>
  );
};

export default BillingAddFundNotes;
