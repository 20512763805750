import React, { useState } from "react";
import Content from "../../containers/Content";
import { BillingLogsFilter } from "../../components/Billing/BillingLogs/BillingLogsFilter";
import BillingLogsTableBody from "../../components/Billing/BillingLogs/BillingLogsTableBody";

const BillingLogs = () => {
  const [filter, setFilter] = useState({
    page: 1,
    pageSize: 20,
    serviceTypes: [
      "add_fund",
      "hc_server",
      "hc_snapshot",
      "hc_volume",
      "hc_server_backup",
      "hc_server_traffic",
      "hc_ipv4",
    ],
  } as WalletTransactionFilter);

  return (
    <Content>
      <div className="card">
        <div className="card-header header-elements-inline">
          <h5 className="card-title">جزئیات پرداخت و مصرف شما</h5>
        </div>
        <div className="card-body">
          <BillingLogsFilter filter={filter} setFilter={setFilter} />
        </div>
        <div
          className="table-responsive table-scrollable"
          style={{ maxHeight: "100%" }}
        >
          <table className="table">
            <thead>
              <tr>
                <th>بابت</th>
                <th>مقدار</th>
                <th>توضیحات</th>
                <th>دوره</th>
              </tr>
            </thead>
            <BillingLogsTableBody filter={filter} setFilter={setFilter} />
          </table>
        </div>
      </div>
    </Content>
  );
};

export default BillingLogs;
