import React from "react";
import ServerBackupTableRow from "./BackupTableRow";

const ServerDetailsBackupsTable = (props: {backups: HCImage[], serverSlug: string;}) => {

  const { backups, serverSlug } = props;
  return (
    <div
      className="table-responsive table-scrollable"
      style={{ maxHeight: "100%" }}
    >
      <table className="table">
        <thead>
          <tr>
            <th colSpan={5}>
              <h5 className="text-center">بک آپ های موجود از این سرور</h5>
            </th>
          </tr>
          <tr className="bg-primary">
            <th>توضیحات</th>
            <th>تاریخ ایجاد</th>
            <th>حجم</th>
            <th>وضعیت</th>
            <th className="text-center">گزینه ها</th>
          </tr>
        </thead>
        <tbody>
          {backups.map((backup) => (
            <ServerBackupTableRow
              backup={backup}
              serverSlug={serverSlug}
              key={"serverBackup" + backup.id}
            />
          ))}

          {backups.length === 0 && (
            <tr>
              <td colSpan={5}>
                <p className="text-center">
                  هیچ بک آپی از این سرور موجود نمیباشد.
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ServerDetailsBackupsTable;
