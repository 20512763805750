import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import { jsonToServerType } from "../../api/mappers";
import apiRoutes from "../../constants/apiRoutes";
import { authCheckAndRedirect } from "../../utilities/functions";
import getDefaultHeaders from "../utils";

export const fetchServerTypes = createAsyncThunk(
  "createServer/fetchServerTypes",
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await Axios.get(apiRoutes.V1.SERVERS.GET_SERVER_TYPES, {
        headers: getDefaultHeaders(token),
      });
      const serverTypes: ServerType[] = response.data.map((serverType: any) => {
        return jsonToServerType(serverType);
      });
      return serverTypes;
    } catch (error: any) {
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);
