import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { fetchTickets } from "../../api/ticketing";
import TicketRow from "../../components/Ticketing/TicketRow";
import SimpleLoader from "../../components/Utils/SimpleLoader";
import { APIQueryName } from "../../constants/queries";
import routes from "../../constants/routes";
import Content from "../../containers/Content";
import ContentCenter from "../../containers/ContentCenter";

const Tickets = () => {
  const { data, isError, isLoading } = useQuery(
    APIQueryName.TICKETS,
    fetchTickets,
    {
      staleTime: 15000,
      retry: 1,
    }
  );

  if (isLoading) {
    return (
      <Content>
        <ContentCenter>
          <div className="text-center mb-5 mt-2">
            <SimpleLoader iconSize={5} />
            <p className="mt-3">در حال دریافت لیست تیکت ها ...</p>
          </div>
        </ContentCenter>
      </Content>
    );
  }

  if (isError) {
    return (
      <Content>
        <ContentCenter>
          <div className="text-center mb-5 mt-2">
            <i className="icon-warning2 icon-5x mr-2" />
            <p className="mt-3">متاسفانه مشکلی در لود تیکت ها رخ داده است!</p>
          </div>
        </ContentCenter>
      </Content>
    );
  }

  return (
    <Content>
      <div className="card">
        <div className="card-header header-elements-inline">
          <h2 className="card-title">لیست درخواست های شما</h2>

          <div className="header-elements">
            <Link to={{ pathname: routes.TICKET_CREATE }}>
              <button type="button" className="btn bg-blue">
                ایجاد درخواست جدید
              </button>
            </Link>
          </div>
        </div>

        <div className="card-body">
          <p>
            لطفا تا پاسخگویی به درخواست خود صبور باشید و از ارسال تیکت جدید و
            تکراری خودداری کنید.
          </p>
        </div>
        <div className="table-responsive text-right mb-5">
          <table className="table">
            <thead>
              <tr className="bg-slate-600">
                <th className="text-center"> موضوع </th>
                <th className="text-center"> دپارتمان </th>
                <th className="text-center"> اهمیت </th>
                <th className="text-center"> وضعیت </th>
              </tr>
            </thead>
            <tbody className="server-list" key={"ticketingList"}>
              {data?.map((ticket) => (
                <TicketRow ticket={ticket} key={"ticket" + ticket.slug} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Content>
  );
};

export default Tickets;
