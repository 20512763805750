import React from "react";
import { useQuery } from "react-query";
import SSHKeyTR from "./SSHKeyTR";
import { fetchSSHKeys } from "../../api/hcSSHKeys";
import LoadingData from "../Utils/LoadingData";
import LoadDataFailed from "../Utils/LoadDataFailed";
import { APIQueryName } from "../../constants/queries";

const SSHKeysTable = (props: { projectId?: number }) => {
  const { projectId } = props;

  const { data, isError, isLoading } = useQuery(
    APIQueryName.PROJECT_SSHKEYS + projectId,
    () => fetchSSHKeys(projectId),
    {
      staleTime: 20000,
      retry: 1,
    }
  );

  if (isLoading) {
    return <LoadingData />;
  }

  if (isError) {
    return <LoadDataFailed />;
  }

  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>عنوان</th>
            <th>اثر انگشت</th>
            <th>پروژه</th>
            <th>زمان ایجاد</th>
            <th>گزینه ها</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((sshKey) => (
            <SSHKeyTR
              projectId={projectId}
              sshKey={sshKey}
              key={"sshkey" + sshKey.id}
            />
          ))}
          {data && data.length < 1 && (
            <tr>
              <td colSpan={5}>
                <p className="text-center">شما هیچ کلیدی ندارید.</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SSHKeysTable;
