import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import LoginFooter from '../components/Auth/LoginFooter'
import LoginForm from '../components/Auth/LoginForm'
import RegisterForm from '../components/Auth/RegisterForm'
import routes from '../constants/routes'
import Content from '../containers/Content'
import ContentWrapper from '../containers/ContentWrapper'
import PageContent from '../containers/PageContent'

const Authentication = () => {
  const [activeTab, setActiveTab] = useState("login")
	const [registerSuccess, setRegisterSuccess] = useState(false)

	const tabSwitcher = (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tab: string, registerSuccess = false) => {
    evt && evt.preventDefault()
		setActiveTab(tab)
		setRegisterSuccess(registerSuccess)
	}
  return (
    <PageContent>
      <ContentWrapper>
        <Content cls="d-flex justify-content-center align-items-center">
        <div className="login-form wmin-sm-400">
						<div className="card mb-0">
							<ul className="nav nav-tabs nav-justified bg-dark mb-0">
								<a href="#login" className="nav-item" onClick={(evt) => tabSwitcher(evt, "login")}><span className={activeTab === "login" ? "nav-link border-y-0 border-left-0 active show" : "nav-link border-y-0 border-left-0"} data-toggle="tab"><h6 className="my-1 text-center"> ورود به حساب </h6></span></a>
								<a href="#login" className="nav-item" onClick={(evt) => tabSwitcher(evt, "register")}><span className={activeTab === "register" ? "nav-link border-y-0 border-right-0 active show" : "nav-link border-y-0 border-right-0"} data-toggle="tab"><h6 className="my-1 text-center"> ساخت حساب </h6></span></a>
							</ul>

							<div className="tab-content card-body">
								<div className={activeTab === "login" ? "tab-pane fade active show" : "tab-pane fade"}>
									<div className="text-center mb-3">
										<i className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1" />
										{((registerSuccess) &&
											<span className="form-text text-success text-center"><i className="icon-user-check mr-2" /> ثبت نام با موفقیت انجام شد. لطفا وارد شوید.</span>
										)}
										<br />
										<h5 className="mb-0"> ورود به حساب کاربری </h5>
										{/* <span className="d-block text-muted">Your credentials</span> */}
									</div>

									<LoginForm />

									<div className="form-group text-center text-muted content-divider">
										<span className="px-2"> حساب کاربری ندارید یا رمز را فراموش کرده اید؟ </span>
									</div>

									<div className="form-group">
										<button className="btn btn-light btn-block" onClick={(evt) => { evt.preventDefault(); setActiveTab("register") }}> ایجاد حساب کاربری </button>
									</div>
									<div className="form-group">
										<Link to={routes.FORGET_PASSWORD}>
											<button className="btn btn-light btn-block"> بازیابی رمز عبور </button>
										</Link>
									</div>

									<LoginFooter />
								</div>

								<div className={activeTab === "register" ? "tab-pane fade active show" : "tab-pane fade"}>
									<div className="text-center mb-3">
										<i className="icon-plus3 icon-2x text-success border-success border-3 rounded-round p-3 mb-3 mt-1"></i>
										<h5 className="mb-0"> ساخت حساب </h5>
										<span className="d-block text-muted">وارد کردن تمام فیلد ها الزامی است</span>
									</div>

									<RegisterForm tabSwitcher={tabSwitcher} />
								</div>
							</div>
						</div>
					</div>
        </Content>
      </ContentWrapper>

    </PageContent>
  )
}

export default Authentication
