import React, { useState } from "react";
import { useDeleteHCVolume } from "../../../mutations/volumes";
import Alert from "../../Utils/Alert";
import Modal from "../../Utils/Modal";
import Spinner from "../../Utils/Spinner";

const DeleteModal = (props: { dismiss: () => void; volume: HCVolume }) => {
  const { dismiss, volume } = props;
  const { mutate, isLoading } = useDeleteHCVolume();
  const [valid, setValid] = useState(false);

  const nameFieldChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.currentTarget.value === volume.name ? setValid(true) : setValid(false);
  };

  const handleDelete = () => {
    if (!valid) return;
    mutate(volume.id);
    dismiss();
  };

  return (
    <Modal size="large">
      <div>
        <Modal.Header>
          <>
            <h5 className="modal-title">حذف فضا</h5>
            <button type="button" className="close" onClick={() => dismiss()}>
              ×
            </button>
          </>
        </Modal.Header>
        <Modal.Body>
          <>
            <p className="text-center code">
              با حذف فضا تمامی اطلاعات موجود حذف می شوند. اطلاعات تحت هیچ شرایطی
              قابل بازیابی نخواهند بود.
            </p>
            <p className="text-center">نام فضای انتخاب شده:</p>
            <p>
              <code style={{ direction: "ltr" }}>{volume.name}</code>
            </p>
            {!!volume.server && (
              <Alert
                alertType="warning"
                text="لطفا ابتدا فضا را از سروری که به آن متصل است جدا کنید."
              />
            )}
            {!volume.server && (
              <>
                <p>لطفا نام فضا را در فیلد زیر وارد کنید:</p>
                <div className="row">
                  <div className="col-lg-5 mx-lg-auto">
                    <div className="form-group">
                      <input
                        name="volumeName"
                        id="volumeName"
                        type="text"
                        className="form-control text-center"
                        placeholder={volume.name}
                        onChange={nameFieldChangeHandler}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <button
              className="btn btn-danger"
              disabled={isLoading || !!volume.server || !valid}
              onClick={handleDelete}
              style={{ direction: "ltr" }}
            >
              Delete {isLoading && <Spinner />}
            </button>
          </>
        </Modal.Body>
        <Modal.Footer>
          <>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => dismiss()}
            >
              بستن
            </button>
          </>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default DeleteModal;
