import React from "react";

const LoginFooter = () => {
  return (
    <span className="form-text text-center text-muted">
      ثبت نام یا وارد شدن و استفاده از سرویس ها به منزله مطالعه کامل و پذیرش{" "}
      <a href="https://arvix.cloud/tos">شرایط سرویس دهی و قوانین</a> است.
    </span>
  );
};

export default LoginFooter;
