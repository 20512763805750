import React from "react";
import ServerISOListTable from "./ISOListTable";

const ServerDetailsISOSection = (props: { details: HCServerDetails }) => {
  const { details } = props;
  return (
    <div className="card">
      <div className="card-body text-center">
        <i className="icon-drive icon-2x text-danger-400 border-danger-400 border-3 rounded-round p-3 mb-3 mt-1" />
        <h5 className="card-title text-center">Mount ISO</h5>

        <p className="mb-3 text-center">
          با اتصال یک ISO به سرور، قادر خواهید بود سیستم عامل مورد نظر خود را که
          در سیستم عامل های پیشفرض یافت نمی شود نصب کنید.
        </p>
        <p className="mb-3 text-center">
          بعد از ریبود سرور در صورتی که یک ISO به سرور شما متصل باشد، سرور شما
          از روی ISO متصل شده بود خواهد شد. دقت داشته باشید که در برخی از ISO ها
          باید در مدت مشخصی کلیدی را فشار دهید تا سرور از روی ISO بوت شود.
        </p>
      </div>
      <ServerISOListTable details={details} />
    </div>
  );
};

export default ServerDetailsISOSection;
