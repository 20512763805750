import React from "react";
import { getCountryNameByCode } from "../../../../utilities/functions";

const ServerDetailsLocationData = (props: { details: HCServerDetails }) => {
  const { details } = props;

  if (!details) return <div></div>;

  return (
    <div className="col-md-4">
      <div className="card">
        <div className="card-body">
          <div
            className="mb-3"
            style={{ borderBottom: "1px solid rgba(153,153,153,0.5" }}
          >
            <h2 className="m-0 pr-3 text-center">موقعیت سرور</h2>
            <p className="text-center mt-2">شهر، کشور و دیتاسنتر</p>
          </div>

          <div className="">
            <ul className="media-list">
              <li className="media">
                <div className="mr-3">
                  <span className="btn bg-transparent border-teal-600 text-teal-600 rounded-round border-2 btn-icon">
                    <i className="icon-server"></i>
                  </span>
                </div>

                <div className="media-body">
                  <span>دیتاسنتر</span>
                  <br />
                  <h6 className="font-weight-semibold">
                    {details.datacenter.description}
                  </h6>
                </div>
              </li>

              <li className="media">
                <div className="mr-3">
                  <span className="btn bg-transparent border-teal-600 text-teal-600 rounded-round border-2 btn-icon">
                    <i className="icon-location3"></i>
                  </span>
                </div>

                <div className="media-body">
                  <span>شهر</span>
                  <br />
                  <h6 className="font-weight-semibold">
                    {details.location.city}
                  </h6>
                </div>
              </li>

              <li className="media">
                <div className="mr-3">
                  <span className="btn bg-transparent border-teal-600 text-teal-600 rounded-round border-2 btn-icon">
                    <i className="icon-location4"></i>
                  </span>
                </div>

                <div className="media-body">
                  <span>کشور</span>
                  <br />
                  <h6 className="font-weight-semibold">
                    {getCountryNameByCode(details.location.country)}
                  </h6>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerDetailsLocationData;
