import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import { getTokenString } from "../../api/auth";
import { jsonToVolumeDetails } from "../../api/mappers";
import apiRoutes from "../../constants/apiRoutes";
import { authCheckAndRedirect } from "../../utilities/functions";
import { addNotification } from "../baseSlice";
import getDefaultHeaders from "../utils";

export const fetchVolumes = createAsyncThunk(
  "volumes/fetchVolumes",
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await Axios.get(apiRoutes.V1.VOLUMES.GET_VOLUMES, {
        headers: getDefaultHeaders(token),
      });
      const volumes: VolumeDetails[] = response.data.map((volume: any) => {
        return jsonToVolumeDetails(volume);
      });
      return volumes;
    } catch (error: any) {
      console.log(error);
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const detachVolume = createAsyncThunk(
  "volumes/detachVolume",
  async (
    props: { volumeId: number; actionKey: string },
    { rejectWithValue, dispatch }
  ) => {
    const { volumeId } = props;
    try {
      const response = await Axios.post(
        apiRoutes.V1.VOLUMES.DETACH(volumeId),
        {},
        {
          headers: getDefaultHeaders(getTokenString()),
        }
      );
      dispatch(addNotification({
        type: "info",
        title: "درخواست جدا سازی فضا",
        text: "درخواست شما دریافت و در حال اجراست!",
      }))
      return response.data.action_id;
    } catch (error: any) {
      dispatch(addNotification({
        type: "error",
        title: "درخواست جدا سازی فضا",
        text: "متاسفانه مشکلی در دریافت درخواست شما رخ داده است!",
      }))
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response ? error.response.status : 0,
        data: error.response ? error.response.data : null,
      });
    }
  }
);

export const attachVolume = createAsyncThunk(
  "volumes/attachVolume",
  async (
    props: { volumeId: number; actionKey: string; serverSlug: string, autoMount: string},
    { rejectWithValue, dispatch }
  ) => {
    const { volumeId, serverSlug, autoMount } = props;
    let data = new FormData();
    data.append("server", serverSlug);
    data.append("auto_mount", autoMount);
    try {
      const response = await Axios.post(
        apiRoutes.V1.VOLUMES.ATTACH(volumeId),
        data,
        {
          headers: getDefaultHeaders(getTokenString()),
        }
      );
      dispatch(addNotification({
        type: "info",
        title: "درخواست اتصال فضا",
        text: "درخواست شما دریافت و در حال اجراست!",
      }))
      return response.data.action_id;
    } catch (error: any) {
      dispatch(addNotification({
        type: "error",
        title: "درخواست اتصال فضا",
        text: "متاسفانه مشکلی در دریافت درخواست شما رخ داده است!",
      }))
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response ? error.response.status : 0,
        data: error.response ? error.response.data : null,
      });
    }
  }
);


export const resizeVolume = createAsyncThunk(
  "volumes/resizeVolume",
  async (
    props: { volumeId: number; actionKey: string; newSize: number},
    { rejectWithValue, dispatch }
  ) => {
    const { volumeId, newSize } = props;
    let data = new FormData();
    data.append("size", newSize.toString());
    try {
      const response = await Axios.post(
        apiRoutes.V1.VOLUMES.RESIZE(volumeId),
        data,
        {
          headers: getDefaultHeaders(getTokenString()),
        }
      );
      dispatch(addNotification({
        type: "info",
        title: "درخواست افزایش حجم فضا",
        text: "درخواست شما دریافت و در حال اجراست!",
      }))
      return response.data.action_id;
    } catch (error: any) {
      dispatch(addNotification({
        type: "error",
        title: "درخواست افزایش حجم فضا",
        text: "متاسفانه مشکلی در دریافت درخواست شما رخ داده است!",
      }))
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response ? error.response.status : 0,
        data: error.response ? error.response.data : null,
      });
    }
  }
);


export const deleteVolume = createAsyncThunk(
  "volumes/deleteVolume",
  async (
    props: { volumeId: number; actionKey: string;},
    { rejectWithValue, dispatch }
  ) => {
    const { volumeId } = props;
    try {
      const response = await Axios.post(
        apiRoutes.V1.VOLUMES.DELETE(volumeId),
        {},
        {
          headers: getDefaultHeaders(getTokenString()),
        }
      );
      dispatch(addNotification({
        type: "info",
        title: "درخواست حذف فضا",
        text: "درخواست شما دریافت و در حال اجراست!",
      }))
      return response.data.action_id;
    } catch (error: any) {
      dispatch(addNotification({
        type: "error",
        title: "درخواست حذف فضا",
        text: "متاسفانه مشکلی در دریافت درخواست شما رخ داده است!",
      }))
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response ? error.response.status : 0,
        data: error.response ? error.response.data : null,
      });
    }
  }
);
