import React from "react";
import BillingAddFundNotes from "../../components/Billing/AddFund/AddFundNotes";
import AddFundOnlineForm from "../../components/Billing/AddFund/AddFundOnlineForm";
import Content from "../../containers/Content";
import ContentCenter from "../../containers/ContentCenter";

const BillingAddFund = () => {
  return (
    <Content>
      <ContentCenter>
        <div className="card">
          <div className="card-body">
            <AddFundOnlineForm modal={false} />
          </div>
        </div>
        <BillingAddFundNotes />
      </ContentCenter>
    </Content>
  );
};

export default BillingAddFund;
