import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { fetchHCServers } from "../../../api/servers";
import { APIQueryName } from "../../../constants/queries";
import routes from "../../../constants/routes";
import SimpleLoader from "../../Utils/SimpleLoader";

const ServersListTableBody = (props: { projectId?: number, query?: string }) => {
  const { projectId, query } = props;
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    [APIQueryName.PROJECT_SERVERS + projectId, query],
    () => fetchHCServers(projectId || 0, query),
    {
      staleTime: 120000,
      retry: 1,
    }
  );

  return (
    <tbody>
      {data?.map((server) => (
        <tr
          className="text-center bg-light"
          key={"server" + server.slug}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(routes.SERVER_DETAILS + server.slug)}
        >
          <td className="text-center">
            <span className="font-weight-bold text-default text-center">
              {server.name}
            </span>
            <br />
            <span className="text-default text-center">
              {server.description} | {server.location.country} |{" "}
              {server.serverType.cores}
              vCPU | {server.serverType.memory}GB Ram | {server.serverType.disk}
              GB Storage
            </span>
          </td>
          <td className="text-center">{server.activeTime} ساعت</td>
          <td className="text-center">{server.ipv4}</td>
          <td className="text-center">{server.projectName}</td>
          <td className="text-center">{server.status}</td>
        </tr>
      ))}
      {data && data.length < 1 && (
        <tr>
          <td colSpan={5} className="text-center">
            شما هیچ سرور فعالی ندارید.
          </td>
        </tr>
      )}
      {isLoading && (
        <tr>
          <td colSpan={5}>
            <p className="text-center">
              <SimpleLoader iconSize={2} />
            </p>
            <p className="text-center">در حال دریافت لیست سرور ها ...</p>
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default ServersListTableBody;
