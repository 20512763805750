import React from "react";
import { useSelector } from "react-redux";
import { VolumeAction } from "../../../constants/volumes";
import { volumesState } from "../../../features/volumes/volumesSlice";
import { useHCVolumeAction } from "../../../mutations/volumes";
import Spinner from "../../Utils/Spinner";

const VolumeDetach = (props: { volume: HCVolume }) => {
  const { mutate } = useHCVolumeAction();
  const { volumesPendings } = useSelector(volumesState);

  const { volume } = props;
  const actionKey = "volumeDetach" + volume.id;

  const handleDetach = () => {
    if (volumesPendings.includes(actionKey)) return;
    mutate({volumeId: volume.id, actionKey: actionKey, actionType: VolumeAction.DETACH});
  }

  return (
    <button
      type="button"
      className="btn btn-warning btn-sm mr-1 ml-1 mt-1"
      disabled={volumesPendings.includes(actionKey)}
      onClick={handleDetach}
    >
      Detach {volumesPendings.includes(actionKey) && <Spinner/>}
    </button>
  );
};

export default VolumeDetach;
