import Axios from "axios";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTokenString } from "../../api/auth";
import apiRoutes from "../../constants/apiRoutes";
import { updateUserProfile, userState } from "../../features/users/userSlice";
import { addNotification } from "../../features/baseSlice";
import getDefaultHeaders from "../../features/utils";
import {
  emailValidator,
  phoneNumberValidator,
  postalCodeValidator,
} from "../../utilities/validators";
import EmailField from "./FormFields/EmailField";
import PhoneNumberField from "./FormFields/PhoneNumberField";

type formData = {
  firstName: string;
  lastName: string;
  email: string;
  postalCode: string;
  address: string;
  phoneNumber: string;
};

const ProfileCard = () => {
  const dispatch = useDispatch();
  const [working, setWorking] = useState(false);
  const { firstName, lastName, email, phoneNumber, address, postalCode } =
    useSelector(userState);

  const dataChanged = (newData: formData) => {
    return (
      newData.firstName !== firstName ||
      newData.lastName !== lastName ||
      newData.email !== email ||
      newData.postalCode !== postalCode ||
      newData.address !== address ||
      newData.phoneNumber !== phoneNumber
    );
  };

  const formSubmitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const newData: formData = {
      email: evt.currentTarget.email.value,
      address: evt.currentTarget.address.value,
      firstName: evt.currentTarget.firstName.value,
      lastName: evt.currentTarget.lastName.value,
      phoneNumber: evt.currentTarget.phoneNumber.value,
      postalCode: evt.currentTarget.postalCode.value,
    };
    // Don't do update if nothing changed
    if (!dataChanged(newData)) {
      return;
    }

    if (
      newData.address.length < 10 ||
      !emailValidator(newData.email) ||
      !postalCodeValidator(newData.postalCode) ||
      newData.firstName.length < 1 ||
      newData.lastName.length < 1 ||
      !phoneNumberValidator(newData.phoneNumber)
    ) {
      return;
    }
    setWorking(true);
    const data = {
      first_name: newData.firstName,
      last_name: newData.lastName,
      email,
      postal_code: newData.postalCode,
      address,
      phone: newData.phoneNumber,
    };

    Axios.put(apiRoutes.V1.USER.UPDATE_PROFILE, data, {
      headers: getDefaultHeaders(getTokenString()),
    })
      .then((response) => {
        setWorking(false);
        dispatch(updateUserProfile(newData));
        dispatch(
          addNotification({
            type: "success",
            title: "عملیات موفق",
            text: "اطلاعات با موفقیت برزورسانی شدند.",
          })
        );
      })
      .catch((error) => {
        setWorking(false);
        dispatch(
          addNotification({
            type: "error",
            title: "عملیات ناموفق",
            text: error.response.data.message,
          })
        );
      });
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="header-elements-inline">
              <h5 className="card-title">اطلاعات شما</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <form onSubmit={formSubmitHandler}>
              <div className="form-group">
                <label>نام:</label>
                <input
                  required
                  minLength={1}
                  type="text"
                  name="firstName"
                  className="form-control"
                  defaultValue={firstName}
                />
              </div>

              <div className="form-group">
                <label>نام خانوادگی:</label>
                <input
                  required
                  minLength={1}
                  type="text"
                  name="lastName"
                  className="form-control"
                  defaultValue={lastName}
                />
              </div>

              <EmailField />

              <div className="form-group">
                <label>کد پستی:</label>
                <input
                  required
                  minLength={10}
                  maxLength={10}
                  type="text"
                  name="postalCode"
                  className="form-control persian-number"
                  defaultValue={postalCode || ""}
                />
              </div>

              <div className="form-group">
                <label>آدرس:</label>
                <input
                  required
                  minLength={21}
                  type="text"
                  name="address"
                  className="form-control"
                  defaultValue={address || ""}
                />
              </div>

              <PhoneNumberField />

              <div className="text-center">
                {!working && (
                  <button type="submit" className="btn btn-primary">
                    تایید و بروزرسانی
                  </button>
                )}
                {working && (
                  <button disabled className="btn btn-primary">
                    <i className="icon-spinner6 spinner mr-2" /> در حال
                    بروزرسانی ...
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
