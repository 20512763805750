import React from 'react'

type Props = {
  iconSize?: number,
  extraClass?: string
}


const SimpleLoader = (props: Props) => {
  const { iconSize, extraClass } = props
    return (
        <i className={`icon-spinner2 icon-${iconSize}x spinner ${extraClass}`} />
    )
}
export default SimpleLoader
