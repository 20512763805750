import React, { useEffect, useState } from "react";
import SimpleLoader from "../../../Utils/SimpleLoader";
import ServerLocationItem from "./ServerLocationItem";

const ServerLocationSection = (props: {
  loading: boolean;
  configurations: HCServerConfiguration[] | undefined;
  onLocationChange: (index: number) => void;
}) => {
  const { loading, configurations, onLocationChange } = props;
  const [selectedLocation, setSelectedLocation] = useState<null | number>(null);

  useEffect(() => {
    if (configurations && !selectedLocation) {
      setSelectedLocation(configurations[0].location.id)
      onLocationChange(0)
    }
  }, [configurations, onLocationChange, selectedLocation]);

  const locationChangeHandler = (index: number) => {
    setSelectedLocation(configurations ? configurations[index].location.id : 0)
    onLocationChange(index)
  }

  return (
    <div className="row d-flex justify-content-center">
      <input
        name="serverLocation"
        id="serverLocation"
        type="hidden"
        value={selectedLocation || 0}
      />
      {configurations?.map((config, index) => (
        <ServerLocationItem
          key={"serverLocation" + config.location.id}
          selected={selectedLocation === config.location.id}
          location={config.location}
          selectHandler={() => locationChangeHandler(index)}
        />
      ))}
      {loading && (
        <div className="col-md-12 text-center">
          <SimpleLoader />
          <p className="text-center">در حال دریافت لیست لوکیشن ها ...</p>
        </div>
      )}
    </div>
  );
};

export default ServerLocationSection;
