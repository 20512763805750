import Axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getTokenString } from "../../../api/auth";
import apiRoutes from "../../../constants/apiRoutes";
import { addNotification } from "../../../features/baseSlice";
import getDefaultHeaders from "../../../features/utils";
import { authCheckAndRedirect } from "../../../utilities/functions";

const AddFundOnlineForm = (props: { modal?: boolean }) => {
  const { modal } = props;

  const dispatch = useDispatch();

  const [valid, setValid] = useState(true);
  const [working, setWorking] = useState(false);

  const formSubmitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    setValid(true);
    setWorking(true);
    const selectedAmount = evt.currentTarget.amount.value;
    if (selectedAmount > 10000000 || selectedAmount < 1000) {
      setValid(false);
      return;
    }

    const data = { amount: parseInt(selectedAmount) };
    Axios.post(apiRoutes.V1.PAYMENT.INITIATE, data, {
      headers: getDefaultHeaders(getTokenString()),
    })
      .then((response) => {
        window.location.href = response.data.url;
      })
      .catch((error) => {
        authCheckAndRedirect(error.response);
        dispatch(
          addNotification({
            type: "error",
            title: "عملیات ناموفق",
            text: "متاسفانه مشکلی در ایجاد ایجاد فاکتور رخ داده است!",
          })
        );
      });
  };

  return (
    <form onSubmit={formSubmitHandler}>
      <div className="row justify-content-md-center">
        <div className="col-md-6 alert alert-info alert-styled-left text-center">
          <span className="font-weight-semibold">
            در صورت پرداخت از طریق کارت به کارت 2% شارژ بیشتر دریافت خواهید کرد!
          </span>
        </div>
      </div>
      <div className="form-group row justify-content-md-center">
        <div
          className={modal ? "col-md-4 text-center" : "col-md-2 text-center"}
        >
          <label className="">مقدار: </label>
          <div>
            <div className="input-group bootstrap-touchspin text-center">
              <span className="input-group-prepend bootstrap-touchspin-prefix d-none">
                <span className="input-group-text" />
              </span>
              <input
                name="amount"
                id="amount"
                type="number"
                className={
                  !valid
                    ? "form-control touchspin-empty border-danger text-center"
                    : "form-control touchspin-empty text-center"
                }
                defaultValue={10000}
                min={1000}
                max={10000000}
              />
              <span className="input-group-append bootstrap-touchspin-postfix d-none">
                <span className="input-group-text" />
              </span>
            </div>
            <span className="form-text text-muted text-center">تومان</span>
          </div>
        </div>
      </div>
      <div>
        <p className="text-center">- حداقل مقدار: ۱۰۰۰ تومان</p>
        <p className="text-center">- حداکثر مقدار: ۱۰۰۰۰۰۰۰ تومان</p>
      </div>
      <div className="text-center mt-3">
        {working && (
          <button type="button" disabled className="btn bg-success-400">
            <i className="icon-spinner6 spinner mr-2" /> در حال ایجاد فاکتور ...
          </button>
        )}
        {!working && (
          <button className="btn bg-success-400">
            <i className="icon-plus3 mr-2" /> پرداخت
          </button>
        )}
      </div>
    </form>
  );
};

export default AddFundOnlineForm;
