import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { deleteSnapshot, updateSnapshot } from "../api/hcImages";
import { APIQueryName } from "../constants/queries";
import { addNotification } from "../features/baseSlice";

export const useDeleteHCImage = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (req: { id: number; serverSlug: string }) => {
      return deleteSnapshot(req.id);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(
          APIQueryName.SERVER_DETAILS + variables.serverSlug,
          (details: any) => {
            if (!details) {
              return;
            }
            details.snapshots = details.snapshots.filter(
              (snapshot: any) => snapshot.id !== variables.id
            );
            details.backups = details.backups.filter(
              (backup: any) => backup.id !== variables.id
            );
            return details;
          }
        );
        queryClient.setQueryData(
          APIQueryName.HC_SNAPSHOTS,
          (snapshots: any) => {
            if (!snapshots) {
              return;
            }
            return snapshots.filter(
              (snapshot: any) => snapshot.id !== variables.id
            );
          }
        );
        queryClient.setQueryData(APIQueryName.HC_BACKUPS, (backups: any) => {
          if (!backups) {
            return;
          }
          backups = backups.filter((backup: any) => backup.id !== variables.id);
          return backups;
        });
        dispatch(
          addNotification({
            type: "success",
            title: "حذف بک آپ/اسنپ شات",
            text: "بک آپ/اسنپ شات با موفقیت حذف شد.",
          })
        );
      },
      onError: () => {
        dispatch(
          addNotification({
            type: "error",
            title: "حذف بک آپ/اسنپ شات",
            text: "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};

export const useUpdateHCImage = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (req: { id: number; serverSlug: string; description: string, type: string}) => {
      return updateSnapshot(req.id, req.description, req.type);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(
          APIQueryName.SERVER_DETAILS + variables.serverSlug,
          (details: any) => {
            if (!details) {
              return;
            }
            // if backup was converted to snapshot
            if (variables.type === "snapshot") {
              details.snapshots = [...details.snapshots, result];
              details.backups = details.backups.filter((backup: any) => backup.id !== variables.id);
              return details
            }
            details.snapshots = details.snapshots.map((snapshot: any) => {
              if (snapshot.id === result.id) return result;
              return snapshot;
            });
            details.backups = details.backups.map((backup: any) => {
              if (backup.id === result.id) return result;
              return backup;
            });
            return details;
          }
        );
        queryClient.setQueryData(
          APIQueryName.HC_SNAPSHOTS,
          (snapshots: any) => {
            if (!snapshots) {
              return;
            }
            if (variables.type === "snapshot") {
              snapshots = [...snapshots, result];
              return snapshots
            }
            snapshots = snapshots.map((snapshot: any) => {
              if (snapshot.id === result.id) return result;
              return snapshot;
            });
            return snapshots;
          }
        );
        queryClient.setQueryData(APIQueryName.HC_BACKUPS, (backups: any) => {
          if (!backups) {
            return;
          }
          if (variables.type === "snapshot") {
            backups = backups.filter((backup: any) => backup.id !== variables.id);
            return backups;
          }
          backups = backups.map((backups: any) => {
            if (backups.id === result.id) return result;
            return backups;
          });
          return backups;
        });
        dispatch(
          addNotification({
            type: "success",
            title: "بروزرسانی بک آپ/اسنپ شات",
            text: "بک آپ/اسنپ شات با موفقیت بروزرسانی شد.",
          })
        );
      },
      onError: () => {
        dispatch(
          addNotification({
            type: "error",
            title: "بروزرسانی بک آپ/اسنپ شات",
            text: "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};
