import React, { useState } from 'react'
import 'react-input-range/lib/css/index.css'

const VolumeSizeSelect = (props: { modal?: boolean, value?: number, minValue?: number}) => {

  const {modal, value, minValue} = props

    const [volumeSize, setVolumeSize] = useState(value ? value : 10)
    const [valid, setValid] = useState(true)

    const sizeValidator = (newSize: number) => {
        if ((newSize < (minValue || 10) || newSize > 1024) || (value && newSize < value)) {
            setValid(false)
            return
        }
        setValid(true)
    }

    const sizeChangeHandler = (value: number) => {
        setVolumeSize(value)
        sizeValidator(value)
    }

    const inputChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
        evt.preventDefault()
        const newValue = Number(evt.currentTarget.value)
        sizeChangeHandler(newValue)
        sizeValidator(newValue)
    }

    return (
        <div>
            <div className="form-group row justify-content-md-center">
                <div className={modal ? "col-md-4 text-center" : "col-md-2 text-center"}>
                    <label className="">فضای مورد نیاز: </label>
                    <div>
                        <div className="input-group bootstrap-touchspin text-center">
                            <span className="input-group-prepend">
                                <button
                                    className={!valid ? "btn btn-light bootstrap-touchspin-down border-danger" : "btn btn-light bootstrap-touchspin-down"}
                                    type="button"
                                    onClick={() => { (volumeSize > (minValue || 10) && (!value || volumeSize - 1 >= value)) ? sizeChangeHandler(volumeSize - 1) : setVolumeSize(volumeSize) }}
                                >–</button>
                            </span>
                            <span className="input-group-prepend bootstrap-touchspin-prefix d-none">
                                <span className="input-group-text">
                                </span>
                            </span>
                            <input name="volumeSize" id="volumeSize" type="text"
                                className={!valid ? "form-control touchspin-empty border-danger text-center" : "form-control touchspin-empty text-center"}
                                value={volumeSize}
                                onChange={inputChangeHandler}
                            />
                            <span className="input-group-append bootstrap-touchspin-postfix d-none">
                                <span className="input-group-text">
                                </span>
                            </span>
                            <span className="input-group-append">
                                <button
                                    className={!valid ? "btn btn-light bootstrap-touchspin-up border-danger" : "btn btn-light bootstrap-touchspin-up"}
                                    type="button"
                                    onClick={() => { volumeSize < 10240 ? sizeChangeHandler(volumeSize + 1) : setVolumeSize(volumeSize) }}
                                >+</button>
                            </span>
                        </div>
                        {volumeSize < (minValue || 10) && volumeSize <= 10240 && value && !valid &&
                            <span className="form-text text-danger text-center">امکان کاهش فضا وجود ندارد</span>
                        }
                        {volumeSize < 10 &&
                            <span className="form-text text-danger text-center">حداقل فضا: 10GB</span>
                        }
                        {volumeSize > 10240 &&
                            <span className="form-text text-danger text-center">حداکثر فضا: 1024</span>
                        }
                        <span className="form-text text-muted text-center">هزینه ماهانه: {(volumeSize * 0.04).toFixed(2)} یورو</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VolumeSizeSelect
