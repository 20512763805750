import axios from "axios";
import apiRoutes from "../constants/apiRoutes";
import getDefaultHeaders from "../features/utils";
import { authCheckAndRedirect } from "../utilities/functions";
import { getTokenString } from "./auth";
import { jsonToSSHKey } from "./mappers";

export const fetchSSHKeys = async (projectId?: number) => {
  try {
    const { data } = await axios.get(apiRoutes.V1.SSH_KEYS.LIST(projectId), {
      headers: getDefaultHeaders(getTokenString()),
    });
    const keys: SSHKey[] = data.map((key: any) => jsonToSSHKey(key));
    return keys;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const deleteSSHKey = async (id: number) => {
  try {
    const { data } = await axios.delete(apiRoutes.V1.SSH_KEYS.DELETE(id), {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: SimpleResponse = { status: data.status };
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const createSSHKey = async (payload: CreateSSHKeyRequest) => {
  try {
    const { data } = await axios.post(apiRoutes.V1.SSH_KEYS.CREATE, payload, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: SSHKey = jsonToSSHKey(data);
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};
