import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchBillingLogs, fetchBillingStats } from "./billingAPI";

const billingSlice = createSlice({
  name: "billing",
  initialState: {
    billingLoading: false,
    billingLoadingError: false,
    billingLoadedTimestamp: 0,
    billingMinBalance: 0.0,
    billingShutdownLimit: 0,
    billingDeleteLimit: 0,
    billingExchangeRates: [] as ExchangeRate[],
    billingMonthlyCosts: {
      servers: 0,
      volumes: 0,
      snapshots: 0,
      ips: 0,
      total: 0,
    },
    // Logs
    billingLogsLoading: false,
    billingLogsLoadingError: false,
    billingLogsLoadedTimestamp: 0,
    billingLogsHasPrevious: false,
    billingLogsHasNext: false,
    billingLogs: [] as BillingLog[],
    billingLogsLimit: 20,
    billingLogsOffset: 0,
    billingLogsFilter: {
      startDate: null,
      endDate: null,
      logTypes: [
        "server",
        "volume",
        "snapshot",
        "extra_traffic",
        "server_backup_enabled",
        "ip",
        "add_fund",
      ],
    } as BillingLogsFilter,
  },
  reducers: {
    billingLogsChangePage: (state, { payload }) => {
      state.billingLogsOffset = payload > 0 ? payload : 0;
    },
    billingLogsChangeLimit: (state, { payload }) => {
      state.billingLogsLimit = payload;
    },
    billingSetFilters: (state, { payload }) => {
      state.billingLogsFilter = payload ;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBillingStats.pending, (state) => {
        state.billingLoading = true;
        state.billingLoadingError = false;
        state.billingLogs = [];
      })
      .addCase(fetchBillingStats.rejected, (state, action) => {
        state.billingLoading = false;
        state.billingLoadingError = true;
      })
      .addCase(fetchBillingStats.fulfilled, (state, { payload }) => {
        state.billingLoadedTimestamp = Date.now();
        state.billingLoading = false;
        state.billingLoadingError = false;
        state.billingMinBalance = payload.min_balance;
        state.billingShutdownLimit = payload.shutdown_limit;
        state.billingDeleteLimit = payload.delete_limit;
        state.billingMonthlyCosts = payload.month_costs;
        state.billingExchangeRates = payload.exchange_rates.map((rate: any) => {
          return {
            from: rate.from_currency.name,
            to: rate.to_currency.name,
            rate: rate.rate,
          };
        });
      })
      .addCase(fetchBillingLogs.pending, (state) => {
        state.billingLoading = true;
        state.billingLogsLoadingError = false;
      })
      .addCase(fetchBillingLogs.rejected, (state, action) => {
        state.billingLogsLoading = false;
        state.billingLogsLoadedTimestamp = Date.now();
        state.billingLogsLoadingError = true;
      })
      .addCase(fetchBillingLogs.fulfilled, (state, { payload }) => {
        state.billingLogsLoadedTimestamp = Date.now();
        state.billingLogsLoading = false;
        state.billingLogsLoadingError = false;
        // state.billingLogs = payload.logs;
        // state.billingLogsHasNext = payload.hasNext;
        // state.billingLogsHasPrevious = payload.hasPrevious;
      });
  },
});

export const { billingLogsChangePage, billingSetFilters, billingLogsChangeLimit } = billingSlice.actions;

export default billingSlice.reducer;

export const billingState = (state: RootState) => state.billing;
