import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import apiRoutes from "../../constants/apiRoutes";
import { authCheckAndRedirect } from "../../utilities/functions";
import getDefaultHeaders from "../utils";

export const fetchKeys = createAsyncThunk(
  "sshKeys/fetchKeys",
  async (token: string, {rejectWithValue}) => {
    try {
      const response = await Axios.get(apiRoutes.V1.SSH_KEYS.LIST(), {
        headers: getDefaultHeaders(token),
      });
      let sshKeys: SSHKey[] = response.data.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          fingerprint: item.fingerprint,
          created: item.created,
          publicKey: item.public_key,
        }
      })
      return sshKeys;
    } catch (error: any) {
      authCheckAndRedirect(error.response)
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data
      })
    }
  }
);
