import React from "react";
import { useQuery } from "react-query";
import { fetchHCImages } from "../../api/hcImages";
import { APIQueryName } from "../../constants/queries";
import UserSnapshotTableRow from "./SnapshotTableRow";

const UserSnapshotsTable = (props: { projectId?: number }) => {
  const { projectId } = props;
  const { data } = useQuery(
    APIQueryName.HC_SNAPSHOTS,
    () => fetchHCImages(["snapshot"], undefined, projectId),
    { staleTime: 30000, retry: 1 }
  );
  return (
    <div
      className="table-responsive table-scrollable"
      style={{ maxHeight: "100%" }}
    >
      <table className="table">
        <thead>
          <tr>
            <th colSpan={6}>
              <h5 className="text-center">اسنپ شات های موجود در این پروژه</h5>
            </th>
          </tr>
          <tr>
            <th>توضیحات</th>
            <th>تاریخ ایجاد</th>
            <th>حجم</th>
            <th>سرور</th>
            <th>وضعیت</th>
            <th className="text-center">گزینه ها</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((snapshot) => (
            <UserSnapshotTableRow
              snapshot={snapshot}
              key={"userSnapshot" + snapshot.id}
            />
          ))}

          {data?.length === 0 && (
            <tr>
              <td colSpan={6}>
                <p className="text-center">شما هیچ اسنپ شاتی ندارید.</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserSnapshotsTable;
