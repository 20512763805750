import React from "react";
import { numberWithCommas } from "../../../../utilities/functions";

const ServerDetailsConfigData = (props: { details: HCServerDetails }) => {
  const { details } = props;

  if (!details) return <div></div>;

  return (
    <div className="col-md-4">
      <div className="card">
        <div className="card-body">
          <div
            className="mb-3"
            style={{ borderBottom: "1px solid rgba(153,153,153,0.5" }}
          >
            <h2 className="m-0 pr-3 text-center">مشخصات سرور</h2>
            <p className="text-center mt-2">
              {details.serverType.name.toUpperCase()}
            </p>
          </div>

          <div className="">
            <ul className="media-list">
              <li className="media">
                <div className="mr-3">
                  <span className="btn bg-transparent border-primary text-primary rounded-round border-2 btn-icon">
                    <i className="icon-chip"></i>
                  </span>
                </div>

                <div className="media-body">
                  <span>پردازنده</span>
                  <br />
                  <h6 className="font-weight-semibold">
                    {details.serverType.cores}vCore
                  </h6>
                </div>
              </li>

              <li className="media">
                <div className="mr-3">
                  <span className="btn bg-transparent border-primary text-primary rounded-round border-2 btn-icon">
                    <i className="icon-eraser3"></i>
                  </span>
                </div>

                <div className="media-body">
                  <span>رم</span>
                  <br />
                  <h6 className="font-weight-semibold">
                    {details.serverType.memory}GB
                  </h6>
                </div>
              </li>

              <li className="media">
                <div className="mr-3">
                  <span className="btn bg-transparent border-primary text-primary rounded-round border-2 btn-icon">
                    <i className="icon-box-add"></i>
                  </span>
                </div>

                <div className="media-body">
                  <span>دیسک</span>
                  <br />
                  <h6 className="font-weight-semibold">{details.diskSize}GB</h6>
                </div>
              </li>

              <li className="media">
                <div className="mr-3">
                  <span className="btn bg-transparent border-primary text-primary rounded-round border-2 btn-icon">
                    <i className="icon-cash4"></i>
                  </span>
                </div>

                <div className="media-body">
                  <span>هزینه ماهانه</span>
                  <br />
                  <h6 className="font-weight-semibold">
                    {numberWithCommas(
                      details.serverType.pricing
                        ? details.serverType.pricing.monthlyPrice
                        : 0
                    )}{" "}
                    تومان
                  </h6>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerDetailsConfigData;
