import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchProject } from "../../api/projects";
import ProjectTabs from "../../components/Projects/ProjectTabs";
import ServersList from "../../components/UserServers/List/ServersList";
import SimpleLoader from "../../components/Utils/SimpleLoader";
import { APIQueryName } from "../../constants/queries";
import Content from "../../containers/Content";
import ContentCenter from "../../containers/ContentCenter";

const ProjectServers = () => {
  const params = useParams();
  const projectId = parseInt(params.projectId || "0");

  const { data, isLoading } = useQuery(
    APIQueryName.PROJECT_DETAILS + projectId,
    () => fetchProject(projectId),
    {
      staleTime: 120000,
      retry: 1,
    }
  );
  if (isLoading) {
    return (
      <Content>
        <ContentCenter>
          <div className="text-center mb-5 mt-2">
            <SimpleLoader iconSize={5} />
          </div>
        </ContentCenter>
      </Content>
    );
  }

  return (
    <Content>
      {data && (
        <div className="mt-3 mb-4">
          <h4 className="text-center">{data.name}</h4>
          {/* <p className="text-muted text-center mb-3">
            سرور ها را می توانید از چندین لوکشن مختلف تهیه کنید. تفاوتی در کیفیت
            سخت افزار و شبکه این لوکیشن ها وجود ندارد. توصیه می شود نزدیک ترین
            لوکیشن به کاربرانتان را انتخاب کنید.
          </p> */}
        </div>
      )}
      <ProjectTabs projectId={projectId} />
      <ServersList projectId={projectId} />
    </Content>
  );
};

export default ProjectServers;
