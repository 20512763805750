import React from "react";
import ServerVolumeCreate from "./VolumeCreate";
import ServerDetailsVolumeNotes from "./VolumeNotes";
import VolumesTable from "../../../Volumes/VolumesTable";

const ServerDetailsVolumeSection = (props: { details: HCServerDetails }) => {
  const { details } = props;

  return (
    <div>
      <div className="card">
        <ServerDetailsVolumeNotes />
        <VolumesTable volumes={details.volumes} serverSection={true} />
      </div>
      <div className="card">
        <div className="card-body text-center">
          <h5 className="card-title text-center">اضافه کردن فضا</h5>
          <ServerVolumeCreate slug={details.slug} />
        </div>
      </div>
    </div>
  );
};

export default ServerDetailsVolumeSection;
