import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import { getTokenString } from "../../api/auth";
import { jsonToBillingLogs } from "../../api/mappers";
import apiRoutes from "../../constants/apiRoutes";
import { authCheckAndRedirect } from "../../utilities/functions";
import getDefaultHeaders from "../utils";

export const fetchBillingStats = createAsyncThunk(
  "billing/fetchBillingStats",
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await Axios.get(apiRoutes.V1.BILLING.GET_STATS, {
        headers: getDefaultHeaders(token),
      });
      return response.data;
    } catch (error: any) {
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const fetchBillingLogs = createAsyncThunk(
  "billing/fetchBillingLogs",
  async (
    settings: { filters: BillingLogsFilter; offset: number; limit: number },
    { rejectWithValue }
  ) => {
    const { startDate, endDate, logTypes } = settings.filters;
    let url =
      apiRoutes.V1.BILLING.GET_LOGS +
      "?offset=" +
      settings.offset +
      "&limit=" +
      settings.limit;
    if (logTypes) {
      logTypes.forEach((t) => {
        url += "&type=" + t;
      });
    }
    if (startDate) url += "&from=" + startDate;
    if (endDate) url += "&to=" + endDate;

    try {
      const response = await Axios.get(url, {
        headers: getDefaultHeaders(getTokenString()),
      });
      let logs: BillingLog[] = response.data.results.map((item: any) => {return jsonToBillingLogs(item)});

      return {
        logs,
        hasNext: !!response.data.next,
        hasPrevious: !!response.data.previous,
      };
    } catch (error: any) {
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);
