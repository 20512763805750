import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  fetchTicketDepartments,
  fetchTicketPriorities,
  fetchTickets,
  fetchTicketStats,
} from "./ticketingAPI";

const ticketingSlice = createSlice({
  name: "ticketing",
  initialState: {
    tickets: [] as Ticket[],
    ticketsLoading: false,
    ticketsLoaded: false,
    ticketsLoadError: null as any,
    // Departments
    departments: [] as TicketDepartment[],
    departmentsLoading: false,
    departmentsLoaded: false,
    departmentsLoadError: null as any,
    // Priorities
    priorities: [] as TicketPriority[],
    prioritiesLoading: false,
    prioritiesLoaded: false,
    prioritiesLoadError: null as any,
    // Stats
    statsLoading: false,
    statsLoaded: false,
    statsLoadError: null as any,
    stats: { total: 0, answered: 0, waiting: 0 } as TicketStats,
  },
  reducers: {
    addTicket: (state, { payload }) => {
      state.tickets.unshift(payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTickets.pending, (state) => {
        state.ticketsLoading = true;
        state.ticketsLoadError = null;
      })
      .addCase(fetchTickets.rejected, (state, { payload }) => {
        state.ticketsLoadError = payload;
        state.ticketsLoading = false;
        state.ticketsLoaded = true;
      })
      .addCase(fetchTickets.fulfilled, (state, { payload }) => {
        state.ticketsLoaded = true;
        state.ticketsLoading = false;
        state.ticketsLoadError = null;
        // state.tickets = payload;
      })
      .addCase(fetchTicketStats.pending, (state) => {
        state.statsLoading = true;
        state.statsLoadError = null;
      })
      .addCase(fetchTicketStats.rejected, (state, { payload }) => {
        state.statsLoadError = payload;
        state.statsLoading = false;
        state.statsLoaded = true;
      })
      .addCase(fetchTicketStats.fulfilled, (state, { payload }) => {
        state.statsLoaded = true;
        state.statsLoading = false;
        state.statsLoadError = null;
        // state.stats = payload;
      })
      .addCase(fetchTicketDepartments.pending, (state) => {
        state.departmentsLoading = true;
        state.departmentsLoadError = false;
      })
      .addCase(fetchTicketDepartments.rejected, (state, { payload }) => {
        state.departmentsLoadError = payload;
      })
      .addCase(fetchTicketDepartments.fulfilled, (state, { payload }) => {
        state.departmentsLoaded = true;
        state.departmentsLoading = false;
        state.departmentsLoadError = false;
        // state.departments = payload;
      })
      .addCase(fetchTicketPriorities.pending, (state) => {
        state.prioritiesLoading = true;
        state.prioritiesLoadError = false;
      })
      .addCase(fetchTicketPriorities.rejected, (state, { payload }) => {
        state.prioritiesLoadError = payload;
      })
      .addCase(fetchTicketPriorities.fulfilled, (state, { payload }) => {
        state.prioritiesLoaded = true;
        state.prioritiesLoading = false;
        state.prioritiesLoadError = false;
        // state.priorities = payload;
      });
  },
});

export const { addTicket } = ticketingSlice.actions;

export default ticketingSlice.reducer;

export const ticketingState = (state: RootState) => state.ticketing;
