import React from "react";

const ConsoleCredentials = (props: { password: string; url: string }) => {
  const { password, url } = props;
  return (
    <div className="container mb-4">
      <p className="mb-2 mr-5">
        <strong>رمز عبور: </strong>
        <code>{password}</code>
      </p>
      <p className="mb-1 mr-5">
        <strong>لینک ورود: </strong>
        <code>{url}</code>
      </p>
    </div>
  );
};

export default ConsoleCredentials;
