import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { fetchUserServicedsStats } from "../../api/projects";
import { APIQueryName } from "../../constants/queries";
import routes from "../../constants/routes";
import SimpleLoader from "../Utils/SimpleLoader";

const UserServiceStats = () => {
  const { data, isLoading } = useQuery(
    APIQueryName.USER_SERVICES_STATS,
    fetchUserServicedsStats,
    {
      staleTime: 60000,
      retry: 1,
    }
  );
  return (
    <div className="card card-body mt-3">
      <p className="text-center font-weight-semibold">آمار سرویس ها</p>
      <div className="row text-center">
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 p-2 text-center">
          <p className="text-center">
            <Link to={routes.SERVERS}>
              <i className="icon-server icon-2x d-inline-block text-blue-400" />
            </Link>
          </p>
          {isLoading && <SimpleLoader />}
          {data && (
            <h5 className="font-weight-semibold mb-0 text-center">
              {data.servers.total}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">تعداد سرور ها</span>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 p-2 text-center">
          <p className="text-center">
            <Link to={routes.VOLUMES}>
              <i className="icon-database-add icon-2x d-inline-block text-orange-600" />
            </Link>
          </p>
          {isLoading && <SimpleLoader />}
          {data && (
            <h5 className="font-weight-semibold mb-0 text-center">
              {`${data.volumes.total} - ${data.volumes.totalSize}GB`}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">فضا های اضافه</span>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 p-2 text-center">
          <p className="text-center">
            <Link to={routes.SNAPSHOTS}>
              <i className="icon-camera icon-2x d-inline-block text-teal-600" />
            </Link>
          </p>
          {isLoading && <SimpleLoader />}
          {data && (
            <h5 className="font-weight-semibold mb-0 text-center">
              {`${data.snapshots.total} - ${data.snapshots.totalSize.toFixed(
                2
              )}GB`}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">اسنپ شات ها</span>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 p-2 text-center">
          <p className="text-center">
            <i className="icon-key icon-2x d-inline-block text-slate-600" />
          </p>
          {isLoading && <SimpleLoader />}
          {data && (
            <h5 className="font-weight-semibold mb-0 text-center">
              {data.sshKeys.total}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">کلید های SSH</span>
        </div>
      </div>
    </div>
  );
};

export default UserServiceStats;
