import React from "react";
import BillingDetailedFutureCosts from "../../components/Billing/Stats/DetailedFutureCosts";
import BillingFutureCost from "../../components/Billing/Stats/FutureCost";
import BillingUserCredit from "../../components/Billing/Stats/UserCredit";
import Content from "../../containers/Content";

const BillingStats = () => {
  return (
    <Content>
      <div className="row">
        <div className="col-md-6">
          <BillingUserCredit />
        </div>
        <div className="col-md-6">
          <BillingFutureCost />
        </div>
      </div>
      <div className="row">
        <BillingDetailedFutureCosts />
      </div>
    </Content>
  );
};

export default BillingStats;
