import Axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getTokenString } from "../../api/auth";
import apiRoutes from "../../constants/apiRoutes";
import Content from "../../containers/Content";
import ContentCenter from "../../containers/ContentCenter";
import ContentWrapper from "../../containers/ContentWrapper";
import PageContent from "../../containers/PageContent";
import { addNotification } from "../../features/baseSlice";
import { applyPayment } from "../../features/wallet/walletSlice";
import getDefaultHeaders from "../../features/utils";
import { jsonToPaymentConfirmationResponse } from "../../api/mappers";
import routes from "../../constants/routes";

const VerifyAddFund = () => {
  const { verificationCode } = useParams();
  const dispatch = useDispatch();

  const [verifying, setVerifying] = useState(true);
  const [verifyStatus, setVerifyStatus] = useState("verifying");
  const [paymentResponse, setPaymentResponse] =
    useState<PaymentConfirmationResponse | null>(null);

  useEffect(() => {
    Axios.get(apiRoutes.V1.PAYMENT.CONFIRM(verificationCode ? verificationCode : ""), {
      headers: getDefaultHeaders(getTokenString()),
    })
      .then((response) => {
        setVerifying(false);
        const res = jsonToPaymentConfirmationResponse(response.data);
        setPaymentResponse(res);
        if (res.success) {
          setVerifyStatus("verified");
          !res.old && dispatch(applyPayment(response.data.amount)); // if payment isn't added before
          dispatch(
            addNotification({
              type: "success",
              title: "عملیات موفق",
              text: "مبلغ پرداخت شده به حساب شما اضافه شد.",
            })
          );
        } else {
          dispatch(
            addNotification({
              type: "error",
              title: "عملیات ناموفق",
              text: "تراکنش شما تایید نشد.",
            })
          );
        }
      })
      .catch((_) => {
        setVerifying(false);
        setVerifyStatus("failed");
      });
  }, [verificationCode, dispatch]);

  return (
    <PageContent>
      <ContentWrapper>
        <Content>
          <ContentCenter>
            <div className="card">
              {verifying && (
                <div className="card-body text-center">
                  <i className="icon-spinner6 spinner icon-4x text-teal-400 border-teal-400 border-3 rounded-round p-3 mb-3 mt-1" />
                  <h5 className="card-title text-center">
                    در حال بررسی تراکنش
                  </h5>
                  <p className="mb-3 text-center">
                    مبلغ افزوده شده بعد از تایید تراکنش به حساب شما افزوده خواهد
                    شد.
                  </p>
                </div>
              )}
              {!verifying && verifyStatus === "verified" && (
                <div className="card-body text-center">
                  <i className="icon-checkmark3 icon-4x text-success-400 border-success-400 border-3 rounded-round p-3 mb-3 mt-1" />
                  <h5 className="card-title text-center">
                    تراکنش شما تایید شد
                  </h5>
                  <p className="mb-3 text-center persian-number">
                    مبلغ {paymentResponse?.amount} به حساب شما افزوده شد.
                  </p>
                  <Link to={{ pathname: routes.HOME }}>
                    <button className="btn btn-primary">
                      <i className="icon-home4 mr-2" /> پنل کاربری
                    </button>
                  </Link>
                </div>
              )}
              {!verifying && verifyStatus === "failed" && (
                <div className="card-body text-center">
                  <i className="icon-cross2 icon-4x text-danger-600 border-danger-600 border-3 rounded-round p-3 mb-3 mt-1" />
                  <h5 className="card-title text-center">
                    تراکنش مورد نظر تایید نشد
                  </h5>
                  <p className="mb-3 text-center">
                    در صورت بروز مشکلی از سمت ما، لطفا از طریق بخش پشتیبانی در
                    ارتباط باشید.
                  </p>
                  <p className="mb-3 text-center">
                    کد پیگیری: {verificationCode}
                  </p>
                  <Link to={{ pathname: routes.HOME }}>
                    <button className="btn btn-primary">
                      <i className="icon-home4 mr-2" /> پنل کاربری
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </ContentCenter>
        </Content>
      </ContentWrapper>
    </PageContent>
  );
};

export default VerifyAddFund;
