import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchServerDetails } from "../../api/servers";
import ServerDetailsContent from "../../components/UserServers/Details/DetailsContent";
import ServerDetailsTabs from "../../components/UserServers/Details/DetailsTabs";
import ServerDetailsInfoBar from "../../components/UserServers/Details/Info/InfoBar";
import Alert from "../../components/Utils/Alert";
import LoadDataFailed from "../../components/Utils/LoadDataFailed";
import LoadingData from "../../components/Utils/LoadingData";
import { APIQueryName } from "../../constants/queries";
import Content from "../../containers/Content";

const ServerDetails = () => {
  const { slug } = useParams();

  const { data, isError, isLoading } = useQuery(
    APIQueryName.SERVER_DETAILS + slug,
    () => fetchServerDetails(slug ? slug : ""),
    {
      staleTime: 120000,
      retry: 1,
    }
  );

  if (isLoading) {
    return <LoadingData />;
  }

  if (isError) {
    return <LoadDataFailed />;
  }

  return (
    <Content>
      <div className="row">
        <div className="col-12">
          {data && <ServerDetailsInfoBar details={data} />}
          {data?.rescueMode && (
            <Alert
              alertType="warning"
              text="حالت Rescue برای این سرور فعال است. برای غیر فعال سازی به تب Rescue مراجعه کنید."
            />
          )}
          {data?.creditLock && (
            <Alert
              alertType="danger"
              text="سرور به دلیل بدهی قفل و دسترسی شما محدود شده است. لطفا از قسمت مالی اقدام به شارژ حساب کنید."
            />
          )}
          {data?.iso && (
            <Alert
              alertType="warning"
              text={`در حال حاضر یک ISO به سرور شما متصل است (${data.iso.description}). برای جدا کردن به تب ISO مراجعه کنید.`}
            />
          )}
          <div className="nav-tabs-responsive mb-3">
            <ServerDetailsTabs />
          </div>
          {data && <ServerDetailsContent details={data} />}
        </div>
      </div>
    </Content>
  );
};

export default ServerDetails;
