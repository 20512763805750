/* eslint-disable import/no-cycle */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import baseReducer from "./features/baseSlice";
import userReducer from "./features/users/userSlice";
import sshKeysReducer from "./features/sshKeys/sshKeysSlice";
import ticketingReducer from "./features/ticketing/ticketingSlice";
import billingReducer from "./features/billing/billingSlice";
import serversgReducer from "./features/userServers/serverSlice";
import imagesgReducer from "./features/serverImages/imagesSlice";
import volumesReducer from "./features/volumes/volumesSlice";
import createServerReducer from "./features/createServer/createServerSlice";
import walletReducer from "./features/wallet/walletSlice";

export default function createRootReducer(history: History) {
  return combineReducers({
    router: connectRouter(history),
    base: baseReducer,
    user: userReducer,
    sshKeys: sshKeysReducer,
    ticketing: ticketingReducer,
    billing: billingReducer,
    servers: serversgReducer,
    images: imagesgReducer,
    volumes: volumesReducer,
    createServer: createServerReducer,
    wallet: walletReducer,
  });
}

