import React, { useEffect } from "react";
import { useDeleteProject } from "../../mutations/projects";
import Modal from "../Utils/Modal";

const DeleteProjectModal = (props: {
  dismiss: () => void;
  project: Project;
}) => {
  const { dismiss, project } = props;

  const { mutate, isLoading } = useDeleteProject();

  useEffect(() => {
    document.body.classList.add("modal-open");

    return () => document.body.classList.remove("modal-open");
  }, []);
  return (
    <Modal>
      <div>
        <Modal.Header>
          <>
            <h5 className="modal-title">{`حذف پروژه ${project.name}`}</h5>
            <button type="button" className="close" onClick={() => dismiss()}>
              ×
            </button>
          </>
        </Modal.Header>
        <Modal.Body>
          <>
            {(project.serversCount > 0 ||
              project.snapshotsCount > 0 ||
              project.volumesCount > 0 ||
              project.sshKeysCount > 0) && (
              <div className="alert alert-danger border-0 alert-dismissible">
                برای حذف یک پروژه لطفا ابتدا تمامی سرور ها، اسنپ شات ها، فضا های
                اضافه و کلید های SSH را حذف کنید.
              </div>
            )}
            <p className="text-center">
              برای تایید حذف پروژه لطفا بر روی کلید "حذف پروژه" کلیک کنید.
            </p>
            <div className="text-center mt-3">
              <button
                type="submit"
                className="btn bg-danger"
                onClick={() => mutate(project.id)}
                disabled={
                  project.serversCount > 0 ||
                  project.snapshotsCount > 0 ||
                  project.volumesCount > 0 ||
                  project.sshKeysCount > 0 ||
                  isLoading
                }
              >
                حذف پروژه
              </button>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => dismiss()}
            >
              لغو
            </button>
          </>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default DeleteProjectModal;
