import React from "react";
import UserSnapshotsTable from "../../components/ServerImages/SnapshotTable";
import Content from "../../containers/Content";

const UserSnapshots = (props: { projectId?: number }) => {
  const { projectId } = props;
  return (
    <Content>
      <div className="mt-3 mb-4 text-center">
        <p className="mb-3 text-muted">
          اسنپ شات یک کپی از سرور شما در لحظه درخواست ایجاد خواهد کرد. شما قادر
          خواهید بود تا از اسنپ شات ها برای ایجاد سرور جدید استفاده کنید. همچنین
          در صورت حذف سروری که اسنپ شات از آن تهیه شده، اسنپ شات همچنان باقی
          مانده و قابل استفاده خواهد بود.
        </p>
        <span className="bg-purple py-1 px-2 rounded text-center">
          <span className="text-white">
            هزینه اسنپ شات ها ۰.۰۱ یورو/ماه به ازای هر گیگابایت خواهد بود.
          </span>
        </span>
      </div>
      <div className="card">
        <UserSnapshotsTable projectId={projectId} />
      </div>
    </Content>
  );
};

export default UserSnapshots;
