import React from "react";

const BillingLogsPagination = (props: {
  filter: WalletTransactionFilter;
  setFilter: (filter: WalletTransactionFilter) => void;
  result?: WalletTransactionsPaginatedResult;
}) => {
  const { filter, setFilter, result } = props;

  const pageChangeHandler = (changeType: "next" | "prev") => {
    if (changeType === "next") {
      setFilter({ ...filter, page: filter.page + 1 });
      return;
    }
    setFilter({ ...filter, page: filter.page - 1 });
  };
  return (
    <tr>
      <td colSpan={6} className="text-center">
        <button
          disabled={!result || result.page <= 1}
          onClick={() => pageChangeHandler("prev")}
          type="button"
          className="btn btn-primary btn-icon mr-1 ml-1"
        >
          <i className="icon-arrow-right15" />
        </button>
        <button
          disabled={!result || result.total <= result.page * result.pageSize}
          onClick={() => pageChangeHandler("next")}
          type="button"
          className="btn btn-primary btn-icon mr-1 ml-1"
        >
          <i className="icon-arrow-left15" />
        </button>
      </td>
    </tr>
  );
};

export default BillingLogsPagination;
