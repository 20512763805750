import React from "react";
import { useQuery } from "react-query";
import { fetchHCImages } from "../../api/hcImages";
import { APIQueryName } from "../../constants/queries";
import UserBackupTableRow from "./BackupTableRow";

const UserBackupsTable = () => {
  const { data } = useQuery(
    APIQueryName.HC_BACKUPS,
    () => fetchHCImages(["backup"]),
    { staleTime: 30000, retry: 1 }
  );
  return (
    <div
      className="table-responsive table-scrollable"
      style={{ maxHeight: "100%" }}
    >
      <table className="table">
        <thead>
          <tr>
            <th colSpan={6}>
              <h5 className="text-center"> بک آپ های موجود از این سرور</h5>
            </th>
          </tr>
          <tr className="bg-primary">
            <th>توضیحات</th>
            <th>تاریخ ایجاد</th>
            <th>حجم</th>
            <th>سرور</th>
            <th>وضعیت</th>
            <th className="text-center">گزینه ها</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((backup) => (
            <UserBackupTableRow
              backup={backup}
              key={"userBackup" + backup.id}
            />
          ))}

          {data?.length === 0 && (
            <tr>
              <td colSpan={6}>
                <p className="text-center">شما هیچ بک آپی ندارید.</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserBackupsTable;
