import { AxiosResponse } from "axios";

export function getCountryNameByCode(countryCode: string) {
  switch (countryCode.toLowerCase()) {
    case "de":
      return "آلمان";
    case "fi":
      return "فنلاند";
    default:
      return countryCode;
  }
}

export function authCheckAndRedirect(errorResponse: AxiosResponse) {
  if (errorResponse && errorResponse.status) {
    if (errorResponse.status === 401) {
      window.location.href = "/auth";
    }
  }
}

export function getCountryFlagImage(countryCode: string) {
  switch (countryCode.toLocaleLowerCase()) {
    case "fi":
      return "/static/img/finland-flag.png";
    case "de":
      return "/static/img/german-flag.jpg";
    case "us":
      return "/static/img/us-flag.jpg";
    default:
      return "/static/img/earth-flag.jpg";
  }
}

export function getOSPrettyName(name: string) {
  switch (name) {
    case "ubuntu":
      return "Ubuntu";
    case "fedora":
      return "Fedora";
    case "centos":
      return "CentOS";
    case "debian":
      return "Debian";
    case "windows":
      return "Windows";
    case "rocky":
      return "Rocky Linux";
    default:
      return name;
  }
}

export function getOSImage(osName: string) {
  switch (osName.toLocaleLowerCase()) {
    case "fedora":
      return "/static/img/fedora-icon.png";
    case "ubuntu":
      return "/static/img/ubuntu-icon.png";
    case "centos":
      return "/static/img/centos-icon.png";
    case "debian":
      return "/static/img/debian-icon.png";
    case "windows":
      return "/static/img/windows-icon.png";
    case "rocky":
      return "/static/img/rocky-linux-icon.png";
    default:
      return "/static/img/os-icon.png";
  }
}

export function numberWithCommas(number: number) {
  const rndNumber = Math.round(number);
  return rndNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function datetimeToString(datetime: number | Date, detailed = true) {
  let dateObj;
  if (typeof datetime === "number") {
    dateObj = new Date(datetime * 1000);
  } else {
    dateObj = datetime;
  }

  // Change 1 to 01
  // EX: 1:8 => 01:08
  const convertToNormal = (n: number) => {
    if (n < 10) return "0" + n;
    return n;
  };

  let dateString = `${convertToNormal(dateObj.getFullYear())}/${convertToNormal(
    dateObj.getMonth() + 1
  )}/${convertToNormal(dateObj.getDate())}`;

  if (detailed)
    dateString += ` - ${convertToNormal(dateObj.getHours())}:${convertToNormal(
      dateObj.getMinutes()
    )}`;

  return dateString;
}

export function dateToUTCString(date: Date | null) {
  if (!date) {
    return "";
  }
  return `${date.getUTCFullYear()}-${
    date.getUTCMonth() + 1
  }-${date.getUTCDate()}`;
}

export function durationPrettify(duration: number) {
  if (duration < 1000) return `${duration}ms`;
  return `${(duration / 1000).toPrecision(3)}s`;
}
