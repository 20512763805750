// Server
export const jsonToServerType = (data: any) => {
  let prices: { [key: string]: ServerTypePrice } = {};
  Object.keys(data.prices).forEach((pr: string) => {
    prices[pr] = {
      hourly: data.prices[pr].hourly,
      hourlyEuro: data.prices[pr].hourly_euro,
      monthly: data.prices[pr].monthly,
      monthlyEuro: data.prices[pr].monthly_euro,
    };
  });

  const serverType: ServerType = {
    id: data.id,
    name: data.name,
    description: data.description,
    cores: data.cores,
    memory: data.memory,
    disk: data.disk,
    storageType: data.storage_type,
    cpuType: data.cpu_type,
    availableLocations: data.available_locations,
    prices: prices,
  };
  return serverType;
};

export const jsonToServerLocation = (data: any) => {
  const serverLocation: ServerLocation = {
    id: data.id,
    name: data.name,
    description: data.description,
    country: data.country,
    city: data.city,
    networkZone: data.network_zone,
  };
  return serverLocation;
};

export const jsonToDataCenter = (data: any) => {
  const dc: DataCenter = {
    id: data.id,
    name: data.name,
    description: data.description,
  };

  return dc;
};

export const jsonToServerSummary = (data: any) => {
  const server: ServerSummary = {
    slug: data.slug,
    locked: data.locked,
    creditLocked: data.credit_locked,
    name: data.name,
    status: data.status,
    description: data.description,
  };
  return server;
};

export const jsonToServerImageData = (data: any) => {
  const imageData: ServerImageData = {
    id: data.id,
    name: data.name,
    description: data.description,
    osFlavor: data.os_flavor,
    osVersion: data.os_version,
    diskSize: data.disk_size,
    imageSize: data.image_size,
    status: data.status,
    type: data.type,
    created: data.created,
    boundTo: data.bound_to ? jsonToServerSummary(data.bound_to) : null,
    createdFrom: data.created_from
      ? jsonToServerSummary(data.created_from)
      : null,
  };
  return imageData;
};

export const jsonToServer = (data: any) => {
  const server: UserServerData = {
    slug: data.slug,
    name: data.name,
    status: data.status,
    description: data.description,
    ipv4: data.ipv4,
    locked: data.locked,
    creditLock: data.credit_lock,
    created: data.created,
    activeTime: data.active_time,
    backupEnabled: data.backup_enabled,
    diskSize: data.disk_size,
    rescueMode: data.rescue_mode,
    serverType: jsonToServerType(data.server_type),
    serverLocation: jsonToServerLocation(data.server_location),
    dataCenter: jsonToDataCenter(data.data_center),
    image: jsonToServerImageData(data.image),
  };

  return server;
};

export const jsonToServerDetails = (data: any) => {
  const server: UserServerDetails = {
    slug: data.slug,
    name: data.name,
    status: data.status,
    description: data.description,
    ipv4: data.ipv4,
    locked: data.locked,
    creditLock: data.credit_lock,
    created: data.created,
    activeTime: data.active_time,
    backupEnabled: data.backup_enabled,
    diskSize: data.disk_size,
    rescueMode: data.rescue_mode,
    serverType: jsonToServerType(data.server_type),
    serverLocation: jsonToServerLocation(data.server_location),
    dataCenter: jsonToDataCenter(data.data_center),
    image: jsonToServerImageData(data.image),
    ipv6: data.ipv6,
    includedTraffic: data.included_traffic,
    outgoingTraffic: data.outgoing_traffic,
    incomingTraffic: data.incoming_traffic,
    billedAmount: data.billed,
    snapshots: data.snapshots
      ? data.snapshots.map((snapshot: any) => {
          return jsonToServerImageData(snapshot);
        })
      : [],
    backups: data.backups
      ? data.backups.map((backup: any) => {
          return jsonToServerImageData(backup);
        })
      : [],
    iso: data.iso ? jsonToHCISO(data.iso) : null,
    volumes: data.volumes
      ? data.volumes.map((volume: any) => {
          return jsonToVolumeDetails(volume);
        })
      : [],
  };
  return server;
};

// Volume

export const jsonToVolumeSummary = (data: any) => {
  const volume = {
    id: data.id,
    name: data.name,
    status: data.status,
    description: data.description,
    created: data.created,
    format: data.format,
    size: data.size,
  };
  return volume;
};

export const jsonToVolumeDetails = (data: any) => {
  const volume: VolumeDetails = {
    id: data.id,
    name: data.name,
    status: data.status,
    description: data.description,
    created: data.created,
    format: data.format,
    size: data.size,
    hetznerId: data.hetzner_id,
    server: data.server ? jsonToServerSummary(data.server) : null,
    location: jsonToServerLocation(data.location),
    linuxDevice: data.linux_device,
  };
  return volume;
};

// Billing

export const jsonToBillingLogs = (data: any) => {
  const log: BillingLog = {
    id: data.pk,
    amount: data.amount,
    logType: data.log_type.name,
    fromCurrency: data.from_currency.name,
    currency: data.currency.name,
    exchangeRate: data.exchange_rate_value,
    note: data.note,
    fromDate: data.from_datetime,
    toDate: data.to_datetime,
    created: data.created,
    server: data.server ? jsonToServerSummary(data.server) : null,
    snapshot: data.snapshot ? jsonToServerImageData(data.snapshot) : null,
    volume: data.volume ? jsonToVolumeSummary(data.volume) : null,
  };
  return log;
};

// Monitoring
export const jsonToWebMonitoring = (data: any) => {
  const monitoring: WebMonitoring = {
    name: data.name,
    id: data.id,
    address: data.address,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    active: data.active,
    creditLock: data.credit_lock,
    billedTill: data.billed_till,
    interval: data.check_interval,
    body: data.body,
    headers: data.headers,
    method: data.method,
    protocol: data.protocol,
    lastCheck: data.last_check,
    regions: data.regions,
  };
  return monitoring;
};

export const jsonToWebMonitoringPricing = (data: any) => {
  const base: WebMonitoringBasePricing = {
    daily: data.base.daily,
    monthly: data.base.monthly,
    minBalance: data.base.min_balance,
  };
  const pricing: WebMonitoringPricing = {
    base: base,
    intervals: data.intervals,
  };
  return pricing;
};

export const jsonToWebMonitoringAlertPricing = (data: any) => {
  const pricing: WebMonitorinAlertgPricing = {
    daily: data.daily,
    minBalance: data.min_balance,
    smsCost: data.sms_cost,
  };
  return pricing;
};

export const jsonToWebMonitoringCreateData = (data: any) => {
  const createData: WebMonitoringCreateData = {
    pricing: jsonToWebMonitoringPricing(data.pricing),
    regions: data.regions,
  };
  return createData;
};

export const jsonToWebMonitoringAlertCreateData = (data: any) => {
  const createData: MonitoringAlertCreateData = {
    pricing: jsonToWebMonitoringAlertPricing(data.pricing),
    validators: data.validators,
    regions: data.regions,
  };
  return createData;
};

export const jsonToWebMonitoringReportPoint = (data: any) => {
  const point: WebMonitoringReportPoint = {
    startPoint: data.start_point,
    endPoint: data.end_point,
    count: data.count,
    sum: data.sum,
    average: data.average,
    valuePercentage: data.value_percentage,
  };
  return point;
};

export const jsonToWebMonitoringReportAggregate = (data: any) => {
  const points: WebMonitoringReportPoint[] = data.points.map((point: any) =>
    jsonToWebMonitoringReportPoint(point)
  );
  const aggregate: WebMonitoringReportAggregate = {
    minValue: data.min_value,
    maxValue: data.max_value,
    maxCount: data.max_count,
    startPoint: data.start_point,
    endPoint: data.end_point,
    points: points,
  };
  return aggregate;
};

export const jsonToWebMonitoringReportGeneralMetrics = (data: any) => {
  const metrics: WebMonitoringReportGeneralMetrics = {
    total: data.total,
    handshake: data.handshake,
    dns: data.dns,
    firstByte: data.first_byte,
    connect: data.connect,
    redirects: data.redirects,
    bodyLength: data.body_length,
    headersLength: data.headers_length,
    successRequests: data.success_requests,
    uptime: data.uptime,
  };
  return metrics;
};

export const jsonToWebMonitoringReport = (data: any) => {
  const report: WebMonitoringReport = {
    monitoring: jsonToWebMonitoring(data.monitoring),
    from: data.from,
    to: data.to,
    period: data.period,
    region: data.region,
    dataPoints: data.data_points,
    averageTotal: data.average_total,
    generalMetrics: jsonToWebMonitoringReportGeneralMetrics(
      data.general_metrics
    ),
    totalReport: jsonToWebMonitoringReportAggregate(data.total_report),
    dnsReport: jsonToWebMonitoringReportAggregate(data.dns_report),
    handshakeReport: jsonToWebMonitoringReportAggregate(data.handshake_report),
    connectReport: jsonToWebMonitoringReportAggregate(data.connect_report),
    firstByteReport: jsonToWebMonitoringReportAggregate(data.first_byte_report),
    statusCodesReport: data.status_codes_report,
  };
  return report;
};

export const jsonToWebMonitoringAlert = (data: any) => {
  const alert: WebMonitoringAlert = {
    monitoring: data.monitoring ? jsonToWebMonitoring(data.monitoring) : null,
    monitoringID: data.monitoring_id,
    id: data.id,
    name: data.name,
    active: data.active,
    alertType: data.alert_type,
    indicator: data.indicator,
    threshold: data.threshold,
    duration: data.duration,
    repeatStep: data.repeat_step,
    direction: data.direction,
    phoneNumbers: data.phone_numbers,
    regions: data.regions,
    emails: data.emails,
    lastNotification: data.last_notification,
    creditLock: data.credit_lock,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    billedTill: data.billed_till,
    lastCheck: data.last_check,
  };
  return alert;
};

export const jsonToMonitoringAlertLog = (data: any) => {
  const log: MonitoringAlertLog = {
    id: data.id,
    alertID: data.alert_id,
    monitoringID: data.monitoring_id,
    receiver: data.receiver,
    notificationType: data.notification_type,
    createdAt: data.created_at,
  };
  return log;
};

export const jsonToTicketReply = (data: any): TicketReply => {
  const ticketReply: TicketReply = {
    text: data.text,
    adminResponse: data.admin_response,
    created: data.timestamp,
  };
  return ticketReply;
};

export const jsonToTicket = (data: any): Ticket => {
  const department: TicketDepartment = {
    id: data.department.id,
    title: data.department.title,
  };
  const priority: TicketPriority = {
    id: data.priority.id,
    title: data.priority.title,
  };
  const status: TicketStatus = {
    id: data.status.id,
    title: data.status.title,
  };
  const ticket: Ticket = {
    slug: data.slug,
    text: data.text,
    title: data.title,
    department: department,
    priority: priority,
    status: status,
    adminResponse: data.admin_response,
    closed: data.closed,
    created: data.timestamp,
    replies: data.replies.map((reply: any) => jsonToTicketReply(reply)),
  };
  return ticket;
};

export const jsonToTicketStats = (data: any) => {
  const stats: TicketStats = {
    total: data.total,
    answered: data.answered,
    waiting: data.waiting,
  };
  return stats;
};

export const jsonToWallet = (data: any) => {
  const wallet: Wallet = {
    balance: data.balance,
    monthUsage: data.month_usage,
    totalDeposits: data.total_deposits,
  };
  return wallet;
};

export const jsonToPaymentConfirmationResponse = (data: any) => {
  const res: PaymentConfirmationResponse = {
    amount: data.amount,
    key: data.key,
    success: data.success,
    old: data.old,
  };
  return res;
};

export const jsonToSSHKey = (data: any) => {
  const res: SSHKey = {
    id: data.id,
    name: data.name,
    projectId: data.project_id,
    projectName: data.project_name,
    description: data.description,
    fingerprint: data.fingerprint,
    publicKey: data.public_key,
    created: new Date(data.created_at),
  };
  return res;
};

export const jsonToTicketDepartment = (data: any) => {
  const res: TicketDepartment = { id: data.id, title: data.title };
  return res;
};

export const jsonToTicketPriority = (data: any) => {
  const res: TicketPriority = { id: data.id, title: data.title };
  return res;
};

export const jsonToHCLocation = (data: any) => {
  const res: HCLocation = {
    id: data.id,
    name: data.name,
    description: data.description,
    country: data.country,
    city: data.city,
    networkZone: data.network_zone,
  };
  return res;
};

export const jsonToHCServerTypePricing = (data: any) => {
  const res: HCServerTypePricing = {
    hourlyPrice: data.hourly_price,
    monthlyPrice: data.monthly_price,
  };
  return res;
};

export const jsonToHCServerType = (data: any) => {
  const res: HCServerType = {
    id: data.id,
    name: data.name,
    description: data.description,
    cores: data.cores,
    cpuType: data.cpu_type,
    memory: data.memory,
    disk: data.disk,
    storageType: data.storage_type,
    pricing: data.pricing ? jsonToHCServerTypePricing(data.pricing) : null,
  };
  return res;
};

export const jsonToHCServerConfiguration = (data: any) => {
  const res: HCServerConfiguration = {
    location: jsonToHCLocation(data.location),
    serverTypes: data.server_types.map((t: any) => jsonToHCServerType(t)),
  };
  return res;
};

export const jsonToBasicHCServer = (data: any) => {
  const s: HCBasicServerInfo = {
    name: data.name,
    description: data.description,
    slug: data.slug,
    deleted: data.deleted,
    status: data.status,
  };
  return s;
};

export const jsonToHCImage = (data: any) => {
  const image: HCImage = {
    id: data.id,
    projectID: data.project_id,
    projectName: data.project_name,
    hetznerProjectID: data.hetzner_project_id,
    hetznerID: data.hetzner_id,
    userID: data.user_id,
    type: data.type,
    status: data.status,
    name: data.name,
    description: data.description,
    imageSize: data.image_size,
    diskSize: data.disk_size,
    osFlavor: data.os_flavor,
    osVersion: data.os_version,
    rapidDeploy: data.rapid_deploy,
    createdAt: new Date(data.created_at),
    updatedAt: new Date(data.updated_at),
    deletedAt: data.deleted_at ? new Date(data.deleted_at) : null,
    deprecated: data.deprecated ? new Date(data.deprecated) : null,
    toSnapshot: data.to_snapshot ? new Date(data.to_snapshot) : null,
    billedTill: data.billed_till ? new Date(data.billed_till) : null,
    createdFrom: data.created_from
      ? jsonToBasicHCServer(data.created_from)
      : null,
  };
  return image;
};

export const jsonToHCServerCreateResponse = (data: any) => {
  return {
    slug: data.slug,
    name: data.name,
    ipv4: data.ipv4,
    rootPassword: data.root_password,
    serverType: data.server_type,
  } as HCServerCreateResponse;
};

export const jsonToHCISO = (data: any) => {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
  } as HCISO;
};

export const jsonToHCDatacenter = (data: any) => {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
  } as HCDatacenter;
};

export const jsonToHCVolume = (data: any) => {
  const volume: HCVolume = {
    id: data.id,
    hetznerId: data.hetzner_id,
    name: data.name,
    description: data.description,
    size: data.size,
    linuxDevice: data.linux_device,
    format: data.format,
    status: data.status,
    projectId: data.project_id,
    projectName: data.project_name,
    createdAt: new Date(data.created_at),
    server: data.server ? jsonToBasicHCServer(data.server) : null,
    location: jsonToHCLocation(data.location),
  };
  return volume;
};

export const jsonToHCServer = (data: any) => {
  const server: HCServer = {
    slug: data.slug,
    projectId: data.project_id,
    projectName: data.project_name,
    name: data.name,
    status: data.status,
    description: data.description,
    ipv4: data.ipv4,
    serverType: jsonToHCServerType(data.server_type),
    location: jsonToHCLocation(data.location),
    createdAt: new Date(data.created_at),
    activeTime: data.active_time,
    diskSize: data.disk_size,
  };
  return server;
};

export const jsonToHCServerState = (data: any) => {
  return {
    status: data.status,
    locked: data.locked,
    creditLocked: data.credit_locked,
    ready: data.ready,
    slug: data.slug,
    rescueMode: data.rescue_mode,
    activeTime: data.active_time,
  } as HCServerState;
};

export const jsonToHCServerDetails = (data: any) => {
  const details: HCServerDetails = {
    slug: data.slug,
    projectId: data.project_id,
    projectName: data.project_name,
    name: data.name,
    status: data.status,
    description: data.description,
    locked: data.locked,
    creditLock: data.credit_lock,
    ipv4: data.ipv4,
    ipv6: data.ipv6,
    serverType: jsonToHCServerType(data.server_type),
    location: jsonToHCLocation(data.location),
    createdAt: new Date(data.created_at),
    image: jsonToHCImage(data.image),
    datacenter: jsonToHCDatacenter(data.datacenter),
    activeTime: data.active_time,
    backupEnabled: data.backup_enabled,
    diskSize: data.disk_size,
    rescueMode: data.rescue_mode,
    includedTraffic: data.included_traffic,
    outgoingTraffic: data.outgoing_traffic,
    incomingTraffic: data.incoming_traffic,
    billedAmount: 0,
    snapshots: data.snapshots.map((snapshot: any) => jsonToHCImage(snapshot)),
    backups: data.backups.map((backup: any) => jsonToHCImage(backup)),
    iso: data.iso ? jsonToHCISO(data.iso) : null,
    volumes: data.volumes.map((volume: any) => jsonToHCVolume(volume)),
  };
  return details;
};

export const jsonToHCServerActionResponse = (data: any) => {
  return {
    id: data.id,
    serverStatus: data.server_status,
    serverLocked: data.server_locked,
    serverSlug: data.server_slug,
  } as HCServerActionResponse;
};

export const jsonToHCServerActionResult = (data: any) => {
  return {
    id: data.id,
    serverStatus: data.server_status,
    status: data.status,
    serverLocked: data.server_locked,
    serverSlug: data.server_slug,
    iso: data.iso ? jsonToHCISO(data.iso) : null,
    backupEnabled: data.backup_enabled,
    serverDiskSize: data.server_disk_size,
    serverType: data.server_type ? jsonToHCServerType(data.server_type) : null,
  } as HCServerActionResult;
};

export const jsonToHCVolumeActionResult = (data: any) => {
  return {
    id: data.id,
    volumeStatus: data.volume_status,
    status: data.status,
    volumeId: data.volume_id,
    server: data.server ? jsonToBasicHCServer(data.server) : null,
    oldServerSlug: data.old_server_slug,
    size: data.size,
  } as HCVolumeActionResult;
};

export const jsonToHCServerConsole = (data: any) => {
  return {
    password: data.password,
    websocketURL: data.websocket_url,
  } as HCServerConsole;
};

export const jsonToWalletTransaction = (data: any) => {
  return {
    serviceName: data.service_name,
    type: data.type,
    amount: data.amount,
    description: data.description,
    serviceType: data.service_type,
    createdAt: new Date(data.created_at),
    from: data.from ? new Date(data.from) : null,
    to: data.to ? new Date(data.to) : null,
  } as WalletTransaction;
};

export const jsonToWalletTransactionPaginatedResult = (data: any) => {
  return {
    count: data.count,
    total: data.total,
    page: data.page,
    pageSize: data.page_size,
    result: data.result
      ? data.result.map((transaction: any) =>
          jsonToWalletTransaction(transaction)
        )
      : ([] as WalletTransaction[]),
  } as WalletTransactionsPaginatedResult;
};

export const jsonToProject = (data: any) => {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
    serversLimit: data.servers_limit,
    serversCount: data.servers_count,
    volumesCount: data.volumes_count,
    snapshotsCount: data.snapshots_count,
    sshKeysCount: data.ssh_keys_count,
    default: data.default,
    createdAt: new Date(data.created_at),
  } as Project;
};

export const jsonToUserServicesStats = (data: any) => {
  return {
    servers: {
      total: data.servers.total,
      dailyCreates: data.servers.daily_creates,
    },
    volumes: {
      total: data.volumes.total,
      dailyCreates: data.volumes.daily_creates,
      totalSize: data.volumes.total_size,
    },
    snapshots: {
      total: data.snapshots.total,
      dailyCreates: data.snapshots.daily_creates,
      totalSize: data.snapshots.total_size,
    },
    sshKeys: {
      total: data.ssh_keys.total,
    },
  } as UserServicesStats;
};

export const jsonToBulletin = (data: any) => {
  return {
    id: data.id,
    title: data.title,
    body: data.body,
    class: data.class,
    createdAt: new Date(data.created_at),
  } as Bulletin;
}

export const jsonToBulletins = (data: any) => {
  return data.map((bulletin: any) => jsonToBulletin(bulletin));
}
