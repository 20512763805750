import React, { useState } from "react";
import SimpleRadio from "../../Utils/SimpleRadio";
import FileSystemOptions from "./FileSystemOptions";

const VolumeMountOptions = (props: { fileSystem?: boolean; col: number }) => {
  const { fileSystem, col } = props;
  const [autoMount, setAutoMount] = useState("true");

  return (
    <div>
      <div className="form-group row justify-content-md-center">
        <input
          type="hidden"
          name="mountOption"
          id="mountOption"
          value={autoMount}
        />
        <div className={`col-md-${col} text-center`}>
          <SimpleRadio
            on={autoMount === "true"}
            label="Mount خودکار"
            onClick={() => setAutoMount("true")}
            inline
          />
          <SimpleRadio
            on={autoMount === "false"}
            label="Mount دستی"
            onClick={() => setAutoMount("false")}
            inline
          />
          {autoMount === "true" && (
            <p className="mt-1 text-muted text-center">
              در این حالت فضای تهیه شده به صورت خودکار فرمت شده و به سرور شما
              اضافه خواهد شد.
            </p>
          )}
          {autoMount === "false" && (
            <p className="mt-1 text-muted text-center">
              در این حالت فضای تهیه شده تنها به سرور شما متصل شده و خود شما باید
              آن را فرمت و Mount کنید.
            </p>
          )}
        </div>
      </div>

      {/* Filesystem is only selectable on initial volume create */}
      {autoMount === "true" && fileSystem && <FileSystemOptions />}
    </div>
  );
};

VolumeMountOptions.defaultProps = {
  fileSystem: true,
  col: 4,
};

export default VolumeMountOptions;
