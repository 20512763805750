import Axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import apiRoutes from "../../constants/apiRoutes";
import routes from "../../constants/routes";
import { passwordValidator } from "../../utilities/validators";
import Alert from "../Utils/Alert";

const ResetPasswordForm = (props: { resetKey: string }) => {
  const { resetKey } = props;
  const [working, setWorking] = useState(false);
  const [warning, setWarning] = useState("");
  const [success, setSuccess] = useState("");
  const [valid, setValid] = useState(true);
  const [reValid, setReValid] = useState(true);

  const changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { status } = passwordValidator(evt.currentTarget.value);
    setValid(status);
  };

  const submitHandler = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const newPassword = evt.currentTarget.newPassword.value;
    const reNewPassword = evt.currentTarget.reNewPassword.value;
    if (newPassword !== reNewPassword) {
      setReValid(false);
      return;
    }

    if (!passwordValidator(newPassword)) return;
    setWarning("");
    setSuccess("");
    setWorking(true);
    const data = {
      password: newPassword,
      key: resetKey,
    };
    try {
      await Axios.patch(apiRoutes.V1.USER.RESET_PASSWORD, data, {});
      setSuccess("رمز عبور با موفقیت تغییر کرد.");
    } catch (error: any) {
      if (error.status === 500) {
        setWarning(
          "مشکلی در بررسی درخواست شما رخ داده است! لطفا بعدا امتحان کنید"
        );
      } else {
        setWarning(error.response.data.message);
      }
    }
    setWorking(false);
  };
  return (
    <form className="login-form" onSubmit={submitHandler}>
      <div className="text-center mb-3">
        {warning !== "" && <Alert alertType="warning" text={warning} />}
        {success !== "" && <Alert alertType="success" text={success} />}
        <i className="icon-key icon-2x text-warning border-warning border-3 rounded-round p-3 mb-3 mt-1" />
        <h5 className="mb-0"> تغییر رمز عبور </h5>
      </div>
      <div className="form-group">
        <label>رمز جدید:</label>
        <input
          required
          type="password"
          name="newPassword"
          className="form-control"
          onChange={changeHandler}
        />
      </div>
      {!valid && (
        <span className="form-text text-danger">
          <i className="icon-cancel-circle2 mr-2" />
          رمز انتخاب معتبر نمباشد. طول رمز باید بین ۸ تا ۳۰ و شامل حروف انگلیسی
          همراه با اعداد باشد
        </span>
      )}

      <div className="form-group">
        <label>تکرار رمز جدید:</label>
        <input
          required
          type="password"
          name="reNewPassword"
          className="form-control"
          onChange={() => {
            if (!reValid) setReValid(true);
          }}
        />
      </div>
      {!reValid && (
        <span className="form-text text-danger">
          <i className="icon-cancel-circle2 mr-2" />
          رمز های وارد شده برار نیستند!
        </span>
      )}

      <div className="text-center mt-2">
        {!working && (
          <button type="submit" className="btn btn-primary btn-block">
            تایید و تغییر رمز
          </button>
        )}
        {working && (
          <button disabled className="btn btn-primary btn-block">
            <i className="icon-spinner6 spinner mr-2" /> در حال تغییر رمز ...
          </button>
        )}
      </div>
      <div className="text-center mt-2">
        <Link to={routes.AUTHENTICATION}>
          <button type="button" className="btn btn-light btn-block">
            ورود
          </button>
        </Link>
      </div>
    </form>
  );
};

export default ResetPasswordForm;
