import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import routes from "../../constants/routes";
import { baseState } from "../../features/baseSlice";

const Sidebar = () => {
  const { pathname } = useLocation();
  const { mobileSidebarOpen } = useSelector(baseState);

  return (
    <div
      className={`sidebar sidebar-light sidebar-main sidebar-expand-lg ${
        mobileSidebarOpen ? "sidebar-mobile-expanded" : ""
      }`}
    >
      <div className="sidebar-content">
        <div className="sidebar-section">
          <ul className="nav nav-sidebar" data-nav-type="accordion">
            <li className="nav-item-header">
              <div className="text-uppercase font-size-xs line-height-xs">
                منوی اصلی
              </div>{" "}
              <i className="icon-menu" title="Main" />
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link${
                  pathname === routes.HOME ? " active" : ""
                }`}
                to={routes.HOME}
              >
                <i className="icon-home4" />
                <span>داشبورد</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link${
                  pathname === routes.PROJECTS ? " active" : ""
                }`}
                to={routes.PROJECTS}
              >
                <i className="icon-folder" />
                <span>پروژه ها</span>
              </Link>
            </li>
            <li
              className={`nav-item nav-item-submenu${
                pathname.includes(routes.SERVERS) ? " nav-item-open" : ""
              }`}
            >
              <Link className="nav-link" to={routes.SERVERS}>
                <i className="icon-user" /> <span>سرور ها</span>
              </Link>

              <ul
                className="nav nav-group-sub"
                style={{
                  display: pathname.includes(routes.SERVERS) ? "block" : "none",
                }}
              >
                <li className="nav-item">
                  <Link
                    to={routes.SERVERS}
                    className={`nav-link${
                      pathname === routes.SERVERS ? " active" : ""
                    }`}
                  >
                    سرور های شما
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={routes.OLD_CREATE_SERVER}
                    className={`nav-link${
                      pathname === routes.OLD_CREATE_SERVER ? " active" : ""
                    }`}
                  >
                    سرور جدید
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={routes.SNAPSHOTS}
                    className={`nav-link${
                      pathname === routes.SNAPSHOTS ? " active" : ""
                    }`}
                  >
                    اسنپ شات ها
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={routes.BACKUPS}
                    className={`nav-link${
                      pathname === routes.BACKUPS ? " active" : ""
                    }`}
                  >
                    بک آپ ها
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={routes.VOLUMES}
                    className={`nav-link${
                      pathname === routes.VOLUMES ? " active" : ""
                    }`}
                  >
                    فضا ها
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item nav-item-submenu${
                pathname.includes(routes.TICKETS) ? " nav-item-open" : ""
              }`}
            >
              <Link className="nav-link" to={routes.TICKETS}>
                <i className="icon-user" /> <span>پشتیبانی</span>
              </Link>

              <ul
                className="nav nav-group-sub"
                style={{
                  display: pathname.includes(routes.TICKETS) ? "block" : "none",
                }}
              >
                <li className="nav-item">
                  <Link
                    to={routes.TICKETS}
                    className={`nav-link${
                      pathname === routes.TICKETS ? " active" : ""
                    }`}
                  >
                    درخواست های پشتیبانی
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={routes.TICKET_CREATE}
                    className={`nav-link${
                      pathname === routes.TICKET_CREATE ? " active" : ""
                    }`}
                  >
                    درخواست جدید
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link${
                  pathname === routes.SSH_KEYS ? " active" : ""
                }`}
                to={routes.SSH_KEYS}
              >
                <i className="icon-key" />
                <span>کلید های SSH</span>
              </Link>
            </li>
            <li
              className={`nav-item nav-item-submenu${
                pathname.includes(routes.BILLING) ? " nav-item-open" : ""
              }`}
            >
              <Link className="nav-link" to={routes.BILLING}>
                <i className="icon-cash3" /> <span>مالی</span>
              </Link>

              <ul
                className="nav nav-group-sub"
                style={{
                  display: pathname.includes(routes.BILLING) ? "block" : "none",
                }}
              >
                <li className="nav-item">
                  <Link
                    to={routes.BILLING}
                    className={`nav-link${
                      pathname === routes.BILLING ? " active" : ""
                    }`}
                  >
                    وضعیت کلی
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={routes.BILLING_LOGS}
                    className={`nav-link${
                      pathname === routes.BILLING_LOGS ? " active" : ""
                    }`}
                  >
                    ریز گزارشات
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={routes.BILLING_ADD_FUND}
                    className={`nav-link${
                      pathname === routes.BILLING_ADD_FUND ? " active" : ""
                    }`}
                  >
                    افزایش موجودی
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item nav-item-submenu${
                pathname.includes(routes.USER_PROFILE) ? " nav-item-open" : ""
              }`}
            >
              <Link className="nav-link" to={routes.USER_PROFILE}>
                <i className="icon-user" /> <span>حساب کاربری</span>
              </Link>

              <ul
                className="nav nav-group-sub"
                style={{
                  display: pathname.includes(routes.USER_PROFILE)
                    ? "block"
                    : "none",
                }}
              >
                <li className="nav-item">
                  <Link
                    to={routes.USER_PROFILE}
                    className={`nav-link${
                      pathname === routes.USER_PROFILE ? " active" : ""
                    }`}
                  >
                    مشخصات حساب
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={routes.USER_PASSWORD_CHANGE}
                    className={`nav-link${
                      pathname === routes.USER_PASSWORD_CHANGE ? " active" : ""
                    }`}
                  >
                    تغییر رمز ورود
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
