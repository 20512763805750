import React from "react";

const ServerSnapshotItem = (props: {
  snapshot: HCImage;
  selected: boolean;
  selectHandler: (id: number) => void;
}) => {
  const { snapshot, selected, selectHandler } = props;

  return (
    <div
      className="col-md-4 col-sm-4 col-xs-12 col-lg-3"
      onClick={() => selectHandler(snapshot.id)}
    >
      <button
        type="button"
        className={`btn border-light-alpha btn-block btn-float border-top-0 border-left-0 m-0 ${
          selected ? "bg-primary" : "btn-light"
        }`}
      >
        <strong>{snapshot.description}</strong>
        {/* <p className="mt-1">({snapshot.createdFrom?.name})</p> */}
      </button>
    </div>
  );
};

export default ServerSnapshotItem;
