import React from "react";
import { useSelector } from "react-redux";
import Notification from "../components/Utils/Notification";
import { baseState } from "../features/baseSlice";

const Notifications = () => {
  const { notifications } = useSelector(baseState);

  return (
    <>
      {notifications.map((notification, i) => (
        <Notification
          index={i}
          notification={notification}
          key={"globalNotification" + notification.id}
        />
      ))}
    </>
  );
};

export default Notifications;
