import axios from "axios";
import apiRoutes from "../constants/apiRoutes";
import getDefaultHeaders from "../features/utils";
import { authCheckAndRedirect } from "../utilities/functions";

export const getTokenString = (): string => {
  return localStorage.getItem("authToken")?.toString() || "";
};

export const isEmailValid = async (email: string) => {
  const url = `${apiRoutes.V1.EMAIL_VALIDATION}?email=${email}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data.valid;
  } catch (error) {
    return true;
  }
};
export const isPhoneNumberValid = async (phone: string) => {
  const url = `${apiRoutes.V1.Phone_VALIDATION}?phone=${phone}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data.valid;
  } catch (error) {
    return true;
  }
};
export const isAuthenticated = (): boolean => {
  if (!localStorage.getItem("authToken")) {
    return false;
  }
  return true;
};

export const isTokenExpired = (): boolean => {
  const expireDate = localStorage.getItem("authTokenExpiresAt")
    ? Number(localStorage.getItem("authTokenExpiresAt"))
    : 0;
  if (expireDate === 0) {
    return false;
  } else if (expireDate < Math.floor(new Date().getTime() / 1000)) {
    return true;
  }
  return false;
};
export const isTokenValid = async (token: string) => {
  if (token === "") {
    return false;
  }
  try {
    await axios.get(apiRoutes.V1.TOKEN_VALIDATION, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};
export const setAuthToken = (token: string) => {
  // Set
  localStorage.setItem("authToken", token);
};

export const userLogin = async (
  username: string,
  password: string,
  rememberMe: boolean
) => {
  let headers = {
    "Content-Type": "application/json",
  };
  let result;

  const data = {
    email: username,
    password,
    remember_me: rememberMe,
  };

  try {
    const response = await axios.post(apiRoutes.V1.LOGIN, data, {
      headers: headers,
    });
    if (response.data.session_id) {
      result = {
        success: true,
        data: response.data,
      };
    } else {
      result = {
        success: false,
        error: "unknown",
      };
    }
    return result;
  } catch (error: any) {
    result = {
      success: false,
      error: error.response,
    };
    return result;
  }
};

export const userRegister = async (
  email: string,
  password: string,
  address: string,
  phone: string,
  postalCode: string,
  firstName: string,
  lastName: string
) => {
  const user = {
    first_name: firstName,
    last_name: lastName,
    postal_code: postalCode,
    phone,
    password,
    email,
    address,
  };

  return axios
    .post(apiRoutes.V1.REGISTER, JSON.stringify(user), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data.status && response.data.status === "OK") {
        return {
          success: true,
          errors: [],
        };
      } else {
        return {
          success: false,
          error: response.data.text,
        };
      }
    })
    .catch((error) => {
      if (error.response.status && error.response.status === 400) {
        return {
          success: false,
          error: "data",
        };
      } else if (error.response.status && error.response.status === 500) {
        return {
          success: false,
          error: "server",
          email: true,
        };
      } else {
        return {
          success: false,
          errors: "unknown",
        };
      }
    });
};

export const logoutUser = async () => {
  try {
    await axios.delete(apiRoutes.V1.LOGOUT, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: SimpleResponse = { status: "OK" };
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export default isAuthenticated;
