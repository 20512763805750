import React from "react";
import TicketReply from "./TicketReply";

const TicketReplies = (props: { replies: TicketReply[] }) => {
  const { replies } = props;

  if (replies.length === 0) {
    return (
      <div className="row">
        <div className="col-xl-12 text-center mt-5 mb-5">
          <p>هیچ پاسخی برای این درخواست ارسال نشده است!</p>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-xl-12">
        {replies.map((reply) => (
          <TicketReply reply={reply} key={reply.created + "ticketReply"} />
        ))}
      </div>
    </div>
  );
};

export default TicketReplies;
