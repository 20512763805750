import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { emailValidator } from "../../utilities/validators";
import { setAuthToken, userLogin } from "../../api/auth";
import routes from "../../constants/routes";

const LoginForm = () => {
  const navigate = useNavigate();
  // Email
  const [emailCheckTimeOut, setEmailCheckTimeOut] = useState<any>(null);
  const [emailChecked, setEmailChecked] = useState(false);
  const [emailValid, setEmailValid] = useState(false);

  // Password
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordChecked, setPasswordChecked] = useState(false);
  const [passwordCheckTimeOut, setPasswordCheckTimeOut] = useState<any>(null);

  // Remember Me
  const [rememberMe, setRememberMe] = useState(false);

  // Server Error
  const [loginError, setLoginError] = useState<null | string>(null);
  const [serverError, setServerError] = useState(false);

  const formSubmitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setServerError(false);
    setLoginError(null);

    userLogin(
      evt.currentTarget.loginEmail.value,
      evt.currentTarget.loginPassword.value,
      rememberMe
    ).then((response) => {
      if (response.success) {
        setAuthToken(response.data.session_id);
        navigate(routes.HOME);
      } else {
        if (response.error) {
          if (response.error.data) {
            setLoginError(response.error.data.message);
          } else {
            setServerError(true);
          }
        }
      }
    });
  };

  const emailChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const email = evt.target.value;
    if (emailCheckTimeOut) {
      clearTimeout(emailCheckTimeOut);
    }
    setEmailCheckTimeOut(setTimeout(() => emailHandler(email), 300));
  };

  const emailHandler = (email: string) => {
    setEmailValid(emailValidator(email));
    setEmailChecked(true);
  };

  const passwordChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const password = evt.target.value;
    if (passwordCheckTimeOut) {
      clearTimeout(passwordCheckTimeOut);
    }
    setPasswordCheckTimeOut(setTimeout(() => passwordHandler(password), 300));
  };

  const passwordHandler = (password: string) => {
    password.length < 6 ? setPasswordValid(false) : setPasswordValid(true);
    setPasswordChecked(true);
  };

  useEffect(() => {
    return () => {
      passwordCheckTimeOut && clearTimeout(passwordCheckTimeOut);
      emailCheckTimeOut && clearTimeout(emailCheckTimeOut);
    };
  }, [passwordCheckTimeOut, emailCheckTimeOut]);

  return (
    <form onSubmit={formSubmitHandler}>
      <div className="form-group form-group-feedback form-group-feedback-right">
        <input
          type="text"
          name="loginEmail"
          id="loginEmail"
          className="form-control"
          placeholder="ایمیل"
          onChange={emailChangeHandler}
        />
        <div className="form-control-feedback">
          <i className="icon-user text-muted"></i>
        </div>
        {/* Error */}
        {!emailValid && emailChecked && (
          <span className="form-text text-danger">
            <i className="icon-cancel-circle2 mr-2"></i> ایمیل وارد شده معتبر
            نمیباشد!
          </span>
        )}
      </div>

      <div className="form-group form-group-feedback form-group-feedback-right">
        <input
          type="password"
          name="loginPassword"
          id="loginPassword"
          className="form-control"
          placeholder=" رمز عبور "
          onChange={passwordChangeHandler}
        />
        <div className="form-control-feedback">
          <i className="icon-lock2 text-muted" />
        </div>
        {!passwordValid && passwordChecked && (
          <span className="form-text text-danger">
            <i className="icon-cancel-circle2 mr-2" /> طول رمز عبور باید بیشتر
            از ۵ باشد!
          </span>
        )}
      </div>

      <div className="form-group d-flex align-items-center">
        <label className="custom-control custom-checkbox">
          <div className="uniform-checker">
            <input
              type="checkbox"
              name="remember"
              className="custom-control-input"
              defaultChecked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            />
            <span className="custom-control-label">مرا به خاطر بسپار</span>
          </div>
        </label>
      </div>

      <div className="form-group">
        <button type="submit" className="btn btn-primary btn-block">
          {" "}
          ورود به حساب{" "}
        </button>
      </div>

      {serverError && (
        <div className="formt-group">
          <span className="form-text text-danger text-center">
            <i className="icon-cancel-circle2 mr-2" /> متاسفانه مشکلی از سمت ما
            وجود دارد. لطفا بعدا امتحان کنید!
          </span>
          <br />
        </div>
      )}
      {loginError && (
        <div className="formt-group">
          <span className="form-text text-danger text-center">
            <i className="icon-cancel-circle2 mr-2" /> {loginError}
          </span>
          <br />
        </div>
      )}
    </form>
  );
};

export default LoginForm;
