import React from "react";
import ServersList from "../../components/UserServers/List/ServersList";
import Content from "../../containers/Content";

const Servers = (props: { projectId?: number }) => {
  const { projectId } = props;

  return (
    <Content>
      <ServersList projectId={projectId} />
    </Content>
  );
};

export default Servers;
