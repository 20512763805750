import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { getTokenString } from "../../api/auth";
import { jsonToHCVolumeActionResult } from "../../api/mappers";
import apiRoutes from "../../constants/apiRoutes";
import { APIQueryName } from "../../constants/queries";
import { addNotification } from "../../features/baseSlice";
import getDefaultHeaders from "../../features/utils";
import {
  removeVolumeAction,
  volumesState,
} from "../../features/volumes/volumesSlice";

const VolumeActionsHandler = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { volumeActions } = useSelector(volumesState);

  const [actionTimeout, setActionTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  useEffect(() => {
    if (volumeActions.length === 0 && actionTimeout) {
      clearTimeout(actionTimeout);
      setActionTimeout(null);
      return;
    }
    if (actionTimeout || volumeActions.length === 0) {
      return;
    }

    const timeout = setTimeout(() => {
      volumeActions.forEach(async (action) => {
        try {
          const response = await Axios.get(
            apiRoutes.V1.ACTIONS.GET_VOLUME(action.actionId),
            { headers: getDefaultHeaders(getTokenString()) }
          );
          const actionResult = jsonToHCVolumeActionResult(response.data);
          if (actionResult.status === "pending") return;

          let notification: NotificationProps;
          if (actionResult.status === "success") {
            notification = {
              type: "success",
              title: "عملیات موفق: " + action.actionType.toUpperCase(),
              text: "عملیات با موفقیت انجام شد.",
            };
          } else {
            notification = {
              type: "error",
              title: "عملیات ناموفق: " + action.actionType.toUpperCase(),
              text: "مشکلی در پردازش درخواست رخ داده است.",
            };
          }
          // Volume is not returned in actions
          // So find it so we can add it to the server if necessary
          let v: HCVolume;
          queryClient.setQueryData(APIQueryName.HC_VOLUMES, (volumes: any) => {
            if (!volumes) {
              return;
            }
            volumes = volumes.map((volume: any) => {
              if (volume.id === actionResult.volumeId) {
                volume.status = actionResult.volumeStatus;
                volume.server = actionResult.server;
                volume.size = actionResult.size;
                v = volume;
              }
              return volume;
            });
            return volumes;
          });
          if (actionResult.oldServerSlug !== "") {
            queryClient.setQueryData(
              APIQueryName.SERVER_DETAILS + actionResult.oldServerSlug,
              (details: any) => {
                if (!details) {
                  return;
                }
                details.volumes = details.volumes.filter(
                  (volume: any) => volume.id !== actionResult.volumeId
                );
                return details;
              }
            );
          }
          if (actionResult.server) {
            queryClient.setQueryData(
              APIQueryName.SERVER_DETAILS + actionResult.server.slug,
              (details: any) => {
                if (!details) {
                  return;
                }
                details.volumes = [...details.volumes, v];
                return details;
              }
            );
          }
          dispatch(addNotification(notification));
          dispatch(removeVolumeAction(action));
          //   response.data.server_status &&
          //     dispatch(setServerStatus(response.data.server_status));
          // if (response.data.volume) {
          //   const volume = jsonToVolumeDetails(response.data.volume);
          //   dispatch(volumeUpdate(volume));
          //   // update server's volume in case it includes the current volume
          //   dispatch(updateServerVolume(volume));
          // }
        } catch (error: any) {
          dispatch(
            addNotification({
              type: "error",
              title: "عملیات ناموفق: " + action.actionType.toUpperCase(),
              text: "مشکلی در پردازش درخواست رخ داده است.",
            })
          );
          dispatch(removeVolumeAction(action));
        }
      });
      actionTimeout && clearTimeout(actionTimeout);
      setActionTimeout(null);
    }, 5000);

    setActionTimeout(timeout);

    return () => {
      actionTimeout && clearTimeout(actionTimeout);
    };
  }, [volumeActions, actionTimeout, queryClient, dispatch]);

  return <div></div>;
};

export default VolumeActionsHandler;
