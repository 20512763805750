import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const ContentCenter = (props: Props) => {
  const { children } = props;
  return (
    <div
      className="d-flex flex-column justify-content-center"
      style={{ height: "100%" }}
    >
      {children}
    </div>
  );
};

export default ContentCenter;
