import React from "react";

const ServerDetailsVolumeNotes = () => {
  return (
    <div className="card-body text-center">
      <i className="icon-database-add icon-2x text-teal-700 border-teal-700 border-3 rounded-round p-3 mb-3 mt-1"></i>
      <h5 className="card-title text-center">Volume</h5>
      <p className="mb-3 text-center">
        در این قسمت می توانید فضای SSD تا سقف ۱۰ ترابایت به سرور خود اضافه کنید.
      </p>
      <p className="mb-3 text-center">
        حداقل مقدار فضای قابل تهیه برای هر سرور ۱۰ گیگابایت است که به صورت ساعتی
        محاسبه و در هر زمان قابل حذف و یا تغییر حجم را خواهد داشت.
      </p>
      <p className="mb-3 text-center">
        هزینه هر گیگابایت فضا مبلغ ۰.۰۴ یورو به صورت ماهانه خواهد بود که به صورت
        ساعتی بر اساس نرخ ارز محاسبه و از حساب شما کسر خواهد شد.
      </p>
      <p className="mb-3 text-center">
        همچنین امکان جدا سازی فضای اضافه شده و اضافه کردن آن به سرور دیگری در
        همان لوکیشن نیز ممکن خواهد بود و فایل ها نیز بعد از این فرایند بر روی
        سرور جدید قابل دسترس خواهند بود.
      </p>
    </div>
  );
};

export default ServerDetailsVolumeNotes;
