import React, { useEffect } from "react";
import Modal from "../../../../Utils/Modal";

const PasswordResetConfirmationModal = (props: { dismiss: () => void }) => {
  const { dismiss } = props;
  useEffect(() => {
    document.body.classList.add("modal-open");

    return () => document.body.classList.remove("modal-open");
  }, []);
  return (
    <Modal>
      <div>
        <Modal.Header>
          <>
            <h5 className="modal-title">تایید تغییر پسورد</h5>
            <button type="button" className="close" onClick={() => dismiss()}>
              ×
            </button>
          </>
        </Modal.Header>
        <Modal.Body>
          <>
            <p className="text-center">
              با ریست رمز عبور، رمز عبور قبلی قابل استفاده نخواهد بود.
            </p>
            <p className="text-center">
              بعد از انجام موفق عملیات، رمز عبور شما تنها یکبار به نمایش درآمده
              و شما فرصت کپی آن را خواهید داشت. در صورت تمایل برای ادامه روی
              تایید کلیک کنید.
            </p>
          </>
        </Modal.Body>
        <Modal.Footer>
          <>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => dismiss()}
            >
              لغو
            </button>
            <button type="submit" className="btn bg-primary">
              تایید
            </button>
          </>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default PasswordResetConfirmationModal;
