import React from "react";
import { datetimeToString } from "../../utilities/functions";
import { useDeleteSSHKey } from "../../mutations/sshkeys";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";

const SSHKeyTR = (props: { sshKey: SSHKey; projectId?: number }) => {
  const { sshKey, projectId } = props;

  const { isLoading, mutate } = useDeleteSSHKey(projectId || sshKey.projectId);

  return (
    <tr key={"sshkey" + sshKey.id}>
      <td>{sshKey.name}</td>
      <td>{sshKey.fingerprint}</td>
      <td>
        <Link className="badge badge-primary" to={routes.PROJECT_SSHKEYS(sshKey.projectId)}>
          {sshKey.projectName}
        </Link>
      </td>
      <td style={{ direction: "ltr" }}>{datetimeToString(sshKey.created)}</td>
      <td className="text-center">
        {isLoading && (
          <button className="btn btn-danger btn-icon">
            <i className="icon-spinner6 spinner" />
          </button>
        )}
        {!isLoading && (
          <button
            type="button"
            className="btn btn-danger btn-icon"
            onClick={() => mutate(sshKey.id)}
          >
            <i className="icon-trash" />
          </button>
        )}
      </td>
    </tr>
  );
};

export default SSHKeyTR;
