import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  cls?: string;
};

const PageContent = (props: Props) => {
  const { children, cls } = props;
  return <div className={`page-content ${cls ? cls : ''}`}>{children}</div>;
};

export default PageContent;
