import React, { useState } from "react";
import DeleteModal from "./DeleteModal";

const VolumeDelete = (props: { volume: HCVolume }) => {
  const { volume } = props;
  const [modal, setModal] = useState(false);
  return (
    <>
      <button
        type="button"
        className="btn btn-danger btn-icon btn-sm mr-1 ml-1 mt-1"
        onClick={() => setModal(true)}
      >
        <i className="icon-trash" />
      </button>
      {modal && <DeleteModal dismiss={() => setModal(false)} volume={volume} />}
    </>
  );
};

export default VolumeDelete;
