import React from 'react'

const TicketRowStatusSpan = (props: {status: TicketStatus}) => {
    switch (props.status.title) {
        case "بسته":
            return <span className="badge badge-secondary" style={{fontSize: "80%"}}>{props.status.title}</span>
        case "در انتظار پاسخ":
            return <span className="badge badge-warning" style={{fontSize: "80%"}}>{props.status.title}</span>
        case "در دست بررسی":
            return <span className="badge badge-info" style={{fontSize: "80%"}}>{props.status.title}</span>
        default:
            return <span className="badge badge-success" style={{fontSize: "80%"}}>{props.status.title}</span>
    }
}

export default TicketRowStatusSpan

