import React, { useState } from "react";
import SSHKeyCreateForm from "./SSHKeyCreateForm";

const SSHKeysCreate = (props: { projectId?: number }) => {
  const { projectId } = props;

  const [cardOpen, setCardOpen] = useState(true);

  return (
    <div className={cardOpen ? "card" : "card card-collapsed"}>
      <div className="card-header header-elements-inline">
        <h6 className="card-title">ایجاد کلید جدید</h6>
        <div className="header-elements">
          <div className="list-icons">
            <a
              href="#createClose"
              className="list-icons-item"
              onClick={(evt) => {
                evt.preventDefault();
                setCardOpen(!cardOpen);
              }}
            >
              <i
                className={
                  cardOpen ? "icon-arrow-up12" : "icon-arrow-up12 rotate-180"
                }
              />
            </a>
          </div>
        </div>
      </div>
      <div
        className="card-body"
        style={{ display: cardOpen ? "block" : "none" }}
      >
        <p>توجه داشته باشید که تنها فرمت OpenSSH قابل قبول می باشد.</p>
        <div className="row justify-content-md-center">
          <div className="col-md-6">
            <SSHKeyCreateForm projectId={projectId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SSHKeysCreate;
