import React from "react";
import UserBackupsTable from "../../components/ServerImages/BackupTable";
import Content from "../../containers/Content";

const UserBackups = () => {
  return (
    <Content>
      <div className="card">
        <div className="card-body text-center">
          <i className="icon-copy4 icon-2x text-primary border-primary border-3 rounded-round p-3 mb-3 mt-1" />
          <h5 className="card-title text-center">Backup</h5>

          <p className="mb-3 text-center">
            بک آپ ها کپی های خودکاری از سرور شما هستند. هر سرور قابلیت نگهداری ۷
            نسخه بک آپ را دارد.
          </p>
          <br />
        </div>
        <UserBackupsTable />
      </div>
    </Content>
  );
};

export default UserBackups;
