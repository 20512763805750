import React from "react";
import { Link } from "react-router-dom";
import routes from "../constants/routes";
import Container from "../containers/Container";
import Content from "../containers/Content";
import ContentCenter from "../containers/ContentCenter";
import { useLocation } from "react-router-dom";

const NewServerInfo = () => {
  const { state } = useLocation();

  if (!state) return <div></div>;

  return (
    <Content>
      <Container>
        <ContentCenter>
          <div className="card">
            <div className="card-body text-center">
              <i className="icon-check icon-2x text-success-400 border-success-400 border-3 rounded-round p-3 mb-3 mt-1" />
              <h5 className="card-title text-center">
                سرور با موفقیت ایجاد شد
              </h5>
              <p className="mb-3">
                    <strong>عنوان سرور: </strong> {state.name}
                </p>
                <p className="mb-3">
                    <strong>آدرس سرور: </strong> {state.ipv4}
                </p>
              <p className="mb-3">
                    <strong>نوع سرور: </strong> {state.serverType.toUpperCase()}
                </p>
              <p className="mb-3">
                <strong>رمز عبور روت: </strong>{" "}
                {state.rootPassword
                  ? state.rootPassword
                  : "(استفاده از کلید SSH انتخاب شده در مرحله قبل)"}
              </p>
              <Link to={{ pathname: routes.SERVER_DETAILS + state.slug }}>
                <button type="button" className="btn bg-success-400">
                  انتقال به صفحه سرور
                </button>
              </Link>
            </div>
          </div>
        </ContentCenter>
      </Container>
    </Content>
  );
};

export default NewServerInfo;
