import React from "react";
import VolumeTableRow from "./VolumeTableRow";

const VolumesTable = (props: {
  volumes: HCVolume[];
  serverSection?: boolean;
}) => {
  const { volumes, serverSection } = props;

  return (
    <div className="table-responsive table-scrollable">
      <table className="table" style={{ maxHeight: "100%" }}>
        <thead>
          <tr>
            <th colSpan={!serverSection ? 8 : 7}>
              <h5 className="text-center">لیست فضا ها</h5>
            </th>
          </tr>
          <tr className="bg-teal">
            <th>عنوان</th>
            <th>حجم</th>
            <th>زمان ایجاد</th>
            <th>لوکیشن</th>
            <th>وضعیت</th>
            <th>پروژه</th>
            {!serverSection && <th>سرور</th>}
            <th className="text-center">گزینه ها</th>
          </tr>
        </thead>
        <tbody>
          {volumes.map((volume) => (
            <VolumeTableRow
              serverSection={serverSection}
              volume={volume}
              key={"volume" + volume.id}
            />
          ))}
          {volumes.length === 0 && (
            <tr>
              <td colSpan={!serverSection ? 8 : 7}>
                <p className="text-center">فضای اضافه ای موجود نمی باشد.</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

VolumesTable.defaultProps = {
  serverSection: false,
};

export default VolumesTable;
