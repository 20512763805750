import React from "react";
import { useSelector } from "react-redux";
import { userState } from "../../../../features/users/userSlice";
import { useCreateHCVolume } from "../../../../mutations/volumes";
import VolumeMountOptions from "../../../Volumes/Actions/MountOptions";
import VolumeSizeSelect from "../../../Volumes/VolumeSizeSelect";

const ServerVolumeCreate = (props: { slug: string }) => {
  const { slug } = props;
  const { phoneVerified, emailVerified } = useSelector(userState);
  const { mutate, isLoading } = useCreateHCVolume();

  const formSubmitHandler = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const size = evt.currentTarget.volumeSize.value;
    if (size < 10 || size > 1024) return;

    const autoMount = evt.currentTarget.mountOption.value;
    const fileSystem = evt.currentTarget.fileSystem.value;
    mutate({
      server: slug,
      size: parseInt(size),
      auto_mount: autoMount === "true",
      format: fileSystem,
    });
  };
  return (
    <form onSubmit={formSubmitHandler}>
      <VolumeSizeSelect />
      <VolumeMountOptions />
      <button
        disabled={(!phoneVerified && !emailVerified) || isLoading}
        className="btn bg-teal mt-1 mb-2"
        type="submit"
      >
        تایید و تهیه فضا
      </button>
    </form>
  );
};

export default ServerVolumeCreate;
