import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTokenString, isEmailValid } from "../../../api/auth";
import apiRoutes from "../../../constants/apiRoutes";
import {
  addNotification,
} from "../../../features/baseSlice";
import { userState, verifyUserEmail } from "../../../features/users/userSlice";
import getDefaultHeaders from "../../../features/utils";
import { authCheckAndRedirect } from "../../../utilities/functions";
import { emailValidator } from "../../../utilities/validators";

const EmailField = () => {
  const { email, emailVerified } = useSelector(userState);
  const dispatch = useDispatch();

  const [changed, setChanged] = useState(false);
  const [working, setWorking] = useState(false);
  const [valid, setValid] = useState(true);
  const [exists, setExists] = useState(false);
  const [
    emailCheckTimeOut,
    setEmailCheckTimeOut,
  ] = useState<NodeJS.Timeout | null>(null);

  const emailHandler = (newEmail: string) => {
    const valid = emailValidator(newEmail);
    if (valid) {
      if (newEmail === email) {
        setValid(true);
        setExists(false);
        return;
      }
      isEmailValid(newEmail).then((response) => {
        setValid(response);
        setExists(!response);
      });
    } else {
      setValid(false);
      setExists(false);
    }
  };

  const changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = evt.target.value;
    if (emailCheckTimeOut) {
      clearTimeout(emailCheckTimeOut);
    }
    setEmailCheckTimeOut(setTimeout(() => emailHandler(newEmail), 300));

    if (newEmail !== email) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  };

  const handleReSendError = (err: any) => {
    let notification: NotificationProps = {
      type: "error",
      title: "عملیات ناموفق",
      text: "",
    };
    if (err.status === 500) {
      notification.text =
        "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.";
    } else if (err.data.error === "email is already verified") {
      notification.type = "info";
      dispatch(verifyUserEmail());
    }
    notification.text = err.data.message;
    dispatch(addNotification(notification));
  };

  const sendVerificationRequest = () => {
    setWorking(true);
    Axios.post(
      apiRoutes.V1.USER.SEND_EMAIL_VERIFICATION,
      {},
      { headers: getDefaultHeaders(getTokenString()) }
    )
      .then((_) => {
        setWorking(false);
        dispatch(
          addNotification({
            type: "success",
            title: "عملیات موفق",
            text: "ایمیل با موفقیت ارسال شد. لطفا ایمیل خود را چک کنید.",
          })
        );
      })
      .catch((error) => {
        setWorking(false);
        authCheckAndRedirect(error.response);
        handleReSendError(error.response);
      });
  };

  useEffect(() => {
    return () => {
      emailCheckTimeOut && clearTimeout(emailCheckTimeOut);
    };
  }, [emailCheckTimeOut]);

  return (
    <div className="form-group">
      <label>ایمیل:</label>
      <div className="input-group">
        <input
          required
          type="text"
          className="form-control persian-number"
          name="email"
          defaultValue={email}
          onChange={changeHandler}
        />
        {!emailVerified && (
          <span className="input-group-append">
            {!working && (
              <button
                className="btn btn-light"
                type="button"
                onClick={sendVerificationRequest}
              >
                تایید ایمیل
              </button>
            )}
            {working && (
              <button disabled className="btn btn-light">
                <i className="icon-spinner6 spinner mr-2" /> در حال ارسال ایمیل
                ...
              </button>
            )}
          </span>
        )}
      </div>
      {!valid && (
        <span className="form-text text-danger">
          <i className="icon-cancel-circle2 mr-2" />
          {exists
            ? " ایمیل وارد شده قبلا ثبت شده است!"
            : " ایمیل وارد شده معتبر نمیباشد!"}
        </span>
      )}
      {changed && (
        <label className="text-warning mt-1">
          (در صورت تغییر ایمیل باید مجدد آن را تایید کنید.)
        </label>
      )}
    </div>
  );
};

export default EmailField;
