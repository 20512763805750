import React from "react";
import NewServer from "../components/CreateServer/NewServer";
import Container from "../containers/Container";
import Content from "../containers/Content";

const CreateServer = () => {
  return (
    <Content>
      <Container>
        <div className="row">
          <div className="col-12">
            <h1 className="mb-5"> ایجاد سرور جدید</h1>
            <NewServer />
          </div>
        </div>
      </Container>
    </Content>
  );
};

export default CreateServer;
