import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  deleteUserImage,
  fetchAllBackups,
  fetchAllSnapshots,
  fetchISOs,
  fetchOSImages,
  updateUserImage,
} from "./imagesAPI";

const imagesSlice = createSlice({
  name: "images",
  initialState: {
    // Sometimes user's delete an image from snapshots/backups tab.
    // So we set a imageChanged here so serverDetails page can check if snapshots/backups changed
    // And if so, update itself and not use the old serverDetails
    imagesChanged: false,
    imagesPendings: [] as string[],
    snapshots: [] as ServerImageData[],
    snapshotsLoading: false,
    snapshotsLoadingError: false,
    snapshotsLoadedTimestamp: 0,
    osImages: [] as ServerImageData[],
    osImagesLoading: false,
    osImagesLoadingError: false,
    osImagesLoadedTimestamp: 0,
    backups: [] as ServerImageData[],
    backupsLoading: false,
    backupsLoadingError: false,
    backupsLoadedTimestamp: 0,
    isoLoading: false,
    isoLoadingError: false,
    isoLoadedTimestamp: 0,
    isoList: [] as HCISO[],
    imagesNotifications: [] as NotificationProps[],
  },
  reducers: {
    removeImageNotification: (state, { payload }) => {
      state.imagesNotifications = state.imagesNotifications.filter(
        (notification) => notification.id !== payload
      );
    },
    resetImageChanged: (state) => {
      state.imagesChanged = false;
    },
    removeImage: (state, { payload }) => {
      state.backups = state.backups.filter((backup) => backup.id !== payload);
      state.snapshots = state.snapshots.filter(
        (snapshot) => snapshot.id !== payload
      );
    },
    switchImageType: (state, { payload }) => {
      // remove from backup
      state.backups = state.backups.filter(
        (backup) => backup.id !== payload.id
      );
      // add to snapshot
      state.snapshots.push(payload);
    },
    addImage: (state, { payload }) => {
      if (payload.boundTo) {
        state.backups.push(payload);
        return;
      }
      state.snapshots.push(payload);
    },
    updateImage: (state, { payload }) => {
      if (payload.boundTo) {
        state.backups = state.backups.map((backup) => {
          if (backup.id === payload.id) return payload;
          return backup;
        });
        return;
      }

      state.snapshots = state.snapshots.map((snapshot) => {
        if (snapshot.id === payload.id) return payload;
        return snapshot;
      });
    },
    // removeNotification: (state, {payload}) => {
    //   state.notifications = state.notifications.filter(notification => notification.id !== payload);
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOSImages.pending, (state) => {
        state.osImagesLoading = true;
        state.osImagesLoadingError = false;
      })
      .addCase(fetchOSImages.rejected, (state) => {
        state.osImagesLoadingError = true;
        state.osImagesLoading = false;
        state.osImagesLoadedTimestamp = Date.now();
      })
      .addCase(fetchOSImages.fulfilled, (state, { payload }) => {
        state.osImagesLoadedTimestamp = Date.now();
        state.osImagesLoading = false;
        state.osImagesLoadingError = false;
        // state.osImages = payload;
      })
      .addCase(fetchAllSnapshots.pending, (state) => {
        state.snapshotsLoading = true;
        state.snapshotsLoadingError = false;
      })
      .addCase(fetchAllSnapshots.rejected, (state) => {
        state.snapshotsLoadingError = true;
        state.snapshotsLoading = false;
        state.snapshotsLoadedTimestamp = Date.now();
      })
      .addCase(fetchAllSnapshots.fulfilled, (state, { payload }) => {
        state.snapshotsLoadedTimestamp = Date.now();
        state.snapshotsLoading = false;
        state.snapshotsLoadingError = false;
        // state.snapshots = payload;
      })
      .addCase(fetchISOs.pending, (state) => {
        state.isoLoading = true;
        state.isoLoadingError = false;
      })
      .addCase(fetchISOs.rejected, (state) => {
        state.isoLoadingError = true;
        state.isoLoading = false;
        state.isoLoadedTimestamp = Date.now();
      })
      .addCase(fetchISOs.fulfilled, (state, { payload }) => {
        state.isoLoadedTimestamp = Date.now();
        state.isoLoading = false;
        state.isoLoadingError = false;
        // state.isoList = payload;
      })
      .addCase(fetchAllBackups.pending, (state) => {
        state.backupsLoading = true;
        state.backupsLoadingError = false;
      })
      .addCase(fetchAllBackups.rejected, (state) => {
        state.backupsLoadingError = true;
        state.backupsLoading = false;
        state.backupsLoadedTimestamp = Date.now();
      })
      .addCase(fetchAllBackups.fulfilled, (state, { payload }) => {
        state.backupsLoadedTimestamp = Date.now();
        state.backupsLoading = false;
        state.backupsLoadingError = false;
        // state.backups = payload;
      })
      .addCase(updateUserImage.pending, (state, { meta }) => {
        state.imagesPendings.push(meta.arg.actionKey);
      })
      .addCase(updateUserImage.rejected, (state, { meta }) => {
        state.imagesPendings = state.imagesPendings.filter(
          (action) => action !== meta.arg.actionKey
        );
      })
      .addCase(updateUserImage.fulfilled, (state, { payload, meta }) => {
        state.imagesChanged = true;
        state.imagesPendings = state.imagesPendings.filter(
          (action) => action !== meta.arg.actionKey
        );
        // state.snapshots = state.snapshots.map((snapshot) => {
        //   if (snapshot.id === payload.id) return payload;
        //   return snapshot;
        // });
        // state.backups = state.backups.map((backup) => {
        //   if (backup.id === payload.id) return payload;
        //   return backup;
        // });
      })
      .addCase(deleteUserImage.pending, (state, { meta }) => {
        state.imagesPendings.push(meta.arg.actionKey);
      })
      .addCase(deleteUserImage.rejected, (state, { meta }) => {
        state.imagesPendings = state.imagesPendings.filter(
          (action) => action !== meta.arg.actionKey
        );
      })
      .addCase(deleteUserImage.fulfilled, (state, { payload, meta }) => {
        state.imagesChanged = true;
        state.imagesPendings = state.imagesPendings.filter(
          (action) => action !== meta.arg.actionKey
        );
        state.snapshots = state.snapshots.filter(
          (snapshot) => snapshot.id !== payload
        );
        state.backups = state.backups.filter((backup) => backup.id !== payload);
      });
  },
});

export const {
  removeImageNotification,
  resetImageChanged,
  removeImage,
  addImage,
  updateImage,
  switchImageType,
} = imagesSlice.actions;

export default imagesSlice.reducer;

export const imagesState = (state: RootState) => state.images;
