import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { fetchHCImages } from "../../../../api/hcImages";
import { APIQueryName } from "../../../../constants/queries";
import { ServerAction, ServerStatus } from "../../../../constants/servers";
import { serversState } from "../../../../features/userServers/serverSlice";
import { useHCServerAction } from "../../../../mutations/servers";
import SimpleCheckBox from "../../../Utils/FilterTypeCheckBox";
import SimpleRadio from "../../../Utils/SimpleRadio";
import RebuildConfirmationModal from "./ConfirmationModal";

const ServerRebuildSection = (props: { details: HCServerDetails }) => {
  const { details } = props;
  const { locked, slug, status, creditLock } = details;

  const { data } = useQuery(
    APIQueryName.HC_REBUILD_IMAGES + slug,
    () => fetchHCImages(["backup", "snapshot", "system"], slug, details.projectId),
    { staleTime: 30000, retry: 1 }
  );
  const { mutate } = useHCServerAction();
  const { serverPendings } = useSelector(serversState);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [filters, setFilters] = useState(["backup", "snapshot", "system"]);

  const [selectedImage, setSelectedImage] = useState(0);

  const handleFilterChange = (filter: string) => {
    if (!filters.includes(filter)) {
      setFilters([...filters, filter]);
      return;
    }
    setFilters(filters.filter((f) => f !== filter));
  };

  const getImages = (filters: string[]) => {
    if (filters.length === 3) return data;
    return data?.filter((image) => filters.includes(image.type));
  };

  const rebuildActionKey = "rebuildServer" + slug;

  const rebuildHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setConfirmationModal(false);
    if (selectedImage === 0) return;
    mutate({
      serverSlug: slug,
      actionType: ServerAction.REBUILD,
      actionKey: rebuildActionKey,
      reqData: { image_id: selectedImage },
    });
  };

  return (
    <div className="card">
      <div className="card-body text-center">
        <i className="icon-history icon-2x text-warning border-warning border-3 rounded-round p-3 mb-3 mt-1" />
        <h5 className="card-title text-center">Rebuild</h5>
        <p className="mb-3 text-center">
          با بازسازی مجدد سرور تمامی اطلاعات قبلی از بین رفته و سیستم عامل تازه
          ای بر روی سرور نصب می شود. شما همچنین قادر خواهید بود یک اسنپ شات یا
          بک آپ را برای بازسازی سرور انتخاب کنید.
        </p>

        <div className="row">
          <div className="col-lg-12 mx-lg-auto">
            <div className="form-group">
              <div className="form-group text-center">
                <SimpleCheckBox
                  value={"system"}
                  name={"سیستم عامل ها"}
                  onClick={() => handleFilterChange("system")}
                  checked={filters.includes("system")}
                />
                <SimpleCheckBox
                  value={"snapshot"}
                  name={"اسنپ شات ها"}
                  onClick={() => handleFilterChange("snapshot")}
                  checked={filters.includes("snapshot")}
                />
                <SimpleCheckBox
                  value={"backup"}
                  name={"بک آپ ها"}
                  onClick={() => handleFilterChange("backup")}
                  checked={filters.includes("backup")}
                />
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr className="bg-danger-400">
                      <th>انتخاب</th>
                      <th>عنوان</th>
                      <th>نوع</th>
                      <th>سرور</th>
                    </tr>
                    {getImages(filters)?.map((image) => (
                      <tr
                        onClick={() => setSelectedImage(image.id)}
                        className={
                          selectedImage === image.id ? "bg-primary" : ""
                        }
                        key={"rebuildImage" + image.id}
                      >
                        <td style={{ width: "5%" }}>
                          <SimpleRadio
                            on={selectedImage === image.id}
                            onChange={() => {}}
                          />
                        </td>
                        <td>{image.description}</td>
                        <td>{image.type}</td>
                        <td>
                          {image.createdFrom ? image.createdFrom.name : ""}
                        </td>
                      </tr>
                    ))}
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={rebuildHandler}>
          {confirmationModal && (
            <RebuildConfirmationModal
              dismiss={() => setConfirmationModal(false)}
            />
          )}
        </form>

        <button
          className="btn bg-warning"
          disabled={
            locked ||
            creditLock ||
            selectedImage === 0 ||
            serverPendings.indexOf(rebuildActionKey) >= 0
          }
          onClick={() => setConfirmationModal(true)}
          style={{ direction: "ltr" }}
        >
          Rebuild Server
          {(serverPendings.indexOf(rebuildActionKey) >= 0 ||
            status === ServerStatus.MIGRATING) && (
            <i className="icon-spinner6 spinner mr-2" />
          )}
        </button>
      </div>
    </div>
  );
};

export default ServerRebuildSection;
