import React from "react";
import { numberWithCommas } from "../../../../utilities/functions";

const ServerDetailsUsageData = (props: { details: HCServerDetails }) => {
  const { details } = props;

  if (!details) return <div></div>;

  return (
    <div className="col-md-4">
      <div className="card">
        <div className="card-body">
          <div
            className="mb-3"
            style={{ borderBottom: "1px solid rgba(153,153,153,0.5" }}
          >
            <h2 className="m-0 pr-3 text-center">استفاده سرور</h2>
            <p className="text-center mt-2">هزینه ها و ترافیک مصرفی</p>
          </div>

          <div className="">
            <ul className="media-list">
              <li className="media">
                <div className="mr-3">
                  <span className="btn bg-transparent border-pink text-pink rounded-round border-2 btn-icon">
                    <i className="icon-watch2"></i>
                  </span>
                </div>

                <div className="media-body">
                  <span>مدت زمان فعال بودن</span>
                  <br />
                  <h6 className="font-weight-semibold">
                    {details.activeTime} ساعت
                  </h6>
                </div>
              </li>

              <li className="media">
                <div className="mr-3">
                  <span className="btn bg-transparent border-pink text-pink rounded-round border-2 btn-icon">
                    <i className="icon-cloud-download2"></i>
                  </span>
                </div>

                <div className="media-body">
                  <span>ترافیک مصرفی</span>
                  <br />
                  <h6 className="font-weight-semibold">
                    {details.outgoingTraffic}/
                    {details.includedTraffic} ترابایت
                  </h6>
                </div>
              </li>

              <li className="media">
                <div className="mr-3">
                  <span className="btn bg-transparent border-pink text-pink rounded-round border-2 btn-icon">
                    <i className="icon-files-empty2"></i>
                  </span>
                </div>

                <div className="media-body">
                  <span>هزینه بک آپ</span>
                  <br />
                  <h6 className="font-weight-semibold">غیر فعال</h6>
                </div>
              </li>

              <li className="media">
                <div className="mr-3">
                  <span className="btn bg-transparent border-pink text-pink rounded-round border-2 btn-icon">
                    <i className="icon-coins"></i>
                  </span>
                </div>

                <div className="media-body">
                  <span>هزینه تا به این لحظه</span>
                  <br />
                  <h6 className="font-weight-semibold persian-number">
                    {numberWithCommas(details.billedAmount)} تومان
                  </h6>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerDetailsUsageData;
