import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { fetchHCISOs } from "../../../../api/hsISOs";
import { APIQueryName } from "../../../../constants/queries";
import { ServerAction } from "../../../../constants/servers";
import { serversState } from "../../../../features/userServers/serverSlice";
import { useHCServerAction } from "../../../../mutations/servers";
import LoadDataFailed from "../../../Utils/LoadDataFailed";
import LoadingData from "../../../Utils/LoadingData";
import SimpleLoader from "../../../Utils/SimpleLoader";

const ServerISOListTable = (props: { details: HCServerDetails }) => {
  const { details } = props;
  const {
    data: isoList,
    isError,
    isLoading,
  } = useQuery(APIQueryName.HC_ISOS, fetchHCISOs, {
    staleTime: 200000,
    retry: 1,
  });
  const { mutate } = useHCServerAction();

  const { serverPendings } = useSelector(serversState);

  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const [search, setSearch] = useState("");

  const searchHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    searchTimeout && clearTimeout(searchTimeout);
    const search = evt.currentTarget.value;
    const timeout = setTimeout(() => {
      setSearch(search);
    }, 300);
    setSearchTimeout(timeout);
  };

  const getItems = (search: string): HCISO[] => {
    if (search === "") return isoList as HCISO[];
    const items = isoList?.filter(
      (item) =>
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.description.toLowerCase().includes(search.toLowerCase())
    );
    return items as HCISO[];
  };

  useEffect(() => {
    return () => {
      searchTimeout && clearTimeout(searchTimeout);
    };
  }, [searchTimeout]);

  if (isLoading) {
    return <LoadingData />;
  }

  if (isError) {
    return <LoadDataFailed />;
  }

  if (!details) return <div></div>;

  const { iso: serverISO, slug, creditLock } = details;

  const unMountActionKey = "isoUnmount-" + slug;

  const mountUnmountHandler = (isoId: number) => {
    if (serverISO) {
      mutate({
        serverSlug: slug,
        actionType: ServerAction.DETACH_ISO,
        actionKey: unMountActionKey,
      });
      return;
    }
    mutate({
      serverSlug: slug,
      actionType: ServerAction.ATTACH_ISO,
      actionKey: "isoMount-" + slug + isoId,
      reqData: { iso_id: isoId },
    });
  };

  const getBtnState = (
    pendings: string[],
    slug: string,
    mountedISOId: number,
    isoId: number
  ) => {
    if (creditLock) return true;
    if (mountedISOId > 0 && isoId !== mountedISOId) {
      return true;
    }
    const exist = pendings.some(
      (pending) => !!pending.includes("isoMount-" + slug)
    );

    return exist;
  };

  return (
    <>
      <div className="card-body text-center">
        {serverISO && (
          <div className="row justify-content-md-center mb-4">
            <button
              className="btn bg-danger-400"
              style={{ direction: "rtl" }}
              disabled={
                serverPendings.indexOf(unMountActionKey) >= 0 || creditLock
              }
              onClick={() => mountUnmountHandler(0)}
            >
              Unmount current ISO
              {serverPendings.indexOf(unMountActionKey) >= 0 && (
                <i className="icon-spinner6 spinner ml-2" />
              )}
            </button>
          </div>
        )}

        <div className="row justify-content-md-center">
          <div className="col-md-4 form-group-feedback form-group-feedback-right text-center">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="جستجو ..."
              onChange={searchHandler}
            />
            <div className="form-control-feedback form-control-feedback-lg">
              <i className="icon-search4 text-muted" />
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive table-scrollable">
        <table className="table">
          <thead>
            <tr className="bg-danger-400">
              <th>عنوان</th>
              <th>توضیحات</th>
              <th className="text-right">عملیات</th>
            </tr>
          </thead>
          <tbody>
            {getItems(search).map((iso) => (
              <tr key={"iso" + iso.id}>
                <td style={{ width: "58%" }}>{iso.name}</td>
                <td>{iso.description}</td>
                <td className="text-right" style={{ width: "10%" }}>
                  <button
                    className="btn bg-danger-400"
                    style={{ direction: "rtl" }}
                    disabled={getBtnState(
                      serverPendings,
                      slug,
                      serverISO ? serverISO.id : 0,
                      iso.id
                    )}
                    // disabled={
                    //   (!!serverISO && serverISO.id !== iso.id) || working
                    // }
                    onClick={() => mountUnmountHandler(iso.id)}
                  >
                    {serverISO && serverISO.id === iso.id ? "Unmount" : "Mount"}
                    {(serverPendings.indexOf(
                      "isoMount-" + slug + iso.id
                    ) >= 0 ||
                      serverPendings.indexOf(unMountActionKey) >= 0) && (
                      <i className="icon-spinner6 spinner mr-2" />
                    )}
                  </button>
                </td>
              </tr>
            ))}
            {isLoading && (
              <tr>
                <td colSpan={3}>
                  <p className="text-center">
                    <SimpleLoader />
                  </p>
                  <p className="text-center">در حال دریافت اطلاعات ...</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ServerISOListTable;
