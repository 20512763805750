import React, { useState } from "react";
import { useDeleteHCImage, useUpdateHCImage } from "../../mutations/images";
import { datetimeToString } from "../../utilities/functions";

const UserBackupTableRow = (props: { backup: HCImage }) => {
  const { backup } = props;
  const { mutate: deleteMutate, isLoading: deleting } = useDeleteHCImage();
  const { mutate: updateMutate, isLoading: updating } = useUpdateHCImage();

  const [editDescription, setEditDescription] = useState(false);

  const backupUpdateHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const description = evt.currentTarget.backupNewName.value;
    if (description === backup.description) {
      setEditDescription(false);
      return;
    }
    updateMutate({
      id: backup.id,
      description: description,
      serverSlug: backup.createdFrom ? backup.createdFrom.slug : "",
      type: "",
    });
    setEditDescription(false);
  };

  const backupDeleteHandler = () => {
    deleteMutate({
      id: backup.id,
      serverSlug: backup.createdFrom ? backup.createdFrom.slug : "",
    });
  };

  return (
    <tr>
      <td>
        {!editDescription && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setEditDescription(true)}
          >
            {backup.description}{" "}
            <i
              className={`${
                updating ? "icon-spinner spinner" : "icon-pencil7"
              } top-0 ml-1`}
            />
          </span>
        )}
        {editDescription && (
          <form onSubmit={backupUpdateHandler}>
            <div className="input-group">
              <input
                style={{ direction: "ltr" }}
                name="backupNewName"
                id="backupNewName"
                type="text"
                autoFocus={true}
                className="form-control"
                defaultValue={backup.description ? backup.description : ""}
              />
              <span className="input-group-append">
                <button className="btn btn-light" type="submit">
                  تغییر
                </button>
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={() => setEditDescription(false)}
                >
                  انصراف
                </button>
              </span>
            </div>
          </form>
        )}
      </td>
      <td style={{ direction: "ltr" }}>{datetimeToString(backup.createdAt)}</td>
      <td>{backup.imageSize ? backup.imageSize.toFixed(2) + "GB" : " - "}</td>
      <td>{backup.createdFrom ? backup.createdFrom.name : " - "}</td>
      <td>{backup.status}</td>
      <td className="text-center">
        <button
          type="button"
          className="btn bg-danger-400 mr-1 ml-1"
          onClick={backupDeleteHandler}
          disabled={deleting}
        >
          <span>حذف</span>
          {deleting && <i className="icon-spinner spinner ml-1" />}
        </button>
      </td>
    </tr>
  );
};

export default UserBackupTableRow;
