import React, { useState } from "react";
import { useCreateSSHKey } from "../../mutations/sshkeys";
import { sshPublicKeyValidator } from "../../utilities/validators";

const SSHKeyCreateForm = (props: { projectId?: number }) => {
  const { projectId } = props;
  const [keyValid, setKeyValid] = useState(true);

  const createMutation = useCreateSSHKey(projectId);

  const formSubmitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const publicKey = evt.currentTarget.publicKey.value;
    if (!sshPublicKeyValidator(publicKey)) {
      setKeyValid(false);
      return;
    }
    createMutation.mutate({
      public_key: publicKey,
      description: "",
      project_id: projectId || 0,
    });
  };

  return (
    <div>
      <form onSubmit={formSubmitHandler}>
        <div className="form-group text-center">
          <label>کلید:</label>
          <textarea
            name="publicKey"
            id="publicKey"
            rows={3}
            cols={3}
            className={
              keyValid === false ? "form-control border-danger" : "form-control"
            }
            style={{ direction: "ltr" }}
            onChange={(evt) =>
              setKeyValid(sshPublicKeyValidator(evt.currentTarget.value))
            }
          ></textarea>
        </div>

        <div className="text-center">
          {!createMutation.isLoading && (
            <button type="submit" className="btn btn-primary">
              تایید و ثبت
            </button>
          )}
          {createMutation.isLoading && (
            <button disabled className="btn btn-primary">
              <i className="icon-spinner6 spinner mr-2" /> در حال ثبت ...
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default SSHKeyCreateForm;
