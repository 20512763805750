import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { closeTicket, createTicket, replyTicket } from "../api/ticketing";
import { APIQueryName } from "../constants/queries";
import { addNotification } from "../features/baseSlice";

export const useCreateTicket = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (payload: CreateTicketRequest) => {
      return createTicket(payload);
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(APIQueryName.TICKETS, (tickets: any) => {
          // No list to add to
          if (!tickets) {
            return;
          }
          return [result, ...tickets];
        });
        queryClient.setQueryData(APIQueryName.TICKET_STATS, (stats: any) => {
          // No stats to add to
          if (!stats) {
            return;
          }
          stats.total += 1;
          stats.waiting += 1;
          return stats;
        });
        dispatch(
          addNotification({
            type: "success",
            title: "عملیات موفق",
            text: "درخواست جدید با موفقیت ایجاد شد.",
          })
        );
      },
      onError: (error: any, variables, context) => {
        if (error.response && error.response.data) {
          dispatch(
            addNotification({
              type: "error",
              title: "ثبت تیکت",
              text: error.response.data
              ? error.response.data.message
              : "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
            })
          );
        }
      },
    }
  );
};

export const useReplyTicket = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (req: ReplyTicketRequest) => {
      return replyTicket(req);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(
          APIQueryName.TICKET_DETAILS + variables.slug,
          (ticket: any) => {
            ticket.replies = [...ticket.replies, result];
            return ticket;
          }
        );
        dispatch(
          addNotification({
            type: "success",
            title: "عملیات موفق",
            text: "پاسخ شما با موفقیت ارسال شد.",
          })
        );
      },
      onError: (error: any, variables, context) => {
        if (error.response && error.response.data) {
          dispatch(
            addNotification({
              type: "error",
              title: "پاسخ به تیکت",
              text: error.response.data
              ? error.response.data.message
              : "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
            })
          );
        }
      },
    }
  );
};

export const useCloseTicket = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (slug: string) => {
      return closeTicket(slug);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(
          APIQueryName.TICKET_DETAILS + variables,
          (ticket: any) => {
            ticket.closed = true;
            return ticket;
          }
        );
        queryClient.setQueryData(APIQueryName.TICKETS, (tickets: any) => {
          // No list to add to
          if (!tickets) {
            return;
          }
          return tickets.map((ticket: Ticket) => {
            if (ticket.slug === variables) {
              ticket.closed = true;
            }
            return ticket;
          });
        });
        dispatch(
          addNotification({
            type: "success",
            title: "بستن تیکت",
            text: "درخواست با موفقیت بسته شد.",
          })
        );
      },
      onError: (error: any, variables, context) => {
        if (error.response && error.response.data) {
          dispatch(
            addNotification({
              type: "error",
              title: "بستن تیکت",
              text: error.response.data
              ? error.response.data.message
              : "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
            })
          );
        }
      },
    }
  );
};
