import React from "react";

const ServerNewPassword = (props: { password: string }) => {
  const { password } = props;
  return (
    <div className="container mb-3">
      <p className="mb-2 mr-5">
        <strong>نام کاربری: </strong>
        <code>root</code>
      </p>
      <p className="mb-1 mr-5">
        <strong>رمز عبور جدید: </strong>
        <code>{password}</code>
      </p>
    </div>
  );
};

export default ServerNewPassword;
