import axios from "axios";
import apiRoutes from "../constants/apiRoutes";
import getDefaultHeaders from "../features/utils";
import { authCheckAndRedirect } from "../utilities/functions";
import { getTokenString } from "./auth";
import { jsonToHCISO } from "./mappers";

export const fetchHCISOs = async () => {
  try {
    const { data } = await axios.get(apiRoutes.V1.ISO.LIST, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const result: HCISO[] = data.map((key: any) => jsonToHCISO(key));
    return result;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};
