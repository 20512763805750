export enum ServerStatus {
  OFF = "off",
  RUNNING = "running",
  INITIALIZING = "initializing",
  STARTING = "starting",
  STOPPING = "stopping",
  DELETING = "deleting",
  MIGRATING = "migrating",
  REBUILDING = "rebuilding",
  UNKNOWN = "unknown",
  RESTARTING = "resetting",
  REBOOTING = "rebooting",
}

export enum ServerAction {
  POWER_OFF = "power-off",
  POWER_ON = "power-on",
  SHUTDOWN = "shutdown",
  REBOOT = "reboot",
  RESET = "reset",
  ENABLE_BACKUP = "enable-backup",
  DISABLE_BACKUP = "disable-backup",
  DISABLE_RESCUE = "disable-rescue",
  ATTACH_ISO = "attach-iso",
  DETACH_ISO = "detach-iso",
  REBUILD = "rebuild",
  RESCALE = "rescale",
  DELETE = "delete",
}

export enum ServerTypeGroup {
  ARM = "arm",
  STANDARD_LOCAL = "standard_local",
  STANDARD_NETWORK = "standard_network",
  DEDICATED_LOCAL = "dedicated_local",
}
