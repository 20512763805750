import React from 'react'
import PasswordChangeForm from '../../components/User/PasswordChangeForm'
import Content from '../../containers/Content'
import ContentCenter from '../../containers/ContentCenter'

const UserPasswordChange = () => {
  return (
    <Content>
      <ContentCenter>
      <div className="card">
                <div className="card-header">
                    <div className="row align-md-center">
                        <div className="col-md-10">
                            <div className="header-elements-inline">
                                <h5 className="card-title">تغییر رمز عبور</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <PasswordChangeForm />
                        </div>
                    </div>
                </div>
            </div>
      </ContentCenter>
    </Content>
  )
}

export default UserPasswordChange
