import React, { useEffect } from "react";
import { useCreateProject, useUpdateProject } from "../../mutations/projects";
import Modal from "../Utils/Modal";

const ProjectFromModal = (props: {
  dismiss: () => void;
  project?: Project;
}) => {
  const { dismiss, project } = props;
  const {
    mutate: createMutation,
    isLoading: creating,
    isSuccess: createSuccess,
  } = useCreateProject();
  const {
    mutate: updateMutation,
    isLoading: updating,
    isSuccess: updateSuccess,
  } = useUpdateProject();

  useEffect(() => {
    document.body.classList.add("modal-open");

    return () => document.body.classList.remove("modal-open");
  }, []);

  const submitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const name = evt.currentTarget.projectName.value;
    if (name.length < 2) return;
    if (!project) {
      createMutation({ name });
    } else {
      updateMutation({ name, id: project.id });
    }
  };

  if (createSuccess || updateSuccess) {
    dismiss();
  }

  return (
    <Modal size="large">
      <div>
        <Modal.Header>
          <>
            <h5 className="modal-title">
              {project ? "بروزرسانی پروژه" : "ایجاد پروژه جدید"}
            </h5>
            <button type="button" className="close" onClick={() => dismiss()}>
              ×
            </button>
          </>
        </Modal.Header>
        <Modal.Body>
          <>
            <p className="text-center code">
              پروژه ها راهی برای دسته بندی سرور ها می باشند. توجه کنید که امکان
              انتقال سرور ها بین پروژه های وجود نخواهد داشت.
            </p>
            <form onSubmit={submitHandler}>
              <div className="form-group">
                <label> عنوان :</label>
                <input
                  style={{ direction: "ltr" }}
                  name="projectName"
                  id="projectName"
                  type="text"
                  className="form-control"
                  placeholder="مثال: Group-a1"
                  maxLength={64}
                  minLength={2}
                  defaultValue={project ? project.name : ""}
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={creating || updating}
                >
                  {project ? "بروزرسانی" : "ایجاد پروژه"}
                </button>
              </div>
            </form>
          </>
        </Modal.Body>
        <Modal.Footer>
          <>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => dismiss()}
            >
              بستن
            </button>
          </>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ProjectFromModal;
