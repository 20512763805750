import React, { useState } from "react";
import ProjectFromModal from "./FormModal";

const CreateProject = () => {
  const [modal, setModal] = useState(false);
  return (
    <>
      <div
        className="dropzone dz-clickable mt-1"
        style={{ minHeight: "auto" }}
        onClick={() => setModal(true)}
      >
        <button
          type="button"
          className="btn btn-block btn-float border-top-0 border-left-0 m-0"
        >
          <i className="icon-plus3 icon-2x" style={{ cursor: "pointer" }} />
          <span style={{ cursor: "pointer" }}>New Project</span>
        </button>
      </div>
      {modal && <ProjectFromModal dismiss={() => setModal(false)} />}
    </>
  );
};

export default CreateProject;
