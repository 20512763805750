import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiRoutes from "../../constants/apiRoutes";
import { authCheckAndRedirect } from "../../utilities/functions";
import getDefaultHeaders from "../utils";

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(apiRoutes.V1.USER.PROFILE, {
        headers: getDefaultHeaders(token),
      });
      return response.data;
    } catch (error: any) {
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const sendUserEmailVerification = createAsyncThunk(
  "user/sendUserEmailVerification",
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        apiRoutes.V1.USER.SEND_EMAIL_VERIFICATION,
        {
          headers: getDefaultHeaders(token),
        }
      );
      return response.data;
    } catch (error: any) {
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

