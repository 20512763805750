import React from "react";
import { ServerAction } from "../../../../constants/servers";

type PropsType = {
  details: HCServerDetails;
  buttonType: ServerAction;
  buttonName: string;
  handler: (actionType: ServerAction) => void;
};

const getClasses = (actionType: ServerAction, spinning: boolean) => {
  let btnCls = "";
  let iCls = "";
  switch (actionType) {
    case ServerAction.POWER_OFF:
      btnCls = "btn btn-outline-danger rounded-pill";
      iCls = "icon-power-cord icon-3x p-1 mb-1 mt-1";
      break;
    case ServerAction.POWER_ON:
      btnCls = "btn btn-outline-success rounded-pill";
      iCls = "icon-power2 icon-3x p-1 mb-1 mt-1";
      break;
    case ServerAction.REBOOT:
      btnCls = "btn btn-outline-primary rounded-pill";
      iCls = "icon-history icon-3x p-1 mb-1 mt-1";
      break;
    case ServerAction.RESET:
      btnCls = "btn btn-outline-warning rounded-pill";
      iCls = "icon-reset icon-3x p-1 mb-1 mt-1";
      break;
    case ServerAction.SHUTDOWN:
      btnCls = "btn btn-outline-light rounded-pill";
      iCls = "icon-switch icon-3x p-1 mb-1 mt-1";
      break;
    default:
      break;
  }

  if (spinning) iCls = "icon-spinner6 spinner icon-3x p-1 mb-1 mt-1";

  return [btnCls, iCls];
};

const getDescription = (action: ServerAction) => {
  switch (action) {
    case ServerAction.POWER_OFF:
      return "سرور به صورت غیر اختیاری خاموش خواهد داشت. در این حالت ممکن است اطلاعات در حال پردازش از بین بروند.";
    case ServerAction.POWER_ON:
      return "روشن کردن سرور با اتصال مجدد به جریان انرژی.";
    case ServerAction.REBOOT:
      return "درخواست راه اندازی مجدد به سیستم عامل ارسال خواهد شد. سیستم عامل در زمان مناسب درخواست را اجرا خواهد کرد.";
    case ServerAction.RESET:
      return "سرور به صورت اجباری خاموش و مجدد روشن می شود. در این حالت ممکن است اطلاعات در حال پردازش از بین بروند.";
    case ServerAction.SHUTDOWN:
      return "درخواستی برای خاموش کردن به سرور ارسال خواهد شد. سرور در زمان مناسب درخواست را انجام خواهد داد.";
    default:
      break;
  }
};

const ServerDetailsControlButton = (props: PropsType) => {
  const { details, buttonType, buttonName, handler } = props;

  if (!details) {
    return <div></div>;
  }
  const { status, creditLock } = details;
  const [btnCls, iCls] = getClasses(buttonType, false);

  const disabled = () => {
    if (creditLock) return true;
    if (status === "running" && buttonType !== ServerAction.POWER_ON)
      return false;
    if (status === "off" && buttonType === ServerAction.POWER_ON) return false;
    return true;
  };

  return (
    <div className="text-center">
      <p className="text-center">
        <button
          className={btnCls}
          onClick={() => handler(buttonType)}
          disabled={disabled()}
        >
          <i className={iCls} />
        </button>
      </p>
      <h5 className="font-weight-semibold mb-0 text-center">{buttonName}</h5>
      <span className="text-muted font-size-sm">
        {getDescription(buttonType)}
      </span>
    </div>
  );
};

export default ServerDetailsControlButton;
