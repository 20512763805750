import React, { useState } from "react";
import { useQuery } from "react-query";
import { fetchTicketPriorities } from "../../../api/ticketing";
import { APIQueryName } from "../../../constants/queries";

const TicketPrioritiesSelect = () => {
  const { data } = useQuery(
    APIQueryName.TICKET_PRIORITIES,
    fetchTicketPriorities,
    {
      staleTime: 60000,
      retry: 1,
    }
  );

  const [selectedDepartment, setSelectedDepartment] = useState("");

  return (
    <select
      name="priority"
      id="priority"
      className="form-control"
      value={selectedDepartment}
      onChange={(evt) => setSelectedDepartment(evt.target.value)}
    >
      {data?.map((priority) => (
        <option value={priority.id} key={"priority" + priority.id}>
          {priority.title}
        </option>
      ))}
    </select>
  );
};

export default TicketPrioritiesSelect;
