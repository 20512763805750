import React, { useEffect, useState } from "react";

type ModalSize = "small" | "medium" | "large";

const Modal = (props: { children: JSX.Element; size?: ModalSize }) => {
  const { children, size } = props;

  const [modalSize, setModalSize] = useState("");
  useEffect(() => {
    document.body.classList.add("modal-open");

    switch (size) {
      case "small":
        setModalSize("modal-xs");
        break;
      case "medium":
        setModalSize("");
        break;
      case "large":
        setModalSize("modal-lg");
        break;
      default:
        break;
    }

    return () => document.body.classList.remove("modal-open");
  }, [size]);
  return (
    <div
      className="modal fade show"
      tabIndex={-1}
      style={{
        display: "block",
        paddingRight: "17px",
        background: "rgba(0,0,0,.5)",
      }}
    >
      <div className={`modal-dialog ${modalSize}`}>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

Modal.Header = (props: { children: JSX.Element }): JSX.Element => {
  return <div className="modal-header">{props.children}</div>;
};

Modal.Body = (props: { children: JSX.Element }): JSX.Element => {
  return <div className="modal-body">{props.children}</div>;
};

Modal.Footer = (props: { children: JSX.Element }): JSX.Element => {
  return <div className="modal-footer">{props.children}</div>;
};

export default Modal;
