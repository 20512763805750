import React from "react";
import {useQuery} from "react-query";
import {APIQueryName} from "../../constants/queries";
import {fetchBulletins} from "../../api/bulletin";
import Bulletin from "../Utils/Bulletin";

const BulletinBoard = () => {
  const {data, isLoading} = useQuery(
    APIQueryName.BULLETINS,
    fetchBulletins,
    {
      staleTime: Infinity,
      retry: 1,
    }
  );

  if (isLoading) {
    return <div></div>;
  }

  return (
    <div>
      {data && data.map((bulletin) => <Bulletin key={"bulletin" + bulletin.id} bulletin={bulletin}/>)}
    </div>
  );

};

export default BulletinBoard;
