import React from "react";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import { datetimeToString } from "../../utilities/functions";
import VolumeAttach from "./Actions/Attach";
import VolumeDelete from "./Actions/Delete";
import VolumeDetach from "./Actions/Detach";
import VolumeInfo from "./Actions/Info";
import VolumeResize from "./Actions/Resize";

const VolumeTableRow = (props: {
  volume: HCVolume;
  serverSection?: boolean;
}) => {
  const { volume, serverSection } = props;

  return (
    <tr>
      <td>{volume.name}</td>
      <td style={{ direction: "ltr" }}>{volume.size} GB</td>
      <td>{datetimeToString(volume.createdAt)}</td>
      <td>{volume.location.city}</td>
      <td>{volume.status}</td>
      <td>
        <Link to={routes.PROJECT_VOLUMES(volume.projectId)}>
          {volume.projectName}
        </Link>
      </td>

      {!serverSection && (
        <td>
          {volume.server ? (
            <Link to={routes.SERVER_DETAILS + volume.server.slug}>
              {volume.server.name}
            </Link>
          ) : (
            " - "
          )}
        </td>
      )}
      <td className="text-center">
        <VolumeResize volume={volume} />
        {!volume.server && <VolumeAttach volume={volume} />}
        {volume.server && <VolumeDetach volume={volume} />}
        <VolumeInfo volume={volume} />
        <VolumeDelete volume={volume} />
      </td>
    </tr>
  );
};

export default VolumeTableRow;
