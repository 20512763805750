import React from "react";
import { Link, useLocation } from "react-router-dom";
import routes from "../../constants/routes";

const ProjectTabs = (props: { projectId: number }) => {
  const { projectId } = props;
  const { pathname } = useLocation();

  return (
    <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
      <li className="nav-item">
        <Link
          className={`nav-link ${
            pathname === routes.PROJECT_SERVERS(projectId) ? "active" : ""
          }`}
          to={`${routes.PROJECT_SERVERS(projectId)}`}
        >
          Servers <i className="icon-server ml-2"></i>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${
            pathname === routes.PROJECT_SNAPSHOTS(projectId) ? "active" : ""
          }`}
          to={`${routes.PROJECT_SNAPSHOTS(projectId)}`}
        >
          Snapshots <i className="icon-camera ml-2"></i>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${
            pathname === routes.PROJECT_VOLUMES(projectId) ? "active" : ""
          }`}
          to={`${routes.PROJECT_VOLUMES(projectId)}`}
        >
          Volumes <i className="icon-database-add ml-2"></i>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${
            pathname === routes.PROJECT_SSHKEYS(projectId) ? "active" : ""
          }`}
          to={`${routes.PROJECT_SSHKEYS(projectId)}`}
        >
          SSH Keys <i className="icon-key ml-2"></i>
        </Link>
      </li>
    </ul>
  );
};

export default ProjectTabs;
