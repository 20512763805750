import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {fetchKeys} from './sshKeysAPI'

const sshKeysSlice = createSlice({
  name: "sshKeys",
  initialState: {
    sshKeys: [] as SSHKey[],
    sshKeysLoading: false,
    sshKeysLoaded: false,
    sshKeysLoadError: false,
  },
  reducers: {
    addSSHKey: (state, { payload }) => {
      state.sshKeys.push(payload);
    },
    removeSSHKey: (state, { payload }) => {
      state.sshKeys = state.sshKeys.filter((key => key.id !== payload.id))
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchKeys.pending, (state) => {
        state.sshKeysLoading = true;
        state.sshKeysLoadError = false;
      })
      .addCase(fetchKeys.rejected, (state, action) => {
        state.sshKeysLoadError = true;
        state.sshKeysLoading = false;
      })
      .addCase(fetchKeys.fulfilled, (state, { payload }) => {
        state.sshKeysLoaded = true;
        state.sshKeysLoading = false;
        state.sshKeysLoadError = false;
        // state.sshKeys = payload;
      });
  }
});

export const { addSSHKey, removeSSHKey } = sshKeysSlice.actions;

export default sshKeysSlice.reducer;

export const sshKeysState = (state: RootState) => state.sshKeys;
