import React, { useEffect, useRef, useState } from "react";
import { getOSImage, getOSPrettyName } from "../../../../utilities/functions";

const ServerImageOSItem = (props: {
  osGroup: HCImage[];
  selected: boolean;
  selectHandler: (id: number, flavor: string) => void;
}) => {
  const { osGroup, selected, selectHandler } = props;

  const [selectedImage, setSelectedImage] = useState(osGroup[0]);
  const [showDropDown, setShowDropDown] = useState(false);

  const dropDownNode = useRef<HTMLDivElement | null>(null);

  const versionChangeHandler = (image: HCImage, dropDown = false) => {
    if (dropDown) {
      setShowDropDown(false);
    }
    setSelectedImage(image);
    selectHandler(image.id, image.osFlavor);
  };

  const handleClickOutside = (e: any) => {
    if (
      dropDownNode &&
      dropDownNode.current &&
      dropDownNode.current.contains(e.target)
    ) {
      // inside click
      return;
    }
    // outside click
    setShowDropDown(false);
  };

  useEffect(() => {
    if (showDropDown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropDown]);

  return (
    <div
      className="col-md-4 col-sm-4 col-xs-12 col-lg-3"
      onClick={() => versionChangeHandler(selectedImage)}
    >
      <div className={selected ? "card bg-primary" : "card bg-light"}>
        <button type="button" className="btn btn-block m-0 text-center">
          {/* <i className="fab fa-windows fa-4x mb-2 mt-2"></i> */}
          <img
            src={getOSImage(osGroup[0].osFlavor)}
            className="img-fluid img-preview rounded mt-2 mb-2"
            alt={osGroup[0].osFlavor}
          />
          <br />
          <span>
            <strong className={selected ? "text-white" : ""}>
              {getOSPrettyName(osGroup[0].osFlavor)}
            </strong>
          </span>
        </button>
        <div className="card-footer justify-content-between align-items-center z-i-position">
          <div className="btn-group text-center show">
            <button
              type="button"
              className="btn btn-outline bg-dark border-dark"
              onClick={() => setShowDropDown(!showDropDown)}
            >
              <strong className={selected ? "text-white" : ""}>
                {selectedImage.osVersion}
              </strong>
            </button>
            <button
              type="button"
              className="btn btn-outline bg-dark border-dark dropdown-toggle"
              aria-expanded="true"
              onClick={() => setShowDropDown(!showDropDown)}
            ></button>
            <div
              ref={dropDownNode}
              className={showDropDown ? "dropdown-menu show" : "dropdown-menu"}
              x-placement="bottom-end"
            >
              {osGroup.map((image) => (
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    versionChangeHandler(image, true);
                  }}
                  key={"imageV" + image.id}
                >
                  {image.description}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerImageOSItem;
