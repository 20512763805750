import React, { useState } from "react";
import { ServerTypeGroup } from "../../../../constants/servers";
import SimpleLoader from "../../../Utils/SimpleLoader";
import ServerTypeItem from "./ServerTypeItem";
import ServerTypeTabs from "./ServerTypeTabs";

const ServerTypesSection = (props: {
  diskSize?: number;
  storageType?: "network" | "local";
  currentPlan?: number;
  selected?: number;
  configurations: HCServerConfiguration[] | undefined;
  selectedIndex: number;
  loading: boolean;
}) => {
  const {
    diskSize,
    storageType,
    selected,
    currentPlan,
    selectedIndex,
    configurations,
    loading,
  } = props;
  const [activeTab, setActiveTab] = useState(ServerTypeGroup.STANDARD_LOCAL);
  const [selectedPlan, setSelectedPlan] = useState(selected ? selected : 0);

  const getPlans = (activeTab: ServerTypeGroup, index: number) => {
    if (!configurations || index < 0) {
      return [] as HCServerType[];
    }
    switch (activeTab) {
      case ServerTypeGroup.STANDARD_LOCAL:
        return configurations[index].serverTypes.filter(
          (serverType) =>
            serverType.storageType === "local" &&
            serverType.cpuType === "shared" &&
            (serverType.name.startsWith("cx") || serverType.name.startsWith("cpx"))
        );
      case ServerTypeGroup.STANDARD_NETWORK:
        return configurations[index].serverTypes.filter(
          (serverType) =>
            serverType.storageType === "network" &&
            serverType.cpuType === "shared"
        );
      case ServerTypeGroup.DEDICATED_LOCAL:
        return configurations[index].serverTypes.filter(
          (serverType) =>
            serverType.storageType === "local" &&
            serverType.cpuType === "dedicated"
        );
      case ServerTypeGroup.ARM:
        return configurations[index].serverTypes.filter(
          (serverType) =>
            serverType.storageType === "local" &&
            serverType.cpuType === "shared" &&
            serverType.name.startsWith("cax")
        );
      default:
        return [] as HCServerType[];
    }
  };

  const getAllowedTabs = (storageType: string | undefined) => {
    if (storageType === "local")
      return [ServerTypeGroup.STANDARD_LOCAL, ServerTypeGroup.DEDICATED_LOCAL];
    if (storageType === "network") return [ServerTypeGroup.STANDARD_NETWORK];
    return [
      ServerTypeGroup.DEDICATED_LOCAL,
      ServerTypeGroup.STANDARD_LOCAL,
      ServerTypeGroup.STANDARD_NETWORK,
      ServerTypeGroup.ARM,
    ];
  };

  return (
    <div className="mt-5">
      <ServerTypeTabs
        activeTab={activeTab}
        tabs={getAllowedTabs(storageType)}
        switchHandler={(tab: ServerTypeGroup) => setActiveTab(tab)}
      />
      <input
        type="hidden"
        value={selectedPlan}
        name="selectedServerType"
        id="selectedServerType"
      />
      <div className="table-responsive mt-3">
        <table className="table">
          <thead>
            <tr className="">
              <th>عنوان</th>
              <th>CPU</th>
              <th>RAM</th>
              <th>SSD</th>
              <th>ترافیک</th>
              <th>هزینه/ساعت</th>
              <th>هزینه/ماه</th>
            </tr>
          </thead>
          <tbody>
            {getPlans(activeTab, selectedIndex).map((serverType) => (
              <ServerTypeItem
                key={"serverPlan" + selectedIndex + "" + serverType.id}
                serverType={serverType}
                disabled={
                  diskSize
                    ? (diskSize > serverType.disk &&
                        storageType &&
                        storageType === serverType.storageType) ||
                      serverType.id === currentPlan
                    : false
                }
                selected={selectedPlan === serverType.id}
                selectHandler={(id: number) => setSelectedPlan(id)}
              />
            ))}
            {loading && (
              <tr>
                <td colSpan={7}>
                  <p className="text-center m-2">
                    <SimpleLoader />
                  </p>
                  <p className="text-center">در حال دریافت لیست پلن ها ...</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServerTypesSection;
