import Axios from "axios";
import React, { useState } from "react";
import Alert from "../../components/Utils/Alert";
import apiRoutes from "../../constants/apiRoutes";
import Content from "../../containers/Content";
import ContentWrapper from "../../containers/ContentWrapper";
import PageContent from "../../containers/PageContent";
import { emailValidator } from "../../utilities/validators";

const PasswordForgetForm = () => {
  const [working, setWorking] = useState(false);
  const [warning, setWarning] = useState("");
  const [success, setSuccess] = useState("");

  const submitHandler = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const email = evt.currentTarget.email.value;
    if (!emailValidator(email)) {
      setWarning("ایمیل وارد شده نامعتبر است!");
    }
    setWarning("");
    setSuccess("");
    setWorking(true);

    const data = {
      email: email,
    };
    try {
      await Axios.post(apiRoutes.V1.USER.FORGET_PASSWORD, data, {});
      setSuccess("ایمیلی شامل لینک بازیابی رمز عبور برای شما ارسال شد.");
    } catch (error: any) {
      if (error.status === 500) {
        setWarning(
          "مشکلی در بررسی درخواست شما رخ داده است! لطفا بعدا امتحان کنید"
        );
      } else {
        setWarning(error.response.data.message);
      }
    }
    setWorking(false);
  };
  return (
    <PageContent cls="login-cover">
      <ContentWrapper>
        <Content cls="d-flex justify-content-center align-items-center">
          <form className="login-form" onSubmit={submitHandler}>
            <div className="card mb-0">
              <div className="card-body">
                <div className="text-center mb-3">
                  {warning !== "" && (
                    <Alert alertType="warning" text={warning} />
                  )}
                  {success !== "" && (
                    <Alert alertType="success" text={success} />
                  )}
                  <i className="icon-spinner11 icon-2x text-warning border-warning border-3 rounded-round p-3 mb-3 mt-1" />
                  <h5 className="mb-0"> بازیابی رمز عبور </h5>
                  <span className="d-block text-muted mt-1">
                    {" "}
                    برای ادامه کار ما یک ایمیل به شما ارسال میکنیم{" "}
                  </span>
                </div>

                <div className="form-group form-group-feedback form-group-feedback-right">
                  <input
                    required
                    minLength={6}
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder=" ایمیل "
                  />
                  <div className="form-control-feedback">
                    <i className="icon-mail5 text-muted" />
                  </div>
                </div>
                {!working && (
                  <button type="submit" className="btn bg-blue btn-block">
                    ارسال ایمیل بازیابی رمز{" "}
                  </button>
                )}
                {working && (
                  <button disabled className="btn bg-blue btn-block">
                    <i className="icon-spinner6 spinner mr-2" /> در حال بررسی
                    ...
                  </button>
                )}
              </div>
            </div>
          </form>
        </Content>
      </ContentWrapper>
    </PageContent>
  );
};

export default PasswordForgetForm;
