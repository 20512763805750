import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getTokenString } from "../../api/auth";
import apiRoutes from "../../constants/apiRoutes";
import routes from "../../constants/routes";
import Content from "../../containers/Content";
import ContentCenter from "../../containers/ContentCenter";
import PageContent from "../../containers/PageContent";
import { addNotification } from "../../features/baseSlice";
import { verifyUserEmail } from "../../features/users/userSlice";
import getDefaultHeaders from "../../features/utils";

const UserEmailVerification = () => {
  const { key } = useParams();
  const dispatch = useDispatch();

  const [working, setWorking] = useState(true);
  const [verified, setVerified] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);

  useEffect(() => {
    const data = {
      code: key,
    };
    // API Request
    Axios.patch(apiRoutes.V1.USER.VRIFY_EMAIL, data, {
      headers: getDefaultHeaders(getTokenString()),
    })
      .then((response) => {
        setWorking(false);
        setVerified(true);
        dispatch(verifyUserEmail());
        dispatch(
          addNotification({
            type: "success",
            title: "عملیات موفق",
            text: "ایمیل شما با موفقیت تایید شد.",
          })
        );
      })
      .catch((error) => {
        setWorking(false);
        if (error.response.status === 500) {
          setServerError(true);
        } else {
          setValidationError(error.response.data.message);
        }
      });
  }, [key, dispatch]);

  return (
    <PageContent>
      <Content>
        <ContentCenter>
          {working && (
            <div className="text-center mb-3">
              <i className="icon-spinner2 spinner icon-5x text-slate mt-1 mb-3" />
              <h5>در حال فعال سازی ایمیل ...</h5>
            </div>
          )}
          {serverError && (
            <div className="text-center mb-3">
              <i className="icon-cross2 icon-5x text-danger mt-1 mb-3" />
              <h5>
                متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید!
              </h5>
            </div>
          )}
          {!working && verified && (
            <div className="text-center mb-3">
              <i className="icon-checkmark2 icon-5x text-success mt-1 mb-3" />
              <h5>ایمیل شما با موفقیت تایید شد</h5>
            </div>
          )}

          {!working && validationError && (
            <div className="text-center mb-3">
              <i className="icon-cross2 icon-5x text-danger mt-1 mb-3" />
              <h5>{validationError}</h5>
            </div>
          )}

          <div className="row">
            <div className="col-xl-4 offset-xl-4 col-md-8 offset-md-2">
              <div className="row">
                <div className="col-sm-12">
                  <Link to={{ pathname: routes.HOME }}>
                    <button className="btn btn-primary btn-block">
                      <i className="icon-home4 mr-2" /> پنل کاربری
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </ContentCenter>
      </Content>
    </PageContent>
  );
};

export default UserEmailVerification;
