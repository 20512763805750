import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import UserBillingStats from "../components/Dashboard/BillingStats";
import UserServiceStats from "../components/Dashboard/ServiceStats";
import UserSupportStats from "../components/Dashboard/SupportStats";
import Alert from "../components/Utils/Alert";
import routes from "../constants/routes";
import Content from "../containers/Content";
import {userState} from "../features/users/userSlice";
import BulletinBoard from "../components/Dashboard/BulletinBoard";

const Dashboard = () => {
  const {emailVerified, phoneVerified} = useSelector(userState);
  return (
    <Content>
      <BulletinBoard/>
      {!emailVerified && (
        <Alert
          alertType={"danger"}
          text={
            "ایمیل شما تایید نشده است. لطفا از بخش حساب کاربری اقدام به فعال سازی ایمیل خود کنید."
          }
        />
      )}
      {!phoneVerified && (
        <Alert
          alertType={"danger"}
          text={
            "شماره شما تایید نشده است. لطفا از بخش حساب کاربری اقدام به فعال سازی شماره خود کنید."
          }
        />
      )}

      <div className="row row-tile no-gutters">
        <div className="col-3">
          <Link to={routes.SERVERS}>
            <button
              type="button"
              className="btn btn-light border-light-alpha btn-block btn-float border-top-0 border-bottom-0 border-left-0 m-0 border-right-0"
            >
              <i className="icon-server icon-2x"/>
              <span>مدیریت سرور ها</span>
            </button>
          </Link>
        </div>
        <div className="col-3">
          <Link to={routes.PROJECTS} state={{newServerCreate: true}}>
            <button
              type="button"
              className="btn btn-light border-light-alpha btn-block btn-float m-0 border-top-0 border-bottom-0 border-left-0 border-right-0"
            >
              <i className="icon-server text-blue-400 icon-2x"/>
              <span>ایجاد سرور جدید</span>
            </button>
          </Link>
        </div>
        <div className="col-3">
          <Link to={routes.BILLING}>
            <button
              type="button"
              className="btn btn-light border-light-alpha btn-block btn-float m-0 border-top-0 border-bottom-0 border-right-0 border-left-0"
            >
              <i className="icon-wallet text-pink-400 icon-2x"/>
              <span>وضعیت مالی</span>
            </button>
          </Link>
        </div>
        <div className="col-3">
          <Link to={routes.BILLING_ADD_FUND}>
            <button
              type="button"
              className="btn btn-light border-light-alpha btn-block btn-float border-top-0 m-0 border-bottom-0 border-right-0 border-left-0"
            >
              <i className="icon-plus2 text-success-400 icon-2x"/>
              <span>افزایش موجودی</span>
            </button>
          </Link>
        </div>
      </div>
      <UserServiceStats/>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <UserBillingStats/>
        </div>
        <div className="col-lg-6 col-md-12">
          <UserSupportStats/>
        </div>
      </div>
    </Content>
  );
};

export default Dashboard;
