import { apiAddress } from "../settings";
import { ServerAction } from "./servers";
import { VolumeAction } from "./volumes";

const apiRoutes = {
  V1: {
    TOKEN_VALIDATION: apiAddress + "/v1/auth/validations/token/check/",
    EMAIL_VALIDATION: apiAddress + "/v1/auth/validations/email",
    Phone_VALIDATION: apiAddress + "/v1/auth/validations/phone",
    REGISTER: apiAddress + "/v1/auth/register",
    LOGIN: apiAddress + "/v1/auth/login",
    LOGOUT: apiAddress + "/v1/auth/logout",
    USER: {
      PROFILE: apiAddress + "/v1/user",
      SEND_EMAIL_VERIFICATION: apiAddress + "/v1/user/email/verification",
      SEND_PHONE_VERIFICATION: apiAddress + "/v1/user/phone/verification",
      VRIFY_PHONE_CODE: apiAddress + "/v1/user/phone/verification",
      VRIFY_EMAIL: apiAddress + "/v1/user/email/verification",
      UPDATE_PROFILE: apiAddress + "/v1/user",
      PASSWORD_CHANGE: apiAddress + "/v1/user/password",
      FORGET_PASSWORD: apiAddress + "/v1/auth/reset",
      RESET_PASSWORD: apiAddress + "/v1/auth/reset",
      PASSWORD_RESET_KEY_VALIDATION: (key: string) => {
        return `${apiAddress}/v1/auth/reset?key=${key}`;
      },
    },
    PROJECTS: {
      STATS: apiAddress + "/v1/projects/stats",
      LIST: apiAddress + "/v1/projects",
      DETAILS: (id: number) => {
        return `${apiAddress}/v1/projects/${id}`;
      },
      CREATE: apiAddress + "/v1/projects",
      UPDATE: (id: number) => {
        return `${apiAddress}/v1/projects/${id}`;
      },
      DELETE: (id: number) => {
        return `${apiAddress}/v1/projects/${id}`;
      },
    },
    SSH_KEYS: {
      DELETE: (id: number) => {
        return `${apiAddress}/v1/hc/sshkeys/${id}`;
      },
      LIST: (projectId?: number) => {
        let url = `${apiAddress}/v1/hc/sshkeys?`;
        if (projectId) {
          url += `project_id=${projectId}`;
        }
        return url;
      },
      CREATE: apiAddress + "/v1/hc/sshkeys",
    },
    ISO: {
      LIST: apiAddress + "/v1/hc/isos",
    },
    TICKETING: {
      GET_DETAILS: (slug: string) => {
        return `${apiAddress}/v1/ticket/${slug}`;
      },
      REPLY: (slug: string) => {
        return `${apiAddress}/v1/ticket/${slug}/reply`;
      },
      CLOSE: (slug: string) => {
        return `${apiAddress}/v1/ticket/${slug}/close`;
      },
      CREATE: apiAddress + "/v1/ticket",
      LIST: apiAddress + "/v1/tickets",
      STATS: apiAddress + "/v1/ticket/stats",
      DEPARTMENTS_LIST: apiAddress + "/v1/ticket/departments",
      PRIORITIES_LIST: apiAddress + "/v1/ticket/priorities",
    },
    BILLING: {
      GET_STATS: apiAddress + "/billing/state/",
      GET_LOGS: apiAddress + "/billing/logs/",
      ADD_FUND: apiAddress + "/billing/add-fund/",
      ADD_FUND_VERIFY: apiAddress + "/billing/add-fund/verify/",
    },
    WALLET: {
      SUMMARY: apiAddress + "/v1/wallet",
      TRANSACTIONS: (filter: WalletTransactionFilter) => {
        let url = `${apiAddress}/v1/wallet/transactions?`;
        if (filter.page) {
          url += `page=${filter.page}&`;
        }
        if (filter.pageSize) {
          url += `page_size=${filter.pageSize}&`;
        }
        if (filter.from) {
          url += `from=${filter.from}&`;
        }
        if (filter.to) {
          url += `to=${filter.to}&`;
        }
        if (filter.serviceTypes) {
          filter.serviceTypes.forEach((t) => {
            url += `types=${t}&`;
          });
        }
        return url;
      },
    },
    PAYMENT: {
      INITIATE: apiAddress + "/v1/payment/initiate",
      CONFIRM: (key: string) => {
        return `${apiAddress}/v1/payment/confirm/${key}`;
      },
    },
    BULLETIN: {
      LIST: apiAddress + "/v1/bulletins",
    },
    ACTIONS: {
      GET_SERVER: (id: number) => {
        return `${apiAddress}/v1/hc/actions/servers/${id}`;
      },
      GET_VOLUME: (id: number) => {
        return `${apiAddress}/v1/hc/actions/volumes/${id}`;
      },
    },
    SERVERS: {
      CONFIGURATIONS: apiAddress + "/v1/hc/servers/configurations",
      CREATE: apiAddress + "/v1/hc/servers",
      GET_SERVER_TYPES: apiAddress + "/servers/types/list/",
      GET_SERVER_LOCATIONS: apiAddress + "/servers/datacenter/locations/list/",
      GET_USER_SERVERS: (projectId?: number, query?: string) => {
        let url = `${apiAddress}/v1/hc/servers?q=${query || ""}&`;
        if (projectId) {
          url += "project_id=" + projectId;
        }
        return url;
      },
      GET_SERVER_DETAILS: (slug: string) => {
        return `${apiAddress}/v1/hc/servers/${slug}`;
      },
      GET_SERVER_STATE: (slug: string) => {
        return `${apiAddress}/v1/hc/servers/${slug}/state`;
      },
      GET_ACTION_STATUS: apiAddress + "/servers/actions/status/",
      GET_SERVER_ACTIONS: apiAddress + "/servers/",
      GET_ISOS: apiAddress + "/servers/isos/list/",
      TRIGGER_ACTION: (serverSlug: string, actionType: ServerAction) => {
        return `${apiAddress}/v1/hc/servers/${serverSlug}/${actionType}`;
      },
      CREATE_SNAPSHOT: (serverSlug: string) => {
        return `${apiAddress}/v1/hc/servers/${serverSlug}/snapshot`;
      },
      RESET_PASSWORD: (slug: string) => {
        return `${apiAddress}/v1/hc/servers/${slug}/reset-password`;
      },
      ENABLE_RESCUE: (slug: string) => {
        return `${apiAddress}/v1/hc/servers/${slug}/rescue/enable`;
      },
      DISABLE_RESCUE: (slug: string) => {
        return `${apiAddress}/v1/hc/servers/${slug}/rescue/disable`;
      },
      ATTACH_ISO: (slug: string) => {
        return `${apiAddress}/v1/hc/servers/${slug}/attach-iso`;
      },
      GET_REBUILD_IMAGES: (serverSlug: string) => {
        return `${apiAddress}/servers/${serverSlug}/rebuild/images/`;
      },
      REBUILD: (serverSlug: string) => {
        return `${apiAddress}/servers/${serverSlug}/rebuild/`;
      },
      RESCALE: (serverSlug: string) => {
        return `${apiAddress}/servers/${serverSlug}/rescale/`;
      },
      REQUEST_CONSOLE: (serverSlug: string) => {
        return `${apiAddress}/v1/hc/servers/${serverSlug}/console`;
      },
      DELETE: (serverSlug: string) => {
        return `${apiAddress}/v1/hc/servers/${serverSlug}/delete`;
      },
      GET_STATUS: (serverSlug: string) => {
        return `${apiAddress}/servers/${serverSlug}/status/`;
      },
    },
    IMAGES: {
      UPDATE: (imageId: number) => {
        return `${apiAddress}/v1/hc/images/${imageId}`;
      },
      DELETE_IMAGE: (imageId: number) => {
        return `${apiAddress}/servers/images/${imageId}/delete/`;
      },
      LIST: (types: string[], serverSlug?: string, projectId?: number) => {
        let url = `${apiAddress}/v1/hc/images?`;
        if (types.length > 0) {
          types.forEach((type) => {
            url += "types=" + type + "&";
          });
        }
        if (serverSlug) {
          url += "server=" + serverSlug + "&";
        }
        if (projectId) {
          url += "project_id=" + projectId + "&";
        }
        return url;
      },
      DELETE: (imageId: number) => {
        return `${apiAddress}/v1/hc/images/${imageId}`;
      },
    },
    VOLUMES: {
      LIST: `${apiAddress}/v1/hc/volumes`,
      TRIGGER_ACTION: (volumeId: number, actionType: VolumeAction) => {
        return `${apiAddress}/v1/hc/volumes/${volumeId}/${actionType}`;
      },
      CREATE: `${apiAddress}/v1/hc/volume`,
      GET_ACTION_STATUS: `${apiAddress}/servers/volumes/actions/status/`,
      CREATE_VOLUME: `${apiAddress}/servers/volumes/create/`,
      GET_VOLUMES: `${apiAddress}/servers/volumes/list/`,
      DETACH: (volumeId: number) => {
        return `${apiAddress}/servers/volumes/${volumeId}/detach/`;
      },
      ATTACH: (volumeId: number) => {
        return `${apiAddress}/servers/volumes/${volumeId}/attach/`;
      },
      RESIZE: (volumeId: number) => {
        return `${apiAddress}/servers/volumes/${volumeId}/resize/`;
      },
      DELETE: (volumeId: number) => {
        return `${apiAddress}/v1/hc/volumes/${volumeId}/delete`;
      },
      GET_ATTACHABLE_SERVERS: (volumeId: number) => {
        return `${apiAddress}/v1/hc/volumes/${volumeId}/servers`;
      },
    },
  },
  V2: {},
};

export default apiRoutes;
