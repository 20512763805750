import React from "react";
import SimpleLoader from "./SimpleLoader";

const LoadingData = () => {
  return (
    <div className="text-center mb-5 mt-2">
      <SimpleLoader iconSize={5} />
      <p className="mt-3">در حال دریافت اطلاعات ...</p>
    </div>
  );
};
export default LoadingData;
