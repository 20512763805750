import React, { useState } from "react";
import { useHCServerResetPassword } from "../../../../../mutations/servers";
import PasswordResetConfirmationModal from "./ConfirmationModal";
import ServerNewPassword from "./PasswordBox";

const ServerDetailsPasswordReset = (props: { details: HCServerDetails }) => {
  const { details } = props;

  const { mutate, data, isLoading } = useHCServerResetPassword();

  const [confirmationModal, setConfirmationModal] = useState(false);

  if (!details) return <div></div>;
  const { rescueMode } = details;

  const confirmHandler = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setConfirmationModal(false);
    mutate(details.slug);
  };

  return (
    <div className="card">
      <div className="card-body text-center">
        <i className="icon-key icon-2x text-indigo-400 border-indigo-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
        <h5 className="card-title text-center">Reset Password</h5>
        <p className="mb-3 text-center">
          تنها برای سرور هایی که از سیستم عامل های لینوکس استفاده می کنند قابل
          استفاده است. همچنین سرور باید روشن باشد و{" "}
          <code>qemu guest agent</code> نیز در حال اجرا باشد.
        </p>

        {data && !isLoading && (
          <ServerNewPassword password={data.rootPassword} />
        )}

        {isLoading && (
          <button
            type="button"
            disabled
            className="btn bg-indigo-400"
            style={{ direction: "ltr" }}
          >
            <i className="icon-spinner6 spinner mr-2" /> Resetting Password ...
          </button>
        )}
        {!isLoading && (
          <button
            className="btn bg-indigo-400"
            onClick={() => setConfirmationModal(true)}
            style={{ direction: "ltr" }}
            disabled={rescueMode}
          >
            Reset Password
          </button>
        )}
        {confirmationModal && (
          <form onSubmit={confirmHandler}>
            <PasswordResetConfirmationModal
              dismiss={() => setConfirmationModal(false)}
            />
          </form>
        )}
      </div>
    </div>
  );
};

export default ServerDetailsPasswordReset;
