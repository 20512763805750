import React from "react";
import { useSelector } from "react-redux";
import ProfileCard from "../../components/User/ProfileCard";
import SimpleLoader from "../../components/Utils/SimpleLoader";
import Content from "../../containers/Content";
import ContentCenter from "../../containers/ContentCenter";
import { userState } from "../../features/users/userSlice";

const UserProfile = () => {
  const { userProfileLoaded } = useSelector(userState);

  if (!userProfileLoaded) {
    return (
      <ContentCenter>
        <div className="text-center mt-4">
          <SimpleLoader iconSize={5} />
          <p className="text-center mt-4">در حال دریافت اطلاعات ...</p>
        </div>
      </ContentCenter>
    );
  } else {
    return (
      <Content>
        <ContentCenter>
          <ProfileCard />
        </ContentCenter>
      </Content>
    );
  }
};

export default UserProfile;
