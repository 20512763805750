import React from "react";
import { useHCCreateSnapshot } from "../../../../mutations/servers";
import ServerDetailssnapshotsTable from "./SnapshotsTable";

const ServerDetailsSnapshotSection = (props: { details: HCServerDetails }) => {
  const { details } = props;
  const { mutate, isLoading } = useHCCreateSnapshot();

  const snapshotHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const description = evt.currentTarget.snapshotDescription.value;
    mutate({ slug: details.slug, backup: false, description: description });
  };

  return (
    <div className="card">
      <div className="card-body text-center">
        <i className="icon-copy3 icon-2x text-purple border-purple border-3 rounded-round p-3 mb-3 mt-1"></i>
        <h5 className="card-title text-center">Snapshot</h5>

        <p className="mb-3 text-center">
          اسنپ شات یک کپی از سرور شما در لحظه درخواست ایجاد خواهد کرد. شما قادر
          خواهید بود تا از اسنپ شات ها برای ایجاد سرور جدید استفاده کنید. همچنین
          در صورت حذف سروری که اسنپ شات از آن تهیه شده، اسنپ شات همچنان باقی
          مانده و قابل استفاده خواهد بود.
        </p>
        <p className="mb-3 text-center">
          برای جلوگیری از مشکلات اسنپ شات تهیه شده توصیه می کنیم ابتدا سرور خود
          را خاموش و سپس اقدام به تهیه اسنپ شات کنید.{" "}
        </p>
        <div className="text-center">
          <span className="bg-purple py-1 px-2 rounded">
            <span className="text-white">
              هزینه اسنپ شات ۰.۰۱ یورو/ماه به ازای هر گیگابایت خواهد بود.
            </span>
          </span>
        </div>
        <br />
        <hr />
        <form className="text-center" onSubmit={snapshotHandler}>
          <div className="row">
            <div className="col-lg-6 mx-lg-auto">
              <div className="form-group">
                <input
                  name="snapshotDescription"
                  id="snapshotDescription"
                  type="text"
                  className="form-control text-center"
                  placeholder="توضیح/عنوان"
                />
              </div>
            </div>
          </div>
          <button className="btn bg-purple" disabled={isLoading} type="submit">
            ایجاد اسنپ شات جدید
            {isLoading && <i className="icon-spinner spinner ml-1" />}
          </button>
        </form>
      </div>
      <ServerDetailssnapshotsTable
        snapshots={details.snapshots}
        serverSlug={details.slug}
      />
    </div>
  );
};

export default ServerDetailsSnapshotSection;
