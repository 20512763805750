import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../constants/routes";
import ProjectOptions from "./Options";

const ProjectCard = (props: { project: Project; create?: boolean }) => {
  const { project, create } = props;

  const navigate = useNavigate();

  const handleClick = () => {
    if (create) {
      navigate(routes.CREATE_SERVER(project.id));
      return
    }
    navigate(routes.PROJECT_SERVERS(project.id));
  };

  return (
    <div className="card disabled">
      <div className="card-body" style={{ cursor: "pointer" }}>
        <div className="media align-items-center align-items-lg-start flex-column flex-lg-row">
          <div className="mr-3 align-self-center" onClick={handleClick}>
            <i className="icon-server icon-2x text-primary opacity-75" />
          </div>

          <div
            className="media-body text-center text-lg-left"
            onClick={handleClick}
          >
            <h6 className="media-title font-weight-semibold">
              {project.name}{" "}
              {project.default && (
                <i
                  className="icon-star-full2 opacity-75"
                  title="Default Project"
                />
              )}
            </h6>
            <span className="text-muted">{project.description}</span>
          </div>

          <ProjectOptions project={project} />
        </div>
      </div>
      <div className="card-footer border-light d-flex justify-content-between align-items-center py-2">
        <div className="text-muted" title="Servers">
          <i className="icon-server mr-2" /> ({project.serversCount}/
          {project.serversLimit})
        </div>
        <div className="text-muted" title="Snapshots">
          <i className="icon-camera mr-2" /> {project.snapshotsCount}
        </div>
        <div className="text-muted" title="Volumes">
          <i className="icon-cube2 mr-2" /> {project.volumesCount}
        </div>
        <div className="text-muted" title="SSH Keys">
          <i className="icon-key mr-2" /> {project.sshKeysCount}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
