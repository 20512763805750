import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { fetchWallet } from "../../../api/wallet";
import { APIQueryName } from "../../../constants/queries";
import routes from "../../../constants/routes";
import { numberWithCommas } from "../../../utilities/functions";

const BillingUserCredit = () => {
  const { data } = useQuery(APIQueryName.WALLET_SUMMARY, fetchWallet, {
    staleTime: 20000,
    retry: 1,
  });
  return (
    <div className="card">
      <div className="card-body text-center">
        <i className="icon-wallet icon-3x text-warning mt-1 mb-3" />
        <h6 className="font-weight-semibold text-center persian-number">
          {numberWithCommas(data ? data.balance : 0)}
        </h6>
        <span className="text-muted font-size-sm">(تومان)</span>
        <p className="mb-3 mt-2 text-center">موجودی فعلی شما</p>
        <Link to={routes.BILLING_ADD_FUND}>
          <button className="btn bg-success-400">
            <i className="icon-plus3 mr-2" /> افزایش موجودی
          </button>
        </Link>
      </div>
    </div>
  );
};

export default BillingUserCredit;
