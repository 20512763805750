import React from "react";
import { Link } from "react-router-dom";
import routes from "../../../constants/routes";

const ServersCardHeader = (props: { projectId?: number }) => {
  const { projectId } = props;
  return (
    <div className="card-header header-elements-inline">
      <h5 className="card-title">لیست سرور های شما</h5>
      <div className="header-elements">
        <Link to={routes.CREATE_SERVER(projectId || 0)}>
          <button type="button" className="btn btn-primary">
            ایجاد سرور جدید
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ServersCardHeader;
