import React from "react";
import { useQuery } from "react-query";
import { fetchHCVolumes } from "../api/volume";
import SimpleLoader from "../components/Utils/SimpleLoader";
import VolumesTable from "../components/Volumes/VolumesTable";
import { APIQueryName } from "../constants/queries";
import Content from "../containers/Content";
import ContentCenter from "../containers/ContentCenter";

const Volumes = (props: { projectId?: number }) => {
  const { data, isLoading } = useQuery(
    APIQueryName.HC_VOLUMES,
    fetchHCVolumes,
    {
      staleTime: 60000,
      retry: 1,
    }
  );

  if (isLoading) {
    return (
      <Content>
        <ContentCenter>
          <div className="text-center mb-5 mt-2">
            <SimpleLoader iconSize={5} />
            <p className="mt-3">در حال دریافت لیست فضا ها ...</p>
          </div>
        </ContentCenter>
      </Content>
    );
  }

  return (
    <Content>
      <div className="card">
        <div className="card-header header-elements-inline">
          <h6 className="card-title">لیست فضا های شما</h6>
        </div>
        {data && <VolumesTable volumes={data} />}
      </div>
    </Content>
  );
};

export default Volumes;
