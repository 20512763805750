import React from "react";
import { useSelector } from "react-redux";
import { VolumeAction } from "../../../constants/volumes";
import { userState } from "../../../features/users/userSlice";
import { volumesState } from "../../../features/volumes/volumesSlice";
import { useHCVolumeAction } from "../../../mutations/volumes";
import Alert from "../../Utils/Alert";
import Modal from "../../Utils/Modal";
import Spinner from "../../Utils/Spinner";
import VolumeSizeSelect from "../VolumeSizeSelect";

const ResizeModal = (props: { dismiss: () => void; volume: HCVolume }) => {
  const { dismiss, volume } = props;
  const { mutate } = useHCVolumeAction();

  const { volumesPendings } = useSelector(volumesState);
  const { emailVerified, phoneVerified } = useSelector(userState);

  const actionKey = "volumeResize" + volume.id;

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const newSize = parseInt(evt.currentTarget.volumeSize.value);
    if (newSize <= volume.size) return;
    mutate({
      volumeId: volume.id,
      actionKey: actionKey,
      actionType: VolumeAction.RESIZE,
      reqData: { size: newSize },
    });
    dismiss();
  };

  return (
    <Modal size="large">
      <div>
        <Modal.Header>
          <>
            <h5 className="modal-title">افزایش حجم فضا</h5>
            <button type="button" className="close" onClick={() => dismiss()}>
              ×
            </button>
          </>
        </Modal.Header>
        <Modal.Body>
          <>
            {(!phoneVerified || !emailVerified) && (
              <Alert
                alertType="warning"
                text="شماره موبایل و ایمیل شما تایید نشده است!"
              />
            )}
            <p className="text-center code">
              توجه داشته باشید که امکان کاهش حجم فضا وجود ندارد و تنها قادر به
              افزایش آن خواهید بود.
            </p>
            <form onSubmit={handleSubmit}>
              <VolumeSizeSelect
                modal={true}
                value={volume.size}
                minValue={volume.size}
              />
              <button
                className="btn btn-success mb-2"
                disabled={volumesPendings.includes(actionKey)}
                style={{ direction: "ltr" }}
              >
                Resize {volumesPendings.includes(actionKey) && <Spinner />}
              </button>
              <Alert
                alertType="info"
                text="توجه داشته باشید که باید به اندازه 25% هزینه ماهانه فضای اضافه دارای اعتبار باشید. در غیر این صورت این عملیات با خطا روبرو خواهد شد!"
              />
            </form>
          </>
        </Modal.Body>
        <Modal.Footer>
          <>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => dismiss()}
            >
              انصراف
            </button>
          </>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ResizeModal;
