import Axios, { AxiosResponse } from "axios";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTokenString } from "../../api/auth";
import Alert from "../../components/Utils/Alert";
import apiRoutes from "../../constants/apiRoutes";
import Content from "../../containers/Content";
import ContentCenter from "../../containers/ContentCenter";
import { addNotification } from "../../features/baseSlice";
import {
  userState,
  verifyUserPhoneNumber,
} from "../../features/users/userSlice";
import getDefaultHeaders from "../../features/utils";
import { authCheckAndRedirect } from "../../utilities/functions";

const UserPhoneVerification = () => {
  const dispatch = useDispatch();

  const { phoneVerified } = useSelector(userState);

  const [working, setWorking] = useState(false);
  const [reSendWorking, setReSendWorking] = useState(false);

  const handlerFormError = (err: AxiosResponse) => {
    let notification: NotificationProps = {
      type: "error",
      title: "عملیات ناموفق",
      text: "",
    };
    if (err.status === 500) {
      notification.text =
        "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید!";
    } else {
      notification.text = err.data.message;
    }
    dispatch(addNotification(notification));
  };

  const handleReSendError = (err: AxiosResponse) => {
    let notification: NotificationProps = {
      type: "error",
      title: "عملیات ناموفق",
      text: "",
    };
    if (err.status === 500) {
      notification.text =
        "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.";
    } else if (err.data.error === "phone is already verified") {
      notification.type = "info";
      dispatch(verifyUserPhoneNumber());
    }
    notification.text = err.data.message;
    dispatch(addNotification(notification));
  };

  const formSubmitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setWorking(true);
    const data = {
      code: evt.currentTarget.verificationCode.value,
    };

    // API Request
    Axios.patch(apiRoutes.V1.USER.VRIFY_PHONE_CODE, data, {
      headers: getDefaultHeaders(getTokenString()),
    })
      .then((response) => {
        setWorking(false);
        dispatch(verifyUserPhoneNumber());
        dispatch(
          addNotification({
            type: "success",
            title: "عملیات موفق",
            text: "شماره موبایل شما با موفقیت تایید شد.",
          })
        );
      })
      .catch((error) => {
        setWorking(false);
        handlerFormError(error.response);
      });
  };

  const reSendHandler = () => {
    setReSendWorking(true);
    Axios.post(
      apiRoutes.V1.USER.SEND_PHONE_VERIFICATION,
      {},
      { headers: getDefaultHeaders(getTokenString()) }
    )
      .then((_) => {
        setWorking(false);
        dispatch(
          addNotification({
            type: "success",
            title: "عملیات موفق",
            text: "کد تایید شماره موبایل ارسال شد.",
          })
        );
      })
      .catch((error) => {
        setWorking(false);
        authCheckAndRedirect(error.response);
        handleReSendError(error.response);
      });
  };
  if (phoneVerified) {
    return (
      <Content>
        <ContentCenter>
          <div className="text-center">
            <i className="icon-checkmark3 icon-4x text-success-400 border-success-400 border-3 rounded-round p-3 mb-3 mt-1" />
            <h5 className="card-title text-center">
              شماره شما با موفقیت تایید شده است.
            </h5>
          </div>
        </ContentCenter>
      </Content>
    );
  }
  return (
    <Content>
      <ContentCenter>
        <div className="card">
          <div className="card-body">
            <form onSubmit={formSubmitHandler}>
              <div className="form-group row justify-content-md-center">
                <div className="col-md-6 text-center">
                  <Alert
                    alertType="info"
                    text="در صورت مشکل در دریافت پیامک لطفا یک درخواست پشتیبانی برای تایید شماره ایجاد کنید."
                  />
                </div>
              </div>
              <div className="form-group row justify-content-md-center">
                <div className="col-md-3 text-center">
                  <label className="">کد ارسال شده: </label>
                  <div>
                    <div className="input-group bootstrap-touchspin text-center">
                      <span className="input-group-prepend bootstrap-touchspin-prefix d-none">
                        <span className="input-group-text"></span>
                      </span>
                      <input
                        name="verificationCode"
                        id="verificationCode"
                        type="number"
                        className="form-control touchspin-empty text-center"
                        maxLength={6}
                        placeholder={"کد ۶ رقمی"}
                      />
                      <span className="input-group-append bootstrap-touchspin-postfix d-none">
                        <span className="input-group-text"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-3">
                {working && (
                  <button type="button" disabled className="btn bg-success-400">
                    <i className="icon-spinner6 spinner mr-2" /> در حال بررسی
                    ...
                  </button>
                )}
                {!working && (
                  <button className="btn bg-success-400">
                    <i className="icon-checkmark2 mr-2" /> تایید کد
                  </button>
                )}
                {!reSendWorking && (
                  <button
                    className="btn btn-light mr-1 ml-1"
                    onClick={reSendHandler}
                  >
                    <i className="icon-reload-alt mr-2" /> ارسال مجدد
                  </button>
                )}
                {reSendWorking && (
                  <button disabled className="btn btn-light mr-1 ml-1">
                    <i className="icon-spinner6 spinner mr-2" /> در حال ارسال کد
                    ...
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </ContentCenter>
    </Content>
  );
};

export default UserPhoneVerification;
