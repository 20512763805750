import React from "react";
import { numberWithCommas } from "../../../../utilities/functions";

const getCPUType = (typeName: string) => {
  if (typeName.includes("ccx")) {
    const number = parseInt(typeName.replace("ccx", ""));
    if (number % 2 === 0) {
      return "AMD Milan™ Epyc 7003";
    }
  }

  if (typeName.startsWith("cax")) {
    return "Ampere® Altra®"
  }

  if (typeName.includes("cpx")) {
    return "AMD EPYC 2nd Gen";
  }

  return "Intel® Xeon® Gold";
};

const ServerTypeItem = (props: {
  serverType: HCServerType;
  selected: boolean;
  disabled?: boolean;
  selectHandler: (id: number) => void;
}) => {
  const { serverType, selected, disabled, selectHandler } = props;

  return (
    <tr
      className={`${selected ? "bg-primary" : disabled ? "" : "bg-light"}`}
      style={{ cursor: !disabled ? "pointer" : "" }}
      onClick={() => (!disabled ? selectHandler(serverType.id) : {})}
    >
      <td>
        <div className="custom-control custom-radio custom-control-secondary mb-2">
          <input
            type="radio"
            className="custom-control-input"
            name="cr-l"
            id="cr_l_s_s"
            checked={selected}
            onChange={() => {
              return;
            }}
          />
          <label className="custom-control-label">{serverType.description}</label>
        </div>
      </td>
      <td>
        {serverType.cores} هسته
        <p className="text-muted" style={{direction: "ltr"}}>{getCPUType(serverType.name)}</p>
      </td>
      <td>
        {serverType.memory}GB
        <p className="text-muted">RAM</p>
      </td>
      <td>
        {serverType.disk}GB
        <p className="text-muted">
          {serverType.storageType === "local" ? "NVMe SSD" : "CEPH"}
        </p>
      </td>
      <td>
        20TB
        <p className="text-muted">10Gbit Network</p>
      </td>
      <td className="persian-number">
        {numberWithCommas(serverType.pricing ? serverType.pricing.hourlyPrice : 0)} تومان
      </td>
      <td
        className={`persian-number font-weight-bold ${
          selected ? "" : "text-warning"
        }`}
      >
        {numberWithCommas(serverType.pricing ? serverType.pricing.monthlyPrice : 0)} تومان
      </td>
    </tr>
  );
};

export default ServerTypeItem;
