import axios from "axios";
import apiRoutes from "../constants/apiRoutes";
import getDefaultHeaders from "../features/utils";
import { authCheckAndRedirect } from "../utilities/functions";
import { getTokenString } from "./auth";
import {
  jsonToTicket,
  jsonToTicketDepartment,
  jsonToTicketPriority,
  jsonToTicketReply,
  jsonToTicketStats,
} from "./mappers";

export const fetchTickets = async () => {
  try {
    const { data } = await axios.get(apiRoutes.V1.TICKETING.LIST, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const tickets: Ticket[] = data.map((ticket: any) => jsonToTicket(ticket));
    return tickets;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const fetchTicket = async (slug: string) => {
  try {
    const { data } = await axios.get(apiRoutes.V1.TICKETING.GET_DETAILS(slug), {
      headers: getDefaultHeaders(getTokenString()),
    });
    const ticket: Ticket = jsonToTicket(data);
    return ticket;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const createTicket = async (payload: CreateTicketRequest) => {
  try {
    const { data } = await axios.post(apiRoutes.V1.TICKETING.CREATE, payload, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: Ticket = jsonToTicket(data);
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const replyTicket = async (req: ReplyTicketRequest) => {
  try {
    const { data } = await axios.post(
      apiRoutes.V1.TICKETING.REPLY(req.slug),
      { text: req.text },
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    const res: TicketReply = jsonToTicketReply(data);
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const fetchTicketDepartments = async () => {
  try {
    const { data } = await axios.get(apiRoutes.V1.TICKETING.DEPARTMENTS_LIST, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: TicketDepartment[] = data.map((dep: any) =>
      jsonToTicketDepartment(dep)
    );
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const fetchTicketPriorities = async () => {
  try {
    const { data } = await axios.get(apiRoutes.V1.TICKETING.PRIORITIES_LIST, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: TicketPriority[] = data.map((pr: any) =>
      jsonToTicketPriority(pr)
    );
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const fetchTicketStats = async () => {
  try {
    const { data } = await axios.get(apiRoutes.V1.TICKETING.STATS, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: TicketStats = jsonToTicketStats(data);
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const closeTicket = async (slug: string) => {
  try {
    await axios.patch(
      apiRoutes.V1.TICKETING.CLOSE(slug),
      {},
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    const res: SimpleResponse = { status: "OK" };
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};
