import React from "react";

const SimpleCheckBox = (props: {
  value: string;
  name: string;
  checked: boolean;
  changeHandler?: (value: string) => void;
  onClick?: CallableFunction;
  onChange?: CallableFunction;
}) => {
  const { value, name, checked, onChange, onClick } = props;
  return (
    <div
      className="custom-control custom-checkbox custom-control-inline"
      onClick={() => (onClick ? onClick() : null)}
    >
      <input
        name={name}
        id={name}
        type="checkbox"
        className="custom-control-input"
        value={value}
        checked={checked}
        onChange={() => (onChange ? onChange() : null)}
      />
      <label className="custom-control-label">{name}</label>
    </div>
  );
};

export default SimpleCheckBox;
