import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  serversState,
  setServerDetailsActiveTab,
} from "../../../features/userServers/serverSlice";

const ServerDetailsTabs = () => {
  const handleTabSwitch = (
    evt: React.MouseEvent<HTMLLIElement, MouseEvent>,
    tab: string
  ) => {
    evt.preventDefault();
    dispatch(setServerDetailsActiveTab(tab));
  };
  const dispatch = useDispatch();
  const { serverDetailsActiveTab } = useSelector(serversState);
  return (
    <ul className="nav nav-tabs nav-tabs-bottom flex-nowrap mb-0">
      <li className="nav-item" onClick={(evt) => handleTabSwitch(evt, "info")}>
        <a
          href="#info"
          className={
            serverDetailsActiveTab === "info" ? "nav-link active" : "nav-link"
          }
        >
          <i className="icon-menu7 mr-1 ml-1" /> Info
        </a>
      </li>
      <li
        className="nav-item text-center"
        onClick={(evt) => handleTabSwitch(evt, "power")}
      >
        <a
          href="#power"
          className={
            serverDetailsActiveTab === "power"
              ? "nav-link active"
              : "nav-link"
          }
        >
          <i className="icon-switch2 mr-1 ml-1" /> Power
        </a>
      </li>
      <li
        className="nav-item text-center"
        onClick={(evt) => handleTabSwitch(evt, "backup")}
      >
        <a
          href="#backup"
          className={
            serverDetailsActiveTab === "backup" ? "nav-link active" : "nav-link"
          }
        >
          <i className="icon-copy4 mr-1 ml-1" /> Backup
        </a>
      </li>
      <li
        className="nav-item text-center"
        onClick={(evt) => handleTabSwitch(evt, "snapshot")}
      >
        <a
          href="#snapshot"
          className={
            serverDetailsActiveTab === "snapshot" ? "nav-link active" : "nav-link"
          }
        >
          <i className="icon-copy3 mr-1 ml-1" /> Snapshot
        </a>
      </li>
      <li className="nav-item text-center" onClick={(evt) => handleTabSwitch(evt, "rescue")}>
        <a
          href="#rescue"
          className={
            serverDetailsActiveTab === "rescue" ? "nav-link active" : "nav-link"
          }
        >
          <i className="icon-wrench3 mr-1 ml-1" /> Rescue
        </a>
      </li>
      <li className="nav-item text-center" onClick={(evt) => handleTabSwitch(evt, "console")}>
        <a
          href="#rescue"
          className={
            serverDetailsActiveTab === "console" ? "nav-link active" : "nav-link"
          }
        >
          <i className="icon-terminal mr-1 ml-1" /> Console
        </a>
      </li>
      <li className="nav-item text-center" onClick={(evt) => handleTabSwitch(evt, "iso")}>
        <a
          href="#iso"
          className={
            serverDetailsActiveTab === "iso" ? "nav-link active" : "nav-link"
          }
        >
          <i className="icon-drive mr-1 ml-1" /> ISO
        </a>
      </li>
      <li className="nav-item text-center" onClick={(evt) => handleTabSwitch(evt, "rebuild")}>
        <a
          href="#rebuild"
          className={
            serverDetailsActiveTab === "rebuild" ? "nav-link active" : "nav-link"
          }
        >
          <i className="icon-history mr-1 ml-1" /> Rebuild
        </a>
      </li>
      <li
        className="nav-item text-center"
        onClick={(evt) => handleTabSwitch(evt, "volume")}
      >
        <a
          href="#volume"
          className={
            serverDetailsActiveTab === "volume" ? "nav-link active" : "nav-link"
          }
        >
          <i className="icon-database-add mr-1 ml-1" /> Volume
        </a>
      </li>
      <li
        className="nav-item text-center"
        onClick={(evt) => handleTabSwitch(evt, "rescale")}
      >
        <a
          href="#rescale"
          className={
            serverDetailsActiveTab === "rescale"
              ? "nav-link active"
              : "nav-link"
          }
        >
          <i className="icon-wrench mr-1 ml-1" /> Rescale
        </a>
      </li>
      <li
        className="nav-item text-center"
        onClick={(evt) => handleTabSwitch(evt, "delete")}
      >
        <a
          href="#delete"
          className={
            serverDetailsActiveTab === "delete" ? "nav-link active" : "nav-link"
          }
        >
          <i className="icon-diff-removed mr-1 ml-1" /> Delete
        </a>
      </li>
    </ul>
  );
};

export default ServerDetailsTabs;
