export enum APIQueryName {
  HC_SSH_KEYS = "hc_ssh_keys",
  HC_ISOS = "hc_isos",
  PROJECT_DETAILS = "project_details_",
  PROJECT_SERVERS = "project_servers_",
  PROJECT_SSHKEYS = "project_sshkeys_",
  HC_IMAGES = "hc_images",
  HC_SNAPSHOTS = "hc_snapshots",
  HC_BACKUPS = "hc_backups",
  HC_REBUILD_IMAGES = "hc_rebuild_images_",
  HC_VOLUMES = "hc_volumes",
  HC_VOLUME_SERVERS = "hc_volume_servers_",
  WALLET_SUMMARY = "wallet_summary",
  WALLET_TRANSACTIONS = "wallet_transactions_",
  PROJECTS = "projects",
  USER_SERVICES_STATS = "user_services_stats",
  TICKETS = "tickets",
  TICKET_DETAILS = "ticket_details_",
  TICKET_DEPARTMENTS = "ticket_departments",
  TICKET_PRIORITIES = "ticket_priorities",
  TICKET_STATS = "ticket_stats",
  BULLETINS = "bulletins",
  SERVER_CONFIGURATIONS = "server_configurations",
  SERVER_DETAILS = "server_details_",
  SERVER_STATE = "server_state_",
}
