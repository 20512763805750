import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { createVolume, deleteVolume, requestVolumeAction } from "../api/volume";
import { APIQueryName } from "../constants/queries";
import { VolumeAction } from "../constants/volumes";
import { addNotification } from "../features/baseSlice";
import { addVolumeAction } from "../features/volumes/volumesSlice";

export const useCreateHCVolume = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (payload: CreateVolumeRequest) => {
      return createVolume(payload);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(APIQueryName.HC_VOLUMES, (volumes: any) => {
          // No list to add to
          if (!volumes) {
            return;
          }
          return [result, ...volumes];
        });
        queryClient.setQueryData(
          APIQueryName.SERVER_DETAILS + variables.server,
          (server: any) => {
            // No stats to add to
            if (!server) {
              return;
            }
            server.volumes = [result, ...server.volumes];
            return server;
          }
        );
        dispatch(
          addNotification({
            type: "success",
            title: "ایجاد فضا",
            text: "فضای جدید به موفقیت ایجاد شد.",
          })
        );
      },
      onError: (error: any, variables, context) => {
        if (error.response && error.response.data) {
          dispatch(
            addNotification({
              type: "error",
              title: "ایجاد فضا",
              text: error.response.data
                ? error.response.data.message
                : "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
            })
          );
        }
      },
    }
  );
};

export const useHCVolumeAction = () => {
  // const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (action: {
      volumeId: number;
      actionType: VolumeAction;
      actionKey: string;
      reqData?: any;
    }) => {
      return requestVolumeAction({
        actionType: action.actionType,
        volumeId: action.volumeId,
        reqData: action.reqData,
      });
    },
    {
      onSuccess: (result, variables, context) => {
        dispatch(
          addVolumeAction({
            actionId: result.id,
            actionType: variables.actionType,
            actionKey: variables.actionKey,
          })
        );
        dispatch(
          addNotification({
            type: "info",
            title: "عملیات در حال انجام",
            text: "درخواست شما دریافت و در حال اجراست!",
          })
        );
      },
      onError: (error: any) => {
        dispatch(
          addNotification({
            type: "error",
            title: "عملیات ناموفق",
            text: error.response.data
              ? error.response.data.message
              : "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};

export const useDeleteHCVolume = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (id: number) => {
      return deleteVolume(id);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(APIQueryName.HC_VOLUMES, (volumes: any) => {
          if (!volumes) {
            return;
          }
          volumes = volumes.filter((volume: any) => volume.id !== variables);
          return volumes;
        });
        dispatch(
          addNotification({
            type: "success",
            title: "حذف فضا",
            text: "فضا با موفقیت حذف شد.",
          })
        );
      },
      onError: (error: any) => {
        dispatch(
          addNotification({
            type: "error",
            title: "حذف فضا",
            text: error.response.data
              ? error.response.data.message
              : "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};
