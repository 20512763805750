import React, { useState } from "react";
import SimpleRadio from "../../../../Utils/SimpleRadio";

const RescueOSList = () => {
  const [selected, setSelected] = useState("linux64");
  return (
    <div className="form-group mt-2 mb-3">
      <input name="rescueOS" id="rescueOS" type="hidden" value={selected} />
      <label className="d-block font-weight-semibold">سیستم عامل</label>
      <SimpleRadio
        on={selected === "linux64"}
        onClick={() => setSelected("linux64")}
        onChange={() => {}}
        label={"linux64"}
        inline
      />
      <SimpleRadio
        on={selected === "linux32"}
        onClick={() => setSelected("linux32")}
        onChange={() => {}}
        label={"linux32"}
        inline
      />
      <SimpleRadio
        on={selected === "freebsd64"}
        onClick={() => setSelected("freebsd64")}
        onChange={() => {}}
        label={"freebsd64"}
        inline
      />
    </div>
  );
};

export default RescueOSList;
