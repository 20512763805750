import React from "react";
import { datetimeToString } from "../../../utilities/functions";
import TicketUserTypeBadge from "../TicketUserTypeBadge";

const TicketReply = (props: { reply: TicketReply }) => {
  const { reply } = props;

  return (
    <div
      className={`card border-left-3 border-left-${reply.adminResponse ? "success" : "danger"} rounded-left-0`}
      key={"ticketReply" + reply.created}
    >
      <div className="card-body">
        <div className="d-sm-flex align-item-sm-center flex-sm-nowrap">
          <div>
            <p className="mb-3 mt-3" style={{whiteSpace: "pre-line"}}>{reply.text}</p>
          </div>

          <ul className="list list-unstyled mb-0 mt-3 mt-sm-0 ml-auto">
            <li>
              <span className="text-muted">
                {datetimeToString(reply.created)}
              </span>
            </li>
            <li className="text-center">
              <TicketUserTypeBadge isStaff={reply.adminResponse} />
            </li>
          </ul>
        </div>
      </div>

      <div className="card-footer d-sm-flex justify-content-sm-between align-items-sm-center">
        <span>
          {/* {`${reply.user.firstName} ${reply.user.lastName}`}{" "} */}
          <span className="font-weight-semibold">
            {reply.adminResponse ? "پشتیبان" : "کاربر"}
          </span>
        </span>
      </div>
    </div>
  );
};

export default TicketReply;
