import React from "react";

const ServerSSHKeyItem = (props: {
  sshKey: SSHKey;
  selected: boolean;
  selectHandler: (id: number) => void;
}) => {
  const { sshKey, selected, selectHandler } = props;

  return (
    <div
      className="col-md-3 col-sm-3 col-xs-12 col-lg-2"
      onClick={() => selectHandler(sshKey.id)}
    >
      <button
        type="button"
        className={`btn border-light-alpha btn-block btn-float border-top-0 border-left-0 m-0 ${
          selected ? "bg-primary" : "btn-light"
        }`}
      >
        <strong>{sshKey.name}</strong>
      </button>
    </div>
  );
};

export default ServerSSHKeyItem;
