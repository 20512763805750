import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { createProject, deleteProject, updateProject } from "../api/projects";
import { APIQueryName } from "../constants/queries";
import { addNotification } from "../features/baseSlice";

export const useCreateProject = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (payload: { name: string }) => {
      return createProject(payload);
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(APIQueryName.PROJECTS, (projects: any) => {
          if (!projects) {
            return;
          }
          return [...projects, result];
        });
        dispatch(
          addNotification({
            type: "success",
            title: "عملیات موفق",
            text: "پروژه جدید با موفقیت ایجاد شد.",
          })
        );
      },
      onError: () => {
        dispatch(
          addNotification({
            type: "error",
            title: "عملیات ناموفق",
            text: "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};

export const useUpdateProject = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (payload: { name: string; id: number }) => {
      return updateProject(payload);
    },
    {
      onSuccess: (result, variables) => {
        queryClient.setQueryData(APIQueryName.PROJECTS, (projects: any) => {
          return projects.map((project: any) => {
            if (project.id === result.id) {
              project.name = result.name;
              return project;
            }
            return project;
          });
        });
        dispatch(
          addNotification({
            type: "success",
            title: "عملیات موفق",
            text: "پروژه جدید با موفقیت بروزرسانی شد.",
          })
        );
      },
      onError: () => {
        dispatch(
          addNotification({
            type: "error",
            title: "عملیات ناموفق",
            text: "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};

export const useDeleteProject = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (id: number) => {
      return deleteProject(id);
    },
    {
      onSuccess: (result, variables) => {
        queryClient.setQueryData(APIQueryName.PROJECTS, (projects: any) => {
          return projects.filter(
            (project: { id: number }) => project.id !== variables
          );
        });
        dispatch(
          addNotification({
            type: "success",
            title: "عملیات موفق",
            text: "پروژه با موفقیت حذف شد.",
          })
        );
      },
      onError: () => {
        dispatch(
          addNotification({
            type: "error",
            title: "عملیات ناموفق",
            text: "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};
