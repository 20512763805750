import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getTokenString, logoutUser } from "../../api/auth";
import { fetchWallet } from "../../api/wallet";
import { APIQueryName } from "../../constants/queries";
import routes from "../../constants/routes";
import { toggleSidebar } from "../../features/baseSlice";
import { fetchUser } from "../../features/users/userAPI";
import { userState } from "../../features/users/userSlice";
import { numberWithCommas } from "../../utilities/functions";

const Navbar = () => {
  const navigate = useNavigate();
  const [mobileNavbarOpen, setMobileNavbarOpen] = useState(false);
  const { userProfileLoaded, firstName, lastName } = useSelector(userState);

  const { data } = useQuery(APIQueryName.WALLET_SUMMARY, fetchWallet, {
    staleTime: 20000,
    retry: 1,
  });

  const dispatch = useDispatch();

  const handleMobileSidebar = () => {
    dispatch(toggleSidebar());
  };

  const logoutHandler = async (
    evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    evt.preventDefault();
    await logoutUser();
    localStorage.removeItem("authToken");
    navigate(routes.AUTHENTICATION);
  };

  useEffect(() => {
    !userProfileLoaded && dispatch(fetchUser(getTokenString()));
  }, [userProfileLoaded, dispatch]);
  return (
    <div className="navbar navbar-expand-md navbar-light navbar-static">
      <div className="navbar-header navbar-light d-none d-md-flex align-items-md-center">
        <div className="navbar-brand navbar-brand-md">
          <a href="/" className="d-inline-block">
            <img src="/static/img/logo_light.png" alt="Arvix Cloud" />
          </a>
        </div>
      </div>

      <div className="d-flex flex-1 d-md-none">
        <div className="navbar-brand mr-auto">
          <a href="/" className="d-inline-block">
            <img src="/static/img/logo_light.png" alt="Arvix Cloud" />
          </a>
        </div>

        <button
          className="navbar-toggler collapsed"
          type="button"
          onClick={() => setMobileNavbarOpen(!mobileNavbarOpen)}
        >
          <i className="icon-tree5" />
        </button>

        <button
          className="navbar-toggler sidebar-mobile-main-toggle"
          type="button"
        >
          <i
            className="icon-paragraph-justify3"
            onClick={handleMobileSidebar}
          />
        </button>
      </div>

      <div
        className={`collapse navbar-collapse ${mobileNavbarOpen ? "show" : ""}`}
        id="navbar-mobile"
      >
        <span className="my-3 my-md-0 ml-md-3 mr-md-auto"> </span>

        <ul className="navbar-nav">
          <li className="nav-item">
            <Link
              to={{ pathname: "/billing" }}
              className="navbar-nav-link caret-0"
            >
              <i className="icon-wallet" />
              <span className="d-md-none ml-2">موجودی</span>
              <span className="badge badge-pill bg-warning-400 ml-auto ml-md-0 persian-number font-weight-bold">
                {numberWithCommas(data ? data.balance : 0)}
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={{ pathname: "/user" }}
              className="navbar-nav-link d-flex align-items-center legitRipple"
            >
              <span>{`${firstName} ${lastName}`}</span>
            </Link>
          </li>
          <li className="nav-item">
            <a
              href="#signout"
              className="navbar-nav-link caret-0"
              onClick={logoutHandler}
            >
              <i className="icon-switch2" />
              <span className="d-md-none ml-2">خروج</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
