import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { createSSHKey, deleteSSHKey } from "../api/hcSSHKeys";
import { APIQueryName } from "../constants/queries";
import { addNotification } from "../features/baseSlice";

export const useCreateSSHKey = (projectId?: number) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (payload: CreateSSHKeyRequest) => {
      return createSSHKey(payload);
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(APIQueryName.HC_SSH_KEYS, (keys: any) => {
          if (!keys) {
            return;
          }
          return [result, ...keys];
        });
        if (projectId) {
          queryClient.setQueryData(
            APIQueryName.PROJECT_SSHKEYS + projectId,
            (keys: any) => {
              if (!keys) {
                return;
              }
              return [result, ...keys];
            }
          );
        }
        dispatch(
          addNotification({
            type: "success",
            title: "عملیات موفق",
            text: "کلید جدید با موفقیت اضافه شد.",
          })
        );
      },
      onError: (error: any) => {
        dispatch(
          addNotification({
            type: "error",
            title: "عملیات ناموفق",
            text: error.response.data
              ? error.response.data.message
              : "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};

export const useDeleteSSHKey = (projectId?: number) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (id: number) => {
      return deleteSSHKey(id);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(APIQueryName.HC_SSH_KEYS, (keys: any) => {
          if (!keys) {
            return;
          }
          return keys.filter((localKey: SSHKey) => variables !== localKey.id);
        });
        if (projectId) {
          queryClient.setQueryData(
            APIQueryName.PROJECT_SSHKEYS + projectId,
            (keys: any) => {
              if (!keys) {
                return;
              }
              return keys.filter(
                (localKey: SSHKey) => variables !== localKey.id
              );
            }
          );
        }
        dispatch(
          addNotification({
            type: "success",
            title: "عملیات موفق",
            text: "کلید مورد نظر با موفق حذف شد.",
          })
        );
      },
      onError: (error: any) => {
        dispatch(
          addNotification({
            type: "error",
            title: "عملیات ناموفق",
            text: error.response.data
              ? error.response.data.message
              : "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.",
          })
        );
      },
    }
  );
};
