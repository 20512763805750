import React from "react";
import { getCountryFlagImage } from "../../../../utilities/functions";

const ServerLocationItem = (props: {
  location: HCLocation;
  selected: boolean;
  selectHandler: () => void;
}) => {
  const { location, selected, selectHandler } = props;

  return (
    <div
      className="col-md-4 col-sm-4 col-xs-12 col-lg-3 mt-3"
      onClick={() => selectHandler()}
    >
      <button
        type="button"
        className={`btn border-light-alpha btn-block btn-float border-top-0 border-left-0 m-0 ${
          selected ? "bg-primary" : "btn-light"
        }`}
      >
        <img
          src={getCountryFlagImage(location.country)}
          className="img-fluid img-preview rounded mt-2 mb-2"
          alt={location.city}
        />
        <br />
        <span className={selected ? "text-center text-white" : "text-center"}>
          <strong>{location.city}</strong>
        </span>
      </button>
    </div>
  );
};

export default ServerLocationItem;
