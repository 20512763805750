import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { getTokenString } from "../../../api/auth";
import { jsonToHCServerActionResult } from "../../../api/mappers";
import apiRoutes from "../../../constants/apiRoutes";
import { APIQueryName } from "../../../constants/queries";
import { addNotification } from "../../../features/baseSlice";
import {
  removeServerAction,
  serversState,
} from "../../../features/userServers/serverSlice";
import getDefaultHeaders from "../../../features/utils";

const ServerDetailsActionsHandler = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { serverActions, currentServerSlug } = useSelector(serversState);

  const [actionTimeout, setActionTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  useEffect(() => {
    if (serverActions.length === 0 && actionTimeout) {
      clearTimeout(actionTimeout);
      setActionTimeout(null);
      return;
    }
    if (actionTimeout || serverActions.length === 0) {
      return;
    }

    const timeout = setTimeout(() => {
      serverActions.forEach(async (action) => {
        try {
          const response = await Axios.get(
            apiRoutes.V1.ACTIONS.GET_SERVER(action.actionId),
            { headers: getDefaultHeaders(getTokenString()) }
          );
          const actionResult = jsonToHCServerActionResult(response.data);
          if (actionResult.status === "pending") return;

          let notification: NotificationProps;
          if (actionResult.status === "success") {
            notification = {
              type: "success",
              title: "عملیات موفق: " + action.actionType.toUpperCase(),
              text: "عملیات با موفقیت انجام شد.",
            };
          } else {
            notification = {
              type: "error",
              title: "عملیات ناموفق: " + action.actionType.toUpperCase(),
              text: "مشکلی در پردازش درخواست رخ داده است.",
            };
          }
          queryClient.setQueryData(
            APIQueryName.SERVER_DETAILS + actionResult.serverSlug,
            (details: any) => {
              if (!details) {
                return;
              }
              details.status = actionResult.serverStatus;
              details.locked = actionResult.serverLocked;
              details.iso = actionResult.iso;
              details.backupEnabled = actionResult.backupEnabled;
              details.diskSize = actionResult.serverDiskSize;
              if (actionResult.serverType) {
                details.serverType = actionResult.serverType;
              }
              return details;
            }
          );
          dispatch(addNotification(notification));
          dispatch(removeServerAction(action));
          //   response.data.server_status &&
          //     dispatch(setServerStatus(response.data.server_status));
          // if (response.data.server) {
          //   // push to servers list if server is deleted
          //   if (response.data.server.status === "deleted") {
          //     dispatch(removeServer(response.data.server.slug));
          //     history.push(routes.SERVERS);
          //   } else {
          //     response.data.server.slug === currentServerSlug &&
          //       dispatch(
          //         updateServerDetails(jsonToServerDetails(response.data.server))
          //       );
          //   }
          // }
        } catch (error: any) {
          dispatch(
            addNotification({
              type: "error",
              title: "عملیات ناموفق: " + action.actionType.toUpperCase(),
              text: "مشکلی در پردازش درخواست رخ داده است.",
            })
          );
          dispatch(removeServerAction(action));
        }
      });
      actionTimeout && clearTimeout(actionTimeout);
      setActionTimeout(null);
    }, 5000);

    setActionTimeout(timeout);

    return () => {
      actionTimeout && clearTimeout(actionTimeout);
    };
  }, [serverActions, actionTimeout, currentServerSlug, queryClient, dispatch]);

  return <div></div>;
};

export default ServerDetailsActionsHandler;
