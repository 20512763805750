import React, { useState } from "react";
import { useQuery } from "react-query";
import { fetchSSHKeys } from "../../../../api/hcSSHKeys";
import { APIQueryName } from "../../../../constants/queries";
import SimpleLoader from "../../../Utils/SimpleLoader";
import ServerSSHKeyItem from "./ServerSSHKeyItem";

const ServerSSHKeysSection = () => {
  const { data, isError, isLoading } = useQuery(
    APIQueryName.HC_SSH_KEYS,
    () => fetchSSHKeys(undefined),
    {
      staleTime: 20000,
      retry: 1,
    }
  );

  const [selectedKey, setSelectedKey] = useState(0);

  const handleSelect = (id: number) => {
    if (id === selectedKey) {
      setSelectedKey(0);
      return;
    }
    setSelectedKey(id);
  };

  return (
    <div className="row d-flex justify-content-center">
      <input
        name="serverSSHKey"
        id="serverSSHKey"
        type="hidden"
        value={selectedKey}
      />
      {data?.map((sshKey) => (
        <ServerSSHKeyItem
          key={"sshKey" + sshKey.id}
          selected={selectedKey === sshKey.id}
          sshKey={sshKey}
          selectHandler={handleSelect}
        />
      ))}
      {!isLoading && !isError && data?.length === 0 && (
        <p className="text-center">شما هیچ کلیدی ندارید!</p>
      )}
      {isLoading && (
        <div className="col-md-12 text-center">
          <SimpleLoader />
          <p className="text-center">در حال دریافت لیست کلید ها ...</p>
        </div>
      )}
    </div>
  );
};

export default ServerSSHKeysSection;
