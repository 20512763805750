import React from "react";
import { useHCCreateSnapshot } from "../../../../mutations/servers";

const ServerBackupCreate = (props: { slug: string }) => {
  const { slug } = props;
  const { mutate, isLoading } = useHCCreateSnapshot();

  const createBackupHandler = async () => {
    mutate({ slug: slug, backup: true, description: "" });
  };
  return (
    <div className="text-center mt-2">
      {isLoading && (
        <button
          type="button"
          disabled
          className="btn bg-primary"
          style={{ direction: "ltr" }}
        >
          <i className="icon-spinner6 spinner mr-2" /> در حال ایجاد بک آپ ...
        </button>
      )}
      {!isLoading && (
        <button
          className="btn bg-primary"
          style={{ direction: "rtl" }}
          onClick={createBackupHandler}
        >
          <i className="icon-new mr-2" /> ایجاد بک آپ جدید
        </button>
      )}
    </div>
  );
};

export default ServerBackupCreate;
