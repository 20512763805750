import Axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTokenString } from "../../../api/auth";
import apiRoutes from "../../../constants/apiRoutes";
import routes from "../../../constants/routes";
import { addNotification } from "../../../features/baseSlice";
import {
  userState,
  verifyUserPhoneNumber,
} from "../../../features/users/userSlice";
import getDefaultHeaders from "../../../features/utils";
import { authCheckAndRedirect } from "../../../utilities/functions";
import { phoneNumberValidator } from "../../../utilities/validators";

const PhoneNumberField = () => {
  const navigate = useNavigate();

  const { phoneNumber, phoneVerified } = useSelector(userState);
  const dispatch = useDispatch();

  const [changed, setChanged] = useState(false);
  const [working, setWorking] = useState(false);
  const [valid, setValid] = useState(true);

  const changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.value !== phoneNumber) {
      setChanged(true);
    } else {
      setChanged(false);
    }
    setValid(phoneNumberValidator(evt.currentTarget.value));
  };

  const handleReSendError = (err: any) => {
    let notification: NotificationProps = {
      type: "error",
      title: "عملیات ناموفق",
      text: "",
    };
    if (err.status === 500) {
      notification.text =
        "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.";
    } else if (err.data.error === "phone is already verified") {
      notification.type = "info";
      dispatch(verifyUserPhoneNumber());
    }
    notification.text = err.data.message;
    dispatch(addNotification(notification));
  };

  const sendVerificationRequest = () => {
    setWorking(true);
    Axios.post(
      apiRoutes.V1.USER.SEND_PHONE_VERIFICATION,
      {},
      { headers: getDefaultHeaders(getTokenString()) }
    )
      .then((_) => {
        setWorking(false);
        dispatch(
          addNotification({
            type: "success",
            title: "عملیات موفق",
            text: "کد تایید شماره موبایل ارسال شد.",
          })
        );
        navigate(routes.USER_PHONE_VERIFICATION);
      })
      .catch((error) => {
        setWorking(false);
        authCheckAndRedirect(error.response);
        handleReSendError(error.response);
      });
  };

  return (
    <div className="form-group">
      <label>شماره موبایل:</label>
      <div className="input-group">
        <input
          required
          type="text"
          className="form-control persian-number"
          name="phoneNumber"
          defaultValue={phoneNumber || ""}
          onChange={changeHandler}
        />
        {!phoneVerified && (
          <span className="input-group-append">
            {!working && (
              <button
                className="btn btn-light"
                type="button"
                onClick={sendVerificationRequest}
              >
                تایید شماره
              </button>
            )}
            {working && (
              <button disabled className="btn btn-light">
                <i className="icon-spinner6 spinner mr-2" /> در حال ارسال کد ...
              </button>
            )}
          </span>
        )}
      </div>
      {!valid && (
        <span className="form-text text-danger">
          <i className="icon-cancel-circle2 mr-2" /> شماره موبایل وارد شده معتبر
          نمیباشد!
        </span>
      )}
      {changed && (
        <label className="text-warning mt-1">
          (در صورت تغییر شماره تماس باید مجدد آن را تایید کنید.)
        </label>
      )}
    </div>
  );
};

export default PhoneNumberField;
