import axios from "axios";
import apiRoutes from "../constants/apiRoutes";
import { ServerAction } from "../constants/servers";
import getDefaultHeaders from "../features/utils";
import { authCheckAndRedirect } from "../utilities/functions";
import { getTokenString } from "./auth";
import {
  jsonToHCImage,
  jsonToHCServer,
  jsonToHCServerActionResponse,
  jsonToHCServerConfiguration,
  jsonToHCServerConsole,
  jsonToHCServerCreateResponse,
  jsonToHCServerDetails,
  jsonToHCServerState,
} from "./mappers";

export const fetchConfigurations = async () => {
  try {
    const { data } = await axios.get(apiRoutes.V1.SERVERS.CONFIGURATIONS, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const configs: HCServerConfiguration[] = data.map((c: any) =>
      jsonToHCServerConfiguration(c)
    );
    return configs;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const fetchHCServers = async (projectId?: number, query?: string) => {
  try {
    const { data } = await axios.get(
      apiRoutes.V1.SERVERS.GET_USER_SERVERS(projectId, query),
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    const servers: HCServer[] = data.map((s: any) => jsonToHCServer(s));
    return servers;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const fetchServerDetails = async (slug: string) => {
  try {
    const { data } = await axios.get(
      apiRoutes.V1.SERVERS.GET_SERVER_DETAILS(slug),
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    const details: HCServerDetails = jsonToHCServerDetails(data);
    return details;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const fetchServerState = async (slug: string) => {
  try {
    const { data } = await axios.get(
      apiRoutes.V1.SERVERS.GET_SERVER_STATE(slug),
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    const state: HCServerState = jsonToHCServerState(data);
    return state;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const createServer = async (req: HCServerCreateRequest) => {
  try {
    const { data } = await axios.post(apiRoutes.V1.SERVERS.CREATE, req, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: HCServerCreateResponse = jsonToHCServerCreateResponse(data);
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const enableServerRescue = async (req: HCServerEnableRescueRequest) => {
  try {
    const { data } = await axios.post(
      apiRoutes.V1.SERVERS.ENABLE_RESCUE(req.slug),
      req,
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    const res: HCServerPasswordResponse = {
      rootPassword: data.root_password,
    };
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const disableServerRescue = async (slug: string) => {
  try {
    const { data } = await axios.post(
      apiRoutes.V1.SERVERS.DISABLE_RESCUE(slug),
      {},
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    const res: SimpleResponse = {
      status: data.status,
    };
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const resetServerPassword = async (slug: string) => {
  try {
    const { data } = await axios.post(
      apiRoutes.V1.SERVERS.RESET_PASSWORD(slug),
      {},
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    return {
      rootPassword: data.root_password,
    } as HCServerPasswordResponse;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const createServerSnapshot = async (req: HCServerSnapshotRequest) => {
  try {
    const { data } = await axios.post(
      apiRoutes.V1.SERVERS.CREATE_SNAPSHOT(req.slug),
      req,
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    return jsonToHCImage(data);
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const requestServerAction = async (action: {
  serverSlug: string;
  actionType: ServerAction;
  reqData?: any;
}) => {
  try {
    const { data } = await axios.post(
      apiRoutes.V1.SERVERS.TRIGGER_ACTION(action.serverSlug, action.actionType),
      action.reqData ? action.reqData : {},
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    return jsonToHCServerActionResponse(data);
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const requestServerConsole = async (serverSlug: string) => {
  try {
    const { data } = await axios.post(
      apiRoutes.V1.SERVERS.REQUEST_CONSOLE(serverSlug),
      {},
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    return jsonToHCServerConsole(data);
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const deleteServer = async (serverSlug: string) => {
  try {
    await axios.delete(apiRoutes.V1.SERVERS.DELETE(serverSlug), {
      headers: getDefaultHeaders(getTokenString()),
    });
    return true;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};
