import React from "react";
import { useSelector } from "react-redux";
import { billingState } from "../../../features/billing/billingSlice";
import { numberWithCommas } from "../../../utilities/functions";

const BillingFutureCost = () => {
  const { billingMonthlyCosts } = useSelector(billingState);
  return (
    <div className="card">
      <div className="card-body text-center">
        <i className="icon-piggy-bank icon-3x text-teal mt-1 mb-3" />
        <h6 className="font-weight-semibold text-center persian-number">
          {numberWithCommas(billingMonthlyCosts.total)}
        </h6>
        <span className="text-muted font-size-sm">(تومان)</span>
        <p className="mb-3 text-center mt-1">هزینه مورد نیاز برای ۳۰ روز</p>
        <p className="text-center text-muted mb-3">
          توجه: این مقدار تقریبی است. هزینه شما بسته به شرایط مختلف می تواند
          کمتر یا بیشتر باشد.
        </p>
      </div>
    </div>
  );
};

export default BillingFutureCost;
