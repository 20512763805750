import React, { useState } from "react";
import { useReplyTicket } from "../../../mutations/ticketing";

const TicketNewReply = (props: { slug: string }) => {
  const { slug } = props;

  const [textError, setTextError] = useState(false);
  const [text, setText] = useState("");

  const { mutate } = useReplyTicket();

  const formSubmitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const text = evt.currentTarget.replyText.value;
    if (text.length < 1) {
      setTextError(true);
      return;
    }
    mutate({ slug, text });
    setText("");
  };

  return (
    <div className="card text-right">
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <form onSubmit={formSubmitHandler}>
              <div className="form-group">
                <textarea
                  name="replyText"
                  id="replyText"
                  rows={5}
                  cols={5}
                  className="form-control"
                  placeholder=" پیام خود را اینجا وارد نمایید "
                  value={text}
                  onChange={(evt) => setText(evt.target.value)}
                />
                {textError && (
                  <span className="form-text text-danger">
                    <i className="icon-cancel-circle2 mr-2" /> متن نمیتواند خالی
                    باشد! لطفا مشکل خود را توضیح دهید.
                  </span>
                )}
              </div>

              <div className="text-left">
                <button type="submit" className="btn btn-primary">
                  {" "}
                  ارسال پاسخ{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketNewReply;
