import axios from "axios";
import apiRoutes from "../constants/apiRoutes";
import { VolumeAction } from "../constants/volumes";
import getDefaultHeaders from "../features/utils";
import { authCheckAndRedirect } from "../utilities/functions";
import { getTokenString } from "./auth";
import {
  jsonToBasicHCServer,
  jsonToHCVolume,
  jsonToHCVolumeActionResult,
} from "./mappers";

export const fetchHCVolumes = async () => {
  try {
    const { data } = await axios.get(apiRoutes.V1.VOLUMES.LIST, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const volumes: HCVolume[] = data.map((volume: any) =>
      jsonToHCVolume(volume)
    );
    return volumes;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const createVolume = async (payload: CreateVolumeRequest) => {
  try {
    const { data } = await axios.post(apiRoutes.V1.VOLUMES.CREATE, payload, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: HCVolume = jsonToHCVolume(data);
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};
export const requestVolumeAction = async (action: {
  volumeId: number;
  actionType: VolumeAction;
  reqData?: any;
}) => {
  try {
    const { data } = await axios.post(
      apiRoutes.V1.VOLUMES.TRIGGER_ACTION(action.volumeId, action.actionType),
      action.reqData ? action.reqData : {},
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    return jsonToHCVolumeActionResult(data);
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const fetchHCVolumeAttachableServers = async (volumeId: number) => {
  try {
    const { data } = await axios.get(
      apiRoutes.V1.VOLUMES.GET_ATTACHABLE_SERVERS(volumeId),
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    const servers: HCBasicServerInfo[] = data.map((server: any) =>
      jsonToBasicHCServer(server)
    );
    return servers;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const deleteVolume = async (volumeId: number) => {
  try {
    await axios.delete(apiRoutes.V1.VOLUMES.DELETE(volumeId), {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: SimpleResponse = { status: "OK" };
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};
