import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { fetchHCVolumeAttachableServers } from "../../../api/volume";
import { APIQueryName } from "../../../constants/queries";
import { VolumeAction } from "../../../constants/volumes";
import { volumesState } from "../../../features/volumes/volumesSlice";
import { useHCVolumeAction } from "../../../mutations/volumes";
import Modal from "../../Utils/Modal";
import Spinner from "../../Utils/Spinner";

const AttachModal = (props: { dismiss: () => void; volume: HCVolume }) => {
  const { dismiss, volume } = props;
  const { mutate } = useHCVolumeAction();
  const { data } = useQuery(
    APIQueryName.HC_VOLUME_SERVERS,
    () => fetchHCVolumeAttachableServers(volume.id),
    {
      staleTime: 30000,
      retry: 1,
    }
  );
  const { volumesPendings } = useSelector(volumesState);

  const [selectedServer, setSelectedServer] = useState("");

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => document.body.classList.remove("modal-open");
  }, []);

  const actionKey = "volumeAttach" + volume.id;

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (selectedServer === "") return;
    // const autoMount = evt.currentTarget.mountOption.value;
    mutate({
      volumeId: volume.id,
      actionKey: actionKey,
      actionType: VolumeAction.ATTACH,
      reqData: { server: selectedServer },
    });
    dismiss();
  };

  return (
    <Modal size="large">
      <div>
        <Modal.Header>
          <>
            <h5 className="modal-title">اتصال فضا به سرور</h5>
            <button type="button" className="close" onClick={() => dismiss()}>
              ×
            </button>
          </>
        </Modal.Header>
        <Modal.Body>
          <>
            <p className="text-center code">
              اتصال فضا تنها به سرور هایی ممکن است که لوکیشن آن ها با لوکیشن فضا
              یکی می باشند.
            </p>
            <p className="text-center font-weight-bold">لوکیشن فضا:</p>
            <p className="text-center">
              <code style={{ direction: "ltr" }}>
                {volume.location.name} - {volume.location.city} -{" "}
                {volume.location.networkZone} - {volume.location.country}
              </code>
            </p>
            <p className="text-center">
              لطفا یکی از سرور های زیر را انتخاب کنید:
            </p>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr className="bg-teal-300">
                    <th>انتخاب</th>
                    <th>نام سرور</th>
                    <th>وضعیت</th>
                  </tr>
                  {data?.map((server) => (
                    <tr
                      onClick={() => setSelectedServer(server.slug)}
                      className={
                        server.slug === selectedServer ? "bg-primary" : ""
                      }
                      key={"attachServer" + server.slug}
                    >
                      <td style={{ width: "5%" }}>
                        <div className="uniform-choice">
                          <span
                            className={
                              selectedServer === server.slug ? "checked" : ""
                            }
                          >
                            <input
                              type="radio"
                              className="form-check-input-styled"
                              name="radio-inline-left"
                            />
                          </span>
                        </div>
                      </td>
                      <td>{server.name}</td>
                      <td>{server.status}</td>
                    </tr>
                  ))}
                </thead>
              </table>
            </div>
            <p className="text-center mt-4">نوع اتصال:</p>
            <form onSubmit={handleSubmit}>
              {/* <VolumeMountOptions fileSystem={false} col={12} /> */}
              <button
                className="btn btn-success"
                disabled={
                  volumesPendings.includes(actionKey) || selectedServer === ""
                }
                style={{ direction: "ltr" }}
              >
                Attach {volumesPendings.includes(actionKey) && <Spinner />}
              </button>
            </form>
          </>
        </Modal.Body>
        <Modal.Footer>
          <>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => dismiss()}
            >
              بستن
            </button>
          </>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default AttachModal;
