import React from "react";
import ServerDetailsPasswordReset from "./PasswordReset/PasswordReset";
import ServerRescueMode from "./RescueMode/RescueMode";

const ServerDetailsRescueSection = (props: { details: HCServerDetails }) => {
  const { details } = props;
  return (
    <>
      <ServerDetailsPasswordReset details={details} />
      <ServerRescueMode details={details} />
    </>
  );
};

export default ServerDetailsRescueSection;
