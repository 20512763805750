import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchWallet } from "./walletAPI";

const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    balance: 0,
    monthUsage: 0,
    totalDeposits: 0,

    walletLoading: false,
    walletLoaded: false,
    walletLoadError: null as any,
  },
  reducers: {
    applyPayment: (state, { payload }) => {
      state.balance += payload.amount;
      state.totalDeposits += payload.amount;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWallet.pending, (state) => {
        state.walletLoading = true;
        state.walletLoaded = false;
        state.walletLoadError = null;
      })
      .addCase(fetchWallet.rejected, (state, { payload }) => {
        state.walletLoadError = payload;
        state.walletLoaded = true;
        state.walletLoading = false;
      })
      .addCase(fetchWallet.fulfilled, (state, { payload }) => {
        state.walletLoading = false;
        state.walletLoaded = true;
        state.walletLoadError = null;
        // state.balance = payload.balance;
        // state.monthUsage = payload.monthUsage;
        // state.totalDeposits = payload.totalDeposits;
      });
  },
});

export const { applyPayment } = walletSlice.actions;

export default walletSlice.reducer;

export const walletState = (state: RootState) => state.wallet;
