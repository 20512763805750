import React from "react";

const ServerImageTabs = (props: {
  activeTab: "snapshots" | "os";
  switchHandler: (tab: "snapshots" | "os") => void;
}) => {
  const { activeTab, switchHandler } = props;

  const handleSelect = (
    evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    group: "snapshots" | "os"
  ) => {
    evt.preventDefault();
    switchHandler(group);
  };
  return (
    <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
      <li
        className="nav-item text-center"
      >
        <a
          href="#os"
          className={`nav-link text-center ${
            activeTab === "os" ? "active" : ""
          }`}
          onClick={(e) => {
            handleSelect(e, "os");
          }}
        >
          سیستم عامل ها
        </a>
      </li>
      <li
        className="nav-item text-center"
      >
        <a
          href="#snapshots"
          className={`nav-link text-center ${
            activeTab === "snapshots" ? "active" : ""
          }`}
          onClick={(e) => {
            handleSelect(e, "snapshots");
          }}
        >
          اسنپ شات ها
        </a>
      </li>
    </ul>
  );
};

export default ServerImageTabs;
