import React, { useEffect, useState } from "react";
import ServersListTableBody from "./ListTableBody";
import ServersCardHeader from "./ServersCardHeader";

const ServersList = (props: { projectId?: number }) => {
  const [queryTimeout, setQueryTimeout] = useState<any>(null);
  const [query, setQuery] = useState("");

  const { projectId } = props;

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const q = e.currentTarget.value;
    queryTimeout && clearTimeout(queryTimeout);
    setQueryTimeout(
      setTimeout(() => {
        setQuery(q);
      }, 500)
    );
  };

  useEffect(() => {
    return () => clearTimeout(queryTimeout);
  }, [queryTimeout]);

  return (
    <div className="card mb-5">
      <ServersCardHeader projectId={projectId} />

      <div
        className="table-responsive table-scrollable"
        style={{ maxHeight: "100%" }}
      >
        <div className="row card-body justify-content-md-center">
          <div className="col-md-4 text-center mt-2">
            <div className="form-group-feedback form-group-feedback-left mb-3 mb-sm-0">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={searchHandler}
              />
              <div className="form-control-feedback">
                <i className="icon-search4 text-muted" />
              </div>
            </div>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">مشخصات</th>
              <th className="text-center">مدت فعالیت</th>
              <th className="text-center">آی پی</th>
              <th className="text-center">پروژه</th>
              <th className="text-center">وضعیت</th>
            </tr>
          </thead>
          <ServersListTableBody projectId={projectId} query={query} />
        </table>
      </div>
    </div>
  );
};

export default ServersList;
