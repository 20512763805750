import React from "react";
import ServerRescaleNotes from "./RescaleNotes";
import ServerRescaleForm from "./RescaleForm";

const ServerRescaleSection = (props: { details: HCServerDetails }) => {
  const { details } = props;
  return (
    <>
      <ServerRescaleNotes />
      <ServerRescaleForm details={details} />
    </>
  );
};

export default ServerRescaleSection;
