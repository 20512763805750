import React, { useState } from "react";
import VolumeInfoModal from "../VolumeInfoModal";

const VolumeInfo = (props: { volume: HCVolume }) => {
  const { volume } = props;
  const [modal, setModal] = useState("");
  return (
    <>
      <button
        type="button"
        className="btn btn-info btn-icon btn-sm mr-1 ml-1 mt-1"
        onClick={() => setModal("info")}
      >
        <i className="icon-notification2" />
      </button>
      {modal && (
        <VolumeInfoModal volume={volume} dismiss={() => setModal("")} />
      )}
    </>
  );
};

export default VolumeInfo;
