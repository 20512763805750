import React from "react";
import { ServerAction } from "../../../../constants/servers";
import Alert from "../../../Utils/Alert";
import ServerDetailsControlButton from "./ControlButton";
import { useHCServerAction } from "../../../../mutations/servers";

const ServerDetailsControlSection = (props: { details: HCServerDetails }) => {
  const { details } = props;
  const { mutate } = useHCServerAction();

  if (!details) return <div></div>;

  const { status, slug } = details;

  const actionHandler = (actionType: ServerAction) => {
    const actionKey = `server_${actionType}_${slug}`;
    mutate({serverSlug: slug, actionType: actionType, actionKey});
  };
  return (
    <div className="row">
      <div className="col-md-12">
        {status !== "running" && status !== "off" && (
          <Alert
            alertType="warning"
            text="کنترل ها تنها در شرایطی فعال هستند که وضعیت سرور در حالت Running یا Off قرار داشته باشد. در صورتی سرور در حالت عادی قرار دارد ولی وضعیت دیگری مشاهده می کنید، لطفا دقایقی صبر کنید تا وضعیت سرور بروزرسانی شود."
          />
        )}
        <div className="card card-body text-center">
          <div className="row text-center">
            <div className="col-2 mr-auto text-center">
              <ServerDetailsControlButton
                details={details}
                buttonName={"Reboot"}
                buttonType={ServerAction.REBOOT}
                handler={actionHandler}
              />
            </div>

            <div className="col-2 mr-auto text-center">
              <ServerDetailsControlButton
                details={details}
                buttonName={"Reset"}
                buttonType={ServerAction.RESET}
                handler={actionHandler}
              />
            </div>

            <div className="col-2 mr-auto text-center">
              <ServerDetailsControlButton
                details={details}
                buttonName={"Shutdown"}
                buttonType={ServerAction.SHUTDOWN}
                handler={actionHandler}
              />
            </div>

            <div className="col-2 mr-auto text-center">
              <ServerDetailsControlButton
                details={details}
                buttonName={"Power Off"}
                buttonType={ServerAction.POWER_OFF}
                handler={actionHandler}
              />
            </div>

            <div className="col-2 mr-auto text-center">
              <ServerDetailsControlButton
                details={details}
                buttonName={"Power On"}
                buttonType={ServerAction.POWER_ON}
                handler={actionHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerDetailsControlSection;
