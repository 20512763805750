import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";
import { isAuthenticated } from "./api/auth";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";
import routes from "./constants/routes";
import ContentWrapper from "./containers/ContentWrapper";
import Notifications from "./containers/Notifications";
import PageContent from "./containers/PageContent";
import Authentication from "./pages/Authentication";
import UserEmailVerification from "./pages/User/UserEmailVerification";
import UserPhoneVerification from "./pages/User/UserPhoneVerification";
import UserProfile from "./pages/User/UserProfile";
import UserPasswordChange from "./pages/User/UserPasswordChange";
import SSHKeys from "./pages/SSHKeys";
import Tickets from "./pages/Ticketing/Tickets";
import TicketCreate from "./pages/Ticketing/TicketCreate";
import TicketDetails from "./pages/Ticketing/TicketDetails";
import BillingStats from "./pages/Billing/BillingStats";
import BillingLogs from "./pages/Billing/BillingLogs";
import BillingAddFund from "./pages/Billing/AddFund";
import VerifyAddFund from "./pages/Billing/VerifyAddFund";
import Servers from "./pages/UserServers/ServersList";
import ServerDetails from "./pages/UserServers/ServerDetails";
import UserSnapshots from "./pages/ServerImages/Snapshots";
import UserBackups from "./pages/ServerImages/Backups";
import VolumeActionsHandler from "./components/Volumes/ActionsHandler";
import Volumes from "./pages/Volumes";
import ServerDetailsActionsHandler from "./components/UserServers/Details/ActionsHandler";
import CreateServer from "./pages/CreateServer";
import NewServerInfo from "./pages/NewServerInfo";
import Dashboard from "./pages/Dashboard";
import PasswordForgetForm from "./pages/PasswordReset/PasswordForgetForm";
import PasswordResetForm from "./pages/PasswordReset/PasswordResetForm";

import "./static/css/icomoon/styles.min.css";
import "./static/css/bootstrap.min.css";
import "./static/css/bootstrap_limitless.min.css";
import "./static/css/layout.min.css";
import "./static/css/components.min.css";
import "./static/css/colors.min.css";
import "./static/css/style.min.css";
import "./static/css/styles.min.css";
import { QueryClient, QueryClientProvider } from "react-query";
import Projects from "./pages/Projects/Projects";
import ProjectServers from "./pages/Projects/ProjectServers";
import ProjectSnapshots from "./pages/Projects/ProjectSnapshots";
import ProjectSSHKeys from "./pages/Projects/ProjectSSHKeys";
import ProjectVolumes from "./pages/Projects/ProjectVolumes";

const queryClient = new QueryClient();

function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();

  if (!isAuthenticated()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={routes.AUTHENTICATION} state={{ from: location }} />;
  }

  return children;
}

const DefaultLayout = () => {
  return (
    <RequireAuth>
      <>
        <Navbar />
        <PageContent>
          <Sidebar />
          <ContentWrapper>
            <Outlet />
          </ContentWrapper>
        </PageContent>
        <Notifications />
        <ServerDetailsActionsHandler />
        <VolumeActionsHandler />
      </>
    </RequireAuth>
  );
};

function Root() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route
            path={routes.FORGET_PASSWORD + "/:key"}
            element={<PasswordResetForm />}
          />
          <Route
            path={routes.FORGET_PASSWORD}
            element={<PasswordForgetForm />}
          />
          <Route
            path={`${routes.USER_EMAIL_VERIFICATION}/:key`}
            element={<UserEmailVerification />}
          />
          <Route path={"/auth"} element={<Authentication />} />
          <Route
            path={`${routes.BILLING_ADD_FUND_VERIFY}:verificationCode`}
            element={<VerifyAddFund />}
          />

          <Route element={<DefaultLayout />}>
            <Route
              path={routes.USER_PASSWORD_CHANGE}
              element={<UserPasswordChange />}
            />
            <Route
              path={routes.USER_PHONE_VERIFICATION}
              element={<UserPhoneVerification />}
            />

            <Route path={routes.USER_PROFILE} element={<UserProfile />} />
            {/* SSHKeys Routes */}
            <Route path={routes.SSH_KEYS} element={<SSHKeys />} />
            {/* Volumes Routes */}
            <Route path={routes.VOLUMES} element={<Volumes />} />
            {/* Ticketing Routes */}

            <Route path={routes.TICKET_CREATE} element={<TicketCreate />} />
            <Route
              path={`${routes.TICKET_DETAILS}:slug`}
              element={<TicketDetails />}
            />
            <Route path={routes.TICKETS} element={<Tickets />} />
            {/* Billing Routes */}

            <Route
              path={routes.BILLING_ADD_FUND}
              element={<BillingAddFund />}
            />
            <Route path={routes.BILLING_LOGS} element={<BillingLogs />} />
            <Route path={routes.BILLING} element={<BillingStats />} />
            {/* Servers */}
            <Route path={routes.NEW_SERVER_INFO} element={<NewServerInfo />} />
            <Route path={routes.SNAPSHOTS} element={<UserSnapshots />} />
            <Route path={routes.BACKUPS} element={<UserBackups />} />
            <Route path={routes.OLD_CREATE_SERVER} element={<CreateServer />} />
            <Route
              path={`${routes.SERVER_DETAILS}:slug`}
              element={<ServerDetails />}
            />
            <Route path={routes.SERVERS} element={<Servers />} />
            <Route
              path={`${routes.PROJECTS}/:projectId/servers/new`}
              element={<CreateServer />}
            />
            <Route
              path={`${routes.PROJECTS}/:projectId/servers`}
              element={<ProjectServers />}
            />
            <Route
              path={`${routes.PROJECTS}/:projectId/snapshots`}
              element={<ProjectSnapshots />}
            />
            <Route
              path={`${routes.PROJECTS}/:projectId/sshkeys`}
              element={<ProjectSSHKeys />}
            />
            <Route
              path={`${routes.PROJECTS}/:projectId/volumes`}
              element={<ProjectVolumes />}
            />
            <Route path={routes.PROJECTS} element={<Projects />} />
            {/* Home Route */}
            <Route path={"/"} element={<Dashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default Root;
