import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { fetchConfigurations } from "../../api/servers";
import { APIQueryName } from "../../constants/queries";
import routes from "../../constants/routes";
import { userState } from "../../features/users/userSlice";
import Alert from "../Utils/Alert";
import Spinner from "../Utils/Spinner";
import ServerImageSection from "./FormSections/ServerImage/ServerImageSection";
import ServerLocationSection from "./FormSections/ServerLocation/ServerLocationSection";
import ServerTypesSection from "./FormSections/ServerType/ServerTypesSection";
import ServerSSHKeysSection from "./FormSections/SSHKeys/SSHKeysSection";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateHCServer } from "../../mutations/servers";
import { fetchWallet } from "../../api/wallet";

const NewServer = () => {
  const navigate = useNavigate();
  const params = useParams();

  const projectId = parseInt(params.projectId || "0");

  if (projectId === 0) {
    navigate(routes.PROJECTS, {
      state: { newServerCreate: true },
    });
  }

  const {
    mutate,
    data: createData,
    isSuccess,
    isLoading: creating,
  } = useCreateHCServer();

  const { data, isLoading } = useQuery(
    APIQueryName.SERVER_CONFIGURATIONS,
    fetchConfigurations,
    {
      staleTime: 60000,
      retry: 1,
    }
  );

  const { data: walletData, isLoading: walletLoading } = useQuery(
    APIQueryName.WALLET_SUMMARY,
    fetchWallet,
    {
      staleTime: 60000,
      retry: 1,
    }
  );

  const [warning, setWarning] = useState("");
  const [serverTypesIndex, setServerTypesIndex] = useState(-1);

  const { phoneVerified, emailVerified } = useSelector(userState);

  const createHandler = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const serverLocation = evt.currentTarget.serverLocation.value;
    const serverType = evt.currentTarget.selectedServerType.value;
    const serverImage = evt.currentTarget.serverImage.value;
    const sshKey = evt.currentTarget.serverSSHKey.value;

    if (serverLocation === "0") {
      setWarning("لطفا موقعیت سرور را انتخاب کنید.");
      return;
    }
    if (serverType === "0") {
      setWarning("لطفا پلن (مشخصات) سرور را انتخاب کنید.");
      return;
    }
    if (serverImage === "0") {
      setWarning("لطفا سیستم عامل سرور را انتخاب کنید.");
      return;
    }
    setWarning("");
    let req: HCServerCreateRequest = {
      server_type: parseInt(serverType),
      location: parseInt(serverLocation),
      image: parseInt(serverImage),
      ssh_keys: [],
      project_id: projectId,
    };
    if (sshKey !== "0") {
      req.ssh_keys = [parseInt(sshKey)];
    }
    mutate(req);
  };

  if (isSuccess) {
    navigate(routes.NEW_SERVER_INFO, {
      state: { ...createData },
    });
  }

  return (
    <form onSubmit={createHandler}>
      <div className="mb-5">
        <h4>۱ - موقعیت سرور</h4>
        <p className="text-muted mb-3">
          سرور ها را می توانید از چندین لوکشن مختلف تهیه کنید. تفاوتی در کیفیت
          سخت افزار و شبکه این لوکیشن ها وجود ندارد. توصیه می شود نزدیک ترین
          لوکیشن به کاربرانتان را انتخاب کنید.
        </p>
        <ServerLocationSection
          configurations={data}
          loading={isLoading}
          onLocationChange={(index: number) => {
            setServerTypesIndex(index);
          }}
        />
      </div>
      <div className="mb-5">
        <h4>۲ - انتخاب سیستم عامل</h4>
        <p className="text-muted mb-3">
          امکان انتخاب سیستم عامل های مختلف و نسخه های مختلف برای سرور ها ممکن
          است. همچنین می توانید از تب اسنپ شات ها یکی از اسنپ شات های موجود خود
          را انتخاب کنید. توجه کنید که فضای سرور مربوط به اسنپ شات نباید بیشتر
          از پلن انتخابی باشد.
        </p>
        <ServerImageSection projectId={projectId} />
      </div>
      <div className="mb-5">
        <h4>۳ - انتخاب مشخصات سرور</h4>
        <p className="text-muted mb-3">
          سرور ها در چندین دسته مختلف قابل تهیه هستند. دیسک های NVMe مناسب برای
          کاربرانی است که نیاز به I/O بالاتری دارند و دیسک های CEPH مناسب برای
          افرادی است که دسترس پذیری بالایی را مد نظر دارند. همچنین Dedicated CPU
          Cores مناسب افرادی هستند که حجم پردازش بالایی دارند.
        </p>
        <ServerTypesSection
          configurations={data}
          selectedIndex={serverTypesIndex}
          loading={isLoading}
        />
      </div>
      <div className="mb-5">
        <h4>۴ - انتخاب کلید های SSH</h4>
        <p className="text-muted mb-3">
          توصیه می شود تا برای امنیت بیشتر از کلید SSH برای دسترسی به سرور خود
          استفاده کنید. در صورت انتخاب نکردن هیچ کلیدی، رمز عبوری برای سرور شما
          مشخص و در اختیار شما قرار خواهد گرفت.
        </p>
        <ServerSSHKeysSection />
      </div>
      <div className="text-center mb-5">
        <Alert
          alertType="info"
          text="توجه داشته باشید که باید حداقل به اندازه 25% هزینه ماهانه پلن انتخابی دارای اعتبار باشید. در غیر این صورت این عملیات ناموفق خواهد بود."
        />
        {((walletData && walletData.balance < 10000) || walletLoading) && (
          <Alert
            alertType="warning"
            text="متاسفانه شما اعتبار کافی برای تهیه سرور ندارید!"
          />
        )}
        {(!phoneVerified || !emailVerified) && (
          <Alert
            alertType="warning"
            text="برای تهیه سرور باید شماره تماس و ایمیل شما تایید شده باشد!"
          />
        )}

        {warning !== "" && <Alert alertType="warning" text={warning} />}
        <button
          className="btn btn-primary mt-2"
          style={{ direction: "ltr" }}
          type="submit"
          disabled={
            creating ||
            (walletData && walletData.balance < 10000) ||
            walletLoading ||
            !phoneVerified ||
            !emailVerified
          }
        >
          Create Server {creating && <Spinner />}
        </button>
      </div>
    </form>
  );
};

export default NewServer;
