import { createSlice } from "@reduxjs/toolkit";
import { VolumeAction } from "../../constants/volumes";
import { RootState } from "../../store";
import {
  attachVolume,
  deleteVolume,
  detachVolume,
  fetchVolumes,
  resizeVolume,
} from "./volumesAPI";

const volumesSlice = createSlice({
  name: "volumes",
  initialState: {
    // Sometimes user's delete an image from snapshots/backups tab.
    // So we set a imageChanged here so serverDetails page can check if snapshots/backups changed
    // And if so, update itself and not use the old serverDetails
    volumesChanged: false,
    volumesPendings: [] as string[],
    volumeActionNames: [] as string[],
    volumes: [] as VolumeDetails[],
    volumesLoading: false,
    volumesLoadingError: false,
    volumesLoadedTimestamp: 0,
    volumesNotifications: [] as NotificationProps[],
    volumeActions: [] as ActionData[],
  },
  reducers: {
    removeVolumesNotification: (state, { payload }) => {
      state.volumesNotifications = state.volumesNotifications.filter(
        (notification) => notification.id !== payload
      );
    },
    addVolumeAction: (state, { payload }) => {
      state.volumeActions.push(payload);
      state.volumeActionNames.push(payload.actionType);
      state.volumesPendings.push(payload.actionKey);
    },
    resetVolumeChanged: (state) => {
      state.volumesChanged = false;
    },
    removeVolumeAction: (state, { payload }) => {
      state.volumeActions = state.volumeActions.filter(
        (action) => action.actionId !== payload.actionId
      );
      if (payload.actionKey) {
        state.volumesPendings = state.volumesPendings.filter(
          (action) => action !== payload.actionKey
        );
      }
    },
    addVolume: (state, { payload }) => {
      state.volumes.push(payload);
    },
    volumeUpdate: (state, { payload }) => {
      // If volume deleted
      if (payload.status === "deleted") {
        state.volumes = state.volumes.filter(volume => volume.id !== payload.id)
        return
      }
      state.volumes = state.volumes.map((volume) => {
        if (volume.id !== payload.id) return volume;
        return payload;
      });
      // state.volumesChanged = true
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVolumes.pending, (state) => {
        state.volumesLoading = true;
        state.volumesLoadingError = false;
      })
      .addCase(fetchVolumes.rejected, (state) => {
        state.volumesLoadingError = true;
        state.volumesLoading = false;
        state.volumesLoadedTimestamp = Date.now();
      })
      .addCase(fetchVolumes.fulfilled, (state, { payload }) => {
        state.volumesLoadedTimestamp = Date.now();
        state.volumesLoading = false;
        state.volumesLoadingError = false;
        // state.volumes = payload;
      })
      .addCase(detachVolume.pending, (state, { meta }) => {
        state.volumesPendings.push(meta.arg.actionKey);
      })
      .addCase(detachVolume.rejected, (state, action) => {
        state.volumesPendings = state.volumesPendings.filter(
          (actionKey) => actionKey !== action.meta.arg.actionKey
        );
      })
      .addCase(detachVolume.fulfilled, (state, { payload, meta }) => {
        state.volumeActions.push({
          actionId: payload,
          actionType: VolumeAction.DETACH,
          actionKey: meta.arg.actionKey,
        });
      })
      .addCase(attachVolume.pending, (state, { meta }) => {
        state.volumesPendings.push(meta.arg.actionKey);
      })
      .addCase(attachVolume.rejected, (state, action) => {
        state.volumesPendings = state.volumesPendings.filter(
          (actionKey) => actionKey !== action.meta.arg.actionKey
        );
      })
      .addCase(attachVolume.fulfilled, (state, { payload, meta }) => {
        state.volumeActions.push({
          actionId: payload,
          actionType: VolumeAction.ATTACH,
          actionKey: meta.arg.actionKey,
        });
      })
      .addCase(resizeVolume.pending, (state, { meta }) => {
        state.volumesPendings.push(meta.arg.actionKey);
      })
      .addCase(resizeVolume.rejected, (state, action) => {
        state.volumesPendings = state.volumesPendings.filter(
          (actionKey) => actionKey !== action.meta.arg.actionKey
        );
      })
      .addCase(resizeVolume.fulfilled, (state, { payload, meta }) => {
        state.volumeActions.push({
          actionId: payload,
          actionType: VolumeAction.RESIZE,
          actionKey: meta.arg.actionKey,
        });
      })
      .addCase(deleteVolume.pending, (state, { meta }) => {
        state.volumesPendings.push(meta.arg.actionKey);
      })
      .addCase(deleteVolume.rejected, (state, action) => {
        state.volumesPendings = state.volumesPendings.filter(
          (actionKey) => actionKey !== action.meta.arg.actionKey
        );
      })
      .addCase(deleteVolume.fulfilled, (state, { payload, meta }) => {
        state.volumeActions.push({
          actionId: payload,
          actionType: VolumeAction.DELETE,
          actionKey: meta.arg.actionKey,
        });
      });
  },
});

export const {
  removeVolumesNotification,
  resetVolumeChanged,
  removeVolumeAction,
  volumeUpdate,
  addVolume,
  addVolumeAction,
} = volumesSlice.actions;

export default volumesSlice.reducer;

export const volumesState = (state: RootState) => state.volumes;
