import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import { getTokenString } from "../../api/auth";
import { jsonToHCISO, jsonToServerImageData } from "../../api/mappers";
import apiRoutes from "../../constants/apiRoutes";
import { authCheckAndRedirect } from "../../utilities/functions";
import { addNotification } from "../baseSlice";
import { serverAddSnapshot } from "../userServers/serverSlice";
import getDefaultHeaders from "../utils";


export const fetchOSImages = createAsyncThunk(
  "images/fetchOSImages",
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        apiRoutes.V1.IMAGES.LIST + "?types=system&types=os",
        {
          headers: getDefaultHeaders(token),
        }
      );
      const images: ServerImageData[] = response.data.map(
        (image: any) => {
          return jsonToServerImageData(image);
        }
      );
      return images;
    } catch (error: any) {
      console.log(error);
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const fetchAllSnapshots = createAsyncThunk(
  "images/fetchAllSnapshots",
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        apiRoutes.V1.IMAGES.LIST + "?types=snapshot",
        {
          headers: getDefaultHeaders(token),
        }
      );
      const snapshots: ServerImageData[] = response.data.map(
        (snapshot: any) => {
          return jsonToServerImageData(snapshot);
        }
      );
      return snapshots;
    } catch (error: any) {
      console.log(error);
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const fetchAllBackups = createAsyncThunk(
  "images/fetchAllBackups",
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        apiRoutes.V1.IMAGES.LIST + "?types=backup",
        {
          headers: getDefaultHeaders(token),
        }
      );
      const backups: ServerImageData[] = response.data.map((backup: any) => {
        return jsonToServerImageData(backup);
      });
      return backups;
    } catch (error: any) {
      console.log(error);
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const fetchISOs = createAsyncThunk(
  "images/fetchISOs",
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await Axios.get(apiRoutes.V1.SERVERS.GET_ISOS, {
        headers: getDefaultHeaders(token),
      });
      const isos: HCISO[] = response.data.map((iso: any) => {
        return jsonToHCISO(iso);
      });
      return isos;
    } catch (error: any) {
      console.log(error);
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const updateUserImage = createAsyncThunk(
  "images/updateUserImage",
  async (
    context: {
      actionKey: string;
      imageId: number;
      description?: string;
      changeToSnapshot?: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    let data = new FormData();
    context.description && data.append("description", context.description);
    context.changeToSnapshot && data.append("type", "snapshot");
    try {
      const response = await Axios.post(
        apiRoutes.V1.IMAGES.UPDATE(context.imageId),
        data,
        {
          headers: getDefaultHeaders(getTokenString()),
        }
      );
      dispatch(
        addNotification({
          type: "success",
          title: "بروزرسانی ایمیج",
          text: "ایمیج مورد نظر با موفقیت بروزرسانی شد!",
        })
      );
      const image = jsonToServerImageData(response.data.image);
      context.changeToSnapshot && dispatch(serverAddSnapshot(image));
      return image;
    } catch (error: any) {
      dispatch(
        addNotification({
          type: "error",
          title: "بروزرسانی ایمیج",
          text: "متاسفانه خطایی در بروزرسانی ایمیج رخ داده است",
        })
      );
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response ? error.response.status : null,
        data: error.response ? error.response.data : null,
      });
    }
  }
);

export const deleteUserImage = createAsyncThunk(
  "images/deleteUserImage",
  async (
    context: {
      actionKey: string;
      imageId: number;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await Axios.post(
        apiRoutes.V1.IMAGES.DELETE_IMAGE(context.imageId),
        {},
        {
          headers: getDefaultHeaders(getTokenString()),
        }
      );
      dispatch(
        addNotification({
          type: "success",
          title: "حذف ایمیج",
          text: "ایمیج مورد نظر با موفقیت حذف شد!",
        })
      );
      return context.imageId;
    } catch (error: any) {
      dispatch(
        addNotification({
          type: "error",
          title: "حذف ایمیج",
          text: "متاسفانه خطایی در حذف ایمیج مورد نظر رخ داده است!",
        })
      );
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response ? error.response.status : null,
        data: error.response ? error.response.data : null,
      });
    }
  }
);
