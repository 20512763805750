import React, { useEffect, useRef, useState } from "react";
import DeleteProjectModal from "./DeleteModal";
import ProjectFromModal from "./FormModal";

const ProjectOptions = (props: { project: Project }) => {
  const { project } = props;

  const menuRef = useRef<HTMLDivElement>(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const clickHandler = (event: any) => {
    event.preventDefault();
    toggle();
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isOpen && menuRef.current && !menuRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <div className="list-icons">
      <div className="dropdown d-inline-flex">
        <a
          href="#options"
          className="list-icons-item dropdown-toggle"
          data-toggle="dropdown"
          onClick={clickHandler}
        >
          <i className="icon-menu7"></i>
        </a>
        <div
          ref={menuRef}
          className={
            "dropdown-menu dropdown-menu-right" + (isOpen ? " show" : "")
          }
          style={{ minWidth: "5rem" }}
        >
          <button
            className="dropdown-item"
            onClick={() => setUpdateModal(true)}
          >
            ویرایش
          </button>
          <button
            className="dropdown-item text-danger"
            onClick={() => setDeleteModal(true)}
          >
            حذف
          </button>
        </div>
      </div>
      {updateModal && (
        <ProjectFromModal
          dismiss={() => setUpdateModal(false)}
          project={project}
        />
      )}
      {deleteModal && (
        <DeleteProjectModal
          dismiss={() => setDeleteModal(false)}
          project={project}
        />
      )}
    </div>
  );
};

export default ProjectOptions;
