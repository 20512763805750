import React from "react";
import SimpleLoader from "../../Utils/SimpleLoader";
import {
  numberWithCommas,
  datetimeToString,
} from "../../../utilities/functions";
import BillingLogsPagination from "./BillingLogsPagination";
import { useQuery } from "react-query";
import { APIQueryName } from "../../../constants/queries";
import { fetchWalletTransactions } from "../../../api/wallet";

const getLogTypeText = (log: WalletTransaction) => {
  switch (log.serviceType) {
    case "hc_server":
      return <p>سرور: {log.serviceName !== "" ? log.serviceName : "-"}</p>;
    case "hc_ipv4":
      return <p>آی پی سرور: {log.serviceName !== "" ? log.serviceName : "-"}</p>;
    case "hc_volume":
      return (
        <p>فضای اضافه: {log.serviceName !== "" ? log.serviceName : "-"}</p>
      );
    case "hc_snapshot":
      return <p>اسنپ شات: {log.serviceName !== "" ? log.serviceName : "-"}</p>;
    case "hc_server_backup":
      return (
        <p>بک آپ سرور: {log.serviceName !== "" ? log.serviceName : "-"}</p>
      );
    case "hc_server_traffic":
      return (
        <p>ترافیک اضافه: {log.serviceName !== "" ? log.serviceName : "-"}</p>
      );
    case "add_fund":
      return <p>افزایش موجودی: {log.amount}</p>;
    default:
      return log.serviceType;
  }
};

const BillingLogsTableBody = (props: {
  filter: WalletTransactionFilter;
  setFilter: (filter: WalletTransactionFilter) => void;
}) => {
  const { filter, setFilter } = props;
  const { data, isLoading } = useQuery(
    [APIQueryName.WALLET_TRANSACTIONS, filter],
    () => fetchWalletTransactions(filter),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: true,
    }
  );

  return (
    <tbody>
      {data?.result.map((log, i) => (
        <tr
          className="persian-number"
          key={"billingLog" + i + log.amount + log.createdAt.getUTCSeconds()}
        >
          <td>{getLogTypeText(log)}</td>
          <td>{numberWithCommas(log.amount)}</td>
          <td style={{ width: "30%" }}>{log.description}</td>
          <td style={{ width: "25%" }}>
            {log.from ? datetimeToString(log.from) : "-"} -{" "}
            {log.to ? datetimeToString(log.to) : "-"}
          </td>
        </tr>
      ))}
      {isLoading && (
        <tr>
          <td className="text-center" colSpan={6}>
            <SimpleLoader />
            <p className="text-center">در حال دریافت اطلاعات ...</p>
          </td>
        </tr>
      )}
      {!isLoading && data?.result.length === 0 && (
        <tr>
          <td className="text-center" colSpan={6}>
            <p className="text-center">هیچ اطلاعاتی در این بازه وجود ندارد.</p>
          </td>
        </tr>
      )}
      <BillingLogsPagination
        filter={filter}
        setFilter={setFilter}
        result={data}
      />
    </tbody>
  );
};

export default BillingLogsTableBody;
