import React from "react";
import ServerDetailsConfigData from "./ServerConfig";
import ServerDetailsLocationData from "./ServerLocation";
import ServerDetailsUsageData from "./ServerUsage";

const ServerDetailsInfoSection = (props: { details: HCServerDetails }) => {
  const { details } = props;
  return (
    <div>
      {/* <ServerDetailsInfoBar /> */}
      <div className="row">
        <ServerDetailsConfigData details={details} />
        <ServerDetailsUsageData details={details} />
        <ServerDetailsLocationData details={details} />
      </div>
    </div>
  );
};

export default ServerDetailsInfoSection;
