import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateToUTCString } from "../../../utilities/functions";
import SimpleCheckBox from "../../Utils/FilterTypeCheckBox";

const getOldDate = () => {
  let date = new Date();
  date.setFullYear(date.getFullYear() - 2);
  return date;
};

export const BillingLogsFilter = (props: {
  filter: WalletTransactionFilter;
  setFilter: (filter: WalletTransactionFilter) => void;
}) => {
  const { filter, setFilter } = props;

  const [startDate, setStartDate] = useState<Date | null>(getOldDate());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [changed, setChanged] = useState(false);

  const typeFilterChangeHandler = (value: string) => {
    const index = filter.serviceTypes.indexOf(value);
    let newFilter = { ...filter };
    if (index >= 0) {
      newFilter.serviceTypes.splice(index, 1);
    } else {
      newFilter.serviceTypes.push(value);
    }
    setFilter(newFilter);
    setChanged(true);
  };

  const filterResetHandler = () => {
    setFilter({
      page: 1,
      pageSize: 20,
      serviceTypes: [
        "add_fund",
        "hc_server",
        "hc_snapshot",
        "hc_volume",
        "hc_server_backup",
        "hc_server_traffic",
        "hc_ipv4",
      ],
    });
    setChanged(false);
  };

  const filterSubmitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (!changed) return;
    const limit = evt.currentTarget.logsLimit.value
      ? evt.currentTarget.logsLimit.value
      : 20;

    const newFilters: WalletTransactionFilter = {
      ...filter,
      page: 1,
      pageSize: limit,
      from: dateToUTCString(startDate),
      to: dateToUTCString(endDate),
    };
    setFilter(newFilters);
    setChanged(false);
  };
  const dateChangeHandler = (
    date: Date | [Date | null, Date | null] | null,
    filterType: string
  ) => {
    const newDate = Array.isArray(date) ? date[0] : date ? date : new Date();
    filterType === "start" ? setStartDate(newDate) : setEndDate(newDate);
    setChanged(true);
  };
  return (
    <form onSubmit={filterSubmitHandler}>
      <h4 className="text-center font-weight-semibold">فیلتر نتایج</h4>
      <div className="row justify-content-md-center">
        <div className="col-md-4">
          <div className="form-group">
            <p className="text-center text-muted">از تاریخ:</p>
            <div className="text-center">
              <i className="icon-calendar2 mr-2" />
              <DatePicker
                selected={startDate}
                className={"react-datepicker-ignore-onclickoutside text-center"}
                onChange={(date: Date) => dateChangeHandler(date, "start")}
                dateFormat="yyyy-M-d"
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <p className="text-center text-muted">تا تاریخ:</p>
            <div className="text-center">
              <i className="icon-calendar2 mr-2" />
              <DatePicker
                selected={endDate}
                className={"react-datepicker-ignore-onclickoutside text-center"}
                onChange={(date) => dateChangeHandler(date, "end")}
                dateFormat="yyyy-M-d"
              />
            </div>
          </div>
        </div>
      </div>
      <p className="text-center text-muted">
        (تاریخ مربوط به زمان ایجاد گزارش است نه تاریخ مربوط به سرویس)
      </p>

      <div className="row justify-content-md-center mt-3">
        <div className="col-md-10 text-center">
          <p className="text-center text-muted">نوع گزارش ها</p>
          <SimpleCheckBox
            value="hc_server"
            name="سرور"
            onClick={() => typeFilterChangeHandler("hc_server")}
            checked={filter.serviceTypes.includes("hc_server")}
          />
          <SimpleCheckBox
            value="hc_ipv4"
            name="آی پی ورژن 4"
            onClick={() => typeFilterChangeHandler("hc_ipv4")}
            checked={filter.serviceTypes.includes("hc_ipv4")}
          />
          <SimpleCheckBox
            value="hc_volume"
            name="فضای اضافه"
            onClick={() => typeFilterChangeHandler("hc_volume")}
            checked={filter.serviceTypes.includes("hc_volume")}
          />
          <SimpleCheckBox
            value="hc_snapshot"
            name="اسنپ شات"
            onClick={() => typeFilterChangeHandler("hc_snapshot")}
            checked={filter.serviceTypes.includes("hc_snapshot")}
          />
          <SimpleCheckBox
            value="hc_server_backup"
            name="بک آپ سرور"
            onClick={() => typeFilterChangeHandler("hc_server_backup")}
            checked={filter.serviceTypes.includes("hc_server_backup")}
          />
          <SimpleCheckBox
            value="hc_server_traffic"
            name="ترافیک اضافه"
            onClick={() => typeFilterChangeHandler("hc_server_traffic")}
            checked={filter.serviceTypes.includes("hc_server_traffic")}
          />
          <SimpleCheckBox
            value="add_fund"
            name="افزایش موجودی"
            onClick={() => typeFilterChangeHandler("add_fund")}
            checked={filter.serviceTypes.includes("add_fund")}
          />
        </div>
      </div>
      <div className="row justify-content-md-center mt-3">
        <div className="col-md-2 text-center">
          <div className="form-group text-center">
            <label className="text-muted">تعداد نمایش در صفحه</label>
            <select
              className="form-control form-control-uniform"
              name="logsLimit"
              id="logsLimit"
              onChange={() => setChanged(true)}
            >
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
      <div className="text-center">
        <button
          className="btn bg-primary"
          type="submit"
          style={{ direction: "ltr" }}
        >
          اعمال فیلتر
        </button>
        <button
          className="btn bg-slate mr-1 ml-1"
          type="button"
          style={{ direction: "ltr" }}
          onClick={filterResetHandler}
        >
          ریست فیلتر
        </button>
      </div>
    </form>
  );
};
