import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { fetchTicketStats } from "../../api/ticketing";
import { APIQueryName } from "../../constants/queries";
import routes from "../../constants/routes";
import { numberWithCommas } from "../../utilities/functions";
import SimpleLoader from "../Utils/SimpleLoader";

const UserSupportStats = () => {
  const { data, isLoading } = useQuery(
    APIQueryName.TICKET_STATS,
    fetchTicketStats,
    {
      staleTime: 60000,
      retry: 1,
    }
  );
  return (
    <div className="card card-body mt-3">
      <p className="text-center font-weight-semibold text-muted">پشتیبانی</p>
      <div className="row text-center">
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 p-2 text-center">
          <p className="text-center">
            <Link to={routes.TICKETS}>
              <i className="icon-comment-discussion icon-2x d-inline-block text-slate-600" />
            </Link>
          </p>
          {isLoading && <SimpleLoader />}
          {!isLoading && (
            <h5 className="font-weight-semibold mb-0 text-center persian-number">
              {numberWithCommas(data ? data.total : 0)}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">کل درخواست ها</span>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 p-2 text-center">
          <p className="text-center">
            <Link to={routes.TICKETS}>
              <i className="icon-comment icon-2x d-inline-block text-success-600" />
            </Link>
          </p>
          {isLoading && <SimpleLoader />}
          {!isLoading && (
            <h5 className="font-weight-semibold mb-0 text-center persian-number">
              {numberWithCommas(data ? data.answered : 0)}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">پاسخ داده شده</span>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 p-2 text-center">
          <p className="text-center">
            <Link to={routes.TICKETS}>
              <i className="icon-spinner2 icon-2x d-inline-block text-warning-600" />
            </Link>
          </p>
          {isLoading && <SimpleLoader />}
          {!isLoading && (
            <h5 className="font-weight-semibold mb-0 text-center persian-number">
              {numberWithCommas(data ? data.waiting : 0)}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">در انتظار</span>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 p-2 text-center">
          <p className="text-center">
            <Link to={routes.TICKET_CREATE}>
              <i className="icon-plus2 icon-3x d-inline-block text-info-600" />
            </Link>
          </p>
          <span className="text-muted ml-2 font-size-sm">درخواست جدید</span>
        </div>
      </div>
    </div>
  );
};

export default UserSupportStats;
