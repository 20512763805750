import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { billingState } from "../../../features/billing/billingSlice";
import { numberWithCommas } from "../../../utilities/functions";

const BillingDetailedFutureCosts = () => {
  const { billingMonthlyCosts } = useSelector(billingState);
  return (
    <div className="card col-md-12">
      <div className="card-body mt-3">
        <p className="text-center font-weight-semibold ">
          هزینه ماهانه سرویس های فعلی
        </p>
        <div className="row text-center mt-5">
          <div className="col-3 text-center">
            <p className="text-center">
              <Link to={{ pathname: "/servers" }}>
                <i className="icon-server icon-2x d-inline-block text-indigo-600" />
              </Link>
            </p>

            <h5 className="font-weight-semibold mb-0 text-center persian-number">
              {numberWithCommas(billingMonthlyCosts.servers)}
            </h5>
            <span className=" font-size-sm">سرور ها</span>
          </div>

          <div className="col-3 text-center">
            <p className="text-center">
              <Link to={{ pathname: "/servers/volumes" }}>
                <i className="icon-database-add icon-2x d-inline-block text-orange-600" />
              </Link>
            </p>
            <h5 className="font-weight-semibold mb-0 text-center persian-number">
              {numberWithCommas(billingMonthlyCosts.volumes)}
            </h5>
            <span className=" font-size-sm">فضا های اضافه</span>
          </div>

          <div className="col-3 text-center">
            <p className="text-center">
              <Link to={{ pathname: "/servers/snapshots" }}>
                <i className="icon-copy3 icon-2x d-inline-block text-primary-600" />
              </Link>
            </p>
            <h5 className="font-weight-semibold mb-0 text-center persian-number">
              {numberWithCommas(billingMonthlyCosts.snapshots)}
            </h5>
            <span className="font-size-sm">اسنپ شات ها</span>
          </div>

          <div className="col-3 text-center">
            <p className="text-center">
              <i className="icon-location3 icon-2x d-inline-block text-slate-600" />
            </p>
            <h5 className="font-weight-semibold mb-0 text-center persian-number">
              0
            </h5>
            <span className="font-size-sm">آی پی ها</span>
          </div>
        </div>
        <p className="text-center font-weight-semibold mt-3">
          (تمام قیمت ها به تومان و به صورت تقریبی محاسبه شده اند)
        </p>
      </div>
    </div>
  );
};

export default BillingDetailedFutureCosts;
