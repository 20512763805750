import React from "react";
import {ServerTypeGroup} from "../../../../constants/servers";

const ServerTypeTabs = (props: {
  activeTab: ServerTypeGroup;
  tabs?: ServerTypeGroup[];
  switchHandler: (tab: ServerTypeGroup) => void;
}) => {
  const {tabs, activeTab, switchHandler} = props;

  const handleSelect = (
    evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    group: ServerTypeGroup
  ) => {
    evt.preventDefault();
    switchHandler(group);
  };
  return (
    <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
      <li
        className="nav-item text-center"
        style={{
          display: tabs?.includes(ServerTypeGroup.STANDARD_LOCAL)
            ? "block"
            : "none",
        }}
      >
        <a
          href="#ssd"
          className={`nav-link text-center ${
            activeTab === ServerTypeGroup.STANDARD_LOCAL ? "active" : ""
          }`}
          onClick={(e) => {
            handleSelect(e, ServerTypeGroup.STANDARD_LOCAL);
          }}
        >
          NVMe Disks & Shared x86 vCPU
        </a>
      </li>
      <li
        className="nav-item text-center"
        style={{
          display: tabs?.includes(ServerTypeGroup.ARM)
            ? "block"
            : "none",
        }}
      >
        <a
          href="#arm"
          className={`nav-link text-center ${
            activeTab === ServerTypeGroup.ARM ? "active" : ""
          }`}
          onClick={(e) => {
            handleSelect(e, ServerTypeGroup.ARM);
          }}
        >
          NVMe Disks & Shared ARM64 vCPU
        </a>
      </li>

      <li
        className="nav-item text-center"
        style={{
          display: tabs?.includes(ServerTypeGroup.DEDICATED_LOCAL)
            ? "block"
            : "none",
        }}
      >
        <a
          href="#cpu"
          className={`nav-link text-center ${
            activeTab === ServerTypeGroup.DEDICATED_LOCAL ? "active" : ""
          }`}
          onClick={(e) => {
            handleSelect(e, ServerTypeGroup.DEDICATED_LOCAL);
          }}
        >
          NVMe SSD & Dedicated vCPU
        </a>
      </li>
    </ul>
  );
};

ServerTypeTabs.defaultProps = {
  tabs: [
    ServerTypeGroup.STANDARD_NETWORK,
    ServerTypeGroup.STANDARD_LOCAL,
    ServerTypeGroup.DEDICATED_LOCAL,
  ],
};

export default ServerTypeTabs;
