const routes = {
  HOME: "/",
  USER_PROFILE: "/user",
  FORGET_PASSWORD: "/auth/reset",
  USER_PHONE_VERIFICATION: "/user/phone/verification",
  USER_EMAIL_VERIFICATION: "/user/email/verification",
  USER_PASSWORD_CHANGE: "/user/password/change",
  SSH_KEYS: "/sshkeys",
  AUTHENTICATION: "/auth",
  TICKETS: "/ticketing",
  TICKET_CREATE: "/ticketing/new",
  TICKET_DETAILS: "/ticketing/",
  BILLING: "/billing",
  BILLING_LOGS: "/billing/logs",
  BILLING_ADD_FUND: "/billing/fund",
  BILLING_ADD_FUND_VERIFY: "/billing/fund/verify/",
  SERVERS: "/servers",
  PROJECTS: "/projects",
  PROJECT_SERVERS: (pId: number) => {
    return `/projects/${pId}/servers`;
  },
  PROJECT_SNAPSHOTS: (pId: number) => {
    return `/projects/${pId}/snapshots`;
  },
  PROJECT_VOLUMES: (pId: number) => {
    return `/projects/${pId}/volumes`;
  },
  PROJECT_SSHKEYS: (pId: number) => {
    return `/projects/${pId}/sshkeys`;
  },
  OLD_CREATE_SERVER: "/servers/create",
  CREATE_SERVER: (pId: number) => {
    return `/projects/${pId}/servers/new`;
  },
  NEW_SERVER_INFO: "/servers/create/info",
  SERVER_DETAILS: "/servers/",
  SNAPSHOTS: "/servers/snapshots",
  BACKUPS: "/servers/backup/",
  VOLUMES: "/servers/volumes/",
  WEB_MONITORING_BASE: "/monitoring",
  WEB_MONITORING: "/monitoring/web",
  WEB_MONITORING_REPORT: "/monitoring/web/",
  WEB_MONITORING_ALERT_DETAILS: "/monitoring/alert/web/",
  WEB_MONITORING_CREATE: "/monitoring/web/new",
  WEB_MONITORING_ALERT_CREATE: "/monitoring/alert/new",
  WEB_MONITORING_ALERT_LIST: "/monitoring/alert",
};

export default routes;
