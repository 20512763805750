import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../../constants/routes";
import { useHCServerDelete } from "../../../../mutations/servers";
import Spinner from "../../../Utils/Spinner";

const ServerDeleteSection = (props: { details: HCServerDetails }) => {
  const navigate = useNavigate();
  const { mutate, isLoading, isSuccess } = useHCServerDelete();
  const { details } = props;

  if (!details) return <div></div>;

  const deleteHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (evt.currentTarget.serverName.value !== details.name) return;
    mutate({slug: details.slug, projectId: details.projectId});
  };

  if (isSuccess) {
    navigate(routes.PROJECT_SERVERS(details.projectId));
  }

  return (
    <div className="card">
      <div className="card-body text-center">
        <i className="icon-diff-removed icon-2x text-danger border-danger border-3 rounded-round p-3 mb-3 mt-1"></i>
        <h5 className="card-title text-center">Delete</h5>
        <h6 className="card-title text-center">{details.name}</h6>
        <span className="bg-warning py-1 px-2 rounded">
          <span className="text-white">
            با حذف سرور تمام اطلاعات مربوط به سرور از بین خواهد رفت و تحت هیچ
            شرایطی قابل بازیابی نخواهد بود.
          </span>
        </span>
        <p className="mb-3 mt-2 text-center">
          بعد از حذف سرور دیگر هزینه ای بابت سرور محاسبه نخواهد شد.
        </p>
        <p className="mb-3 text-center">
          برای تایید لطفا نام سرور را در فیلد زیر وارد کرده و سپس برای حذف اقدام
          کنید.
        </p>
        <form className="text-center" onSubmit={deleteHandler}>
          <div className="row">
            <div className="col-lg-5 mx-lg-auto">
              <div className="form-group">
                <input
                  name="serverName"
                  id="serverName"
                  type="text"
                  className="form-control text-center"
                  placeholder={details.name}
                />
              </div>
            </div>
          </div>
          <button
            className="btn bg-danger"
            style={{ direction: "ltr" }}
            disabled={isLoading}
          >
            Delete {isLoading && <Spinner />}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ServerDeleteSection;
