import React from "react";

type Props = {
  on: boolean;
  label?: string;
  disabled?: boolean;
  onChange?: CallableFunction;
  onClick?: CallableFunction;
  inline?: boolean;
  extraClass?: string;
};

export default function SimpleRadio(props: Props) {
  const { on, onChange, label, disabled, onClick, inline, extraClass } = props;

  return (
    <div
      className={`custom-control custom-radio custom-control-secondary ${
        inline ? "custom-control-inline" : ""
      } ${extraClass ? extraClass : ""}`}
    >
      <input
        type="radio"
        className="custom-control-input"
        checked={on}
        disabled={disabled}
        onChange={() => (onChange ? onChange() : null)}
      />
      <label
        className="custom-control-label"
        onClick={() => (onClick ? onClick() : null)}
      >
        {label || ""}
      </label>
    </div>
  );
}

SimpleRadio.defaultProps = {
  label: null,
  disabled: false,
};
