import React from "react";
import { useHCServerConsoleRequest } from "../../../../mutations/servers";
import Spinner from "../../../Utils/Spinner";
import ConsoleCredentials from "./ConsoleCredentials";

const ServerConsoleSection = (props: { slug: string }) => {
  const { slug } = props;
  const { mutate, data, isLoading } = useHCServerConsoleRequest();

  const requestHandler = async () => {
    mutate(slug);
  };
  return (
    <div className="card">
      <div className="card-body text-center">
        <i className="icon-terminal icon-2x text-slate-700 border-slate-700 border-3 rounded-round p-3 mb-3 mt-1" />
        <h5 className="card-title text-center">Request Console</h5>
        <p className="mb-3 text-center">
          پس از دریافت توکن به مدت 1 دقیقه فرصت خواهید داشت تا وارد کنسول شوید.
          رمز عبور نیز به تنهایی اعتباری ندارد و باید همراه با توکن معتبر باشد.
        </p>

        {data && (
          <ConsoleCredentials
            password={data.password}
            url={data.websocketURL}
          />
        )}

        <button
          className="btn bg-slate-700"
          onClick={requestHandler}
          style={{ direction: "ltr" }}
          disabled={isLoading}
        >
          Request Console {isLoading && <Spinner />}
        </button>
      </div>
    </div>
  );
};

export default ServerConsoleSection;
