import Axios, { AxiosResponse } from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getTokenString } from "../../api/auth";
import apiRoutes from "../../constants/apiRoutes";
import routes from "../../constants/routes";
import { addNotification } from "../../features/baseSlice";
import getDefaultHeaders from "../../features/utils";
import { history } from "../../store";
import { authCheckAndRedirect } from "../../utilities/functions";
import { passwordValidator } from "../../utilities/validators";

const PasswordChangeForm = () => {
  const dispatch = useDispatch();

  const [working, setWorking] = useState(false);
  const [valid, setValid] = useState(true);
  const [reValid, setReValid] = useState(true);

  const changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { status } = passwordValidator(evt.currentTarget.value);
    setValid(status);
  };

  const submitErrorHandler = (err: AxiosResponse) => {
    let notification: NotificationProps = {
      type: "error",
      title: "عملیات ناموفق",
      text: "",
    };
    if (err.status === 500) {
      notification.text =
        "متاسفانه مشکلی از سمت ما رخ داده است. لطفا بعدا امتحان کنید.";
    }else {
      notification.text = err.data.message;
    }
    dispatch(addNotification(notification));
  };

  const submitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const oldPassword = evt.currentTarget.oldPassword.value;
    const newPassword = evt.currentTarget.newPassword.value;
    const reNewPassword = evt.currentTarget.reNewPassword.value;

    if (newPassword !== reNewPassword) {
      setReValid(false);
      return;
    }

    const { status } = passwordValidator(newPassword);
    if (!status) return;

    setWorking(true);
    const data = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    Axios.post(apiRoutes.V1.USER.PASSWORD_CHANGE, data, {
      headers: getDefaultHeaders(getTokenString()),
    })
      .then((_) => {
        setWorking(false);
        dispatch(
          addNotification({
            type: "success",
            title: "عملیات موفق",
            text: "رمز عبور با موفقیت تغییر کرد",
          })
        );
        localStorage.removeItem("authToken");
        history.push(routes.AUTHENTICATION);
      })
      .catch((error) => {
        setWorking(false);
        authCheckAndRedirect(error.response);
        submitErrorHandler(error.response);
      });
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="form-group">
        <label>رمز قدیمی:</label>
        <input
          required
          type="password"
          name="oldPassword"
          className="form-control"
        />
      </div>

      <div className="form-group">
        <label>رمز جدید:</label>
        <input
          required
          type="password"
          name="newPassword"
          className="form-control"
          onChange={changeHandler}
        />
      </div>
      {!valid && (
        <span className="form-text text-danger">
          <i className="icon-cancel-circle2 mr-2" />
          رمز انتخاب معتبر نمباشد. طول رمز باید بین ۸ تا ۳۰ و شامل حروف انگلیسی
          همراه با اعداد باشد
        </span>
      )}

      <div className="form-group">
        <label>تکرار رمز جدید:</label>
        <input
          required
          type="password"
          name="reNewPassword"
          className="form-control"
          onChange={() => {
            if (!reValid) setReValid(true);
          }}
        />
      </div>
      {!reValid && (
        <span className="form-text text-danger">
          <i className="icon-cancel-circle2 mr-2" />
          رمز های وارد شده برار نیستند!
        </span>
      )}

      <div className="text-center">
        {!working && (
          <button type="submit" className="btn btn-primary">
            تایید و تغییر رمز
          </button>
        )}
        {working && (
          <button disabled className="btn btn-primary">
            <i className="icon-spinner6 spinner mr-2" /> در حال تغییر رمز ...
          </button>
        )}
      </div>
    </form>
  );
};

export default PasswordChangeForm;
