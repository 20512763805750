import React, { useState } from "react";
import { useSelector } from "react-redux";
import { volumesState } from "../../../features/volumes/volumesSlice";
import Spinner from "../../Utils/Spinner";
import ResizeModal from "./ResizeModal";

const VolumeResize = (props: { volume: HCVolume }) => {
  const { volume } = props;
  const { volumesPendings } = useSelector(volumesState);

  const [modal, setModal] = useState(false);
  const actionKey = "volumeResize" + volume.id;
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm mr-1 ml-1 mt-1"
        onClick={() => setModal(true)}
        style={{ direction: "ltr" }}
        disabled={volumesPendings.includes(actionKey)}
      >
        Resize {volumesPendings.includes(actionKey) && <Spinner />}
      </button>
      {modal && <ResizeModal volume={volume} dismiss={() => setModal(false)} />}
    </>
  );
};

export default VolumeResize;
