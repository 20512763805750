import React, { useState } from "react";

export type AlertProps = {
  alertType: string;
  text: string;
  dismissible?: boolean;
};

const Alert = (props: AlertProps) => {
  const [alertShow, setAlertShow] = useState(true);
  const { alertType, text, dismissible } = props;

  return (
    <div
      className={`alert alert-${alertType} border-0 ${
        dismissible ? "alert-dismissible" : ""
      } col-md-12`}
      style={{ display: !alertShow ? "none" : "" }}
    >
      <button
        type="button"
        className="close"
        onClick={() => setAlertShow(false)}
      >
        {dismissible && <span>×</span>}
      </button>
      {text}
    </div>
  );
};

Alert.defaultProps = {
  dismissible: true,
};

export default Alert;
