import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import { getTokenString } from "../../api/auth";
import {
  jsonToServer,
  jsonToServerDetails,
  jsonToServerImageData,
} from "../../api/mappers";
import apiRoutes from "../../constants/apiRoutes";
import { ServerAction } from "../../constants/servers";
import { authCheckAndRedirect } from "../../utilities/functions";
import { addNotification } from "../baseSlice";
import {
  addImage,
  removeImage,
  switchImageType,
  updateImage,
} from "../serverImages/imagesSlice";
import getDefaultHeaders from "../utils";

export const fetchUserServers = createAsyncThunk(
  "servers/fetchServersList",
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await Axios.get(apiRoutes.V1.SERVERS.GET_USER_SERVERS(), {
        headers: getDefaultHeaders(token),
      });
      const servers: UserServerData[] = response.data.map((server: any) => {
        return jsonToServer(server);
      });
      return servers;
    } catch (error: any) {
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const triggerServerAction = createAsyncThunk(
  "servers/triggerServerAction",
  async (
    props: {
      actionKey: string;
      serverSlug: string;
      actionType: ServerAction;
      postData?: ActionDataValue[];
      getParams?: ActionDataValue[];
    },
    { rejectWithValue, dispatch }
  ) => {
    const { serverSlug, actionType, postData, getParams } = props;
    let data = new FormData();
    if (postData) {
      postData.forEach((item) => {
        data.append(item.key, item.value);
      });
    }
    let url: string;
    if (actionType === ServerAction.REBUILD) {
      url = apiRoutes.V1.SERVERS.REBUILD(serverSlug);
    } else {
      url = apiRoutes.V1.SERVERS.TRIGGER_ACTION(serverSlug, actionType);
    }
    if (getParams) {
      url += "?params=true";
      getParams.forEach(
        (param) => (url += "&" + param.key + "=" + param.value)
      );
    }

    try {
      const response = await Axios.post(url, data, {
        headers: getDefaultHeaders(getTokenString()),
      });
      dispatch(
        addNotification({
          type: "info",
          title: "درخواست " + actionType.toUpperCase(),
          text: "درخواست شما دریافت و در حال اجراست!",
        })
      );
      return response.data.action_id;
    } catch (error: any) {
      dispatch(
        addNotification({
          type: "error",
          title: "درخواست " + actionType.toUpperCase(),
          text: "متاسفانه مشکلی در دریافت درخواست شما رخ داده است!",
        })
      );
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const rescaleServer = createAsyncThunk(
  "servers/rescaleServer",
  async (
    props: {
      actionKey: string;
      serverSlug: string;
      newServerType: number;
      upgradeDisk: "true" | "false";
    },
    { rejectWithValue, dispatch }
  ) => {
    const { serverSlug, newServerType, upgradeDisk } = props;
    let data = new FormData();
    data.append("upgrade_disk", upgradeDisk);
    data.append("server_type", newServerType.toString());
    try {
      const response = await Axios.post(
        apiRoutes.V1.SERVERS.RESCALE(serverSlug),
        data,
        {
          headers: getDefaultHeaders(getTokenString()),
        }
      );
      dispatch(
        addNotification({
          type: "info",
          title: "درخواست تغییر پلن سرور",
          text: "درخواست شما دریافت و در حال اجراست!",
        })
      );
      return response.data.action_id;
    } catch (error: any) {
      if (
        error.response.status === 400 &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error === "balance_not_enough"
      ) {
        dispatch(
          addNotification({
            type: "error",
            title: "درخواست تغییر پلن سرور",
            text: "اعتبار شما کافی نمیباشد!",
          })
        );
      } else {
        dispatch(
          addNotification({
            type: "error",
            title: "درخواست تغییر پلن سرور",
            text: "متاسفانه مشکلی در دریافت درخواست شما رخ داده است!",
          })
        );
      }

      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const fetchServerDetails = createAsyncThunk(
  "servers/fetchServerDetails",
  async (serverSlug: string, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        apiRoutes.V1.SERVERS.GET_SERVER_DETAILS + serverSlug + "/details/",
        {
          headers: getDefaultHeaders(getTokenString()),
        }
      );
      return jsonToServerDetails(response.data);
    } catch (error: any) {
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const updateServerImage = createAsyncThunk(
  "servers/updateServerImage",
  async (
    context: {
      actionKey: string;
      imageId: number;
      description?: string;
      changeToSnapshot?: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    let data = new FormData();
    context.description && data.append("description", context.description);
    context.changeToSnapshot && data.append("type", "snapshot");
    try {
      const response = await Axios.post(
        apiRoutes.V1.IMAGES.UPDATE(context.imageId),
        data,
        {
          headers: getDefaultHeaders(getTokenString()),
        }
      );
      dispatch(
        addNotification({
          type: "success",
          title: "بروزرسانی ایمیج",
          text: "ایمیج مورد نظر با موفقیت بروزرسانی شد!",
        })
      );
      const image = jsonToServerImageData(response.data.image);
      if (context.changeToSnapshot) {
        dispatch(switchImageType(image));
      } else {
        dispatch(updateImage(image));
      }

      return image;
    } catch (error: any) {
      dispatch(
        addNotification({
          type: "error",
          title: "بروزرسانی ایمیج",
          text: "متاسفانه مشکلی در بروزرسانی ایمیج رخ داده است!",
        })
      );
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response ? error.response.status : null,
        data: error.response ? error.response.data : null,
      });
    }
  }
);

export const deleteServerImage = createAsyncThunk(
  "servers/deleteServerImage",
  async (
    context: {
      actionKey: string;
      imageId: number;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await Axios.post(
        apiRoutes.V1.IMAGES.DELETE_IMAGE(context.imageId),
        {},
        {
          headers: getDefaultHeaders(getTokenString()),
        }
      );
      dispatch(
        addNotification({
          type: "success",
          title: "درخواست حذف ایمیج",
          text: "ایمیج مورد نظر با موفقیت حذف شد!",
        })
      );
      dispatch(removeImage(context.imageId));
      return context.imageId;
    } catch (error: any) {
      dispatch(
        addNotification({
          type: "error",
          title: "درخواست حذف ایمیج",
          text: "متاسفانه مشکلی در انجام درخواست شما رخ داده است!",
        })
      );
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response ? error.response.status : null,
        data: error.response ? error.response.data : null,
      });
    }
  }
);

export const createServerSnapshot = createAsyncThunk(
  "servers/createServerSnapshot",
  async (
    context: {
      actionKey: string;
      serverSlug: string;
      description?: string;
      backup?: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    let data = new FormData();
    context.description && data.append("description", context.description);
    context.backup && data.append("type", "backup");
    try {
      const response = await Axios.post(
        apiRoutes.V1.SERVERS.CREATE_SNAPSHOT(context.serverSlug),
        data,
        {
          headers: getDefaultHeaders(getTokenString()),
        }
      );
      dispatch(
        addNotification({
          type: "success",
          title: context.backup ? "ایجاد بک آپ" : "ایجاد اسنپ شات",
          text: context.backup
            ? "ایجاد بک آپ با موفقیت انجام شد"
            : "ایجاد اسنپ شات با موفقیت انجام شد ",
        })
      );
      const image = jsonToServerImageData(response.data.image);
      dispatch(addImage(image));
      return image;
    } catch (error: any) {
      dispatch(
        addNotification({
          type: "error",
          title: context.backup
            ? "خطا در ایجاد بک آپ"
            : "خطا در ایجاد اسنپ شات",
          text: context.backup
            ? "ایجاد بک آپ با خطا روبرو شد"
            : "ایجاد اسنپ شات با خطا روبرو شد",
        })
      );
      authCheckAndRedirect(error.response);
      return rejectWithValue({
        status: error.response ? error.response.status : null,
        data: error.response ? error.response.data : null,
      });
    }
  }
);
