import React, { useState } from "react";
import SimpleRadio from "../../Utils/SimpleRadio";

const FileSystemOptions = () => {
  const [fileSystem, setFileSystem] = useState("ext4");

  return (
    <div>
      <p className="text-center">انتخاب فایل سیستم:</p>
      <div className="form-group row justify-content-md-center">
        <input
          type="hidden"
          name="fileSystem"
          id="fileSystem"
          value={fileSystem}
        />
        <SimpleRadio
          on={fileSystem === "ext4"}
          label="ext4"
          onClick={() => setFileSystem("ext4")}
          inline
        />
        <SimpleRadio
          on={fileSystem === "xfs"}
          label="xfs"
          onClick={() => setFileSystem("xfs")}
          inline
        />
      </div>
    </div>
  );
};

export default FileSystemOptions;
