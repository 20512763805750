import axios from "axios";
import apiRoutes from "../constants/apiRoutes";
import getDefaultHeaders from "../features/utils";
import { authCheckAndRedirect } from "../utilities/functions";
import { getTokenString } from "./auth";
import { jsonToProject, jsonToUserServicesStats } from "./mappers";

export const fetchProjects = async () => {
  try {
    const { data } = await axios.get(apiRoutes.V1.PROJECTS.LIST, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const projects: Project[] = data.map((key: any) => jsonToProject(key));
    return projects;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const fetchProject = async (id: number) => {
  try {
    const { data } = await axios.get(apiRoutes.V1.PROJECTS.DETAILS(id), {
      headers: getDefaultHeaders(getTokenString()),
    });
    const project: Project = jsonToProject(data);
    return project;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const fetchUserServicedsStats = async () => {
  try {
    const { data } = await axios.get(apiRoutes.V1.PROJECTS.STATS, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const stats: UserServicesStats = jsonToUserServicesStats(data);
    return stats;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
}

export const createProject = async (payload: { name: string }) => {
  try {
    const { data } = await axios.post(apiRoutes.V1.PROJECTS.CREATE, payload, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: Project = jsonToProject(data);
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const updateProject = async (payload: { name: string; id: number }) => {
  try {
    const { data } = await axios.put(
      apiRoutes.V1.PROJECTS.UPDATE(payload.id),
      payload,
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    const res: Project = jsonToProject(data);
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const deleteProject = async (id: number) => {
  try {
    const { data } = await axios.delete(apiRoutes.V1.PROJECTS.DELETE(id), {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: SimpleResponse = { status: data.status };
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};
