import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  cls?: string;
};

const Content = (props: Props) => {
  const { children, cls } = props;
  return (
    <div className="content-inner">
      <div className={`content ${cls ? cls : ""}`}>{children}</div>
    </div>
  );
};

export default Content;
