import React, { useState } from "react";
import { useQuery } from "react-query";
import { fetchTicketDepartments } from "../../../api/ticketing";
import { APIQueryName } from "../../../constants/queries";

const TicketDepartmentsSelect = () => {
  const { data } = useQuery(
    APIQueryName.TICKET_DEPARTMENTS,
    fetchTicketDepartments,
    {
      staleTime: 60000,
      retry: 1,
    }
  );

  const [selectedDepartment, setSelectedDepartment] = useState("");

  return (
    <select
      name="department"
      id="department"
      className="form-control"
      value={selectedDepartment}
      onChange={(evt) => setSelectedDepartment(evt.target.value)}
    >
      {data?.map((department) => (
        <option value={department.id} key={"department" + department.id}>
          {department.title}
        </option>
      ))}
    </select>
  );
};

export default TicketDepartmentsSelect;
