import React, { useState } from "react";

const ServerRescaleRAMAndCPUOnly = () => {
  const [checked, setChecked] = useState("true");
  return (
    <div
      className={`card card-body ${
        checked === "true" ? "bg-primary" : "bg-light"
      }`}
      onClick={() => setChecked(checked === "true" ? "false" : "true")}
      style={{ cursor: "pointer" }}
    >
      <div className="media">
        <div className="ml-1 mr-2 align-self-center">
          <div className="custom-control custom-checkbox custom-control-inline">
            <input
              name="cpuRamOnly"
              id="cpuRamOnly"
              type="checkbox"
              className="custom-control-input"
              value={checked}
              checked={checked === "true"}
              onChange={(evt) => evt.preventDefault()}
            />
            <label className="custom-control-label" />
          </div>
        </div>
        <div className="media-body">
          <h6 className="media-title font-weight-semibold">
            تنها تغییر CPU و Ram سرور (برای کاهش پلن در آینده)
          </h6>
          <span className="text-muted">
            در صورتی که در حال ارتقاء سرور هستید و در نظر دارید در آینده این
            سرور را کاهش دهید، لطفا این تیک را بزنید تا دیسک سرور تغییر نکند.
          </span>
        </div>
      </div>
    </div>
  );
};

export default ServerRescaleRAMAndCPUOnly;
