import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchProject } from "../../api/projects";
import ProjectTabs from "../../components/Projects/ProjectTabs";
import { APIQueryName } from "../../constants/queries";
import Content from "../../containers/Content";
import Volumes from "../Volumes";

const ProjectVolumes = () => {
  const params = useParams();
  const projectId = parseInt(params.projectId || "0");

  const { data } = useQuery(
    APIQueryName.PROJECT_DETAILS + projectId,
    () => fetchProject(projectId),
    {
      staleTime: 120000,
      retry: 1,
    }
  );

  return (
    <Content>
      {data && (
        <div className="mt-3 mb-4">
          <h4 className="text-center">{data.name}</h4>
        </div>
      )}
      <ProjectTabs projectId={projectId} />
      <Volumes projectId={projectId} />
    </Content>
  );
};

export default ProjectVolumes;
