import axios from "axios";
import apiRoutes from "../constants/apiRoutes";
import getDefaultHeaders from "../features/utils";
import { authCheckAndRedirect } from "../utilities/functions";
import { getTokenString } from "./auth";
import { jsonToHCImage } from "./mappers";

export const fetchHCImages = async (
  types: string[],
  serverSlug?: string,
  projectId?: number
) => {
  const { data } = await axios.get(
    apiRoutes.V1.IMAGES.LIST(types, serverSlug, projectId),
    {
      headers: getDefaultHeaders(getTokenString()),
    }
  );
  const images: HCImage[] = data.map((image: any) => jsonToHCImage(image));
  return images;
};

export const deleteSnapshot = async (id: number) => {
  try {
    await axios.delete(apiRoutes.V1.IMAGES.DELETE(id), {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: SimpleResponse = { status: "OK" };
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const updateSnapshot = async (
  id: number,
  description: string,
  type: string
) => {
  try {
    const { data } = await axios.patch(
      apiRoutes.V1.IMAGES.UPDATE(id),
      { description: description, type: type },
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    const res: HCImage = jsonToHCImage(data);
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};
