import React from "react";
import { useCloseTicket } from "../../../mutations/ticketing";
import { datetimeToString } from "../../../utilities/functions";
import TicketRowPrioritySpan from "../TicketRowPrioritySpan";
import TicketRowStatusSpan from "../TicketRowStatusSpan";

const TicketDetailsSidebar = (props: { ticketDetails: Ticket }) => {
  const { ticketDetails } = props;

  const { mutate } = useCloseTicket();

  return (
    <div className="col-md-4">
      <div className="card">
        <div className="card-header bg-transparent header-elements-inline">
          <span className="text-uppercase font-size-sm font-weight-bold">
            اطلاعات
          </span>
          <div className="header-elements">
            {ticketDetails && !ticketDetails.closed && (
              <button
                type="button"
                className="btn bg-blue"
                onClick={() => mutate(ticketDetails.slug)}
              >
                بستن درخواست
              </button>
            )}
          </div>
        </div>

        <table className="table table-borderless table-xs my-2">
          <tbody>
            <tr>
              <td>
                <i className="icon-section mr-2" /> بخش:
              </td>
              <td className="text-right">
                <span className="badge badge-flat border-grey text-grey-600">
                  {ticketDetails ? ticketDetails.department.title : ""}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <i className="icon-warning mr-2" /> اهمیت:
              </td>
              <td className="text-right">
                <div className="btn-group">
                  {ticketDetails && (
                    <TicketRowPrioritySpan priority={ticketDetails.priority} />
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <i className="icon-price-tag3 mr-2" /> وضعیت:
              </td>
              <td className="text-right">
                <div className="btn-group">
                  {ticketDetails && (
                    <TicketRowStatusSpan status={ticketDetails.status} />
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <i className="icon-alarm-add mr-2" /> زمان ثبت:
              </td>
              <td
                className="text-right text-muted"
                style={{ direction: "ltr" }}
              >
                {ticketDetails ? datetimeToString(ticketDetails.created) : ""}
              </td>
            </tr>
            <tr>
              <td>
                <i className="icon-comment-discussion mr-2" /> تعداد پاسخ ها:
              </td>
              <td className="text-right text-muted">
                {ticketDetails ? ticketDetails.replies.length : ""}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TicketDetailsSidebar;
