import React from "react";
import { useSelector } from "react-redux";
import { serversState } from "../../../features/userServers/serverSlice";
import ServerDetailsBackupSection from "./Backup/BackupSection";
import ServerConsoleSection from "./Console/ConsoleSection";
import ServerDetailsControlSection from "./Control/ControlSection";
import ServerDeleteSection from "./Delete/DeleteSection";
import ServerDetailsInfoSection from "./Info/InfoSection";
import ServerDetailsISOSection from "./ISO/ISOSection";
import ServerRebuildSection from "./Rebuild/RebuildSection";
import ServerRescaleSection from "./Rescale/RescaleSection";
import ServerDetailsRescueSection from "./Rescue/RescueSection";
import ServerDetailsSnapshotSection from "./Snapshot/SnapshotSection";
import ServerDetailsVolumeSection from "./Volume/VolumeSection";

const ServerDetailsContent = (props: { details: HCServerDetails }) => {
  const { details } = props;
  const { serverDetailsActiveTab } = useSelector(serversState);

  switch (serverDetailsActiveTab) {
    case "info":
      return <ServerDetailsInfoSection details={details} />;
    case "power":
      return <ServerDetailsControlSection details={details} />;
    case "backup":
      return <ServerDetailsBackupSection details={details} />;
    case "snapshot":
      return <ServerDetailsSnapshotSection details={details} />;
    case "rescue":
      return <ServerDetailsRescueSection details={details} />;
    case "iso":
      return <ServerDetailsISOSection details={details} />;
    case "rebuild":
      return <ServerRebuildSection details={details} />;
    case "volume":
      return <ServerDetailsVolumeSection details={details} />;
    case "rescale":
      return <ServerRescaleSection details={details} />;
    case "console":
      return <ServerConsoleSection slug={details.slug} />;
    case "delete":
      return <ServerDeleteSection details={details} />;
    default:
      return <div></div>;
  }
};

export default ServerDetailsContent;
