import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ResetPasswordForm from "../../components/Auth/ResetPasswordForm";
import apiRoutes from "../../constants/apiRoutes";
import Content from "../../containers/Content";
import ContentWrapper from "../../containers/ContentWrapper";
import PageContent from "../../containers/PageContent";

const PasswordResetForm = () => {
  const { key } = useParams()

  const [validating, setValidating] = useState(true);
  const [keyValid, setKeyValid] = useState(false);

  useEffect(() => {
    Axios.get(apiRoutes.V1.USER.PASSWORD_RESET_KEY_VALIDATION(key || ""))
      .then((_) => {
        setValidating(false);
        setKeyValid(true);
      })
      .catch((_) => {
        setValidating(false);
        setKeyValid(false);
      });
  }, [key]);

  return (
    <PageContent cls="login-cover">
      <ContentWrapper>
        <Content cls="d-flex justify-content-center align-items-center">
          {!validating && !keyValid && (
            <div className="text-center">
              <div className="text-center">
                <i className="icon-cancel-square2 icon-5x text-danger" />
                <h3 className="mt-4">
                  کلید شما معتبر نمیباشد یا تاریخ مصرف آن گذشته است!
                </h3>
              </div>
            </div>
          )}
          {!validating && keyValid && (
            <div className="card">
              <div className="card-body">
                <ResetPasswordForm resetKey={key || ""} />
              </div>
            </div>
          )}
        </Content>
      </ContentWrapper>
    </PageContent>
  );
};

export default PasswordResetForm;
